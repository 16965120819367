import { useParams } from 'react-router-dom'
import DataFormNew from '../../../components/common/DataFormNew'
import useAuth from '../../../common/hooks/useAuth'
import SectionContainer from '../../../components/common/SectionContainer'

export default function EditOrganization() {
    const { organizationId } = useParams()
    const auth = useAuth()

    const inputs = [
        {
            key: 'name',
            label: 'Name',
            disabled: true,
        },
    ]

    function mapItemToData(item) {
        return {
            name: item.name,
        }
    }

    return (
        <SectionContainer>
            <DataFormNew
                url={`/v1/organizations/${organizationId}?fields=name`}
                method="PATCH"
                submitText="Save"
                submitToast="Saved"
                submitNavArg={'/contacts'}
                inputs={inputs}
                getBody={(data) => {
                    const body = { ...data }
                    delete body.name
                    return body
                }}
                mapItemToData={mapItemToData}
                mutationRegexes={[
                    /\/v1\/contacts/,
                    /\/v1\/users\/me\/profile-completion/,
                ]}
                mainButton
                onSuccess={async () =>
                    auth.isClient && (await auth.refetchAuth())
                }
            />
        </SectionContainer>
    )
}
