import useAuth from '../../../common/hooks/useAuth'
import Break from '../../../components/common/Break'
import Button from '../../../components/common/Button'
import MessageSection from '../../../components/common/MessageSection'
import SectionContainer from '../../../components/common/SectionContainer'
import TabsOverview from '../../../components/navigation/TabsOverview'
import CreateDonation from './CreateDonation'
import DonationSubsList from './DonationSubsList'

export default function DonationsOverview() {
    const auth = useAuth()
    const pages = {
        donate: <CreateDonation />,
        my_donations: <DonationSubsList />,
    }

    if (auth.isClient && !auth.isPremiumUser) {
        pages.premium = (
            <SectionContainer>
                <MessageSection type="info">
                    Upgrade to Premium
                    <Break />
                    <Button
                        small
                        outline
                        white
                        href={`/profile/billing/purchase-premium`}
                        text="Upgrade now"
                    />
                </MessageSection>
            </SectionContainer>
        )
    }

    const defaultTab = 'donate'

    return <TabsOverview defaultTab={defaultTab} pagesMap={pages} />
}
