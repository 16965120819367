import { OTHER_CONTACT_DETAILS_FIELDS } from '../../common/constants'
import InlineStack from '../../components/common/InlineStack'
import InlineStackItem from '../../components/common/InlineStackItem'

export default function Social({ contactDetails }) {
    const entries = Object.entries(OTHER_CONTACT_DETAILS_FIELDS).filter(
        ([key]) => contactDetails?.[key],
    )

    if (entries.length === 0) {
        return '-'
    }

    return entries.map(([key, { label }]) => (
        <InlineStack key={label} gap="sm">
            <InlineStackItem>
                <div className="text-subdued">{label}:</div>
            </InlineStackItem>
            <div>{contactDetails[key]}</div>
        </InlineStack>
    ))
}
