import { useParams } from 'react-router-dom'
import { formatDate } from '../../../common/helpers'
import ResourceList from '../../../components/admin/ResourceList'
import BlockStack from '../../../components/common/BlockStack'
import InlineStack from '../../../components/common/InlineStack'
import Tag from '../../../components/common/Tag'
import Grid from '../../../components/common/Grid'

function SubsList(props) {
    const { userId, source } = useParams()
    return (
        <ResourceList
            smallTitle
            renderMainButton={props.renderMainButton === false ? false : true}
            apiUrl={'/payment/clients/:userId/subscriptions'}
            getItemsFromResponse={(data) => data?.subscriptions}
            mapItems={(sub, response) => ({
                ...sub,
                email: response.email,
            })}
            newItemPath={'/users/:userId/subscriptions/new'}
            title={'Subscriptions'}
            itemClickPath={'/users/:userId/subscriptions/:item.source/:item.id'}
            fields={[
                {
                    column: 1,
                    getValue: (item) => (
                        <Grid cols={2}>
                            <BlockStack gap={'tiny'}>
                                {!userId && <div>{item.email}</div>}
                                <h3
                                    className={`m-0 ${`${item.status === 'active' ? 'success' : ''} ${
                                        item.status === 'canceled'
                                            ? 'alert'
                                            : ''
                                    }`}`}
                                >
                                    {item.items
                                        .map(
                                            (subItem) =>
                                                subItem.servicePrice
                                                    .marketingService.title,
                                        )
                                        .join(', ')}
                                </h3>

                                <div>
                                    <Tag outline>
                                        {item.source === 'manual'
                                            ? 'Manual billing'
                                            : item.source}
                                    </Tag>
                                </div>
                            </BlockStack>
                            {!!item.canceledAt && (
                                <BlockStack gap={'tiny'}>
                                    <div>{`Canceled ${formatDate(item.canceledAt)}`}</div>
                                </BlockStack>
                            )}
                        </Grid>
                    ),
                },
            ]}
        />
    )
}

export default SubsList
