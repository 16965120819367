import fetchAPI from '../../../common/fetchAPI'
import { formatDateTime, getUserDisplayName } from '../../../common/helpers'
import useAuth from '../../../common/hooks/useAuth'
import useModal from '../../../common/hooks/useModal'
import useToast from '../../../common/hooks/useToast'
import ResourceList from '../../../components/admin/ResourceList'
import Break from '../../../components/common/Break'
import Button from '../../../components/common/Button'
import Chat from '../../../components/common/Chat'
import InlineStack from '../../../components/common/InlineStack'
import InputModalContent from '../../../components/common/InputModalContent'
import SectionContainer from '../../../components/common/SectionContainer'

export default function ChatsList() {
    const { setModal, setModalLoading, isModalLoading } = useModal()
    const setToast = useToast()
    const auth = useAuth()

    async function onConfirmNewChat(email) {
        if (isModalLoading) return
        setModalLoading(true)
        const { error } = await fetchAPI(
            `/v1/users/chats/initiate-chat`,
            { email },
            'POST',
        )
        setModalLoading(false)
        if (error) {
            setToast(error, 'alert')
            setModal(null)
            return
        }
        setToast('Invite sent')
        setModal(null)
    }

    function onNewChat() {
        setModal(
            <InputModalContent
                topHtml={
                    <span className="text-subdued">
                        {`If the user exists, they will receive a notification to
                        accept the chat.`}
                    </span>
                }
                label="User email"
                type="email"
                confirmText="OK"
                onCancel={() => {
                    setModal(null)
                }}
                onConfirm={async (data) => {
                    onConfirmNewChat(data.text)
                }}
            />,
            'User email',
            'modal-full',
        )
    }

    return (
        <>
            <SectionContainer>
                <InlineStack justifyEnd>
                    <Button small text={'New chat'} onClick={onNewChat} />
                </InlineStack>
            </SectionContainer>
            <ResourceList
                revalidateInterval={1000 * 30}
                paginated
                paginationLimit={20}
                emptyText={'No chats yet'}
                getItemsFromResponse={(data) => data.results}
                apiUrl={`/users/me/chats`}
                fields={[
                    {
                        column: 1,
                        getValue: (item) => {
                            const otherUser = item.users.find(
                                (u) => u.id !== auth.user.id,
                            )
                            return (
                                <div
                                    role="button"
                                    onClick={() =>
                                        setModal(
                                            <Chat
                                                roomType="direct"
                                                otherUserIds={[otherUser.id]}
                                            />,
                                            getUserDisplayName(otherUser),
                                            'modal-full',
                                        )
                                    }
                                >
                                    <InlineStack gap={'sm'} spaceBetween>
                                        <span>
                                            {getUserDisplayName(otherUser)}
                                        </span>
                                        <span>
                                            {formatDateTime(
                                                item.lastMessage?.createdAt,
                                                false,
                                                true,
                                            )}
                                        </span>
                                    </InlineStack>
                                    <Break />
                                    <span className="text-subdued">
                                        {item.lastMessage?.messageType ===
                                        'text'
                                            ? item.lastMessage.content
                                            : null}
                                        {item.lastMessage?.messageType ===
                                        'image'
                                            ? 'Image'
                                            : null}
                                    </span>
                                </div>
                            )
                        },
                    },
                ]}
            />
        </>
    )
}
