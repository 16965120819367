import BlockStack from '../../components/common/BlockStack'

export default function Notes({ externalNotes, internalNotes, notes }) {
    return (
        <>
            <BlockStack gap={'md'}>
                {!!internalNotes && (
                    <BlockStack gap={'tiny'}>
                        <h3 className="h6 text-subdued m-0">Internal notes</h3>
                        <pre className="condensed">{internalNotes || '-'}</pre>
                    </BlockStack>
                )}
                {!!externalNotes && (
                    <BlockStack gap={'tiny'}>
                        <h3 className="h6 text-subdued m-0">External notes</h3>
                        <pre className="condensed">{externalNotes || '-'}</pre>
                    </BlockStack>
                )}
                {!!notes && (
                    <BlockStack gap={'tiny'}>
                        <h3 className="h6 text-subdued m-0">Notes</h3>
                        <pre className="condensed">{notes || '-'}</pre>
                    </BlockStack>
                )}
            </BlockStack>
        </>
    )
}
