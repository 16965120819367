import { useState } from 'react'
import Input from './data-form/Input'
import useData from '../../common/hooks/useData'
import Grid from './Grid'
import BlockStack from './BlockStack'
import ScrollableContent from './ScrollableContent'
import CheckboxInput from './data-form/CheckboxInput'
import Button from './Button'
import ErrorMessage from './ErrorMessage'
import Spinner from './Spinner'
import fetchAPI from '../../common/fetchAPI'
import useToast from '../../common/hooks/useToast'
import StorageFile from './StorageFile'
import InlineStack from './InlineStack'

function downsizeImage(file, downsizeToWidth) {
    return new Promise(function (resolve, reject) {
        const reader = new FileReader()
        reader.onload = function (e) {
            const img = new Image()
            img.onload = function () {
                const aspectRatio = img.height / img.width
                const canvas = document.createElement('canvas')
                canvas.width = downsizeToWidth
                canvas.height = downsizeToWidth * aspectRatio
                const ctx = canvas.getContext('2d')
                ctx.drawImage(img, 0, 0, canvas.width, canvas.height)
                canvas.toBlob((blob) => {
                    resolve(blob)
                }, file.type)
            }
            img.src = e.target.result
        }
        reader.onerror = reject
        reader.readAsDataURL(file)
    })
}

function getImageDimensions(file) {
    return new Promise(function (resolve) {
        const reader = new FileReader()
        reader.onload = function (e) {
            const img = new Image()
            img.onload = function () {
                resolve({
                    width: img.width,
                    height: img.height,
                })
            }
            img.src = e.target.result
        }
        reader.readAsDataURL(file)
    })
}

function FilePickerModal({
    onClose,
    onConfirm,
    maxFiles,
    downsizeToWidth,
    accept,
    confirmLabel,
}) {
    const [selectedFileIds, setSelectedFileIds] = useState([])
    const [uploadLoading, setUploadLoading] = useState(null)
    const [inputKey, _setInputKey] = useState(Date.now())
    const [isOptionChecked, setIsOptionChecked] = useState(
        localStorage.getItem('file_picker_submit_on_pick') === 'true',
    )
    const setToast = useToast()
    const { files, filesError, filesLoading, filesMutate, filesValidating } =
        useData(
            `/v1/files/list/me`,
            'files',
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            true,
        )

    function handleCheckboxChange(id) {
        setSelectedFileIds((prev) => {
            if (prev.includes(id)) {
                return prev.filter((i) => i !== id)
            } else {
                if (prev.length >= maxFiles) {
                    return [...prev.slice(1), id]
                } else {
                    return [...prev, id]
                }
            }
        })
    }

    async function onUpload(files) {
        if (!files?.length) {
            return
        }

        const finalFiles = [...files]
        if (downsizeToWidth) {
            for (let i = 0; i < finalFiles.length; i++) {
                const file = finalFiles[i]
                if (!file.type.startsWith('image')) {
                    continue
                }
                const downsizeBlob = await downsizeImage(file, downsizeToWidth)
                finalFiles[i] = new File([downsizeBlob], file.name, {
                    type: file.type,
                })
            }
        }

        setUploadLoading(true)

        const fileDimensions = await Promise.all(
            finalFiles.map(async (file) => {
                if (!file.type.startsWith('image')) {
                    return null
                }
                const { width, height } = await getImageDimensions(file)
                return { width, height }
            }),
        )

        const { responseData: urlResponseData, error: urlError } =
            await fetchAPI(
                `/v1/files/list/me`,
                {
                    files: finalFiles.map((file, i) => ({
                        fileName: file.name,
                        width: fileDimensions[i]?.width,
                        height: fileDimensions[i]?.height,
                        mimeType: file.type,
                        size: file.size,
                    })),
                },
                'POST',
            )

        if (urlError) {
            setToast(urlError, 'alert')
            return
        }

        const finalUrls = []

        for (let i = 0; i < finalFiles.length; i++) {
            const file = finalFiles[i]
            try {
                const dimensions = fileDimensions[i]
                const { uploadUrl, dbId } = urlResponseData[i]
                const uploadResponse = await fetch(uploadUrl, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': file.type,
                        'x-goog-meta-filename': file.name,
                        ...(dimensions
                            ? {
                                  'x-goog-meta-width': dimensions.width,
                                  'x-goog-meta-height': dimensions.height,
                              }
                            : {}),
                    },
                    body: file,
                })
                if (!uploadResponse?.ok) {
                    throw new Error('File upload failed')
                }
                const { responseData, error } = await fetchAPI(
                    `/v1/files/list/me/${dbId}/make-public`,
                    {},
                    'POST',
                )

                if (error) {
                    throw new Error('File make public failed')
                }
                finalUrls.push(responseData.publicUrl)
            } catch (error) {
                console.error(error)
                setToast(`File upload failed for ${file.name}`, 'alert')
            }
        }

        filesMutate()
        setUploadLoading(false)
        setToast('Uploaded successfully')

        if (isOptionChecked) {
            const backendFileItems = finalUrls.map((url, i) => ({
                // TODO: non-public etc.
                url,
                publicUrl: url,
                dbId: urlResponseData[i]?.dbId,
                id: url,
                height: fileDimensions[i]?.height,
                width: fileDimensions[i]?.width,
                mimeType: finalFiles[i].type,
                name: finalFiles[i].name,
            }))
            onConfirm(backendFileItems)
        }
    }

    function handleClose() {
        onClose()
    }

    function handleConfirm() {
        onConfirm(files.filter(({ dbId }) => selectedFileIds.includes(dbId)))
    }

    function handleOptionChange(v) {
        localStorage.setItem('file_picker_submit_on_pick', v)
        setIsOptionChecked(v)
    }

    return (
        <div className="modal file-picker-modal">
            <div className={`modal-container show`}>
                <div className="modal-header">
                    <h4>Pick a file</h4>

                    <InlineStack gap={'tiny'}>
                        <CheckboxInput
                            small
                            id="use-on-input"
                            label={'Submit on pick'}
                            checked={isOptionChecked}
                            onChange={handleOptionChange}
                        />
                        <button
                            className="modal-close-btn"
                            onClick={handleClose}
                        >
                            &#10005;
                        </button>
                    </InlineStack>
                </div>
                <div className="modal-content">
                    <Input
                        key={inputKey}
                        label={'Upload a file'}
                        type="file"
                        id="form-attachment-browser"
                        multiple
                        accept={accept || '*'}
                        onChange={(files) => onUpload([...files])}
                    />

                    {/* <Break /> */}
                    <ScrollableContent>
                        {Boolean(filesError) && (
                            <ErrorMessage>{filesError}</ErrorMessage>
                        )}
                        {(filesLoading || filesValidating || uploadLoading) && (
                            <Spinner marginBottom />
                        )}

                        <Grid cols={2}>
                            {(files || []).map((file) => (
                                <BlockStack gap={'tiny'} key={file.dbId}>
                                    <CheckboxInput
                                        label={file.name}
                                        hideLabel
                                        noBr
                                        value={selectedFileIds.includes(
                                            file.dbId,
                                        )}
                                        onChange={() =>
                                            handleCheckboxChange(file.dbId)
                                        }
                                    />
                                    <div
                                        onClick={() =>
                                            handleCheckboxChange(file.dbId)
                                        }
                                    >
                                        <StorageFile {...file} />
                                        {/* <PrivateFile driveFileId={id} /> */}
                                    </div>
                                    <small className="text-subdued">
                                        {file.name}
                                    </small>
                                </BlockStack>
                            ))}
                        </Grid>
                    </ScrollableContent>

                    {Boolean(selectedFileIds.length) && (
                        <Button
                            noMargin
                            text={confirmLabel || 'Select'}
                            onClick={handleConfirm}
                        />
                    )}
                </div>
            </div>
            <div className="modal-overlay"></div>
        </div>
    )
}

export default function useFilePicker() {
    const [pickerHtml, setPickerHtml] = useState(false)

    function openPicker({
        maxFiles,
        isPublic,
        confirmLabel,
        accept,
        downsizeToWidth,
    } = {}) {
        return new Promise(function (resolve) {
            const pickerHtml = (
                <FilePickerModal
                    confirmLabel={confirmLabel}
                    isPublic={isPublic}
                    maxFiles={maxFiles}
                    accept={accept}
                    downsizeToWidth={downsizeToWidth}
                    onClose={() => {
                        resolve()
                        setPickerHtml(null)
                    }}
                    onConfirm={(results) => {
                        resolve(results)
                        setPickerHtml(null)
                    }}
                />
            )

            setPickerHtml(pickerHtml)
        })
    }

    return {
        openPicker,
        pickerHtml,
    }
}
