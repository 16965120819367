export default function ActionItem({
    children,
    disabled,
    loading,
    type,
    color,
    icon,
    onClick,
    onMouseUp,
}) {
    let className = 'action-item'
    if (disabled) className += ' disabled'
    if (loading) className += ' loading'
    if (type === 'alert') className += ' alert'
    if (type === 'warning') className += ' warning'
    if (color) className += ` ${color}`

    return (
        <li
            onClick={onClick}
            onMouseUp={onMouseUp}
            role="button"
            className={className}
        >
            {icon ? (
                <img
                    className="btn-icon"
                    src={icon}
                    width="16"
                    height="16"
                    alt=""
                />
            ) : (
                <div className="btn-icon-empty"></div>
            )}

            {children}
        </li>
    )
}
