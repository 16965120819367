export default function SelectInput({
    value,
    onChange,
    options,
    name,
    id,
    errors,
    ...rest
}) {
    function handleChange(e) {
        onChange(e.target.value)
    }
    const textValue = value || ''

    return (
        <div className={`select-input${errors?.length ? ' has-error' : ''}`}>
            <select
                value={textValue}
                onChange={handleChange}
                required={rest.required}
                id={id}
                disabled={rest.disabled}
                name={name}
            >
                {options.map((option) =>
                    option.groupTitle ? (
                        <optgroup
                            key={option.groupTitle}
                            label={option.groupTitle}
                        >
                            {option.options.map((subOption) => (
                                <option
                                    key={subOption.value}
                                    value={subOption.value}
                                >
                                    {subOption.label}
                                </option>
                            ))}
                        </optgroup>
                    ) : (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ),
                )}
            </select>
            <div className="chevron-container"></div>
        </div>
    )
}
