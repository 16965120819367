import { useEffect, useRef, useState } from 'react'
import { setSearchParams } from '../../common/helpers'
import { useLocation } from 'react-router-dom'
import MainButton from '../admin/MainButton'

function updateParamsTitle(title, checkHref) {
    if (checkHref && checkHref !== window.location.href) return
    const searchParams = new URLSearchParams(window.location.search)

    const newParams = {}
    for (const [key, value] of searchParams.entries()) {
        newParams[key] = value
    }

    newParams.t_title = title
    setSearchParams(newParams)
}

function Toggle({
    title,
    children,
    headerChildrenHtml,
    open,
    small,
    heading = 'h4',
    gold,
    plain,
    mainButton,
}) {
    const location = useLocation()
    const [isOpen, setIsOpen] = useState(open)
    const elRef = useRef(null)
    const bodyWrapperRef = useRef(null)

    const escapedTitle = (title || '').replace(/,/g, '_')

    const rememberToggles =
        localStorage.getItem('forget_toggles_state') === 'false'

    const savedOpen = localStorage
        .getItem('open_toggles')
        ?.split(',')
        ?.includes(escapedTitle)
    const savedClosed = localStorage
        .getItem('closed_toggles')
        ?.split(',')
        ?.includes(escapedTitle)

    useEffect(
        function () {
            let finalOpen = open

            if (rememberToggles) {
                if (savedOpen && !savedClosed) {
                    finalOpen = true
                } else if (savedClosed && !savedOpen) {
                    finalOpen = false
                }
            }

            if (
                new URLSearchParams(window.location.search).get('t_title') ===
                title
            ) {
                finalOpen = true
            }

            updateMaxHeight(!finalOpen)
            setIsOpen(finalOpen)

            const observer = new MutationObserver(updateMaxHeight)
            if (bodyWrapperRef.current) {
                observer.observe(bodyWrapperRef.current, {
                    childList: true,
                    subtree: true,
                })
            }

            return function () {
                if (bodyWrapperRef.current) {
                    observer.disconnect()
                }
            }
        },
        [open, children],
    )

    useEffect(
        function () {
            const shouldScrollTo =
                isOpen &&
                new URLSearchParams(window.location.search).get('t_title') ===
                    title

            let observer
            if (shouldScrollTo) {
                observer = new MutationObserver((mutations) => {
                    mutations.forEach((mutation) => {
                        if (mutation.addedNodes.length) {
                            elRef.current.scrollIntoView({
                                behavior: 'smooth',
                                block: 'start',
                            })
                            observer.disconnect()
                        }
                    })
                })

                observer.observe(elRef.current, {
                    childList: true,
                    subtree: true,
                })
            }

            return function () {
                if (observer) {
                    observer.disconnect()
                }
            }
        },
        [isOpen],
    )

    useEffect(function () {
        return function () {
            if (
                new URLSearchParams(window.location.search).get('t_title') ===
                title
            ) {
                updateParamsTitle(undefined, location.href)
            }
        }
    }, [])

    function updateMaxHeight(isClosing) {
        if (bodyWrapperRef.current) {
            bodyWrapperRef.current.style.setProperty(
                '--max-height',
                bodyWrapperRef.current.scrollHeight + 20 + 'px',
            )
            if (!isClosing) {
                // Give it a large max-height to allow for child changes
                setTimeout(
                    function () {
                        if (!bodyWrapperRef.current) {
                            return
                        }
                        bodyWrapperRef.current.style.setProperty(
                            '--max-height',
                            9999 + 'px',
                        )
                    },
                    isClosing ? 0 : 300,
                )
            }
        }
    }

    function handleToggle(e) {
        e.preventDefault()
        if (rememberToggles) {
            if (!isOpen) {
                if (savedClosed) {
                    localStorage.setItem(
                        'closed_toggles',
                        localStorage
                            .getItem('closed_toggles')
                            .split(',')
                            .filter((t) => t !== escapedTitle)
                            .join(','),
                    )
                }
                if (!savedOpen) {
                    localStorage.setItem(
                        'open_toggles',
                        [
                            ...(
                                localStorage.getItem('open_toggles') || ''
                            ).split(','),
                            escapedTitle,
                        ].join(','),
                    )
                }
            } else {
                if (savedOpen) {
                    localStorage.setItem(
                        'open_toggles',
                        localStorage
                            .getItem('open_toggles')
                            .split(',')
                            .filter((t) => t !== escapedTitle)
                            .join(','),
                    )
                }
                if (!savedClosed) {
                    localStorage.setItem(
                        'closed_toggles',
                        [
                            ...(
                                localStorage.getItem('closed_toggles') || ''
                            ).split(','),
                            escapedTitle,
                        ].join(','),
                    )
                }
            }
        }

        if (!isOpen) {
            updateParamsTitle(title)
        } else {
            if (
                new URLSearchParams(window.location.search).get('t_title') ===
                title
            ) {
                updateParamsTitle(undefined)

                if (
                    new URLSearchParams(window.location.search).get(
                        'f_url_key',
                    ) === title
                ) {
                    const paramsObj = Object.fromEntries(
                        new URLSearchParams(window.location.search).entries(),
                    )
                    const paramsToDelete = Object.keys(paramsObj).filter(
                        (key) => key.startsWith('f_'),
                    )
                    for (const key of paramsToDelete) {
                        delete paramsObj[key]
                    }
                    setSearchParams(paramsObj)
                }
            }
        }

        updateMaxHeight(isOpen)
        setIsOpen(!isOpen)
    }

    let headingHtml
    if (heading === 'h2') {
        headingHtml = <h2>{title}</h2>
    } else {
        headingHtml = <h4>{title}</h4>
    }

    let className = 'toggle'
    if (small) {
        className += ' toggle-sm'
    }
    if (gold) {
        className += ' gold'
    }
    if (plain) {
        className += ' plain'
    }
    if (isOpen) {
        className += ' open'
    }

    return (
        <details ref={elRef} open className={className}>
            <summary
                tabIndex={1}
                onClick={handleToggle}
                aria-expanded={isOpen}
                aria-controls={`toggle-content-${escapedTitle}`}
            >
                <div className="toggle-header">
                    <div className="toggle-header-heading">{headingHtml}</div>
                    <div className="toggle-header-content">
                        {headerChildrenHtml}
                    </div>

                    <div
                        className={`toggle-header-icon${isOpen ? ' open' : ''}`}
                    >
                        <img
                            alt="collapse"
                            src="/img/icons/chevron-left-white.svg"
                        ></img>
                    </div>
                </div>
            </summary>
            <div
                id={`toggle-content-${escapedTitle}`}
                className="toggle-body-wrapper"
                ref={bodyWrapperRef}
                aria-hidden={!isOpen}
            >
                <div
                    className="toggle-body"
                    onClick={() => updateParamsTitle(title, location.href)}
                >
                    {children}
                </div>
            </div>
            {mainButton && isOpen && <MainButton {...mainButton} />}
        </details>
    )
}

export default Toggle
