import { useState } from 'react'
import fetchAPI from '../../../common/fetchAPI'
import useData from '../../../common/hooks/useData'
import useError from '../../../common/hooks/useError'
import useToast from '../../../common/hooks/useToast'
import Button from '../../../components/common/Button'
import { DEFAULT_TEAM_ROLES } from '../../../common/constants'
import Spinner from '../../../components/common/Spinner'
import ErrorMessage from '../../../components/common/ErrorMessage'
import TextInput from '../../../components/common/data-form/TextInput'

export default function TeamSettings() {
    const [teamRoles, setTeamRoles] = useState('')
    const [jobTypes, setJobTypes] = useState('')
    const [loading, setLoading] = useState(false)
    const [error, setError, errorFields] = useError()
    const setToast = useToast()

    const { rolesSettingLoading, rolesSettingError, rolesSettingMutate } =
        useData(
            '/v1/settings/teamRoles',
            'rolesSetting',
            (data) => data?.settingValue || '',
            false,
            false,
            (data) => {
                setTeamRoles(data?.settingValue || '')
            },
        )

    const { jobSettingLoading, jobSettingError, jobSettingMutate } = useData(
        '/v1/settings/jobTypes',
        'jobSetting',
        (data) => data?.settingValue || '',
        false,
        false,
        (data) => {
            setJobTypes(data?.settingValue || '')
        },
    )

    async function onSubmitTeamRoles(e) {
        e.preventDefault()
        setLoading(true)
        const { error } = await fetchAPI(
            `/v1/settings/teamRoles`,
            { settingValue: teamRoles || '' },
            'PATCH',
        )
        setLoading(false)
        if (error) {
            return setError(error, ['teamRoles'])
        }
        setToast('Saved')
        rolesSettingMutate()
    }

    async function onSubmitJobTypes(e) {
        e.preventDefault()
        setLoading(true)
        const { error } = await fetchAPI(
            `/v1/settings/jobTypes`,
            { settingValue: jobTypes || '' },
            'PATCH',
        )
        setLoading(false)
        if (error) {
            return setError(error, ['jobTypes'])
        }
        setToast('Saved')
        jobSettingMutate()
    }

    if (rolesSettingLoading || jobSettingLoading) {
        return <Spinner />
    }

    if (rolesSettingError || jobSettingError) {
        return (
            <ErrorMessage section>
                {rolesSettingError || jobSettingError}
            </ErrorMessage>
        )
    }
    return (
        <>
            {error && (
                <ErrorMessage onDismiss={() => setError('')} section>
                    {error}
                </ErrorMessage>
            )}
            <form onSubmit={onSubmitTeamRoles}>
                <label htmlFor="form-team-roles">
                    Team names (new line separates names)
                </label>
                <TextInput
                    rows={6}
                    name="team_roles"
                    id="form-team-roles"
                    onChange={(v) => setTeamRoles(v)}
                    value={teamRoles || ''}
                    type="textarea"
                    errors={errorFields.filter((f) => f === 'teamRoles')}
                />
                <small className="input-info">
                    Do not add{' '}
                    {DEFAULT_TEAM_ROLES.map((r) => `'${r}'`).join(', ')}. These
                    are reserved names.
                </small>

                <Button
                    type="submit"
                    text="Save team names"
                    small
                    alignRight
                    isLoading={loading}
                />
                <br />
            </form>
            <form onSubmit={onSubmitJobTypes}>
                <label htmlFor="form-job-types">
                    Job types (new line separates types)
                </label>
                <TextInput
                    rows={6}
                    id="form-job-types"
                    onChange={(v) => setJobTypes(v)}
                    value={jobTypes || ''}
                    type="textarea"
                    errors={errorFields.filter((f) => f === 'jobTypes')}
                />
                <Button
                    type="submit"
                    text="Save job types"
                    small
                    alignRight
                    isLoading={loading}
                />
            </form>
        </>
    )
}
