// .acronym ok
import ResourceList from '../../../components/admin/ResourceList'
import useToast from '../../../common/hooks/useToast'
import useModal from '../../../common/hooks/useModal'
import fetchAPI from '../../../common/fetchAPI'
import { ICONS } from '../../../common/constants'
import ConfirmModalContent from '../../../components/common/ConfirmModalContent'
import { useParams } from 'react-router-dom'
import useData from '../../../common/hooks/useData'
import Spinner from '../../../components/common/Spinner'
import ErrorMessage from '../../../components/common/ErrorMessage'

function CustomProductsList() {
    const setToast = useToast()
    const { accountId } = useParams()
    const { setModal, isModalLoading, setModalLoading } = useModal()

    const { account, accountError, accountLoading, accountMutate } = useData(
        `/v1/accounts/${accountId}?accountType=vendor`,
        'account',
    )

    if (accountLoading) return <Spinner marginTop />
    if (accountError) return <ErrorMessage section>{accountError}</ErrorMessage>

    const customProductNames = account.customProductNames

    async function onItemDelete(name) {
        setModal(
            <ConfirmModalContent
                text="This will not remove the vendor from existing custom products."
                confirmText="Delete"
                onConfirm={async () => {
                    if (isModalLoading) return
                    setModalLoading(true)
                    const url = `/v1/accounts/${account.id}`
                    const { error } = await fetchAPI(
                        url,
                        {
                            accountType: 'vendor',
                            customProductNames: customProductNames.filter(
                                (item) => item !== name,
                            ),
                        },
                        'PATCH',
                    )
                    setModalLoading(false)
                    if (error) {
                        setToast(error, 'alert')
                        setModal(null)
                        return
                    }
                    setModal(null)
                    setToast('Done')
                    accountMutate()
                }}
            />,
            'Are you sure?',
        )
    }

    return (
        <ResourceList
            items={customProductNames || []}
            newItemPath={`/accounts/vendors/${accountId}/custom-products/new`}
            itemsLoading={accountLoading}
            renderMainButton={true}
            newItemLabel={'New product'}
            fields={[
                {
                    column: 1,
                    getValue: (item) => item,
                    getClassName: () => 'title',
                },
            ]}
            actions={[
                {
                    title: 'Delete',
                    onClick: (item) => onItemDelete(item),
                    icon: ICONS.TRASH_RED,
                    type: 'alert',
                },
            ]}
        />
    )
}

export default CustomProductsList
