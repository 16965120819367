import Button from '../Button'
import Grid from '../Grid'
import PrivateFile from '../PrivateFile'

export default function DriveFilesInput({ value, onChange, errors }) {
    function handleChange(e, id) {
        e.preventDefault()
        onChange(value.filter((v) => v !== id))
    }
    return (
        <div
            className={`drive-files-input${errors?.length ? ' has-error' : ''}`}
        >
            {value?.length ? (
                <Grid cols={3}>
                    {value.map((id) => (
                        <div key={id}>
                            <Button
                                text={<>&#10005;</>}
                                small
                                plain
                                title="Remove"
                                onClick={(e) => handleChange(e, id)}
                            />
                            <PrivateFile openFullOnClick driveFileId={id} />
                        </div>
                    ))}
                </Grid>
            ) : (
                <div>No files left</div>
            )}
        </div>
    )
}
