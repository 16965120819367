import fetchAPI from '../../../common/fetchAPI'
import ResourceList from '../../../components/admin/ResourceList'
import Button from '../../../components/common/Button'
import useToast from '../../../common/hooks/useToast'
import useModal from '../../../common/hooks/useModal'
import ConfirmModalContent from '../../../components/common/ConfirmModalContent'
import useAuth from '../../../common/hooks/useAuth'
import MessageSection from '../../../components/common/MessageSection'
import { useParams } from 'react-router-dom'
import ImportProductItem from './ImportProductItem'
import useData from '../../../common/hooks/useData'
import Spinner from '../../../components/common/Spinner'
import ErrorMessage from '../../../components/common/ErrorMessage'
import CheckboxInput from '../../../components/common/data-form/CheckboxInput'
import SectionContainer from '../../../components/common/SectionContainer'
import Break from '../../../components/common/Break'

function Products({ onImport }) {
    const { shopifyShopId } = useParams()

    const { shopifyProducts, shopifyProductsLoading, shopifyProductsError } =
        useData(
            `/v1/shop/products/sync/preview?ownShopId=${shopifyShopId}`,
            'shopifyProducts',
        )

    if (shopifyProductsLoading) {
        return (
            <SectionContainer>
                <Spinner />
                <div className="text-center">
                    <small>Searching for products...</small>
                </div>
            </SectionContainer>
        )
    }

    if (shopifyProductsError) {
        return (
            <SectionContainer>
                <ErrorMessage>{shopifyProductsError}</ErrorMessage>
            </SectionContainer>
        )
    }

    return (
        <>
            <SectionContainer>
                <hr />
                <h4>
                    Found {shopifyProducts.length} items that are not synced
                    into the app.
                </h4>
                <Button
                    text="Sync all now"
                    small
                    inline
                    alignRight
                    outline
                    onClick={onImport}
                />
            </SectionContainer>

            {shopifyProducts.length > 0 && (
                <>
                    <ResourceList
                        key={shopifyProducts.length}
                        items={shopifyProducts}
                        fields={[
                            {
                                column: 1,
                                getValue: (item) => (
                                    <ImportProductItem item={item} />
                                ),
                            },
                        ]}
                    />
                </>
            )}
        </>
    )
}

export default function ImportProductsOwn() {
    const { shopifyShopId } = useParams()

    const { shop, shopLoading, shopError, shopMutate } = useData(
        `/v1/shop/shops/${shopifyShopId}?fields=name,syncStrategies`,
        'shop',
    )

    const setToast = useToast()
    const auth = useAuth()
    const { setModal, setModalLoading, isModalLoading } = useModal()

    function onImport() {
        setModal(
            <ConfirmModalContent
                onConfirm={async () => {
                    if (isModalLoading) return
                    setModalLoading(true)
                    const { error } = await fetchAPI(
                        `/v1/shop/products/sync`,
                        {
                            ownShopId: shopifyShopId,
                        },
                        'POST',
                    )
                    if (error) {
                        setModal(null)
                        setToast(error, 'alert')
                        return
                    }
                    setModal(null)
                    setToast(`Sync has started`)
                }}
            />,
            'Sync all products?',
        )
    }

    async function onSettingChange(v) {
        const { error } = await fetchAPI(
            `/v1/shop/shops/${shopifyShopId}`,
            {
                syncStrategies: v
                    ? [
                          ...shop.syncStrategies,
                          { strategy: 'updates_app', field: 'all' },
                      ]
                    : shop.syncStrategies.filter(
                          (s) => s.strategy !== 'updates_app',
                      ),
            },
            'PATCH',
        )
        if (error) {
            setToast(error, 'alert')
            return
        }
        setToast(`Saved`)
        shopMutate()
    }

    if (!auth.user.orgsData.some((o) => o.organizationType === 'company')) {
        return (
            <SectionContainer>
                <MessageSection type="warning">
                    Please add a company first, or enable product management on
                    your personal profile.
                    <Break />
                    <Button
                        small
                        outline
                        white
                        href={`/users/${auth.user.id}/edit?type=${auth.user.role}&isOwnProfile=true`}
                        text="Add company"
                    />
                </MessageSection>
            </SectionContainer>
        )
    }

    if (shopLoading) {
        return <Spinner />
    }

    if (shopError) {
        return (
            <SectionContainer>
                <ErrorMessage>{shopError}</ErrorMessage>
            </SectionContainer>
        )
    }

    const hasSync = shop.syncStrategies.some(
        (s) => s.strategy === 'updates_app',
    )

    return (
        <>
            <SectionContainer className="section">
                <h2>{shop.name}</h2>

                <CheckboxInput
                    value={hasSync}
                    label={'Auto sync products to the aCo app'}
                    info={
                        'When enabled, products added, edited or deleted from your Shopify store will be automatically synchronized to the aCo app. Sync runs at least once a day.'
                    }
                    onChange={onSettingChange}
                />
            </SectionContainer>
            {!hasSync ? (
                <Products onImport={onImport} />
            ) : (
                <Button
                    text="Sync all now"
                    small
                    inline
                    alignRight
                    outline
                    onClick={onImport}
                />
            )}
        </>
    )
}
