import useAuth from '../../common/hooks/useAuth'
import TabsOverview from '../../components/navigation/TabsOverview'
import ReferralsList from './referrals/ReferralsList'
import ExpensesList from './expenses/ExpensesList'
import LateFeeList from './late-fees/LateFeeList'
import ServiceRevenuePage from './services/ServiceRevenuePage'
import ServicesList from './services/ServicesList'
import BillingChartPage from './chart/BillingChartPage'
import StripeClientsList from './stripe-clients/StripeClientsList'
import WholesaleBillingPage from './wholesale/WholesaleBillingPage'
import PremiumBanner from '../admin/app-billing/PremiumBanner'

function BillingOverview({ parentPage }) {
    const auth = useAuth()

    let pages = {}
    if (auth.isAdmin || auth.isAccountant) {
        pages = {
            overview: <BillingChartPage />,
            clients: <StripeClientsList />,
            wholesale: <WholesaleBillingPage />,
            expenses: <ExpensesList />,
            referrals: <ReferralsList />,
            services: <ServicesList />,
            service_revenue: <ServiceRevenuePage />,
            late_fees: <LateFeeList />,
        }
    } else if (auth.isCollections) {
        pages.clients = <StripeClientsList />
    } else if (auth.isAssistant) {
        pages.wholesale = <WholesaleBillingPage />
    } else if (auth.isLateFeeAssistant) {
        pages.late_fees = <LateFeeList />
    } else if (auth.isClient) {
        pages = {
            overview: (
                <>
                    <span className="text-subdued">{`Connect Stripe to manage subscriptions and invoices.`}</span>
                    <PremiumBanner requestFeature={'app_billing_stripe'} />
                </>
            ),
            clients: (
                <>
                    <span className="text-subdued">{`Connect Stripe to manage subscriptions and invoices.`}</span>
                    <PremiumBanner requestFeature={'app_billing_stripe'} />
                </>
            ),
            wholesale: (
                <>
                    <span className="text-subdued">{`Add vendors to your Shopify store.`}</span>
                    <PremiumBanner requestFeature={'manage_vendors'} />
                </>
            ),
            expenses: (
                <>
                    <span className="text-subdued">{`Manage business expenses.`}</span>
                    <PremiumBanner requestFeature={'manage_expenses'} />
                </>
            ),
            services: (
                <>
                    <span className="text-subdued">{`Manage services you provide.`}</span>
                    <PremiumBanner requestFeature={'manage_services'} />
                </>
            ),
            service_revenue: (
                <>
                    <span className="text-subdued">{`Manage revenue from services you provide.`}</span>
                    <PremiumBanner requestFeature={'manage_service_revenue'} />
                </>
            ),
            late_fees: (
                <>
                    <span className="text-subdued">{`Bill late fees for your subscriptions.`}</span>
                    <PremiumBanner requestFeature={'manage_late_fees'} />
                </>
            ),
        }
    }

    return (
        <TabsOverview
            defaultTab={auth.isAssistant ? 'wholesale' : 'clients'}
            pagesMap={pages}
            parentPage={parentPage}
        />
    )
}

export default BillingOverview
