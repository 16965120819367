import { Link } from 'react-router-dom'
import { formatDateTime, getUserDisplayName } from '../../../common/helpers'
import InlineStack from '../../../components/common/InlineStack'
import BlockStack from '../../../components/common/BlockStack'
import InlineStackItem from '../../../components/common/InlineStackItem'
import ContactThumbnail from './ContactThumbnail'
import Popover from '../../../components/common/Popover'
import ContactCardPopoverContent from './ContactCardPopoverContent'

function ContactCardInner({
    user: data,
    summary = false,
    hideLinks = false,
    topRightContent,
    bottomLeftContent,
    largeThumbnail = false,
    thumbnailOnly,
}) {
    if (!data) return null
    const isContact = data.contactType

    const company = data.orgsData?.find(
        (org) => org.organizationType === 'company',
    )

    const photoHtml = (
        <ContactThumbnail
            hideLink={hideLinks}
            small={!largeThumbnail}
            data={data}
            isContact={isContact}
        />
    )

    return (
        <div
            className={`contact-card${summary ? ' summary' : ''}${isContact ? ' is-contact' : ''}`}
        >
            <InlineStack itemsCenter gap={'sm'}>
                {!summary && <div>{photoHtml}</div>}
                {!thumbnailOnly && (
                    <InlineStackItem grow1>
                        <BlockStack gap={'xxs'}>
                            <InlineStack itemsStart spaceBetween gap={'sm'}>
                                <InlineStackItem selfEnd>
                                    {hideLinks ? (
                                        <div className="strong m-0">
                                            {getUserDisplayName(data)}
                                        </div>
                                    ) : (
                                        <Link
                                            to={`/${
                                                data.role ? 'users' : 'contacts'
                                            }/${data.id}`}
                                            className="strong m-0"
                                        >
                                            {getUserDisplayName(data)}
                                        </Link>
                                    )}
                                </InlineStackItem>
                                <InlineStackItem shrink0>
                                    {topRightContent}
                                </InlineStackItem>
                            </InlineStack>
                            {Boolean(company) && (
                                <div className="text-subdued small">
                                    {company?.name || ''}
                                    {company?.acronym
                                        ? ` (${company.acronym})`
                                        : null}
                                    <span className="text-caps">
                                        {data.role === 'staff' &&
                                            (data.teamRoles || []).join(', ')}
                                    </span>
                                    {data.role === 'staff' &&
                                        Boolean(data.startDate) && (
                                            <>
                                                <br />
                                                {formatDateTime(
                                                    data.startDate,
                                                    true,
                                                )}
                                            </>
                                        )}
                                </div>
                            )}
                            {/* 
                        {auth.isAdmin && isContact && data.creator && (
                            <small className="text-subdued">
                                {`Added by ${getUserDisplayName(data.creator)}`}
                            </small>
                        )} */}

                            {bottomLeftContent}
                        </BlockStack>
                    </InlineStackItem>
                )}
            </InlineStack>
        </div>
    )
}

function ContactCard({
    user: data,
    summary = false,
    hideLinks = false,
    topRightContent,
    bottomLeftContent,
    withDetails = false,
    largeThumbnail = false,
    thumbnailOnly = false,
}) {
    if (withDetails) {
        return (
            <div className="contact-card-with-details">
                <Popover
                    activateOnHover
                    activateOnFocus
                    renderActivator={(ref, listeners) => (
                        <div ref={ref} {...listeners}>
                            <ContactCardInner
                                user={data}
                                summary={summary}
                                hideLinks={true}
                                topRightContent={topRightContent}
                                bottomLeftContent={bottomLeftContent}
                                largeThumbnail={largeThumbnail}
                                thumbnailOnly={thumbnailOnly}
                            />
                        </div>
                    )}
                >
                    <ContactCardPopoverContent
                        userId={data.role && data.id}
                        contactId={
                            !data.role && !data.organizationType && data.id
                        }
                    />
                </Popover>
            </div>
        )
    }

    return (
        <ContactCardInner
            user={data}
            summary={summary}
            hideLinks={hideLinks}
            topRightContent={topRightContent}
            bottomLeftContent={bottomLeftContent}
            largeThumbnail={largeThumbnail}
            thumbnailOnly={thumbnailOnly}
        />
    )
}

export default ContactCard
