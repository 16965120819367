import { useEffect, useState } from 'react'
import Spinner from './Spinner'
import { useScrollLock } from '../../common/hooks/useScrollLock'
import ActionItem from './ActionItem'

const MAX_WIDTH = 220
const NAV_HEIGHT = 80

function handleEllipsesStyles(buttonEl) {
    const buttonCoords = {
        // The target is a butotn
        x: buttonEl.getBoundingClientRect().x,
        y: buttonEl.getBoundingClientRect().y,
    }

    // Calculate the middle of the button vertically
    const middleOfButtonY = buttonCoords.y + buttonEl.offsetHeight / 2

    // Get the next sibling of the button
    const actionsEl = buttonEl.nextElementSibling
    actionsEl.style.opacity = 0

    actionsEl.style.setProperty(
        '--left',
        `${buttonCoords.x - MAX_WIDTH - 10}px`,
    )
    actionsEl.style.setProperty('--bottom', `unset`)
    actionsEl.style.setProperty('--top', `${middleOfButtonY - 10}px`)
    actionsEl.style.setProperty('--after-top', '0')

    // Calculate the width of the actions element
    const actionsHeight = actionsEl.offsetHeight

    // Get the top of the actions element
    const actionsTopInViewport = actionsEl.getBoundingClientRect().top

    // Check if the bottom of the element is outside the viewport

    if (
        actionsTopInViewport + actionsHeight >
        window.innerHeight - NAV_HEIGHT
    ) {
        // If it is, set the bottom of the element to be the same as the bottom of the viewport
        actionsEl.style.setProperty('--bottom', `${NAV_HEIGHT + 10}px`)
        actionsEl.style.setProperty('--top', `unset`)
    }

    // Get the top (Y) of the middle of the button, in relation to the actionsEl top (Y)
    const buttonMiddleTopInActions =
        middleOfButtonY - actionsEl.getBoundingClientRect().top
    const halfButtonHeight = buttonEl.offsetHeight / 2
    actionsEl.style.setProperty(
        '--after-top',
        `${buttonMiddleTopInActions - halfButtonHeight + 10}px`,
    )

    actionsEl.style.opacity = 1
}

function handleOtherActivatorStyles(buttonEl) {
    const buttonCoords = {
        // The target is a butotn
        x: buttonEl.getBoundingClientRect().x,
        y: buttonEl.getBoundingClientRect().y,
    }

    // Calculate the middle of the button horizontally
    const middleOfButtonX = buttonCoords.x + buttonEl.offsetWidth / 2
    const bottomOfButtonY = buttonCoords.y + buttonEl.offsetHeight
    const leftOfButtonX = buttonCoords.x

    // Get the next sibling of the button
    const actionsEl = buttonEl.nextElementSibling
    actionsEl.style.opacity = 0

    actionsEl.style.setProperty('--left', `${leftOfButtonX}px`)
    actionsEl.style.setProperty('--top', `${bottomOfButtonY + 10}px`)
    actionsEl.style.setProperty('--bottom', `unset`)
    actionsEl.style.setProperty('--after-left', '0')

    // Calculate the height and width of the actions element
    const actionsHeight = actionsEl.offsetHeight

    // Get the top and right of the actions element
    const actionsTopInViewport = actionsEl.getBoundingClientRect().top
    const actionsRightInViewport = actionsEl.getBoundingClientRect().right

    // Check if the bottom of the element is outside the viewport
    if (
        actionsTopInViewport + actionsHeight >
        window.innerHeight - NAV_HEIGHT
    ) {
        // If it is, set the bottom of the element to be the same as the bottom of the viewport
        actionsEl.style.setProperty('--bottom', `${NAV_HEIGHT + 10}px`)
        actionsEl.style.setProperty('--top', `unset`)
    }

    // Check if the right of the element is outside the viewport
    if (actionsRightInViewport > window.innerWidth) {
        // If it is, set the right of the element to be the same as the right of the viewport
        actionsEl.style.setProperty('--right', `10px`)
        actionsEl.style.setProperty('--left', `unset`)
    }

    // Get the left (X) of the middle of the button, in relation to the actionsEl left (X)
    // const buttonMiddleTopInActions =
    //     middleOfButtonY - actionsEl.getBoundingClientRect().top
    // const halfButtonHeight = buttonEl.offsetHeight / 2
    // actionsEl.style.setProperty(
    //     '--after-top',
    //     `${buttonMiddleTopInActions - halfButtonHeight + 10}px`,
    // )

    const buttonMiddleLeftInActions =
        middleOfButtonX - actionsEl.getBoundingClientRect().left
    const halfButtonWidth = buttonEl.offsetWidth / 2
    actionsEl.style.setProperty(
        '--after-left',
        `${buttonMiddleLeftInActions - halfButtonWidth + 10}px`,
    )

    actionsEl.style.opacity = 1
}

function ActionsDropdown({ actions, listItem, activator }) {
    const [isOpen, setIsOpen] = useState(false)
    const { lockScroll, unlockScroll } = useScrollLock()

    useEffect(
        function () {
            if (isOpen) {
                lockScroll()
            } else {
                unlockScroll()
            }

            return () => {
                unlockScroll()
            }
        },
        [isOpen],
    )

    if (!actions?.filter((action) => !action.hide)?.length) return null

    function handleToggleClick(e) {
        e.preventDefault()
        setIsOpen((v) => !v)

        const buttonEl = e.currentTarget

        if (activator) {
            handleOtherActivatorStyles(buttonEl)
        } else {
            handleEllipsesStyles(buttonEl)
        }
    }

    function handleOverlayClick(e) {
        e.preventDefault()
        setIsOpen(false)
    }

    const hasIcon = actions.some((action) => action.icon)

    return (
        <>
            <div
                tabIndex="0"
                className={`actions-dropdown${listItem ? ' list-item' : ''}${
                    activator ? ' other-activator' : ''
                }`}
                style={{ '--z-index': isOpen ? 25 : 0 }}
            >
                {activator ? (
                    <button
                        onClick={handleToggleClick}
                        className={`actions-btn`}
                    >
                        {activator}
                    </button>
                ) : (
                    <button
                        onClick={handleToggleClick}
                        className={`actions-btn`}
                    >
                        <span></span>
                        <span></span>
                        <span></span>
                    </button>
                )}

                <div
                    style={{
                        '--max-width': `${MAX_WIDTH}px`,
                        opacity: 0,
                    }}
                    className={`actions${isOpen ? ' open' : ''}`}
                >
                    <ul>
                        {actions
                            ?.filter((action) => !action.hide)
                            ?.map((action, i) => (
                                <ActionItem
                                    disabled={action.disabled}
                                    loading={action.loading}
                                    type={action.type}
                                    color={action.color}
                                    role="button"
                                    onClick={(e) => e.preventDefault()}
                                    onMouseUp={(e) => {
                                        if (e.button === 2) return
                                        e.preventDefault()
                                        if (action.disabled || action.loading) {
                                            return
                                        }
                                        action.onClick(e)
                                        // if (activator) {
                                        // }
                                        setIsOpen(false)
                                    }}
                                    key={i}
                                    icon={hasIcon && action.icon}
                                >
                                    {action.title}
                                    {action.loading && <Spinner />}
                                </ActionItem>
                            ))}
                    </ul>
                </div>
                <div
                    onClick={handleOverlayClick}
                    className="toggle-overlay"
                ></div>
            </div>
        </>
    )
}

export default ActionsDropdown
