import { formatMoney } from '../../../common/helpers'
import ResourceList from '../../../components/admin/ResourceList'
import BlockStack from '../../../components/common/BlockStack'

function PricesList(props) {
    const { items, loading } = props
    return (
        <ResourceList
            smallTitle
            items={items}
            itemsLoading={loading}
            newItemPath={'/services/:serviceId/prices/new'}
            title={'Prices'}
            fields={[
                {
                    column: 1,
                    getValue: (item) => (
                        <BlockStack gap={'tiny'}>
                            <h3 className="m-0">{formatMoney(item.amount)}</h3>
                            <div className="text-caps">
                                {`${item.pricingModel} ${
                                    item.pricingModel === 'recurring'
                                        ? `, every ${item.recurringCount} ${item.recurringPeriod}s`
                                        : ''
                                }`}
                            </div>
                        </BlockStack>
                    ),
                },
            ]}
        />
    )
}

export default PricesList
