import React, { useState } from 'react'
import { useStore } from '../../common/Context'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import fetchAPI from '../../common/fetchAPI'
import useError from '../../common/hooks/useError'
import Button from '../../components/common/Button'
import authStorage from '../../common/authStorage'
import ErrorMessage from '../../components/common/ErrorMessage'
import TextInput from '../../components/common/data-form/TextInput'
import PasswordInput from '../../components/common/data-form/PasswordInput'
import SectionContainer from '../../components/common/SectionContainer'

function Login() {
    const [_state, dispatch] = useStore()
    const [data, setData] = useState({ email: '', password: '' })
    const [loading, setLoading] = useState(false)
    const [error, setError] = useError()
    const { state } = useLocation()

    const navigate = useNavigate()

    const onSubmit = async (e) => {
        setError('')
        if (!e.target.reportValidity()) return
        e.preventDefault()

        const { email, password } = data

        setLoading(true)
        const { responseData, error } = await fetchAPI(
            `/v1/auth/login`,
            {
                email,
                password,
            },
            'POST',
        )
        setLoading(false)

        if (error) {
            setError(error)
            return
        }

        const { user, tokens } = responseData
        authStorage.setItem('access_token', tokens.access.token)
        authStorage.setItem('refresh_token', tokens.refresh.token)

        dispatch({ type: 'LOGIN', payload: user })
        navigate(state?.redirectUrl || '/', {
            state: { showDonationModal: true },
        })
    }

    return (
        <SectionContainer>
            {error && (
                <ErrorMessage onDismiss={() => setError('')}>
                    {error}
                </ErrorMessage>
            )}
            <h1 className="text-center text-very-bold">The App</h1>
            <h2 className="h3">Login</h2>
            <p className="text-subdued">
                Free to sign up and use the basic version.
                <br />
                Upgrade for additional features.
            </p>

            <form onSubmit={onSubmit}>
                <label htmlFor="email">Email</label>
                <TextInput
                    id="email"
                    name="email"
                    type="email"
                    value={data.email}
                    onChange={(v) => setData({ ...data, email: v })}
                    required
                    placeholder="name@email.com"
                />

                <label htmlFor="password">Password</label>
                <PasswordInput
                    name="password"
                    required
                    id="password"
                    placeholder="******"
                    value={data.password}
                    onChange={(v) =>
                        setData({
                            ...data,
                            password: v,
                        })
                    }
                />

                <Button isLoading={loading} type="submit" text={'Log In'} />

                <p className="login-link"></p>

                <footer className="text-center">
                    <div className="links">
                        {!process.env.REACT_APP_DISABLE_REGISTATION ? (
                            <>
                                Need an account?{' '}
                                <Link to="/register">Register</Link>
                                <br />
                                <br />
                            </>
                        ) : null}
                        <Link to="/forgot-password">Forgot password</Link>
                    </div>

                    <br />
                    <p className="text-subdued">
                        Perfect for artists, small businesses, wholesalers,
                        boutiques, crafters, vendor event planners, brands,
                        farmers and retail stores.
                    </p>
                    <h4 className="text-subdued">#1 All-In-One Solution</h4>
                    <p className="text-subdued">
                        Task Management • Social Media Automation • Password
                        Management • Wholesale Dropshipping • Vendor & Team
                        Commissions Reporting • Time Management • Design
                        Services • Printing • Invoicing • Vendor Events
                    </p>
                </footer>
            </form>
        </SectionContainer>
    )
}

export default Login
