// .company ready
import {
    daysBetween,
    formatDate,
    formatMoney,
    getUnitSumFromCalendar,
    getUserDisplayName,
} from '../../../common/helpers'
import useAuth from '../../../common/hooks/useAuth'

import ResourceList from '../../../components/admin/ResourceList'
import BlockStack from '../../../components/common/BlockStack'
import Grid from '../../../components/common/Grid'
import InlineStack from '../../../components/common/InlineStack'
import Tag from '../../../components/common/Tag'
import ContactThumbnail from '../../admin/clients/ContactThumbnail'

const filterFields = {
    search: true,
    sort: {
        name: {
            label: 'Name',
            dbFields: ['lastName', 'firstName', 'email'],
            asc: true,
        },
        isPaid: {
            label: 'Paid',
            dbFields: ['isPaid', 'lastName', 'firstName', 'email'],
            asc: true,
        },
    },
    filters: {
        onCollections: {
            label: 'On hold',
            dbField: 'onHold',
            options: [
                {
                    label: 'Any',
                    value: 'undefined',
                },
                {
                    label: 'Yes',
                    value: 'true',
                },
                {
                    label: 'No',
                    value: 'false',
                },
            ],
        },
        isPaid: {
            label: 'Paid',
            dbField: 'isPaid',
            options: [
                {
                    label: 'Any',
                    value: 'undefined',
                },
                {
                    label: 'Yes',
                    value: 'true',
                },
                {
                    label: 'No',
                    value: 'false',
                },
            ],
        },
    },
}

function getBillingIndicators(user) {
    if (!user.billingData) return null
    // Date indications
    const paidToDate = formatDate(user.billingData.paidToDate) || ''
    const lastPaymentDate = formatDate(user.billingData.lastPaymentDate) || ''
    const lastOpenInvoiceDueDate =
        formatDate(user.billingData.lastOpenInvoiceDueDate) || ''
    const oldestPastDueInvoiceDueDate =
        formatDate(user.billingData.oldestPastDueInvoiceDueDate) || ''

    let dueDate
    let dueDays
    let warnDueDate = false
    if (user.billingData.oldestPastDueInvoiceDueDate) {
        dueDate = user.billingData.oldestPastDueInvoiceDueDate
        dueDays = daysBetween(new Date(dueDate * 1000), new Date())
        warnDueDate = true
    } else if (
        user.billingData.lastOpenInvoiceDueDate * 1000 >
        new Date().getTime()
    ) {
        dueDate = user.billingData.lastOpenInvoiceDueDate
        dueDays = daysBetween(new Date(dueDate * 1000), new Date())
    } else {
        dueDays = ''
    }

    // Money indications
    const amountOwed = user.billingData.amountOwed
        ? formatMoney(user.billingData.amountOwed)
        : ''
    const amountPastDue = user.billingData.amountPastDue
        ? formatMoney(user.billingData.amountPastDue)
        : ''

    let totalPaidInActivePeriod = 0
    totalPaidInActivePeriod = getUnitSumFromCalendar(
        {},
        '',
        user.billingData.paymentsCalendar,
    )

    return {
        paidToDate,
        lastPaymentDate,
        lastOpenInvoiceDueDate,
        oldestPastDueInvoiceDueDate,
        warnDueDate,
        dueDays,
        amountOwed,
        amountPastDue,
        totalPaidInActivePeriod,
    }
}

export default function StripeClientsList() {
    const auth = useAuth()
    return (
        <div className="">
            <ResourceList
                paginated
                paginationLimit={5}
                apiUrl={'/payment/billing-overview-new'}
                getItemsFromResponse={(data) => data?.results}
                initialQuery={auth.isCollections ? { onCollections: true } : {}}
                itemClickPath={'/users/:item.id'}
                filters={filterFields}
                fields={[
                    {
                        column: 1,
                        getValue: (item) => {
                            const {
                                lastPaymentDate,
                                lastOpenInvoiceDueDate,
                                oldestPastDueInvoiceDueDate,
                                totalPaidInActivePeriod,
                                warnDueDate,
                                dueDays,
                                paidToDate,
                                amountPastDue,
                                amountOwed,
                            } = getBillingIndicators(item)
                            return (
                                <Grid cols={'2-1'}>
                                    <BlockStack gap={'tiny'}>
                                        <InlineStack itemsStart gap={'tiny'}>
                                            <ContactThumbnail data={item} />
                                            <BlockStack gap={'xxs'} itemsStart>
                                                <div className="name">
                                                    {getUserDisplayName(item)}
                                                </div>
                                                <div>
                                                    {(item.orgsData || [])
                                                        .map((o) => o.name)
                                                        .join(', ')}
                                                </div>
                                                <div className="email">
                                                    <small>{`${item.email}`}</small>
                                                </div>
                                                {/* {client.customerName || client.customerEmail} */}
                                                {item.billingData
                                                    ?.customerDescription && (
                                                    <div className="company">
                                                        {
                                                            item.billingData
                                                                .customerDescription
                                                        }
                                                    </div>
                                                )}
                                                <InlineStack wrap gap={'tiny'}>
                                                    {item.billingData
                                                        .isActive ? (
                                                        <Tag
                                                            noMargin
                                                            color={'success'}
                                                        >
                                                            Active
                                                        </Tag>
                                                    ) : (
                                                        <Tag
                                                            noMargin
                                                            color="alert"
                                                        >
                                                            Inactive
                                                        </Tag>
                                                    )}
                                                    {item.onHold ? (
                                                        <Tag
                                                            noMargin
                                                            color="alert"
                                                        >
                                                            Hold
                                                        </Tag>
                                                    ) : null}
                                                    {item.onBlackList ? (
                                                        <Tag
                                                            noMargin
                                                            color="alert"
                                                        >
                                                            Blacklist
                                                        </Tag>
                                                    ) : null}
                                                    {item.onCollections ? (
                                                        <Tag
                                                            noMargin
                                                            color="alert"
                                                        >
                                                            Collections
                                                        </Tag>
                                                    ) : null}
                                                </InlineStack>
                                                {!!item.billingData && (
                                                    <BlockStack
                                                        itemsStart
                                                        gap={'xxs'}
                                                    >
                                                        {item.billingData
                                                            .collectionMethods
                                                            ?.length ? (
                                                            <div>
                                                                <small>
                                                                    Billing:&nbsp;
                                                                    <strong>
                                                                        {(
                                                                            item
                                                                                .billingData
                                                                                .collectionMethods ||
                                                                            []
                                                                        ).join(
                                                                            ', ',
                                                                        )}
                                                                    </strong>
                                                                </small>
                                                            </div>
                                                        ) : null}

                                                        <div>
                                                            <small>
                                                                Last paid&nbsp;
                                                                <strong>
                                                                    {lastPaymentDate ||
                                                                        '(never)'}
                                                                </strong>
                                                            </small>
                                                        </div>
                                                        <div>
                                                            <small>
                                                                Recent invoice
                                                                due&nbsp;
                                                                <strong>
                                                                    {lastOpenInvoiceDueDate ||
                                                                        '(never)'}
                                                                </strong>
                                                            </small>
                                                        </div>
                                                        <div>
                                                            <small>
                                                                Overdue
                                                                since&nbsp;
                                                                <strong>
                                                                    {oldestPastDueInvoiceDueDate ||
                                                                        '(never)'}
                                                                </strong>
                                                            </small>
                                                        </div>
                                                        <div>
                                                            <small>
                                                                Paid&nbsp;
                                                                <strong>
                                                                    {formatMoney(
                                                                        totalPaidInActivePeriod ||
                                                                            0,
                                                                    )}
                                                                </strong>
                                                            </small>
                                                        </div>
                                                        <div>
                                                            {item.billingData
                                                                .oldestCancelDate ? (
                                                                <small>
                                                                    Canceled&nbsp;
                                                                    <strong>
                                                                        {formatDate(
                                                                            item
                                                                                .billingData
                                                                                .oldestCancelDate ||
                                                                                0,
                                                                        )}
                                                                    </strong>
                                                                </small>
                                                            ) : null}
                                                        </div>
                                                    </BlockStack>
                                                )}
                                            </BlockStack>
                                        </InlineStack>
                                    </BlockStack>
                                    {!!item.billingData && (
                                        <BlockStack gap={'xxs'}>
                                            <div
                                                className={`date ${
                                                    warnDueDate ? 'alert' : ''
                                                }`}
                                            >
                                                Due&nbsp;
                                                {dueDays
                                                    ? `${dueDays} days`
                                                    : '' || '(never)'}
                                            </div>
                                            <div className="date">
                                                Paid to&nbsp;
                                                {paidToDate || '(never)'}
                                            </div>
                                            <div className="owed alert">
                                                {amountPastDue || '$0'} past due
                                            </div>
                                            <div className="owed">
                                                {amountOwed || '$0'} owed
                                            </div>
                                        </BlockStack>
                                    )}
                                </Grid>
                            )
                        },
                    },
                ]}
            />
        </div>
    )
}
