import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import fetchAPI from '../../common/fetchAPI'
import useError from '../../common/hooks/useError'
import Button from '../../components/common/Button'
import useToast from '../../common/hooks/useToast'
import ErrorMessage from '../../components/common/ErrorMessage'
import TextInput from '../../components/common/data-form/TextInput'
import SectionContainer from '../../components/common/SectionContainer'

function ForgotPassword() {
    const [error, setError] = useError()
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState({ email: '' })
    const navigate = useNavigate()
    const setToast = useToast()

    const onSubmit = async (e) => {
        e.preventDefault()
        setError('')

        const { email } = data

        setLoading(true)
        const { error } = await fetchAPI(
            `/v1/auth/forgot-password`,
            { email },
            'POST',
        )
        setLoading(false)

        if (error) {
            setError(error)
            return
        }

        setToast('A link has been sent to your email')
        navigate('/login')
    }

    return (
        <SectionContainer>
            <div className="hero">
                <h1>Reset password</h1>
            </div>
            {error && (
                <ErrorMessage onDismiss={() => setError('')}>
                    {error}
                </ErrorMessage>
            )}

            <form onSubmit={onSubmit}>
                <label htmlFor="email">Email</label>
                <TextInput
                    id="email"
                    name="email"
                    type="email"
                    value={data.email}
                    onChange={(v) => setData({ ...data, email: v })}
                    required
                    placeholder="name@email.com"
                />

                <div>
                    <Button
                        type="submit"
                        text="Reset"
                        isLoading={loading}
                        info={'We will send you an email with a reset link'}
                    />
                </div>
            </form>
            <footer>
                <div className="links">
                    Back to <Link to="/login">Login</Link>
                </div>
            </footer>
        </SectionContainer>
    )
}

export default ForgotPassword
