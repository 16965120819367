import { formatDateTime, getUserDisplayName } from '../../../common/helpers'
import ResourceList from '../../../components/admin/ResourceList'
import MessageSection from '../../../components/common/MessageSection'
import SectionContainer from '../../../components/common/SectionContainer'
import Tag from '../../../components/common/Tag'

export default function ReferralsList() {
    return (
        <>
            <ResourceList
                paginated
                paginationLimit={10}
                filters={{
                    sort: {
                        name: {
                            label: 'Name',
                            dbFields: ['lastName', 'firstName', 'email'],
                            asc: true,
                        },
                    },
                    filters: {
                        subscribedPremium: {
                            label: 'Premium',
                            dbField: 'subscribedPremium',
                            options: [
                                {
                                    label: 'Any',
                                    value: 'undefined',
                                },
                                {
                                    label: 'Yes',
                                    value: 'true',
                                },
                            ],
                        },
                    },
                }}
                title={'Referrals'}
                apiUrl={'/users/referrals'}
                getItemsFromResponse={(data) => data?.results || []}
                fields={[
                    {
                        column: 1,
                        getValue: (item) => getUserDisplayName(item.user),
                    },
                    {
                        column: 1,
                        getValue: (item) =>
                            `Referred: ${getUserDisplayName(
                                item.referredUser,
                            )}`,
                    },
                    {
                        column: 1,
                        getValue: (item) => formatDateTime(item.creationDate),
                        getClassName: () => 'subtitle',
                    },
                    {
                        column: 1,
                        getValue: (item) =>
                            item.subscribedPremium ? (
                                <Tag outline>Premium</Tag>
                            ) : null,
                    },
                ]}
            />
        </>
    )
}
