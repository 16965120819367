export default function BlockStack({
    children,
    gap,
    flushMargins,
    itemsCenter,
    itemsStart,
    itemsEnd,
}) {
    let finalClassName = 'block-stack'
    if (gap) finalClassName += ` gap-${gap}`
    if (itemsCenter) finalClassName += ' items-center'
    if (itemsStart) finalClassName += ' items-start'
    if (itemsEnd) finalClassName += ' items-end'
    if (flushMargins) finalClassName += ' flush-margins'
    return <div className={finalClassName}>{children}</div>
}
