import { useRef, useState } from 'react'
import { ICONS } from '../../common/constants'
import Tag from '../common/Tag'
import { useSearchParams } from 'react-router-dom'
import Popover from '../common/Popover'
import SortPopoverContent from '../common/SortPopoverContent'
import TextInput from '../common/data-form/TextInput'
import InlineStack from '../common/InlineStack'
import SearchIcon from '../../assets/icons/SearchIcon'
import ActionItem from '../common/ActionItem'

function ResourceListFilters({
    filters,
    currSort,
    setSearchQuery,
    toggleSort,
    openFilters,
    hasActiveFilter,
    currFilter,
    onFilterDismiss,
    hideFilterTags,
    searchPlaceholder,
    onSortSubmit,
    onToggleSelect,
    isSelectableFinal,
    bulkSelectActions,
    selectedItems = [],
}) {
    // Check if URL params has a q value
    const [searchParams] = useSearchParams()
    const initQ = searchParams.get('f_q') || ''
    const [q, setQ] = useState(initQ)
    const popoverRef = useRef(undefined)
    const bulkActionsRef = useRef(undefined)

    function handleChange(v) {
        setQ(v)
        setSearchQuery(v)
    }

    function handleSortSubmit(data) {
        onSortSubmit(data)
        popoverRef.current.closePopover()
    }

    return (
        <div className="resource-list-filters">
            <div>
                {Boolean(filters?.search) && (
                    <div className="search-input-container">
                        <TextInput
                            onChange={handleChange}
                            value={q}
                            placeholder={' '}
                            withClear
                            placeholderContent={
                                <InlineStack gap={'sm'}>
                                    <SearchIcon />
                                    <span>{searchPlaceholder || 'Search'}</span>
                                </InlineStack>
                            }
                        />
                        <div></div>
                    </div>
                )}

                {Boolean(
                    filters?.sort ||
                        Object.values(filters?.filters || [])?.some(
                            (f) => !f.tabs,
                        ),
                ) && (
                    <button
                        className="filter-toggle"
                        onClick={openFilters}
                        type="button"
                    >
                        <img
                            src={`/img/icons/filter-white.svg`}
                            alt="filter"
                            width="24"
                            height="24"
                        />
                        {Boolean(hasActiveFilter) && (
                            <div className="filter-badge"></div>
                        )}
                    </button>
                )}
                {Boolean(filters?.sort) && (
                    <Popover
                        ref={popoverRef}
                        position="left"
                        onClick={toggleSort}
                        isLongPress
                        renderActivator={(ref, listeners) => (
                            <button
                                ref={ref}
                                {...listeners}
                                className="sort-toggle"
                                type="button"
                            >
                                {currSort?.isAsc ? (
                                    <img
                                        src={ICONS.ARROW_UP_A_Z_WHITE}
                                        alt="arrow up"
                                        width="16"
                                        height="16"
                                    />
                                ) : (
                                    <img
                                        src={ICONS.ARROW_DOWN_Z_A_WHITE}
                                        alt="arrow down"
                                        width="16"
                                        height="16"
                                    />
                                )}
                                {Boolean(currSort?.key) && (
                                    <small>
                                        {filters.sort[currSort?.key].label}
                                    </small>
                                )}
                            </button>
                        )}
                    >
                        <SortPopoverContent
                            onSubmit={handleSortSubmit}
                            filters={filters}
                            currSort={currSort}
                        />
                    </Popover>
                )}
                {!!onToggleSelect && (
                    <button
                        className="bulk-select-toggle"
                        onClick={onToggleSelect}
                        type="button"
                    >
                        <img
                            src={
                                isSelectableFinal
                                    ? ICONS.STACKED_SQUARES_ACTIVE
                                    : ICONS.STACKED_SQUARES_WHITE
                            }
                            alt="bulk select"
                            width="24"
                            height="24"
                        />
                    </button>
                )}
                {!!onToggleSelect &&
                    isSelectableFinal &&
                    !!bulkSelectActions?.length && (
                        <Popover
                            closeOnChildClick
                            ref={bulkActionsRef}
                            renderActivator={(ref, listeners) => (
                                <div ref={ref}>
                                    <button
                                        className="bulk-select-actions actions-btn"
                                        // onClick={onToggleSelect}
                                        type="button"
                                        {...listeners}
                                    >
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </button>
                                </div>
                            )}
                        >
                            {bulkSelectActions.map((action, i) => (
                                <ActionItem
                                    key={i}
                                    onClick={() =>
                                        action.onClick(selectedItems)
                                    }
                                    icon={action.icon}
                                >
                                    {action.title}
                                </ActionItem>
                            ))}
                        </Popover>
                    )}
            </div>
            {hasActiveFilter && (
                <div>
                    {!hideFilterTags &&
                        Object.entries(currFilter || {})
                            .filter(
                                ([_k, v]) =>
                                    v !== undefined && v !== 'undefined',
                            )
                            .filter(([k, v]) => {
                                const filter = filters?.filters?.[k]
                                if (!filter) return false

                                if (filter.type) {
                                    return !!v
                                }

                                if (
                                    !filter.options.some((o) =>
                                        [
                                            undefined,
                                            'undefined',
                                            null,
                                            'null',
                                        ].includes(o.value),
                                    )
                                ) {
                                    return false
                                }
                                return true
                            })
                            .map(([k, v]) => {
                                const filter = filters?.filters?.[k]
                                if (!filter) return null
                                return (
                                    <Tag
                                        color="primary"
                                        outline
                                        onClose={() => onFilterDismiss(k)}
                                        key={k}
                                    >{`${filter.label}: ${
                                        filter?.options?.find(
                                            (o) => o.value === v,
                                        )?.label || v
                                    }`}</Tag>
                                )
                            })}
                </div>
            )}
        </div>
    )
}

export default ResourceListFilters
