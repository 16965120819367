import { Link } from 'react-router-dom'
import { BILLING_METHODS, ICONS } from '../../common/constants'
import fetchAPI from '../../common/fetchAPI'
import useAuth from '../../common/hooks/useAuth'
import useData from '../../common/hooks/useData'
import useToast from '../../common/hooks/useToast'
import Button from '../../components/common/Button'
import ErrorMessage from '../../components/common/ErrorMessage'
import InfoList from '../../components/common/InfoList'
import Spinner from '../../components/common/Spinner'
import InlineStack from '../../components/common/InlineStack'
import InlineStackItem from '../../components/common/InlineStackItem'

export default function BillingMethodInfo({ userId, contactId, orgId }) {
    const auth = useAuth()
    const setToast = useToast()

    let url = ''
    if (contactId) {
        url = `/v1/contacts/${contactId}?fields=email&populate=billingMethods`
    } else if (orgId) {
        url = `/v1/organizations/${orgId}?fields=name&populate=billingMethods`
    } else {
        url = `/v1/users/${userId || auth.user.id}?fields=email&populate=billingMethods`
    }

    const {
        billingMethods,
        billingMethodsError,
        billingMethodsValidating,
        billingMethodsLoading,
        billingMethodsMutate,
    } = useData(url, 'billingMethods', (data) => data?.billingMethods || [])

    // if (!billingMethods?.length) {
    //     return 'No billing methods (e.g. PayPal) found.'
    // }

    async function setPreferred(item) {
        let url
        if (userId) {
            url = `/v1/users/${userId}`
        } else if (contactId) {
            url = `/v1/contacts/${contactId}`
        } else if (orgId) {
            url = `/v1/organizations/${orgId}`
        } else {
            url = `/v1/users/${auth.user.id}`
        }

        const { error } = await fetchAPI(
            url,
            {
                billingMethods: billingMethods.map((m) => ({
                    id: m.id || m._id,
                    method: m.method,
                    email: m.email,
                    username: m.username,
                    isPreferred: m === item ? !m.isPreferred : false,
                })),
            },
            'PATCH',
        )
        if (error) {
            setToast(error, 'alert')
            return
        }

        billingMethodsMutate()
    }

    if (billingMethodsLoading || billingMethodsValidating) {
        return <Spinner />
    }

    if (billingMethodsError) {
        return <ErrorMessage section>{billingMethodsError}</ErrorMessage>
    }

    let editUrl
    if (contactId) {
        editUrl = `/contacts/${contactId}/edit?type=contact&t_title=Billing`
    } else if (orgId) {
        editUrl = `/organizations/${orgId}/edit?type=company&t_title=Billing`
    } else {
        editUrl = `/users/${userId || auth.user.id}/edit?type=user&t_title=Billing`
    }

    return (
        <>
            <InfoList
                noIcons
                rows={Object.entries(BILLING_METHODS).map(([k, v]) => {
                    const existingMethod = billingMethods.find(
                        (m) => m.method === k && (m.username || m.email),
                    )

                    if (!existingMethod) {
                        return {
                            content: (
                                <InlineStack itemsStart gap="sm">
                                    <InlineStackItem shrink0>
                                        <div className="text-subdued">
                                            {v.label}
                                        </div>
                                    </InlineStackItem>
                                    <div>
                                        <Link
                                            to={`${editUrl}&scrollField=${v.fieldKey}`}
                                        >
                                            Edit
                                        </Link>
                                    </div>
                                </InlineStack>
                            ),
                        }
                    }

                    return {
                        content: (
                            <>
                                <InlineStack itemsCenter gap="sm">
                                    <InlineStackItem shrink0>
                                        <div className="text-subdued">
                                            {v.label}
                                        </div>
                                    </InlineStackItem>
                                    <div>
                                        {existingMethod.email ||
                                            existingMethod.username}
                                    </div>
                                    <Button
                                        className="vertical-middle"
                                        icon={
                                            existingMethod.isPreferred
                                                ? ICONS.STAR_FILLED_WHITE
                                                : ICONS.STAR_WHITE
                                        }
                                        inline
                                        plain
                                        tiny
                                        onClick={() =>
                                            setPreferred(existingMethod)
                                        }
                                    />
                                </InlineStack>
                            </>
                        ),
                    }
                })}
            />
        </>
    )
}
