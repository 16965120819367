import { useState } from 'react'
import useAuth from '../../../common/hooks/useAuth'
import useToast from '../../../common/hooks/useToast'
import BlockStack from '../../../components/common/BlockStack'
import Button from '../../../components/common/Button'
import fetchAPI from '../../../common/fetchAPI'

function PremiumBanner({ requestFeature }) {
    const auth = useAuth()
    const setToast = useToast()
    const [requestLoading, setRequestLoading] = useState(false)

    let text = 'Premium feature'
    let buttonText = 'Upgrade now'

    if (requestFeature && !auth?.isPremiumUser) {
        text = 'Request access to this feature by upgrading to Premium'
        buttonText = 'Request and upgrade now'
    } else if (requestFeature) {
        text = `Request access to this feature`
        buttonText = 'Request'
    }

    async function onRequest() {
        setRequestLoading(true)
        const { error } = await fetchAPI(
            '/v1/app-billing/feature-request',
            { requestFeature },
            'POST',
        )
        setRequestLoading(false)

        if (error) {
            setToast(error, 'alert')
            return
        }

        setToast('Feature request sent')
    }

    return (
        <div className="premium-banner">
            <BlockStack gap={'sm'}>
                <div>
                    <strong className="text-gold">{text}</strong>
                </div>
                <Button
                    isLoading={requestLoading}
                    small
                    alignRight
                    outline
                    white
                    gold
                    onClick={
                        requestFeature && auth?.isPremiumUser
                            ? onRequest
                            : undefined
                    }
                    href={
                        !auth?.isPremiumUser
                            ? `/profile/billing/purchase-premium?${requestFeature ? 'requestFeature=' + requestFeature : ''}`
                            : undefined
                    }
                    text={buttonText}
                />
            </BlockStack>
        </div>
    )
}

export default PremiumBanner
