import React from 'react'
import MessageSection from './MessageSection'
import Break from './Break'
import SectionContainer from './SectionContainer'

export default function ErrorMessage({ children, section, onDismiss }) {
    const sectionHtml = (
        <>
            <MessageSection title="Error" onDismiss={onDismiss} type="alert">
                {children}
            </MessageSection>

            <Break />
        </>
    )
    if (!section) {
        return sectionHtml
    }
    return <SectionContainer>{sectionHtml}</SectionContainer>
}
