import ResourceList from '../../../components/admin/ResourceList'
import useModal from '../../../common/hooks/useModal'
import { formatMoney } from '../../../common/helpers'

function ProjectedPayoutsList(props) {
    const { setModal } = useModal()

    function openTempPopup(item) {
        const rows = []
        const headers = [
            {
                property: 'orderNumber',
                info: '',
                title: 'Order',
                bg: '1',
            },
            {
                property: 'orderFees',
                info: 'Total amount of order transaction fees',
                title: 'Fees',
                bg: '1',
            },
            {
                property: 'paidInPast',
                info: 'Fees of this order deducted from past vendor payouts',
                title: 'Fees paid',
                bg: '1',
            },
            {
                property: 'toPay',
                info: 'Fees - fees paid',
                title: 'Fees to pay',
                bg: '1',
            },
            {
                property: 'fulfillment',
                info: '',
                title: 'Fulfillment #',
                bg: '2',
            },
            {
                property: 'fulfillmentCreated',
                info: '',
                title: 'Fulf. date',
                bg: '2',
            },
            {
                property: 'lineName',
                info: '',
                title: 'Item',
                bg: '3',
            },
            {
                property: 'refundedAt',
                info: '',
                title: 'Refund date',
                bg: '3',
            },
            {
                property: 'quantity',
                info: '',
                title: 'Quantity',
                bg: '4',
            },
            {
                property: 'refundedQuantity',
                info: '',
                title: 'Refunded Q.',
                bg: '4',
            },
            {
                property: 'pastPaidRefundedQuantity',
                info: 'Refunded quantity accounted for in past vendor payouts',
                title: 'Paid for Refunded Q.',
                bg: '4',
            },
            {
                property: 'currPaidRefundedQuantity',
                info: 'Refunded quantity to account for in current payout',
                title: 'New Refunded Q.',
                bg: '4',
            },
            {
                property: 'unitPrice',
                info: '',
                title: 'Unit price',
            },
            {
                property: 'unitCost',
                info: '',
                title: 'Unit cost',
            },
            {
                property: 'unitDiscount',
                info: '',
                title: 'Unit discount',
            },
            {
                property: 'vendorNet',
                info: '',
                title: 'Vendor net',
            },
            {
                property: 'commission',
                info: '',
                title: 'Commission',
            },
            {
                property: 'payout',
                info: 'Net - commission',
                title: 'Item payout',
            },
        ]
        for (const key of Object.keys(item.detailed).sort()) {
            const data = item.detailed[key]
            rows.push({
                orderNumber: key,
                orderFees: formatMoney(
                    data.transactionFees?.orderFees || 0,
                    false,
                    'shopify',
                ),
                paidInPast: formatMoney(
                    data.transactionFees?.paidInPast || 0,
                    false,
                    'shopify',
                ),
                toPay:
                    '-' +
                    formatMoney(
                        data.transactionFees?.toPay || 0,
                        false,
                        'shopify',
                    ),
                style: { borderTop: '10px solid white' },
            })
            let fIndex = 1
            for (const fId of Object.keys(
                item.detailed[key].fulfillments || [],
            )) {
                const { lineItems, createdAt } =
                    item.detailed[key].fulfillments[fId]
                let lIndex = 1
                for (const lineName of Object.keys(lineItems)) {
                    // const line = lineItems[lineName]
                    const rowData = {
                        lineName: <small>{lineName.split('_')[0]}</small>,
                        quantity: lineItems[lineName].lineItemAmounts.quantity,
                        unitPrice: formatMoney(
                            lineItems[lineName].lineItemAmounts.unitPrice,
                            false,
                            'shopify',
                        ),
                        unitCost: formatMoney(
                            lineItems[lineName].lineItemAmounts.unitCost,
                            false,
                            'shopify',
                        ),
                        unitDiscount: formatMoney(
                            lineItems[lineName].lineItemAmounts.unitDiscount,
                            false,
                            'shopify',
                        ),
                        vendorNet: formatMoney(
                            lineItems[lineName].lineItemAmounts.vendorNet,
                            false,
                            'shopify',
                        ),
                        commission:
                            '-' +
                            formatMoney(
                                lineItems[lineName].lineItemAmounts.commission,
                                false,
                                'shopify',
                            ),
                        payout: formatMoney(
                            lineItems[lineName].lineItemAmounts.payout,
                            false,
                            'shopify',
                        ),
                    }
                    if (lIndex === 1) {
                        rowData.fulfillment = fIndex
                        rowData.fulfillmentCreated = createdAt
                            .substring(0, 16)
                            .replace('T', '\n')
                        rowData.style = { borderTop: '5px solid white' }
                    }
                    rows.push(rowData)
                    lIndex++
                }
                fIndex++
            }
            for (const lineName of Object.keys(
                item.detailed[key]?.refunds || {},
            )) {
                const refundItem = item.detailed[key].refunds[lineName]
                rows.push({
                    fulfillment: 'RETURN',
                    refundedAt: refundItem.refundedAt
                        .substring(0, 16)
                        .replace('T', ' '),
                    lineName: <small>{lineName.split('_')[0]}</small>,
                    refundedQuantity: '-' + refundItem.refundedQuantity,
                    pastPaidRefundedQuantity:
                        '-' + refundItem.refundQuantityPaidInPastPayouts,
                    currPaidRefundedQuantity:
                        '-' + refundItem.remainingRefundQuantityForThisPayout,
                    vendorNet:
                        '-' +
                        formatMoney(
                            refundItem.vendorNetReductionForThisPayout,
                            false,
                            'shopify',
                        ),
                    commission: formatMoney(
                        refundItem.commissionReductionForThisPayout,
                        false,
                        'shopify',
                    ),
                    payout:
                        '-' +
                        formatMoney(
                            refundItem.payoutAmountForThisPayout,
                            false,
                            'shopify',
                        ),
                    style: { borderTop: '5px solid white' },
                })
            }
        }
        setModal(
            <table className="temp-payout-table">
                {/* eslint-disable-next-line react/no-unknown-property */}
                <style jsx>{`
                    .modal .modal-container {
                        max-width: unset;
                    }
                    .temp-payout-table th,
                    .temp-payout-table td {
                        text-align: right;
                        vertical-align: top;
                    }
                    .temp-payout-table td {
                        border-bottom: 1px solid gray;
                    }
                    .temp-payout-table td {
                    }
                    .temp-payout-table {
                        line-height: 1;
                        font-size: 12px;
                        overflow-x: auto;
                        table-layout: fixed;
                        width: 1200px;
                        border-collapse: collapse;
                    }
                    .temp-payout-table small {
                        display: block;
                        font-size: 12px;
                        opacity: 0.5;
                    }
                    .bg-1 {
                        background: #25365a;
                    }
                    .bg-2 {
                        background: #152850;
                    }
                    .bg-3 {
                        background: #162034;
                    }
                    .bg-4 {
                        background: #0f2042;
                    }
                    .bg-5 {
                        background: #252f44;
                    }
                `}</style>
                <thead>
                    <tr>
                        {headers.map((header) => (
                            <th
                                style={{
                                    width: `${(1 / headers.length) * 100}%`,
                                }}
                                className={`bg-${header.bg}`}
                                key={header.property}
                            >
                                {header.title}
                                <small>{header.info}</small>
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {rows.map((row, i) => (
                        <tr style={row.style || {}} key={i}>
                            {headers.map((header) => (
                                <td
                                    className={`bg-${header.bg} amount-${header.property}`}
                                    style={{
                                        width: `${(1 / headers.length) * 100}%`,
                                    }}
                                    key={header.property}
                                >
                                    {row[header.property] || '-'}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>,
        )
    }

    /**
     * Array.from(document.querySelectorAll(".amount-vendorNet")).map(el => el.innerText).filter(v => v !== "-").map(v => v.replace("$", "")).reduce((a,b) => Number(a) + Number(b), 0)
     */
    return (
        <ResourceList
            apiUrl={`/wholesale/show-payouts`}
            initialQuery={{
                month: props.month,
                year: props.year,
                vendor: props.vendor,
                shop: props.shop || undefined,
            }}
            onItemClick={openTempPopup}
            fields={[
                {
                    column: 1,
                    getValue: (item) => (
                        <>
                            <p>Vendor: {item.vendor}</p>
                            <p>Quantity: {item.quantity}</p>
                            {/* <p>Commissions: {item.commission}</p> */}
                            <p>Transactions: {item.transaction}</p>
                            <p>Refunds: {item.refund}</p>
                            <p>Payout: {item.payout}</p>
                        </>
                    ),
                },
            ]}
        />
    )
}

export default ProjectedPayoutsList
