import { useState } from 'react'
import useError from '../../../common/hooks/useError'
import useToast from '../../../common/hooks/useToast'
import { useNavigate } from 'react-router-dom'
import fetchAPI from '../../../common/fetchAPI'
import TextInput from '../../../components/common/data-form/TextInput'
import Button from '../../../components/common/Button'
import MainButton from '../../../components/admin/MainButton'
import AddItemsFieldSet from '../../../components/common/data-form/AddItemsFieldSet'
import ContactFormPreview from './ContactFormPreview'
import useModal from '../../../common/hooks/useModal'
import ErrorMessage from '../../../components/common/ErrorMessage'
import CheckboxInput from '../../../components/common/data-form/CheckboxInput'
import SectionContainer from '../../../components/common/SectionContainer'

const initFields = [
    {
        fieldType: 'text',
        label: 'My first field',
    },
]

export default function CreateContactForm() {
    const [data, setData] = useState({
        title: '',
        whitelistedDomains: '',
        fields: initFields,
        isSaveLead: false,
    })
    const [error, setError, errorFields] = useError()
    const [loading, setLoading] = useState()
    const setToast = useToast()
    const navigate = useNavigate()
    const { setModal } = useModal()

    const [extraFieldSetNames, setExtraFieldSetNames] = useState([])
    const [newExtraFieldSetName, setNewExtraFieldSetName] = useState('')

    function handleExtraFieldSetAdd() {
        setExtraFieldSetNames([...extraFieldSetNames, newExtraFieldSetName])
        setNewExtraFieldSetName('')

        const newFieldData = initFields.map((f) => ({
            ...f,
            groupName: newExtraFieldSetName,
        }))
        setData({
            ...data,
            fields: [...data.fields, ...newFieldData],
        })
    }

    function handleExtraFieldSetRemoval(name) {
        setExtraFieldSetNames(extraFieldSetNames.filter((n) => n !== name))
        setData({
            ...data,
            fields: data.fields.filter((field) => field.groupName !== name),
        })
    }

    function handlePreview() {
        setModal(
            // TODO: hide if isSaveLead
            <ContactFormPreview data={data} />,
            "Preview: Appearance will depend on your site's CSS",
        )
    }

    async function handleSubmit(e) {
        setError('')
        e.preventDefault()

        if (!data.fields.length) {
            setError('You need to add at least one field.')
            return
        }

        setLoading(true)

        const { error, meta } = await fetchAPI(
            `/v1/contact-forms`,
            {
                ...data,
                whitelistedDomains: data.whitelistedDomains.split(','),
                fields: data.fields.map((field) => ({
                    ...field,
                    value: undefined,
                    options:
                        field.fieldType === 'select'
                            ? field.options?.split(',')
                            : undefined,
                })),
            },
            'POST',
        )
        setLoading(false)

        if (error) {
            setError(error, meta?.fields)
            return
        }

        setToast('Saved')
        navigate('/contact-forms')
    }

    return (
        <SectionContainer>
            {error && (
                <ErrorMessage onDismiss={() => setError('')}>
                    {error}
                </ErrorMessage>
            )}

            <form onSubmit={handleSubmit}>
                <label htmlFor={'form-title'} className="required">
                    Title
                </label>
                <TextInput
                    id={'form-title'}
                    value={data.title}
                    onChange={(v) =>
                        setData({
                            ...data,
                            title: v,
                        })
                    }
                    required
                    errors={errorFields.filter((f) => f === 'title')}
                />

                <label htmlFor={'form-domains'} className="required">
                    Whitelisted domains
                </label>
                <small className="input-info">
                    Separate domains with a comma. The contact form won&apos;t
                    accept submissions from other domains.
                </small>
                <TextInput
                    id={'form-domains'}
                    value={data.whitelistedDomains}
                    placeholder={'example.com,www.example.org'}
                    onChange={(v) =>
                        setData({
                            ...data,
                            whitelistedDomains: v,
                        })
                    }
                    required
                    errors={errorFields.filter((f) =>
                        f.startsWith('whitelistedDomains'),
                    )}
                />

                <label htmlFor={'form-recaptcha'}>
                    ReCAPTCHA v2 Checkbox Site Key
                </label>
                <small className="input-info">
                    <a
                        href="https://www.google.com/recaptcha/admin/create"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Create one here
                    </a>{' '}
                    and add it to enable ReCAPTCHA.
                </small>
                <TextInput
                    id={'form-recaptcha'}
                    value={data.recaptchaSiteKey}
                    onChange={(v) =>
                        setData({
                            ...data,
                            recaptchaSiteKey: v,
                        })
                    }
                    errors={errorFields.filter((f) => f === 'recaptchaSiteKey')}
                />

                <label htmlFor={'form-recaptcha-secret'}>
                    ReCAPTCHA v2 Checkbox Secret Key
                </label>
                <TextInput
                    id={'form-recaptcha-secret'}
                    value={data.recaptchaSecretKey}
                    onChange={(v) =>
                        setData({
                            ...data,
                            recaptchaSecretKey: v,
                        })
                    }
                    errors={errorFields.filter(
                        (f) => f === 'recaptchaSecretKey',
                    )}
                />

                <CheckboxInput
                    id="form-save-lead"
                    label="Save submissions as leads"
                    value={data.isSaveLead}
                    onChange={(v) =>
                        setData({
                            ...data,
                            isSaveLead: v,
                        })
                    }
                    info={
                        'Save the form submission as a lead in the CRM. Field groups are not allowed. Requires at least a first name and last name field.'
                    }
                />
                <br />

                <AddItemsFieldSet
                    data={data}
                    setData={setData}
                    errors={errorFields.filter((f) => f.startsWith(`fields_`))}
                />

                {!data.isSaveLead &&
                    extraFieldSetNames.map((name) => (
                        <div key={name}>
                            <AddItemsFieldSet
                                title={name}
                                data={data}
                                setData={setData}
                                deleteFieldSet={() =>
                                    handleExtraFieldSetRemoval(name)
                                }
                                errors={errorFields.filter((f) =>
                                    f.startsWith(`fields_`),
                                )}
                            />
                        </div>
                    ))}

                {!data.isSaveLead && (
                    <>
                        <label htmlFor="form-next-set">New field group</label>
                        <TextInput
                            id={'form-next-set'}
                            value={newExtraFieldSetName}
                            onChange={(v) => setNewExtraFieldSetName(v)}
                        />
                        <Button
                            text="Add field group"
                            outline
                            disabled={!newExtraFieldSetName}
                            onClick={handleExtraFieldSetAdd}
                        />
                    </>
                )}

                <Button
                    text="Preview"
                    isLoading={loading}
                    outline
                    type={'button'}
                    onClick={handlePreview}
                />

                <Button text="Save" isLoading={loading} type={'submit'} />

                <MainButton
                    disabled={loading}
                    functionality="SAVE"
                    loading={loading}
                />
            </form>
        </SectionContainer>
    )
}
