import { useNavigate, useSearchParams } from 'react-router-dom'
import ErrorMessage from '../../../components/common/ErrorMessage'
import { useEffect } from 'react'
import fetchAPI from '../../../common/fetchAPI'
import useError from '../../../common/hooks/useError'
import useToast from '../../../common/hooks/useToast'
import SectionContainer from '../../../components/common/SectionContainer'

export default function AcceptOrgInviteCallback() {
    const [searchParams] = useSearchParams()
    const token = searchParams.get('token')
    const inviteId = searchParams.get('inviteId')
    const navigate = useNavigate()
    const [error, setError] = useError()
    const setToast = useToast()

    useEffect(() => {
        const acceptInvite = async () => {
            if (!token || !inviteId) {
                setError('Invalid invitation. Try to visit the link again.')
                return
            }
            const { error } = await fetchAPI(
                `/v1/organizations/accept-invite?token=${token}&inviteId=${inviteId}`,
                {},
                'POST',
            )

            if (error) {
                setError(error)
                return
            }

            setToast('Invitation accepted')
            navigate('/profile')
        }

        acceptInvite()
    }, [])

    if (error) {
        return (
            <SectionContainer>
                <ErrorMessage>{error}</ErrorMessage>
            </SectionContainer>
        )
    }

    return null
}
