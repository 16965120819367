import { useParams } from 'react-router-dom'
import useData from '../../../common/hooks/useData'
import Spinner from '../../../components/common/Spinner'
import ErrorMessage from '../../../components/common/ErrorMessage'
import MessageSection from '../../../components/common/MessageSection'
import useAuth from '../../../common/hooks/useAuth'
import Button from '../../../components/common/Button'
import { useState } from 'react'
import fetchAPI from '../../../common/fetchAPI'
import useToast from '../../../common/hooks/useToast'
import DataFormNew from '../../../components/common/DataFormNew'
import ResourceList from '../../../components/admin/ResourceList'
import { ICONS } from '../../../common/constants'
import SectionContainer from '../../../components/common/SectionContainer'
import Break from '../../../components/common/Break'

export default function InviteOrganizationMember() {
    const { organizationId } = useParams()
    const auth = useAuth()
    const [claimLoading, setClaimLoading] = useState(false)
    const [inviteCancelLoading, setInviteCancelLoading] = useState(false)
    const [listKey, setListKey] = useState(Date.now())
    const setToast = useToast()

    const {
        organization,
        organizationError,
        organizationLoading,
        organizationMutate,
    } = useData(
        `/v1/organizations/${organizationId}?fields=name,members.role,members.userId,members._id,organizationType`,
        'organization',
    )

    const member = organization?.members?.find((m) => m.userId === auth.user.id)

    async function claimOwnership() {
        setClaimLoading(true)
        const url = `/v1/organizations/${organizationId}/members/${member.id}`
        const { error } = await fetchAPI(
            url,
            {
                role: 'admin',
            },
            'PATCH',
        )
        setClaimLoading(false)
        if (error) {
            setToast(error, 'alert')
            return
        }
        setToast('Ownership claimed')
        organizationMutate()
    }

    async function cancelInvite(id) {
        setInviteCancelLoading(true)
        const url = `/v1/organizations/invites/${id}`
        const { error } = await fetchAPI(url, {}, 'DELETE')
        setInviteCancelLoading(false)

        if (error) {
            setToast(error, 'alert')
            return
        }

        setToast('Invite canceled')
        setListKey(Date.now())
    }

    if (organizationLoading) {
        return <Spinner />
    }

    if (organizationError) {
        return (
            <SectionContainer>
                <ErrorMessage>{organizationError}</ErrorMessage>
            </SectionContainer>
        )
    }

    if (
        !['owner', 'admin'].includes(member.role) &&
        organization.members.some((m) => ['owner', 'admin'].includes(m.role))
    ) {
        return (
            <SectionContainer>
                <ErrorMessage>
                    {/* TODO: allow admins, owners to assign roles */}
                    {`You don't have the required permissions to invite members. Ask an admin to invite members.`}
                </ErrorMessage>{' '}
            </SectionContainer>
        )
    }

    if (
        !['owner', 'admin'].includes(member?.role) &&
        organization?.members?.length > 1
    ) {
        return (
            <SectionContainer>
                <ErrorMessage>{`Contact Support to become an admin first.`}</ErrorMessage>
            </SectionContainer>
        )
    }

    if (
        organization?.members?.length === 1 &&
        !['owner', 'admin'].includes(member?.role)
    ) {
        return (
            <SectionContainer>
                <MessageSection type="info">
                    {`If you are the admin of this ${organization.organizationType}, claim ownership first.`}
                    <Break />
                    <Button
                        isLoading={claimLoading}
                        small
                        outline
                        white
                        text="Claim ownership"
                        onClick={claimOwnership}
                    />
                </MessageSection>
            </SectionContainer>
        )
    }

    return (
        <>
            <SectionContainer>
                <DataFormNew
                    url={`/v1/organizations/${organizationId}/members/invite`}
                    submitText="Invite member"
                    submitToast="The user will receive an invite email."
                    inputs={[
                        {
                            key: 'email',
                            label: 'Email',
                            type: 'email',
                            infoBottom:
                                'We will send an invite email to this address.',
                        },
                    ]}
                    mutationRegexes={[/\/v1\/organizations\/invites*/]}
                    submitNavArg={`/${
                        organization.organizationType === 'company'
                            ? 'companies'
                            : 'organizations'
                    }/${organizationId}?isOwnProfile=true`}
                />
            </SectionContainer>

            <ResourceList
                title="Pending invites"
                key={listKey}
                emptyText={'No pending invites'}
                smallTitle
                initialQuery={{
                    creatorId: auth.user.id,
                    resourceId: organizationId,
                }}
                apiUrl={'/organizations/invites'}
                fields={[
                    {
                        column: 1,
                        getValue: (item) =>
                            item.invitedEmail || item.invitedTempEmail,
                    },
                ]}
                actions={[
                    {
                        title: 'Cancel',
                        loading: inviteCancelLoading,
                        icon: ICONS.TRASH_RED,
                        onClick: (item) => {
                            cancelInvite(item.id)
                        },
                    },
                ]}
            />
        </>
    )
}
