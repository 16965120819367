// .acronym ok
import { useSearchParams } from 'react-router-dom'
import fetchAPI from '../../../common/fetchAPI'
import useDebounceFn from '../../../common/hooks/useDebounceFn'
import DataFormNew from '../../../components/common/DataFormNew'
import SectionContainer from '../../../components/common/SectionContainer'

export default function CreateOrganization() {
    // Get search params
    const [searchParams] = useSearchParams()
    const userId = searchParams.get('userId')

    async function getAcronymPreview(name) {
        if (!name) return 'Acronym preview: -'
        const { responseData, error } = await fetchAPI(
            `/v1/organizations/generate-acronym?name=${name}`,
        )
        if (error) return 'Error while getting preview.'
        return `Acronym preview: ${responseData.acronym}`
    }

    const debounceGetAcronymPreview = useDebounceFn(getAcronymPreview, 500)

    return (
        <SectionContainer>
            <DataFormNew
                url={'/v1/organizations'}
                submitText="Save"
                submitToast="Saved"
                submitNavArg={'/contacts'}
                getBody={(data) => {
                    const newData = { ...data }
                    if (newData.userId) {
                        newData.members = [{ userId: newData.userId }]
                    }
                    delete newData.userId
                    return newData
                }}
                inputs={[
                    {
                        key: 'name',
                        label: 'Name',
                        required: true,
                        autoComplete: 'new-password',
                        getPreviewText: debounceGetAcronymPreview,
                    },
                    {
                        key: 'organizationType',
                        label: 'Type',
                        type: 'select',
                        options: [
                            { value: 'group', label: 'Group' },
                            { value: 'company', label: 'Company' },
                            { value: 'family', label: 'Family' },
                        ],
                        defaultValue:
                            searchParams.get('organizationType') || undefined,
                    },
                    {
                        key: 'userId',
                        type: 'hidden',
                        defaultValue: userId || undefined,
                    },
                ]}
                mainButton
            />
        </SectionContainer>
    )
}
