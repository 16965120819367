import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'

function transformMessageToElements(
    message,
    fields,
    searchParams,
    setSearchParams,
) {
    const elements = []
    let lastIndex = 0

    function focus(e, key) {
        e.preventDefault()
        const newSearchParams = new URLSearchParams(searchParams)
        newSearchParams.set('scrollField', key)
        setSearchParams(newSearchParams, { replace: true })
    }

    for (const field of fields || []) {
        const labelIndex = message.indexOf(field.label, lastIndex)
        const keyIndex = message.indexOf(field.key, lastIndex)

        if (labelIndex !== -1 && (labelIndex < keyIndex || keyIndex === -1)) {
            if (labelIndex > lastIndex) {
                elements.push(message.substring(lastIndex, labelIndex))
            }
            elements.push(
                <a
                    href="#"
                    key={field.key}
                    onClick={(e) => focus(e, field.key)}
                >
                    {field.label}
                </a>,
            )
            lastIndex = labelIndex + field.label.length
        } else if (keyIndex !== -1) {
            if (keyIndex > lastIndex) {
                elements.push(message.substring(lastIndex, keyIndex))
            }
            elements.push(
                <a
                    href="#"
                    key={field.key}
                    onClick={(e) => focus(e, field.key)}
                >
                    {field.key}
                </a>,
            )
            lastIndex = keyIndex + field.key.length
        }
    }

    if (lastIndex < message.length) {
        elements.push(message.substring(lastIndex))
    }
    return elements
}

function transformMessagesToElements(
    messages, //
    fields,
    searchParams,
    setSearchParams,
) {
    const elements = []

    function focus(e, key) {
        e.preventDefault()
        const newSearchParams = new URLSearchParams(searchParams)
        newSearchParams.set('scrollField', key)
        console.log(key)
        setSearchParams(newSearchParams, { replace: true })
    }

    for (const message of messages) {
        const messageElements = []
        let lastIndex = 0

        for (const field of fields || []) {
            const labelIndex = message.indexOf(field.label, lastIndex)
            const keyIndex = message.indexOf(field.key, lastIndex)

            if (
                labelIndex !== -1 &&
                (labelIndex < keyIndex || keyIndex === -1)
            ) {
                if (labelIndex > lastIndex) {
                    messageElements.push(
                        message.substring(lastIndex, labelIndex),
                    )
                }
                messageElements.push(
                    <a
                        href="#"
                        key={field.key}
                        onClick={(e) => focus(e, field.key)}
                    >
                        {field.label}
                    </a>,
                )
                lastIndex = labelIndex + field.label.length
            } else if (keyIndex !== -1) {
                if (keyIndex > lastIndex) {
                    messageElements.push(message.substring(lastIndex, keyIndex))
                }
                messageElements.push(
                    <a
                        href="#"
                        key={field.key}
                        onClick={(e) => focus(e, field.key)}
                    >
                        {field.key}
                    </a>,
                )
                lastIndex = keyIndex + field.key.length
            }
        }

        if (lastIndex < message.length) {
            messageElements.push(message.substring(lastIndex))
        }

        elements.push(<li key={message}>{messageElements}</li>)
    }

    return elements
}

export default function useError(errMessageRef, opts = {}) {
    const [error, setError] = useState(null)
    const [fields, setFields] = useState([])
    const [searchParams, setSearchParams] = useSearchParams()

    function setNewError(error, fields, messages) {
        let finalError = error
        if (opts.linksInMessage && messages?.length > 1 && error) {
            finalError = (
                <ul className="errors-list">
                    {transformMessagesToElements(
                        messages,
                        fields,
                        searchParams,
                        setSearchParams,
                    )}
                </ul>
            )
        } else if (opts.linksInMessage && error) {
            finalError = transformMessageToElements(
                error,
                fields,
                searchParams,
                setSearchParams,
            )
        }
        setError(finalError)
        setFields((fields || []).map((f) => f.key))

        if (!error) return

        if (errMessageRef?.current && !opts.linksInMessage) {
            const headerOffset = 51
            const elementPosition =
                errMessageRef.current.getBoundingClientRect().top
            const offsetPosition = elementPosition - headerOffset - 10

            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth',
            })
        } else {
            // } else if (!opts.linksInMessage) {
            // The linksInMessage option also sets the param,
            // which takes care of scrolling on its own.
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            })
        }
    }

    return [error, setNewError, fields]
}
