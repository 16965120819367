import { useState, Children, useEffect, useRef } from 'react'
import { useScrollLock } from '../../common/hooks/useScrollLock'

export default function DragArea({
    children,
    id = 'default',
    onNewIdPosition,
}) {
    const childItems = Children.toArray(children).map((child, i) => ({
        id: `draggable-${id}-${i.toString()}`,
        child,
    }))

    const count = childItems.length

    const positions = Array.from({ length: count }, (_, i) => ({
        id: `dropzone-${id}-${i + 1}`,
    }))

    const [mappings, setMappings] = useState(
        positions.reduce((acc, pos, i) => {
            acc[pos.id] = childItems[i].id
            return acc
        }, {}),
    )

    const [draggedChild, setDraggedChild] = useState(null)
    const [draggedOverPosId, setDraggedOverPosId] = useState(null)
    const [longPressTimeout, setLongPressTimeout] = useState(null)
    const [isLongPressing, setIsLongPressing] = useState(false)
    const [isNearEdge, setIsNearEdge] = useState(null)
    const scrollInterval = useRef(null)
    const { lockScroll, unlockScroll } = useScrollLock()

    useEffect(() => {
        if (scrollInterval.current) {
            clearInterval(scrollInterval)
        }

        if (isNearEdge === 'top') {
            scrollInterval.current = setInterval(() => {
                window.scrollBy(0, -10)
            }, 50)
        } else if (isNearEdge === 'bottom') {
            scrollInterval.current = setInterval(() => {
                window.scrollBy(0, 10)
            }, 50)
        }

        // Clean up the interval when the user moves away from the edge
        return () => {
            if (scrollInterval.current) {
                clearInterval(scrollInterval.current)
            }
        }
    }, [isNearEdge])

    useEffect(
        function () {
            setMappings(
                positions.reduce((acc, pos, i) => {
                    acc[pos.id] = childItems[i].id
                    return acc
                }, {}),
            )
        },
        [childItems.map((item) => item.child.key).join(',')],
    )

    useEffect(
        function () {
            if (isLongPressing) {
                lockScroll()
            } else {
                unlockScroll()
            }
        },
        [isLongPressing],
    )

    function handleTouchStart(e) {
        // e.preventDefault()
        longPressTimeout && clearTimeout(longPressTimeout)
        const touch = e.touches[0]
        const element = [
            ...document.elementsFromPoint(touch.clientX, touch.clientY),
        ].find((el) => el.hasAttribute('draggable'))

        if (!element) return

        const timeoutId = setTimeout(() => {
            setDraggedChild(childItems.find((item) => item.id === element?.id))
            setIsLongPressing(true)
        }, 500)

        setLongPressTimeout(timeoutId)
    }

    function handleTouchMove(e) {
        if (!isLongPressing) {
            clearTimeout(longPressTimeout)
            return
        }

        // e.preventDefault()
        const touch = e.touches[0]
        const element = [
            ...document.elementsFromPoint(touch.clientX, touch.clientY),
        ].find((el) => el.hasAttribute('data-dropzone'))
        setDraggedOverPosId(element?.id || null)

        // Detect if the touch is near the top or bottom of the screen
        const top = touch.clientY < 100
        const bottom = touch.clientY > window.innerHeight - 100

        if (top) {
            setIsNearEdge('top')
        } else if (bottom) {
            setIsNearEdge('bottom')
        } else {
            setIsNearEdge(null)
        }
    }

    function handleTouchEnd(e) {
        // e.preventDefault()
        clearTimeout(longPressTimeout)

        if (!isLongPressing) {
            return
        }

        const element = [
            ...document.elementsFromPoint(
                e.changedTouches[0].clientX,
                e.changedTouches[0].clientY,
            ),
        ].find((el) => el.hasAttribute('data-dropzone'))
        const newPositionId = element?.id
        const newPositionIndex = positions.findIndex(
            (pos) => pos.id === newPositionId,
        )
        if (newPositionIndex !== -1 && draggedChild) {
            const key = draggedChild.child.props.dragid
            onNewIdPosition(key, newPositionIndex)
        }
        setDraggedChild(null)
        setDraggedOverPosId(null)
        setIsLongPressing(false)
        setIsNearEdge(null)
    }

    function handleDragStart(e) {
        e.dataTransfer.effectAllowed = 'move'
        setDraggedChild(childItems.find((item) => item.id === e.target.id))
    }

    function handleDragOver(e) {
        e.preventDefault()
        e.dataTransfer.dropEffect = 'move'
        // Get the dropzone / target
        setDraggedOverPosId(e.target?.id || null)
    }

    function handleDragDrop(e) {
        e.preventDefault()
        const key = childItems.find(
            (item) => item.child.key === draggedChild.child.key,
        ).child.props.dragid
        const newPositionId = e.target.id
        const newPositionIndex = positions.findIndex(
            (pos) => pos.id === newPositionId,
        )
        onNewIdPosition(key, newPositionIndex)

        setDraggedChild(null)
        setDraggedOverPosId(null)
    }

    return (
        <div className="drag-area">
            {positions.map((pos) => {
                const itemId = mappings[pos.id]
                const item =
                    itemId && childItems.find((item) => item.id === itemId)
                return (
                    <div
                        data-dropzone
                        key={pos.id}
                        id={pos.id}
                        onDrop={handleDragDrop}
                        onDragOver={handleDragOver}
                        className={
                            draggedOverPosId === pos.id ? 'dragged-over' : ''
                        }
                    >
                        {item ? (
                            <div
                                className={`${
                                    draggedChild?.id === item.id
                                        ? 'dragged'
                                        : ''
                                } ${isLongPressing ? 'pressed' : ''}`}
                                style={{
                                    pointerEvents:
                                        draggedChild &&
                                        draggedChild?.id !== item.id
                                            ? 'none'
                                            : 'auto',
                                }}
                                id={item.id}
                                draggable
                                onDragStart={handleDragStart}
                                onTouchStart={handleTouchStart}
                                onTouchMove={handleTouchMove}
                                onTouchEnd={handleTouchEnd}
                                onTouchCancel={handleTouchEnd}
                            >
                                {item.child}
                            </div>
                        ) : null}
                    </div>
                )
            })}

            {/* <div
                style={{
                    position: 'fixed',
                    left: 0,
                    width: '100%',
                    top: 0,
                    pointerEvents: 'none',
                    userSelect: 'none',
                }}
            >
                {String(isLongPressing) || 'null'}
            </div> */}
        </div>
    )
}
