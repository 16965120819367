import React, { useState } from 'react'
import { useStore } from '../../common/Context'
import Button from './Button'
import SelectInput from './data-form/SelectInput'
import SwitchInput from './data-form/SwitchInput'
import LiveSearch from './data-form/LiveSearch'
import TextInput from './data-form/TextInput'

function FilterModalContent(props) {
    const { onSubmit, filters, currFilter, currSort } = props

    const [_state, dispatch] = useStore()
    const [data, setData] = useState({
        sortKey: currSort.key,
        filterValues: currFilter,
    })

    function onCancel() {
        dispatch({ type: 'CLOSE_MODAL' })
    }

    return (
        <div className="filter-modal-content">
            <div className="modal-body">
                <div className="order-container">
                    <label htmlFor="sort-select">Sort</label>
                    <SelectInput
                        value={data.sortKey}
                        id="sort-select"
                        onChange={(v) => setData({ ...data, sortKey: v })}
                        options={Object.entries(filters?.sort || {}).map(
                            ([k, v]) => ({
                                value: k,
                                label: v.label,
                            }),
                        )}
                    />
                </div>
                <div className="filtering-container">
                    {Object.entries(filters?.filters || {})
                        .filter(([_k, v]) => !v.tabs)
                        .map(([k, v]) => {
                            if (v.type) {
                                return (
                                    <>
                                        <label>{v.label}</label>
                                        <TextInput
                                            key={k}
                                            type={v.type}
                                            id={`filter-text-input-${k}`}
                                            value={data.filterValues[k]}
                                            onChange={(v) =>
                                                setData({
                                                    ...data,
                                                    filterValues: {
                                                        ...data.filterValues,
                                                        [k]: v,
                                                    },
                                                })
                                            }
                                        />
                                        {/* <LiveSearch
                                            showAll={false}
                                            id={`filter-live-search-${k}`}
                                            getResultValue={
                                                v.liveSearchGetResultValue
                                            }
                                            url={v.liveSearchUrl}
                                            getItemsFromResults={(data) =>
                                                data?.results || []
                                            }
                                            onItemClick={(item) => {
                                                setData({
                                                    ...data,
                                                    filterValues: {
                                                        ...data.filterValues,
                                                        [k]: item.id,
                                                    },
                                                })
                                            }}
                                        /> */}
                                    </>
                                )
                            }
                            if (v.liveSearchUrl) {
                                return (
                                    <>
                                        <label>{v.label}</label>
                                        <LiveSearch
                                            key={k}
                                            showAll={false}
                                            id={`filter-live-search-${k}`}
                                            getResultValue={
                                                v.liveSearchGetResultValue
                                            }
                                            url={v.liveSearchUrl}
                                            getItemsFromResults={(data) =>
                                                data?.results || []
                                            }
                                            onItemClick={(item) => {
                                                setData({
                                                    ...data,
                                                    filterValues: {
                                                        ...data.filterValues,
                                                        [k]: item.id,
                                                    },
                                                })
                                            }}
                                        />
                                    </>
                                )
                            }
                            return v.options.length > 3 ? (
                                <div key={k}>
                                    <label htmlFor={`filter-select-${k}`}>
                                        {v.label}
                                    </label>

                                    <SelectInput
                                        id={`filter-select-${k}`}
                                        value={data.filterValues[k]}
                                        onChange={(v) =>
                                            setData({
                                                ...data,
                                                filterValues: {
                                                    ...data.filterValues,
                                                    [k]: v,
                                                },
                                            })
                                        }
                                        options={v.options}
                                    />
                                </div>
                            ) : (
                                <div key={k}>
                                    <label htmlFor={`filter-switch-${k}`}>
                                        {v.label}
                                    </label>
                                    <SwitchInput
                                        type="switch"
                                        id={`filter-switch-${k}`}
                                        value={data.filterValues[k]}
                                        onChange={(v) =>
                                            setData({
                                                ...data,
                                                filterValues: {
                                                    ...data.filterValues,
                                                    [k]: v,
                                                },
                                            })
                                        }
                                        options={v.options}
                                    />
                                </div>
                            )
                        })}
                </div>
            </div>
            <div className="modal-buttons">
                <Button
                    small
                    link
                    destructive
                    onClick={onCancel}
                    text={'Cancel'}
                />
                <Button small onClick={() => onSubmit(data)} text={'Submit'} />
            </div>
        </div>
    )
}

export default FilterModalContent
