import { useMemo, useRef } from 'react'
import Button from '../Button'
import XIcon from '../../../assets/icons/XIcon'
import { PhoneInput as LibPhoneInput } from 'react-international-phone'
import 'react-international-phone/style.css'

export default function PhoneInput({ value, onChange, id, errors, ...rest }) {
    const ref = useRef(null)

    function handleChange(v) {
        onChange(v)
    }
    const textValue = value || ''

    function handleClear() {
        handleChange('')
        ref.current?.focus()

        if (rest.onClear) {
            rest.onClear()
        }
    }

    const showClearButton = !rest.disabled && rest.withClear && !!value?.length

    const libPhoneInputHtml = useMemo(() => {
        return (
            <LibPhoneInput
                defaultCountry="us"
                value={textValue}
                onChange={handleChange}
                id={id}
                ref={showClearButton ? ref : undefined}
                required={rest.required}
                autoComplete={rest.autoComplete}
                disabled={rest.disabled}
                placeholder={rest.placeholder}
                className={rest.noMargin ? 'm-0' : ''}
                preferredCountries={['us']}
                disableDialCodePrefill
            />
        )
    }, [textValue, showClearButton, rest.disabled])

    return (
        <div
            className={`text-input${showClearButton ? ' with-clear' : ''}${errors?.length ? ' has-error' : ''}`}
        >
            {libPhoneInputHtml}

            {showClearButton && (
                <div className="clear-btn">
                    <Button
                        text={'Clear'}
                        onClick={handleClear}
                        tiny
                        plain
                        icon={<XIcon />}
                        iconTop
                    />
                </div>
            )}
        </div>
    )
}
