import { Link } from 'react-router-dom'
import Tag from '../../components/common/Tag'

export default function Timesheets({ userId }) {
    return (
        <>
            <Link to={`/users/${userId}/timesheets`}>
                Open timesheets&nbsp;<Tag outline>Beta</Tag>
            </Link>
        </>
    )
}
