import ImageModalContent from './ImageModalContent'
import useModal from '../../common/hooks/useModal'

function isFileImage(fileName) {
    return (
        fileName?.endsWith('.jpg') ||
        fileName?.endsWith('.jpeg') ||
        fileName?.endsWith('.png')
    )
}

export default function StorageFile({
    url,
    width,
    height,
    publicUrl,
    name,
    fileName,
    mimeType,
    openFullOnClick,
}) {
    const { setModal } = useModal()

    const isImage =
        mimeType?.startsWith('image') || isFileImage(name || fileName)

    const onClick = () => {
        if (openFullOnClick) {
            if (isImage) {
                setModal(<ImageModalContent src={url || publicUrl} />)
            } else {
                const a = document.createElement('a')
                a.style = 'display: none'
                a.href = url || publicUrl
                a.download = name || fileName
                a.click()
            }
        }
    }

    return (
        <>
            {!!(url || publicUrl) && isImage && (
                <img
                    className="private-file is-image"
                    crossOrigin="Anonymous"
                    src={url || publicUrl}
                    alt=""
                    onClick={onClick}
                    width={width}
                    height={height}
                />
            )}

            {!isImage && (name || fileName) && (
                <p className="private-file is-other" onClick={onClick}>
                    {name || fileName}
                </p>
            )}
        </>
    )
}
