import { ICONS } from '../../../common/constants'
import { formatDate, formatMoney } from '../../../common/helpers'
import ResourceList from '../../../components/admin/ResourceList'
import BlockStack from '../../../components/common/BlockStack'
import Grid from '../../../components/common/Grid'
import InlineStack from '../../../components/common/InlineStack'

// const filterFields = [
//     {
//         type: 'sort',
//         name: 'Created at',
//         fields: ['creationDate'],
//         asc: false,
//     },
// ]

export default function ExpensesList() {
    return (
        <ResourceList
            title="Expenses"
            renderMainButton={true}
            newItemLabel={'New expense'}
            apiUrl={'/expenses'}
            getItemsFromResponse={(data) => data?.results}
            newItemPath={'/expenses/new'}
            itemClickPath={'/expenses/:item.id'}
            // filters={filterFields}
            paginated={true}
            paginationLimit={20}
            fields={[
                {
                    column: 1,
                    getValue: (item) => (
                        <Grid cols={2}>
                            <BlockStack gap="tiny">
                                {!!item.title && (
                                    <h3 className="m-0">{item.title}</h3>
                                )}

                                {!!item.teamMember && (
                                    <div>{`For ${item.teamMember.firstName} ${item.teamMember.lastName}`}</div>
                                )}
                                <div>{formatMoney(item.amount || 0)}</div>
                            </BlockStack>
                            <BlockStack gap="tiny">
                                {!!item.expenseModel && (
                                    <div className="text-caps">
                                        {item.expenseModel}
                                    </div>
                                )}
                                {item.expenseModel === 'fixed' && (
                                    <div>{`Created ${formatDate(item.creationDate)}`}</div>
                                )}
                            </BlockStack>
                        </Grid>
                    ),
                },
            ]}
            actions={[
                {
                    title: 'Edit',
                    onClick: () => alert('Not implemented'),
                    icon: ICONS.EDIT_GRAY,
                },
            ]}
        />
    )
}
