import InlineStack from '../../../components/common/InlineStack'
import { useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { openUrlInNewTab } from '../../../common/helpers'
import Button from '../../../components/common/Button'
import { ICONS } from '../../../common/constants'
import ActionItem from '../../../components/common/ActionItem'
import Popover from '../../../components/common/Popover'

export default function ContactCardContactInfo({ user: data }) {
    const phonePopoverRef = useRef(null)
    const navigate = useNavigate()

    function openContactApp(e, url) {
        e.preventDefault()
        e.stopPropagation()
        openUrlInNewTab(url)
    }

    if (!data) return null
    const isContact = data.contactType

    let phone
    if (data.contactDetails?.phones?.length) {
        phone = (
            data.contactDetails.phones.find((p) => p.phoneType === 'main') ||
            data.contactDetails.phones[0]
        ).phone
    }

    return (
        <InlineStack wrap colGap={'sm'} className=" ">
            {data.email ? (
                <span>
                    <Button
                        icon={ICONS.MAIL_GRAY}
                        link
                        plain
                        inline
                        tiny
                        onClick={(e) =>
                            openContactApp(e, `mailto:${data.email}`)
                        }
                        text={
                            <span className="text-subdued">{data.email}</span>
                        }
                    />
                </span>
            ) : null}
            {phone ? (
                <span>
                    <Button
                        icon={ICONS.PHONE_GRAY}
                        link
                        plain
                        inline
                        tiny
                        text={<span className="text-subdued">{phone}</span>}
                    />

                    {/* <Popover
                        // activateOnHover
                        ref={phonePopoverRef}
                        isLongPress
                        onClick={(e) => openContactApp(e, `tel:${phone}`)}
                        closeOnChildClick
                        renderActivator={(ref, listeners) => (
                            <div ref={ref} {...listeners} role="button">
                                <Button
                                    icon={ICONS.PHONE_GRAY}
                                    link
                                    plain
                                    inline
                                    tiny
                                    text={
                                        <span className="text-subdued">
                                            {phone}
                                        </span>
                                    }
                                />
                            </div>
                        )}
                    >
                        <ActionItem
                            icon={ICONS.EDIT_GRAY}
                            onClick={() => {
                                navigate(
                                    `/${
                                        isContact ? 'contacts' : 'users'
                                    }/${data.id}/edit?type=${
                                        isContact ? 'contact' : 'user'
                                    }&scrollField=contactDetails_phones`,
                                )
                            }}
                        >
                            Edit
                        </ActionItem>
                    </Popover> */}
                </span>
            ) : null}
        </InlineStack>
    )
}
