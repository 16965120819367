import { useParams } from 'react-router-dom'
import ResourceList from '../../../components/admin/ResourceList'
import {
    formatMoney,
    getLocalDateStr,
    getOneMsBeforeDate,
    getTimesheetEndDateFromStartDate,
    getTimezoneInfo,
} from '../../../common/helpers'
import TimesheetDaysList from './TimesheetDaysList'
import useData from '../../../common/hooks/useData'
import useAuth from '../../../common/hooks/useAuth'
import { useState } from 'react'
import TimesheetEntryForm from './TimesheetEntryForm'
import Button from '../../../components/common/Button'
import useModal from '../../../common/hooks/useModal'
import fetchAPI from '../../../common/fetchAPI'
import useToast from '../../../common/hooks/useToast'
import ConfirmModalContent from '../../../components/common/ConfirmModalContent'
import useMatchMutate from '../../../common/hooks/useMatchMutate'
import Spinner from '../../../components/common/Spinner'
import ErrorMessage from '../../../components/common/ErrorMessage'
import Tag from '../../../components/common/Tag'
import SectionContainer from '../../../components/common/SectionContainer'
import useError from '../../../common/hooks/useError'

function mapEntriesToDates(entries) {
    const processedEntries = []
    for (const entry of entries) {
        const startDate = new Date(entry.startDate)
        const endDate = new Date(entry.endDate)

        if (startDate.getDate() !== endDate.getDate()) {
            const firstEntryPartStart = new Date(startDate.getTime())
            const firstEntryPartEnd = new Date(startDate.getTime())
            firstEntryPartEnd.setHours(23, 59, 59, 999)
            const secondEntryPartStart = new Date(endDate.getTime())
            secondEntryPartStart.setHours(0, 0, 0, 0)
            const secondEntryPartEnd = new Date(endDate.getTime())

            const firstEntryPartMinutes = Math.floor(
                (firstEntryPartEnd.getTime() - startDate.getTime()) /
                    (1000 * 60),
            )
            const secondEntryPartMinutes = Math.floor(
                (endDate.getTime() - secondEntryPartStart.getTime()) /
                    (1000 * 60),
            )

            const firstEntryPartCostAmount =
                (firstEntryPartMinutes / entry.minutes) * entry.costAmount
            const secondEntryPartCostAmount =
                (secondEntryPartMinutes / entry.minutes) * entry.costAmount

            processedEntries.push({
                ...entry,
                startDate: firstEntryPartStart.toISOString(),
                endDate: firstEntryPartEnd.toISOString(),
                minutes: firstEntryPartMinutes,
                costAmount: firstEntryPartCostAmount,
                isSplit: true,
                processedId: `${entry.id}-1`,
            })
            processedEntries.push({
                ...entry,
                startDate: secondEntryPartStart.toISOString(),
                endDate: secondEntryPartEnd.toISOString(),
                minutes: secondEntryPartMinutes,
                costAmount: secondEntryPartCostAmount,
                isSplit: true,
                processedId: `${entry.id}-2`,
            })
            continue
        }

        processedEntries.push({
            ...entry,
            isSplit: false,
            processedId: entry.id,
        })
    }

    const result = new Map()
    for (const entry of processedEntries) {
        const startDateStart = new Date(entry.startDate)
        startDateStart.setHours(0, 0, 0, 0)
        if (!result.has(startDateStart.toISOString())) {
            result.set(startDateStart.toISOString(), [])
        }
        result.get(startDateStart.toISOString()).push(entry)
    }

    return result
}

function EditTimesheet() {
    const { timesheetStartStr } = useParams()
    const auth = useAuth()
    const { setModal, setModalLoading, isModalLoading } = useModal()
    const setToast = useToast()
    const matchMutate = useMatchMutate()
    const [error, setError] = useError()

    const now = new Date()

    // Create a new date object based on the timesheet start date string, which is in the format of YYYY-MM-DD
    // The timesheet start date is in the user's timezone, not UTC
    const startDate = new Date()
    startDate.setFullYear(parseInt(timesheetStartStr.split('-')[0]))
    startDate.setMonth(parseInt(timesheetStartStr.split('-')[1]) - 1)
    startDate.setDate(parseInt(timesheetStartStr.split('-')[2]))
    startDate.setHours(0, 0, 0, 0)
    const endDate = getTimesheetEndDateFromStartDate(startDate)
    const msBeforeEndDate = new Date(endDate.getTime() - 1)

    const dates = []
    for (let i = startDate.getDate(); i < msBeforeEndDate.getDate(); i++) {
        const date = new Date(
            startDate.getFullYear(),
            startDate.getMonth(),
            i,
            0,
        )
        dates.push(date)
    }
    const lastDate = new Date(msBeforeEndDate.getTime())
    lastDate.setHours(0, 0, 0, 0)
    dates.push(lastDate)

    const initCurrDayIndex = dates.findIndex((date) => {
        return getLocalDateStr(date) === getLocalDateStr(now)
    })

    const [currDayIndex, setCurrDayIndex] = useState(
        initCurrDayIndex === -1 ? 0 : initCurrDayIndex,
    )

    const { entries, entriesError, entriesLoading } = useData(
        `/v1/timesheets?between=${dates[0].toISOString()},${msBeforeEndDate.toISOString()}&teamMemberId=${
            auth.user.id
        }`,
        'entries',
        (data) => data?.results || [],
    )

    async function handleSubmitTimesheet() {
        setModal(
            <ConfirmModalContent
                onConfirm={async function () {
                    if (isModalLoading) return
                    setModalLoading(true)
                    const body = {
                        entries: entries
                            .filter((entry) => !entry.submissionDate)
                            .map((entry) => entry.id),
                    }
                    const { error } = await fetchAPI(
                        `/v1/timesheets/submit-timesheet-entries`,
                        body,
                        'PATCH',
                    )
                    if (error) {
                        setModal(null)
                        setError(error)
                        return
                    }
                    matchMutate(/\/v1\/timesheets/)
                    setModal(null)
                    setToast('Submitted')
                }}
            />,
            'Are you sure you want to submit this timesheet?',
        )
    }

    if (entriesLoading) {
        return <Spinner />
    }

    if (entriesError) {
        return <ErrorMessage section>{entriesError}</ErrorMessage>
    }

    const processedEntries = mapEntriesToDates(entries)
    const processedDates = []
    for (const date of dates) {
        processedDates.push({
            date,
            entries: processedEntries.get(date.toISOString()) || [],
        })
    }

    const hasUnsubmittedEntries = entries.some((entry) => !entry.submissionDate)
    const hasPassedLastDate = now >= endDate

    const canBeSubmitted = hasUnsubmittedEntries && hasPassedLastDate
    const isSubmitted = entries.length && !hasUnsubmittedEntries
    const totalHours = Math.floor(
        entries.reduce((a, b) => a + b.minutes / 60, 0),
    ).toFixed(1)
    const totalMoney = formatMoney(
        entries.reduce((a, b) => a + b.costAmount, 0),
        false,
        'api',
    )

    const timezoneInfo = getTimezoneInfo(now)

    return (
        <>
            {!!error && (
                <ErrorMessage section onDismiss={() => setError('')}>
                    {error}
                </ErrorMessage>
            )}

            <SectionContainer>
                {isSubmitted ? <Tag outline>Submitted</Tag> : null}
                {canBeSubmitted ? <Tag outline>Unsubmitted</Tag> : null}
                <h2>
                    {`${startDate.toLocaleDateString()} - 
                                ${getOneMsBeforeDate(
                                    endDate,
                                ).toLocaleDateString()}`}
                </h2>
                <small className="text-subdued">{`Timezone: ${timezoneInfo.name} (${timezoneInfo.offset})`}</small>
            </SectionContainer>
            <TimesheetDaysList
                dateItems={processedDates}
                currIndex={currDayIndex}
                onCurrIndexChange={(i) => setCurrDayIndex(i)}
            />
            <ResourceList
                key={processedDates[currDayIndex].date.getTime()}
                items={processedDates[currDayIndex]?.entries || []}
                fields={[
                    {
                        column: 1,
                        getValue: (item) => <TimesheetEntryForm entry={item} />,
                    },
                ]}
            />

            <SectionContainer>
                <h2>Add time</h2>
                <TimesheetEntryForm
                    key={processedDates[currDayIndex].date.getTime()}
                    dayStartDate={processedDates[currDayIndex].date}
                />
            </SectionContainer>

            <SectionContainer>
                <div>
                    <small>{totalHours} hours</small>
                </div>
                <div>
                    <small>{totalMoney}</small>
                </div>
            </SectionContainer>

            <SectionContainer>
                <Button
                    text="Submit timesheet"
                    onClick={handleSubmitTimesheet}
                    disabled={!canBeSubmitted}
                />
            </SectionContainer>
        </>
    )
}

export default EditTimesheet
