import { useRef, useState } from 'react'
import ResourceList from '../../components/admin/ResourceList'
import fetchAPI from '../../common/fetchAPI'
import useMatchMutate from '../../common/hooks/useMatchMutate'
import useToast from '../../common/hooks/useToast'
import Toggle from '../../components/common/Toggle'
import { ICONS } from '../../common/constants'
import ContactCard from '../admin/clients/ContactCard'
import useAuth from '../../common/hooks/useAuth'
import MessageSection from '../../components/common/MessageSection'
import useError from '../../common/hooks/useError'
import ErrorMessage from '../../components/common/ErrorMessage'
import Break from '../../components/common/Break'

const filterFields = {
    search: true,
    sort: {
        name: {
            label: 'Name',
            dbFields: ['lastName', 'firstName', 'email'],
            asc: true,
        },
    },
    filters: {
        onCollections: {
            label: 'Collections',
            dbField: 'onCollections',
            options: [
                {
                    label: 'Any',
                    value: 'undefined',
                },
                {
                    label: 'Yes',
                    value: 'true',
                },
                {
                    label: 'No',
                    value: 'false',
                },
            ],
        },
    },
}

export function AssignedUsersToggle({
    userId,
    assignedClientIds,
    assignedClients,
}) {
    const [assignedClientsCount, setAssignedClientsCount] = useState(null)
    const setToast = useToast()
    const matchMutate = useMatchMutate()
    const auth = useAuth()
    const errorRef = useRef(null)
    const [error, setError] = useError(errorRef)

    const onRemoveAssignedClient = async (clientId) => {
        const newClientIds = assignedClientIds.filter((id) => id !== clientId)
        const { error } = await fetchAPI(
            `/v1/users/${userId}`,
            {
                assignedClientIds: newClientIds,
            },
            'PATCH',
        )
        if (error) {
            setError(error)
        }
        matchMutate(/\/v1\/users/)
        setToast('Done')
    }

    return (
        <Toggle
            headerChildrenHtml={
                assignedClientsCount !== null && (
                    <div className="counter">{assignedClientsCount}</div>
                )
            }
            title="Assigned clients"
            small
            heading={'h2'}
        >
            {auth.isAdmin && (
                <>
                    <MessageSection type="info">
                        Only admins and assistants can see and edit this. Team
                        members have access to certain data of their assigned
                        users.
                    </MessageSection>
                    <Break />
                </>
            )}

            <div ref={errorRef}>
                {!!error && (
                    <ErrorMessage onDismiss={() => setError('')}>
                        {error}
                    </ErrorMessage>
                )}
            </div>

            <ResourceList
                title=""
                onRemoveAssignedClient={onRemoveAssignedClient}
                items={assignedClients || []}
                newItemPath={'/team-members/:userId/assign'}
                actions={[
                    {
                        title: 'Remove',
                        icon: ICONS.TRASH_RED,
                        type: 'alert',
                        onClick: (item) => onRemoveAssignedClient(item.id),
                    },
                ]}
                filters={filterFields}
                onResponseLoaded={(res) => {
                    setAssignedClientsCount(res.length)
                }}
                fields={[
                    {
                        column: 1,
                        getValue: (item) => <ContactCard summary user={item} />,
                    },
                ]}
            />
        </Toggle>
    )
}
