import { useEffect, useMemo, useRef, useState } from 'react'
import {
    BrowserRouter,
    Link,
    useLocation,
    useNavigate,
    useParams,
} from 'react-router-dom'
import ReactDOM from 'react-dom'
import fetchAPI from '../../../common/fetchAPI'
import useAuth from '../../../common/hooks/useAuth'
import MainButton from '../../../components/admin/MainButton'
import Spinner from '../../../components/common/Spinner'
import ErrorMessage from '../../../components/common/ErrorMessage'
import SectionContainer from '../../../components/common/SectionContainer'
import Button from '../../../components/common/Button'
import useToast from '../../../common/hooks/useToast'
import InlineStack from '../../../components/common/InlineStack'
import Divider from '../../../components/common/Divider'
import { ICONS } from '../../../common/constants'
import useModal from '../../../common/hooks/useModal'
import InputModalContent from '../../../components/common/InputModalContent'
import Break from '../../../components/common/Break'
import useHashNav from '../../../common/hooks/useHashNav'
import ActionsDropdown from '../../../components/common/ActionsDropdown'
import useMatchMutate from '../../../common/hooks/useMatchMutate'
import ConfirmModalContent from '../../../components/common/ConfirmModalContent'
import {
    cleanSlugInput,
    copyToClipboard,
    replaceImgWithDataUrls,
} from '../../../common/helpers'
import Tag from '../../../components/common/Tag'
import ToC from '../../../components/common/ToC'
import { COMPONENT_DATA } from '../../more/components/ComponentData'
import { StoreProvider } from '../../../common/Context'
import { initialState, reducer } from '../../../common/reducer'

function processHTMLForToC(html) {
    let finalHtml = html

    try {
        const titles = []
        const allH2 = finalHtml.match(/<h2.*?<\/h2>/g) || []
        const areH2Unique = allH2.length === new Set(allH2).size

        const allH3 = finalHtml.match(/<h3.*?<\/h3>/g) || []
        const areH3Unique = allH3.length === new Set(allH3).size

        if (allH2.length > 1 && areH2Unique) {
            for (const h2 of allH2) {
                const h2Text = h2.replace(/<[^>]*>/g, '')
                const id = `toc-${cleanSlugInput(h2Text)}`

                finalHtml = finalHtml.replace(
                    h2,
                    `<h2 id="${id}">${h2Text}</h2>`,
                )

                titles.push({ id, title: h2Text })
            }
        } else if (allH3.length > 1 && areH3Unique) {
            for (const h3 of allH3) {
                const h3Text = h3.replace(/<[^>]*>/g, '')
                const id = `toc-${cleanSlugInput(h3Text)}`

                finalHtml = finalHtml.replace(
                    h3,
                    `<h3 id="${id}">${h3Text}</h3>`,
                )

                titles.push({ id, title: h3Text })
            }
        }

        return { html: finalHtml, titles }
    } catch (error) {
        return { html, titles: [] }
    }
}

function Glossary({ items }) {
    const availableLetters = [
        ...new Set(items.map((item) => item.title[0].toUpperCase())),
    ].sort()

    const location = useLocation()

    useEffect(() => {
        if (!location.hash) return
        const removeHash = () => {
            window.history.replaceState(null, null, ' ')
        }
        setTimeout(removeHash, 0)
    }, [location.hash])

    const groups = items.reduce((acc, item) => {
        const letter = item.title[0].toUpperCase()
        if (!acc[letter]) {
            acc[letter] = []
        }
        acc[letter].push(item)
        return acc
    }, {})

    const sortedLetters = Object.keys(groups).sort()

    return (
        <>
            <div className="glossary">
                <div>
                    {sortedLetters.map((letter) => (
                        <div key={letter}>
                            <h3 id={`glossary-letter-${letter}`} key={letter}>
                                {letter}
                            </h3>
                            <div>
                                {groups[letter].map((item) => (
                                    <div
                                        key={item.title}
                                        className={`glossary-item-${letter}`}
                                    >
                                        <Link to={item.link}>{item.title}</Link>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>

                <div className="letter-index">
                    <ul>
                        {availableLetters.map((l) => (
                            <li key={l}>
                                <Link to={`#glossary-letter-${l}`}>{l}</Link>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </>
    )
}

function Page() {
    const { slug, categorySlug } = useParams()
    const auth = useAuth()

    const [page, setPage] = useState(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [_exportLoading, setExportLoading] = useState(false)
    const setToast = useToast()
    const { setModal, setModalLoading, isModalLoading } = useModal()
    const [filterTags, setFilterTags] = useState([])
    const [hash, setHash] = useHashNav()
    const matchMutate = useMatchMutate()
    const navigate = useNavigate()
    const pageRef = useRef()

    useEffect(() => {
        loadPage()
    }, [categorySlug, slug])

    useEffect(
        function () {
            if (!page || hash === `#${page.title}`) {
                return
            }
            setHash(`${page.title}`)
        },
        [page?.title],
    )

    let htmlContent = (page?.content || '')
        .replace(/&lt;/g, '<')
        .replace(/&gt;/g, '>')
        .replace(/“/g, '"')
        .replace(/”/g, '"')

    const [tocProcessedContent, tocTitles] = useMemo(() => {
        const { html, titles } = processHTMLForToC(htmlContent)
        return [html, titles]
    }, [htmlContent])

    htmlContent = tocProcessedContent

    useEffect(
        function () {
            if (!pageRef.current) return
            for (const [name, { versions }] of Object.entries(COMPONENT_DATA)) {
                const el = pageRef.current.querySelector(
                    `.page_component_${name.toLowerCase()}`,
                )
                if (!el) continue
                const componentHtml = versions.map((item, i) => (
                    <BrowserRouter key={i}>
                        <StoreProvider
                            initialState={initialState}
                            reducer={reducer}
                        >
                            <div>
                                <h2>{item.title}</h2>
                                <div>{item.component}</div>
                            </div>
                        </StoreProvider>
                    </BrowserRouter>
                ))
                ReactDOM.render(componentHtml, el)
            }
        },
        [htmlContent],
    )

    async function loadPrivatePage() {
        setLoading(true)
        const { responseData, error } = await fetchAPI(
            `/v1/pages/private/${slug}`,
        )
        setLoading(false)
        if (error) {
            setError(error)
            return
        }
        setPage(responseData)
    }

    async function loadPage() {
        setLoading(true)
        const { responseData, error, status } = await fetchAPI(
            `/v1/pages/${slug}`,
        )
        setLoading(false)
        if (error && status === 403) {
            if (!auth.user) {
                setError(
                    <>
                        Please&nbsp;
                        <Link
                            to={'/login'}
                            state={{
                                redirectUrl: `/pages/${page?.parentPage ? `${page.parentPage.slug}/` : ''}${slug}`,
                            }}
                        >
                            login
                        </Link>
                        &nbsp;or&nbsp;
                        <Link to={'/register'}>register</Link>
                        &nbsp;to access this page
                    </>,
                )
                return
            } else {
                loadPrivatePage()
            }
        } else if (error) {
            setError(error)
            return
        }
        setPage(responseData)
    }

    async function onExportGroupXlsx() {
        setExportLoading(true)
        const { responseData, error } = await fetchAPI(
            `/v1/pages/export?${page.pageType === 'glossary' ? `parentPageId=${page.id}` : `pageIds[]=${page.id}`}${
                filterTags.length > 0
                    ? `&tags[]=${filterTags.join('&tags[]=')}`
                    : ''
            }&format=xlsx`,
            {},
            'POST',
        )
        if (error) {
            setToast(error, 'alert')
            setExportLoading(false)
            return
        }

        setExportLoading(false)

        const arrayBuffer = base64ToArrayBuffer(responseData.xlsxStr)

        // Create a Blob from the ArrayBuffer
        const blob = new Blob([arrayBuffer], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })

        // Create a URL for the Blob and trigger the download
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = 'pages.xlsx'
        a.click()
        window.URL.revokeObjectURL(url)
        a.remove()

        setToast('Exported')
    }

    async function onExportGroupCsv() {
        setExportLoading(true)
        const { responseData, error } = await fetchAPI(
            `/v1/pages/export?${page.pageType === 'glossary' ? `parentPageId=${page.id}` : `pageIds[]=${page.id}`}${
                filterTags.length > 0
                    ? `&tags[]=${filterTags.join('&tags[]=')}`
                    : ''
            }&format=csv`,
            {},
            'POST',
        )
        if (error) {
            setToast(error, 'alert')
            setExportLoading(false)
            return
        }

        setExportLoading(false)

        const { csvStr } = responseData

        const blob = new Blob([csvStr], { type: 'text/csv' })

        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = 'pages.csv'
        a.click()
        window.URL.revokeObjectURL(url)
        a.remove()

        setToast('Exported')
    }

    async function onExportPdf() {
        setExportLoading(true)
        const { responseData, error } = await fetchAPI(
            `/v1/pages/export?pageId=${page.id}&format=pdf`,
            {},
            'POST',
        )
        if (error) {
            setToast(error, 'alert')
            setExportLoading(false)
            return
        }

        setExportLoading(false)

        const { pdfStr } = responseData

        const arrayBuffer = base64ToArrayBuffer(pdfStr)

        const blob = new Blob([arrayBuffer], {
            type: 'application/pdf',
        })

        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = 'page.pdf'
        a.click()
        window.URL.revokeObjectURL(url)
        a.remove()

        setToast('Exported')
    }

    async function onExportDoc() {
        setExportLoading(true)
        const body = await replaceImgWithDataUrls(pageRef.current.innerHTML)
        const docContent = `<!DOCTYPE html><html lang="en"><head><meta charset="UTF-8"> <title>Document</title></head><body class="page-${page.id}"><h1>${page.title}</h1><hr />${body}</body></html>`

        const blob = new Blob([docContent], {
            type: 'application/msword',
        })

        const url = window.URL.createObjectURL(blob)

        const a = document.createElement('a')
        a.href = url
        a.download = 'page.doc'
        a.click()
        window.URL.revokeObjectURL(url)
        a.remove()

        setExportLoading(false)
        setToast('Exported')
    }

    function base64ToArrayBuffer(base64) {
        const binaryString = atob(base64)
        const len = binaryString.length
        const bytes = new Uint8Array(len)
        for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i)
        }
        return bytes.buffer
    }

    function openTagsModal() {
        setModal(
            <InputModalContent
                defaultValue={filterTags}
                items={[
                    ...new Set(page.publishedChildPages.flatMap((p) => p.tags)),
                ]}
                label="Tag"
                type="liveSearchAddItems"
                confirmText="OK"
                onCancel={() => {
                    setModal(null)
                }}
                onConfirm={async (data) => {
                    setFilterTags(data.items)
                    setModal(null)
                }}
            />,
            'Filter by tags',
            'modal-full',
        )
    }

    async function confirmMerge(otherPageIds, setError) {
        setError('')
        if (!otherPageIds || otherPageIds.length === 0) {
            setError('No pages selected', [true])
            return
        }

        setModalLoading(true)
        const { error, meta } = await fetchAPI(
            `/v1/pages/merge?pageId=${page.id}&${otherPageIds
                .map((id) => `otherPageIds[]=${id}`)
                .join('&')}`,
            {},
            'POST',
        )
        if (error) {
            setError(error, meta?.fields?.length ? [true] : [])
            setModalLoading(false)
            return
        }

        setToast('Pages merged')
        setModal(null)
        setModalLoading(false)
        matchMutate(/\/v1\/pages/)
        loadPage()
    }

    function onMerge() {
        setModal(
            <InputModalContent
                topHtml={
                    <div className="text-subdued">
                        The content from the other pages will be added to this
                        page. Only styles of this page will apply. Other pages
                        will be kept as unpubished.
                    </div>
                }
                label="Pages"
                type="liveSearchAddItems"
                confirmText="OK"
                getItemText={(item) => item.title}
                getItemsFromResults={(data) => data?.results || []}
                url="/v1/pages"
                fields={[
                    {
                        column: 1,
                        getValue: (item) => item.title,
                    },
                ]}
                onCancel={() => {
                    setModal(null)
                }}
                onConfirm={async (data, setError) => {
                    confirmMerge(
                        data.items.map((item) => item.id),
                        setError,
                    )
                }}
            />,
            'Merge pages',
            'modal-full',
        )
    }

    function onDelete() {
        setModal(
            <ConfirmModalContent
                onConfirm={async () => {
                    if (isModalLoading) return
                    setModalLoading(true)

                    const { error } = await fetchAPI(
                        `/v1/pages/admin/${page.id}`,
                        {},
                        'DELETE',
                    )

                    if (error) {
                        setModal(null)
                        setModalLoading(false)
                        setToast(error, 'alert')
                        return
                    }

                    setModal(null)
                    setModalLoading(false)
                    navigate(`/edit-pages`)
                    setToast('Page deleted')
                }}
            />,
            'Are you sure you want to delete this page?',
        )
    }

    function onCopyUrl() {
        copyToClipboard(
            `${process.env.REACT_APP_PUBLIC_URL}/pages/${page.slug}`,
        )
        setToast('Copied to clipboard')
    }

    function onShare() {
        const shareData = {
            title: page.title,
            text: page.title,
            url: `${process.env.REACT_APP_PUBLIC_URL}/pages/${page.slug}`,
        }

        navigator?.share(shareData)
    }

    if (loading) {
        return <Spinner />
    }

    if (error) {
        return (
            <SectionContainer>
                <ErrorMessage>{error}</ErrorMessage>
            </SectionContainer>
        )
    }

    if (!page) return null

    return (
        <SectionContainer>
            <InlineStack gap={'sm'} spaceBetween>
                <InlineStack gap={'sm'}>
                    {page.tags?.length > 0 && (
                        <InlineStack gap={'sm'} wrap>
                            {page.tags.map((tag) => (
                                <Tag outline noMargin key={tag}>
                                    {tag}
                                </Tag>
                            ))}
                        </InlineStack>
                    )}

                    {/* <span className="text-subdued">
                        {page.publishedParentPage ? (
                            <>
                                <Link to={`/all-pages`}>{`Pages`}</Link>
                                {` / `}
                                <Link
                                    to={`/pages/${page.publishedParentPage.slug}`}
                                >
                                    {page.publishedParentPage.title}
                                </Link>
                                &nbsp;/&nbsp;
                                <Link to={`/pages/${page.slug}`}>
                                    {page.title}
                                </Link>
                            </>
                        ) : (
                            <>
                                <Link to={`/all-pages`}>{`Pages / `}</Link>
                                <Link to={`/pages/${page.slug}`}>
                                    {page.title}
                                </Link>
                            </>
                        )}
                    </span> */}
                </InlineStack>
                <InlineStack gap={'sm'} justifyEnd>
                    {page.pageType === 'glossary' && (
                        <Button
                            text="Filter"
                            hideText
                            icon={ICONS.FILTER_WHITE}
                            iconHasBadge={filterTags.length > 0}
                            plain
                            // outline
                            onClick={openTagsModal}
                            small
                        />
                    )}
                    <ActionsDropdown
                        actions={[
                            {
                                title: 'Export XLSX',
                                onClick: onExportGroupXlsx,
                                icon: ICONS.DOWNLOAD_GRAY,
                            },
                            {
                                title: 'Export CSV',
                                onClick: onExportGroupCsv,
                                icon: ICONS.DOWNLOAD_GRAY,
                            },
                            {
                                title: 'Export PDF',
                                onClick: onExportPdf,
                                icon: ICONS.DOWNLOAD_GRAY,
                            },
                            {
                                title: 'Export Doc',
                                onClick: onExportDoc,
                                icon: ICONS.DOWNLOAD_GRAY,
                            },

                            {
                                title: 'Edit',
                                onClick: () =>
                                    navigate(`/edit-pages/${page.id}`),
                                icon: ICONS.EDIT_GRAY,
                                hide: !auth.isAdmin && !auth.isAssistant,
                            },
                            {
                                title: 'Merge',
                                onClick: onMerge,
                                hide: !auth.isAdmin && !auth.isAssistant,
                            },
                            {
                                title: 'Delete',
                                onClick: onDelete,
                                hide: !auth.isAdmin,
                                type: 'alert',
                                icon: ICONS.TRASH_RED,
                            },
                            {
                                title: 'Share',
                                onClick: onShare,
                                icon: ICONS.SHARE_2_GRAY,
                            },
                            {
                                title: 'Copy URL',
                                onClick: onCopyUrl,
                                icon: ICONS.CLIPBOARD_GRAY,
                            },
                        ]}
                    />
                </InlineStack>
            </InlineStack>

            <Divider />
            <Break />

            {tocTitles.length > 0 && (
                <>
                    <ToC titles={tocTitles} />
                    <Break />
                </>
            )}

            <div ref={pageRef}>
                {page.content && !page.isHtml ? (
                    <pre>{page.content}</pre>
                ) : null}
                {page.cssCode && page.isHtml ? (
                    <style
                        dangerouslySetInnerHTML={{
                            __html: page.cssCode
                                .replace(/&lt;/g, '<')
                                .replace(/&gt;/g, '>')
                                .replace(/“/g, '"')
                                .replace(/”/g, '"'),
                        }}
                    ></style>
                ) : null}
                {page.content && page.isHtml ? (
                    <div
                        className={`page-content page-${page.id}`}
                        dangerouslySetInnerHTML={{
                            __html: htmlContent,
                        }}
                    ></div>
                ) : null}
                {page.pageType === 'glossary' && (
                    <Glossary
                        items={(page.publishedChildPages || [])
                            .map((p) => ({
                                title: p.title,
                                link: `/pages/${page.slug}/${p.slug}`,
                                tags: p.tags,
                            }))
                            .filter(
                                (p) =>
                                    filterTags.length === 0 ||
                                    filterTags.every((tag) =>
                                        p.tags.includes(tag),
                                    ),
                            )}
                    />
                )}
            </div>

            {auth.isAdmin && (
                <MainButton
                    href={`/edit-pages/${page.id}`}
                    functionality="EDIT"
                />
            )}
        </SectionContainer>
    )
}

export default Page
