import { useSearchParams } from 'react-router-dom'

import SectionContainer from '../../../components/common/SectionContainer'
import PremiumBanner from './PremiumBanner'
import Break from '../../../components/common/Break'

export default function RequestFeature() {
    const [searchParams] = useSearchParams()
    const requestFeature = searchParams.get('requestFeature')
    const message = searchParams.get('message')

    return (
        <SectionContainer>
            {!!message && <span className="text-subdued">{message}</span>}
            <Break />
            <PremiumBanner requestFeature={requestFeature} />
        </SectionContainer>
    )
}
