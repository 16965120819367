import { useParams } from 'react-router-dom'
import ContactCard from '../clients/ContactCard'
import { getUserDisplayName } from '../../../common/helpers'
import Input from '../../../components/common/data-form/Input'
import Button from '../../../components/common/Button'
import MainButton from '../../../components/admin/MainButton'
import { useState } from 'react'
import useData from '../../../common/hooks/useData'
import useToast from '../../../common/hooks/useToast'
import fetchAPI from '../../../common/fetchAPI'
import Spinner from '../../../components/common/Spinner'
import ErrorMessage from '../../../components/common/ErrorMessage'
import SectionContainer from '../../../components/common/SectionContainer'
import useError from '../../../common/hooks/useError'

export default function ResourceIndividualPermissions() {
    const { resource, id, permission } = useParams()
    const [data, setData] = useState([])
    const [actionLoading, setActionLoading] = useState(null)
    const [error, setError] = useError()
    const setToast = useToast()
    const {
        // permissions,
        permissionsLoading,
        permissionsError,
    } = useData(
        `/v1/settings/permissions/resources/${resource}/${id}?targetType=user&scope=${permission}`,
        'permissions',
        null,
        null,
        null,
        (items) => setData(items.map((item) => item.target)),
    )

    async function handleSubmit(e) {
        e.preventDefault()
        setActionLoading(true)
        const { error } = await fetchAPI(
            `/v1/settings/permissions/resources/${resource}/${id}`,
            {
                scope: permission,
                target: data,
                value: true,
                targetType: 'userId',
            },
            'PATCH',
        )
        setActionLoading(false)
        // permissionsMutate()
        if (error) {
            setError(error)
            return
        }
        setToast('Saved')
    }

    if (permissionsLoading) {
        return <Spinner />
    }

    if (permissionsError) {
        return (
            <SectionContainer>
                <ErrorMessage>{permissionsError}</ErrorMessage>
            </SectionContainer>
        )
    }

    return (
        <>
            {!!error && (
                <ErrorMessage section onDismiss={() => setError('')}>
                    {error}
                </ErrorMessage>
            )}

            <SectionContainer>
                <h2 className="h3">{`${resource
                    .replace('-', ' ')
                    .toUpperCase()} - ${id} - "${permission}"`}</h2>
            </SectionContainer>

            <SectionContainer>
                <form onSubmit={handleSubmit}>
                    <Button
                        type="submit"
                        text="Save"
                        small
                        alignRight
                        isLoading={actionLoading}
                    />

                    <Input
                        id="form-user-id"
                        type="resourceList"
                        value={data}
                        onSelectedItemsChange={(ids) => setData(ids)}
                        apiUrl={'/users'}
                        initialQuery={{
                            fields: 'email,firstName,lastName',
                            populate: 'orgsData',
                        }}
                        filters={{ search: true }}
                        getSelectedItemText={(item) => getUserDisplayName(item)}
                        getItemsFromResponse={(data) => data?.results}
                        paginated
                        loadMoreButton
                        paginationLimit={20}
                        fields={[
                            {
                                column: 1,
                                getValue: (item) => (
                                    <ContactCard summary user={item} />
                                ),
                            },
                        ]}
                    />
                    <MainButton
                        functionality={'SAVE'}
                        loading={actionLoading}
                    />
                </form>
            </SectionContainer>
        </>
    )
}
