export default function MembershipInfo({ isPremiumUser, billingIds }) {
    return (
        <>
            {isPremiumUser ? (
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`https://dashboard.stripe.com/${
                        process.env.NODE_ENV === 'development' ? 'test/' : ''
                    }customers/${
                        billingIds.find((item) => item.source === 'stripe')?.id
                    }`}
                >
                    Premium account - Stripe
                </a>
            ) : (
                'Free membership'
            )}
        </>
    )
}
