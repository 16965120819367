import { useRef, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import fetchAPI from '../../../common/fetchAPI'
import useError from '../../../common/hooks/useError'
import useToast from '../../../common/hooks/useToast'
import Button from '../../../components/common/Button'
import ButtonGroup from '../../../components/common/ButtonGroup'
import MessageSection from '../../../components/common/MessageSection'
import SignatureInput from '../../../components/common/SignatureInput'
import useAuth from '../../../common/hooks/useAuth'
import ErrorMessage from '../../../components/common/ErrorMessage'
import useData from '../../../common/hooks/useData'
import Spinner from '../../../components/common/Spinner'
import TextInput from '../../../components/common/data-form/TextInput'
import SectionContainer from '../../../components/common/SectionContainer'
import Break from '../../../components/common/Break'

function TaxForms() {
    const formRef = useRef(null)
    const [error, setError, errorFields] = useError()
    const [loading, setLoading] = useState(false)
    const setToast = useToast()
    const navigate = useNavigate()
    const auth = useAuth()
    const [searchParams] = useSearchParams()

    const org = auth.user.orgsData.find(
        (o) => o.id === searchParams.get('organizationId'),
    )

    const [data, setData] = useState({
        natureOfBusiness: '',
        reason: '',
        signatureUri: null,
        formType: 'iowa',
        organizationId: org?.id,
    })

    const { account, accountLoading, accountError } = useData(
        `/v1/organizations/${org.id}?fields=name&populate=vendorAccount`,
        'account',
        (data) => data?.vendorAccount,
        false,
        false,
        (item) =>
            setData({
                ...data,
                reason: item.isConsignment ? 'consignment' : 'retailer',
            }),
    )

    if (!org) {
        return
    }

    async function onSubmit(e) {
        setError('')

        if (!formRef.current.reportValidity()) {
            e.preventDefault()
            return
        }
        e.preventDefault()

        if (!data.signatureUri) {
            setError('Please add your signature')
            return
        }

        setLoading(true)
        const body = {
            ...data,
            formType: 'salesTaxExemptIowa',
        }
        const { error, meta } = await fetchAPI(
            '/v1/files/pdf-forms',
            body,
            'POST',
        )
        setLoading(false)

        if (error) {
            setError(error, meta?.fields)
            return
        }

        setToast('Submitted successfully')
        navigate('/')
    }

    if (accountLoading) {
        return <Spinner />
    }

    if (accountError) {
        return (
            <SectionContainer>
                <ErrorMessage>{accountError}</ErrorMessage>
            </SectionContainer>
        )
    }

    if (!account || !account.locations?.includes('iowa')) {
        return (
            <SectionContainer>
                <MessageSection type="warning">
                    No need to sign any tax forms for your account.
                    <Break />
                    <Button
                        small
                        outline
                        white
                        href={'/'}
                        text="Go to homepage"
                    />
                </MessageSection>
            </SectionContainer>
        )
    }

    return (
        <SectionContainer>
            {error && (
                <ErrorMessage onDismiss={() => setError('')}>
                    {error}
                </ErrorMessage>
            )}

            <form ref={formRef}>
                <ButtonGroup
                    background
                    label="Iowa tax form"
                    imageSrc="/img/pdf-thumbnails/Sales_Tax_Exempt_Form.jpg"
                    imageWidth="150"
                    imageHeight="194"
                >
                    <Button
                        text="View sample"
                        type="button"
                        href={`${process.env.REACT_APP_BACKEND_URL}/assets/pdf/Sales_Tax_Exempt_Form.pdf`}
                        newPage
                    />
                </ButtonGroup>

                <span>&nbsp;</span>

                <div>
                    <label htmlFor="form-nature" className="required">
                        Nature of business
                    </label>
                    <TextInput
                        required
                        type="text"
                        id="form-nature"
                        placeholder="Boutique"
                        value={data.natureOfBusiness}
                        onChange={(v) =>
                            setData({
                                ...data,
                                natureOfBusiness: v,
                            })
                        }
                        errors={errorFields.filter(
                            (f) => f === 'natureOfBusiness',
                        )}
                    />
                </div>

                <div>
                    <label htmlFor="form-reason">Reason</label>
                    <TextInput
                        disabled
                        type="text"
                        id="form-reason"
                        value={data.reason}
                        errors={errorFields.filter((f) => f === 'reason')}
                    />
                </div>

                <SignatureInput
                    label={'Sign here'}
                    onChange={(dataUri) =>
                        setData({
                            ...data,
                            signatureUri: dataUri,
                        })
                    }
                    errors={errorFields.filter((f) => f === 'signatureUri')}
                />
                <Button
                    onClick={onSubmit}
                    isLoading={loading}
                    text={'Submit'}
                    type="submit"
                />
            </form>
        </SectionContainer>
    )
}

export default TaxForms
