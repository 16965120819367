import { forwardRef, useEffect, useRef, useState } from 'react'
import BlockStack from '../BlockStack'
import InlineStack from '../InlineStack'
import InlineStackItem from '../InlineStackItem'
import Popover from '../Popover'
import Button from '../Button'
import { ICONS } from '../../../common/constants'
import PhoneInput from './PhoneInput'

const InputItem = forwardRef(function InputItemInner(
    {
        item,
        items,
        setItem,
        onAddMore,
        addMoreDisabled,
        typeLabels,
        uniqueType,
        ...rest
    },
    ref,
) {
    const popoverRef = useRef(null)

    function handleChange(v) {
        setItem({
            ...item,
            value: v,
            type: item.type,
        })
    }

    const { type: itemType } = item

    const hasTypeLabels = !!Object.keys(typeLabels).length

    return (
        <InlineStack itemsCenter gap="tiny">
            <InlineStackItem grow1>
                <div className="input-container">
                    {hasTypeLabels && (
                        <Popover
                            ref={popoverRef}
                            closeOnChildClick
                            renderActivator={(ref, listeners) => (
                                <div ref={ref}>
                                    <Button
                                        {...listeners}
                                        tiny
                                        text={
                                            <InlineStack>
                                                <span>
                                                    {typeLabels[itemType]}
                                                </span>
                                                <div
                                                    style={{
                                                        transform:
                                                            'rotate(90deg)',
                                                    }}
                                                >
                                                    <img
                                                        width={16}
                                                        height={16}
                                                        alt="toggle"
                                                        src={
                                                            ICONS.CHEVRON_RIGHT_WHITE
                                                        }
                                                    />
                                                </div>
                                            </InlineStack>
                                        }
                                    />
                                </div>
                            )}
                        >
                            {Object.keys(typeLabels)
                                .filter(
                                    (type) =>
                                        !uniqueType ||
                                        !(
                                            type === uniqueType &&
                                            items.some(
                                                (i) => i.type === uniqueType,
                                            )
                                        ),
                                )
                                .map((type) => (
                                    <div
                                        role="button"
                                        key={type}
                                        onClick={() =>
                                            setItem({ ...item, type })
                                        }
                                    >
                                        <div>{typeLabels[type]}</div>
                                    </div>
                                ))}
                        </Popover>
                    )}

                    <div ref={ref}>
                        <PhoneInput
                            value={item.value}
                            onChange={handleChange}
                            {...rest}
                            withClear
                            noMargin
                        />
                    </div>
                </div>
            </InlineStackItem>

            {!!onAddMore && (
                <InlineStackItem shrink0>
                    <BlockStack itemsCenter>
                        <Button
                            disabled={addMoreDisabled}
                            iconTop
                            text={'Add'}
                            icon={ICONS.PLUS_WHITE}
                            plain
                            tiny
                            onClick={onAddMore}
                        />
                    </BlockStack>
                </InlineStackItem>
            )}
        </InlineStack>
    )
})

export default function MultiplePhoneInputs({
    value = [],
    onChange,
    typeLabels,
    uniqueType,
    errors,
    ...rest
}) {
    const inputRefs = useRef(null)

    const hasTypeLabels = !!Object.keys(typeLabels).length

    const items = value
    if (!items.length) {
        items.push({
            value: '',
            ...(hasTypeLabels ? { type: Object.keys(typeLabels)[0] } : {}),
        })
    }

    function getInputsMap() {
        if (!inputRefs.current) {
            inputRefs.current = new Map()
        }
        return inputRefs.current
    }

    function handleAddMore() {
        const newItems = [...items]
        newItems.push({
            value: '',
            ...(hasTypeLabels
                ? { type: Object.keys(typeLabels).reverse()[0] }
                : {}),
        })
        onChange(newItems)
        setTimeout(function () {
            const map = getInputsMap()
            const lastKey = Array.from(map.keys()).pop()
            const lastInputContainer = map.get(lastKey)
            if (lastInputContainer) {
                lastInputContainer.querySelector('input').focus()
            }
        }, 0)
    }

    const errorIndexRegex = new RegExp('_(\\d+)(_|$)')
    const errorIndices = (errors || []).map((error) =>
        Number(error.match(errorIndexRegex)?.[1]),
    )

    return (
        <div className="multiple-phone-inputs">
            <BlockStack gap={'sm'}>
                {value.map((item, i) => (
                    <InputItem
                        key={i}
                        typeLabels={typeLabels}
                        item={item}
                        items={items}
                        setItem={(newItem) => {
                            const newItems = [...items]
                            newItems[i] = newItem
                            onChange(newItems)
                        }}
                        removeItem={() => {
                            const newItems = [...items]
                            newItems.splice(i, 1)
                            onChange(newItems)
                        }}
                        uniqueType={uniqueType}
                        addMoreDisabled={items.some((item) => !item.value)}
                        onAddMore={
                            i === items.length - 1
                                ? () => handleAddMore(i)
                                : null
                        }
                        ref={(node) => {
                            const map = getInputsMap()
                            if (node) {
                                map.set(i, node)
                            } else {
                                map.delete(i)
                            }
                        }}
                        errors={errorIndices.includes(i) ? [true] : false}
                        {...rest}
                    />
                ))}
            </BlockStack>
        </div>
    )
}
