import { Link, useNavigate, useLocation, useParams } from 'react-router-dom'
import useAuth from '../../common/hooks/useAuth'
import UploadNotifs from '../../common/UploadNotifs'
import useNotifs from '../../common/hooks/useNotifs'
import useHashNav from '../../common/hooks/useHashNav'
import useScrollMeasure from '../../common/hooks/useScrollMeasure'
import { useEffect, useState } from 'react'
import InlineStack from './InlineStack'
import TextSkeleton from './TextSkeleton'
import ContactThumbnail from '../../pages/admin/clients/ContactThumbnail'
import { ICONS } from '../../common/constants'

const BREADCRUMB_ICONS = {
    '/': ICONS.HOME_ACTIVE,
    '/billing': ICONS.BAR_CHART_ACTIVE,
    '/contacts': ICONS.BRIEFCASE_ACTIVE,
    '/content': ICONS.INSTAGRAM_ACTIVE,
    '/shop': ICONS.SHOPPING_CART_ACTIVE,
    '/more': ICONS.MENU_ACTIVE,
    '/settings': ICONS.SETTINGS_ACTIVE,
}

function MainHeader({
    backUrl,
    title: initialTitle,
    getBreadcrumbs,
    getTitle,
}) {
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const params = useParams()
    // const { teamMemberId, userId } = params
    const auth = useAuth()
    const [hash] = useHashNav()
    const [breadcrumbs, setBreadcrumbs] = useState(null)
    const [title, setTitle] = useState(initialTitle || '')

    const { stickyTop, hasScrolled } = useScrollMeasure()

    useEffect(
        function () {
            setBreadcrumbs(null)
            setTitle(initialTitle || '')

            async function initBreadcrumbs() {
                const items = await getBreadcrumbs({ params, auth })
                setBreadcrumbs(items)
            }

            async function initTitle() {
                const newTitle = await getTitle({ params, auth })
                setTitle(newTitle || initialTitle || '')
            }

            if (getBreadcrumbs) {
                initBreadcrumbs()
            }

            if (getTitle) {
                initTitle()
            }
        },
        [getBreadcrumbs],
    )

    useEffect(
        function () {
            if (title) {
                document.title = `${title} | aCo`
            } else {
                document.title = 'aCo Digital'
            }
        },
        [title],
    )

    // const { user } = useData(
    //     (auth.isAdmin || auth.isStaff) && (userId || teamMemberId)
    //         ? `/v1/users/${
    //               userId || teamMemberId
    //           }?fields=email,firstName,lastName${
    //               auth.isAdmin ? ',role,assignedClientIds' : ''
    //           }&populate=orgsData`
    //         : null,
    //     'user',
    // )

    const { notifs } = useNotifs()

    function onBack() {
        if (backUrl) {
            navigate(
                backUrl
                    .split('/')
                    .map((part) =>
                        part.startsWith(':') ? params[part.slice(1)] : part,
                    )
                    .join('/'),
            )
        } else {
            navigate(-1)
        }
    }

    function getBreadcrumbHtml(item, index) {
        if (index !== 0) return item.title

        for (const key in BREADCRUMB_ICONS) {
            if (item.url === key || item.url?.startsWith(`${key}#`)) {
                return (
                    <img
                        width={12}
                        height={12}
                        src={BREADCRUMB_ICONS[key]}
                        alt={item.title}
                    />
                )
            }
        }

        return item.title
    }

    if (!auth.user)
        return (
            <header
                className={`header${hasScrolled ? ' header-scrolled' : ''}`}
                style={{
                    '--header-top': `${stickyTop}px`,
                }}
            >
                <div className="nav-controls"></div>
                <div className="title"></div>
                <div className="controls">
                    <Link to="/login">
                        <img
                            src={'/img/icons/login-active.svg'}
                            alt="login icon"
                            width="24"
                            height="24"
                        />
                        <small>Login</small>
                    </Link>
                </div>
            </header>
        )
    return (
        <header
            className={`header${hasScrolled ? ' header-scrolled' : ''}${
                getBreadcrumbs ? ' with-breadcrumbs' : ''
            }`}
            style={{
                '--header-top': `${stickyTop}px`,
            }}
        >
            <InlineStack>
                <div className="nav-controls">
                    {window.history.length > 1 && (
                        <button
                            aria-label="Back"
                            onClick={onBack}
                            className="header-back-link"
                        >
                            <img
                                src="/img/icons/chevron-left-white.svg"
                                alt="back icon"
                                width="24"
                                height="24"
                            />
                        </button>
                    )}
                </div>
                <div className="title">
                    <h1>
                        {hash && !hash.startsWith('glossary-') ? (
                            <span className="hash">
                                {decodeURIComponent(
                                    hash
                                        .replace('__', '')
                                        .replace(/_/g, ' ')
                                        .replace(/#/g, ' / '),
                                )}
                            </span>
                        ) : (
                            title
                        )}
                    </h1>

                    {/* {(auth.isAdmin || auth.isStaff) && user ? (
                    <p className="title-small">
                        {<ContactName user={user} showCompany />}
                    </p>
                ) : null} */}
                </div>
                <div className="controls">
                    <UploadNotifs />
                    {/* <Link className="header-profile-link" to="/profile">
                        <img
                            src={
                                pathname === '/profile'
                                    ? '/img/icons/user-active.svg'
                                    : '/img/icons/user-white.svg'
                            }
                            alt="user icon"
                            width="24"
                            height="24"
                        />
                        {auth.user?.firstName && (
                            <span
                                className={
                                    pathname === '/profile'
                                        ? 'text-accent'
                                        : 'text-white'
                                }
                            >
                                {auth.user.firstName}
                            </span>
                        )}
                    </Link> */}
                    <ContactThumbnail small data={auth.user} />
                    <Link
                        to="/notifications"
                        className={notifs?.length ? 'with-badge' : ''}
                    >
                        {notifs?.length > 0 && notifs?.length < 100 && (
                            <span>{notifs.length}</span>
                        )}
                        {notifs?.length >= 100 && notifs?.length < 1000 && (
                            <span>99+</span>
                        )}
                        {notifs?.length >= 1000 && notifs?.length < 10000 && (
                            <span>
                                {notifs.length.toString().slice(0, 1) + 'k'}
                            </span>
                        )}
                        {notifs?.length >= 10000 && <span>9k+</span>}
                        <img
                            src={
                                pathname === '/notifications'
                                    ? '/img/icons/bell-active.svg'
                                    : '/img/icons/bell-white.svg'
                            }
                            alt="bell icon"
                            width="24"
                            height="24"
                        />
                    </Link>
                    <Link
                        to={
                            auth.isAdmin || auth.isAssistant
                                ? '/edit-pages'
                                : '/all-pages'
                        }
                    >
                        <img
                            src={
                                pathname === '/edit-pages'
                                    ? '/img/icons/search-active.svg'
                                    : '/img/icons/search-white.svg'
                            }
                            alt="search icon"
                            width="24"
                            height="24"
                        />
                    </Link>
                    <Link to="/settings">
                        <img
                            src={
                                pathname === '/settings'
                                    ? '/img/icons/settings-active.svg'
                                    : '/img/icons/settings-white.svg'
                            }
                            alt="settings icon"
                            width="24"
                            height="24"
                        />
                    </Link>
                </div>
            </InlineStack>
            {getBreadcrumbs && (
                <div className={`title-small`}>
                    {!breadcrumbs && (
                        <TextSkeleton
                            height={'12px'}
                            defaultText="Loading breadcrumbs"
                        />
                    )}
                    {breadcrumbs?.length > 0 && (
                        <InlineStack itemsCenter>
                            {breadcrumbs.map((item, i) => (
                                <Link to={item.url} key={i}>
                                    {i > 0 && <>&nbsp;&gt;&nbsp;</>}
                                    {getBreadcrumbHtml(item, i)}
                                </Link>
                            ))}
                        </InlineStack>
                    )}
                </div>
            )}
        </header>
    )
}

export default MainHeader
