import { ICONS } from '../../common/constants'
import { formatDateTime } from '../../common/helpers'
import InfoList from '../../components/common/InfoList'

export default function AccessInfo({
    lastAccountAccessDate,
    lastPasswordResetDate,
}) {
    return (
        <>
            <h3 className="h6 text-subdued">Account access</h3>
            <InfoList
                rows={[
                    {
                        icon: ICONS.SAVE_WHITE,
                        content: `Last API access: ${
                            lastAccountAccessDate
                                ? formatDateTime(lastAccountAccessDate * 1000)
                                : 'None'
                        }`,
                    },
                    {
                        icon: ICONS.LOCK_WHITE,
                        content: `Last password reset: ${
                            lastPasswordResetDate
                                ? formatDateTime(lastPasswordResetDate * 1000)
                                : 'None'
                        }`,
                    },
                ]}
            />
        </>
    )
}
