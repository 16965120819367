export default function InlineStack({
    children,
    itemsCenter,
    spaceBetween,
    className,
    itemsStart,
    itemsEnd,
    itemsStretch,
    justifyEnd,
    justifyCenter,
    gap,
    rowGap,
    colGap,
    flushMargins,
    wrap,
    overflowHidden,
    justifyStart,
}) {
    let finalClassName = 'inline-stack'
    if (className) finalClassName += ` ${className}`
    if (itemsCenter) finalClassName += ' items-center'
    if (spaceBetween) finalClassName += ' space-between'
    if (itemsStart) finalClassName += ' items-start'
    if (itemsEnd) finalClassName += ' items-end'
    if (itemsStretch) finalClassName += ' items-stretch'
    if (justifyEnd) finalClassName += ' justify-end'
    if (justifyCenter) finalClassName += ' justify-center'
    if (justifyStart) finalClassName += ' justify-start'
    if (flushMargins) finalClassName += ' flush-margins'
    if (wrap) finalClassName += ' wrap'
    if (gap) finalClassName += ` gap-${gap}`
    if (rowGap) finalClassName += ` row-gap-${rowGap}`
    if (colGap) finalClassName += ` col-gap-${colGap}`
    if (overflowHidden) finalClassName += ' overflow-hidden'
    return <div className={finalClassName}>{children}</div>
}
