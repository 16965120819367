import { DEFALT_MENU_TILES } from './constants'

export const initialState = {
    user: null,
    initLoading: true,
    isModalOpen: false,
    isModalLoading: false,
    modalChildren: null,
    modalClass: '',
    modalTitle: '',
    toasts: [],
    files: [],
    translationsCache: {},
    theme: 'dark',
    menuTiles: DEFALT_MENU_TILES,
}

export const reducer = (state, action) => {
    switch (action.type) {
        case 'LOGIN': {
            return { ...state, user: action.payload }
        }
        case 'UPDATE_USER': {
            return { ...state, user: action.payload }
        }
        case 'UPDATE_USER_PROPERTIES': {
            return { ...state, user: { ...state.user, ...action.payload } }
        }
        case 'LOGOUT': {
            return { ...state, user: null }
        }
        case 'START_INITIAL_USER_LOAD': {
            return { ...state, initLoading: true }
        }
        case 'FINISH_INITIAL_USER_LOAD': {
            return { ...state, user: action.payload, initLoading: false }
        }
        case 'OPEN_MODAL': {
            return {
                ...state,
                isModalOpen: true,
                isModalLoading: false,
                modalChildren: action.payload,
            }
        }
        case 'SET_MODAL_OPEN': {
            return {
                ...state,
                isModalOpen: action.payload,
            }
        }
        case 'SET_MODAL_CLASS': {
            return {
                ...state,
                modalClass: action.payload,
            }
        }
        case 'SET_MODAL_TITLE': {
            return {
                ...state,
                modalTitle: action.payload,
            }
        }
        case 'CLOSE_MODAL': {
            return {
                ...state,
                isModalOpen: false,
                isModalLoading: false,
                modalChildren: null,
                modalTitle: '',
                modalClass: '',
            }
        }
        case 'SET_MODAL_LOADING': {
            return {
                ...state,
                isModalLoading: action.payload,
            }
        }
        case 'SET_TOAST': {
            const { text, date, messageType, counter } = action.payload
            const newToasts = [
                ...state.toasts,
                { text, date, messageType, counter },
            ]
            return {
                ...state,
                toasts: newToasts,
            }
        }
        case 'CLEAR_TOAST': {
            const id = action.payload
            const newToasts = state.toasts.filter((toast) => toast.date !== id)
            return {
                ...state,
                toasts: newToasts,
            }
        }
        case 'START_FILES_UPLOAD': {
            const files = action.payload
            return {
                ...state,
                files,
            }
        }
        case 'SET_TRANSLATIONS': {
            const data = action.payload
            return {
                ...state,
                translationsCache: { ...state.translationsCache, ...data },
            }
        }
        case 'SET_THEME': {
            const theme = action.payload
            return {
                ...state,
                theme,
            }
        }
        case 'SET_MENU_TILES': {
            const menuTiles = action.payload
            return {
                ...state,
                menuTiles,
            }
        }
        default:
            return state
    }
}
