import { useState } from 'react'
import { ICONS } from '../../../common/constants'
import Button from '../Button'
import useFilePicker from '../FilePicker'
import InlineStack from '../InlineStack'
import useFileCropper from '../useFileCropper'
import fetchAPI from '../../../common/fetchAPI'
import useToast from '../../../common/hooks/useToast'

async function getImageDimensions(url) {
    return new Promise((resolve, reject) => {
        const img = new Image()
        img.onload = () => {
            resolve({ width: img.width, height: img.height })
        }
        img.onerror = reject
        img.src = url
    })
}

async function getFileFromApi(url) {}

export default function FilePickerInput({
    onChange,
    value,
    accept,
    downsizeToWidth,
    errors,
    externalSources = [],
}) {
    const { pickerHtml, openPicker } = useFilePicker()
    const { cropperHtml, openCropper } = useFileCropper(value)
    const [loadingSourceUrl, setLoadingSourceUrl] = useState(null)
    const setToast = useToast()

    async function handlePickFile() {
        const files = await openPicker({
            isPublic: true,
            maxFiles: 1,
            accept,
            downsizeToWidth,
        })

        if (!files) {
            return
        }

        const url = files?.[0]?.publicUrl

        if (!url) {
            return
        }

        onChange(url)
    }

    async function handleCrop() {
        const file = await openCropper({
            isPublic: true,
        })

        if (!file) {
            return
        }

        const url = file.publicUrl

        if (!url) {
            return
        }

        onChange(url)
    }

    function handleClear() {
        onChange(null)
    }

    async function loadFromExternalSource(apiUrl) {
        setLoadingSourceUrl(apiUrl)
        const {
            responseData: externalImageResponse,
            error: externalImageError,
        } = await fetchAPI(apiUrl)
        if (externalImageError) {
            setToast(externalImageError, 'alert')
            setLoadingSourceUrl(null)
            return
        }

        const blob = await externalImageResponse.blob()
        const url = URL.createObjectURL(blob)

        let dimensions = blob.type.startsWith('image/')
            ? await getImageDimensions(url)
            : null

        const { responseData: urlResponseData, error: urlError } =
            await fetchAPI(
                `/v1/files/list/me`,
                {
                    files: [
                        {
                            fileName: `external-${Date.now()}.${blob.type.split('/')[1]}`,
                            width: dimensions?.width,
                            height: dimensions?.height,
                            mimeType: blob.type,
                            size: blob.size,
                        },
                    ],
                },
                'POST',
            )

        if (urlError) {
            setToast(urlError, 'alert')
            setLoadingSourceUrl(null)
            return
        }

        const { uploadUrl, dbId } = urlResponseData[0]
        const uploadResponse = await fetch(uploadUrl, {
            method: 'PUT',
            headers: {
                'Content-Type': blob.type,
                'x-goog-meta-filename': `external-${Date.now()}.${blob.type.split('/')[1]}`,
                ...(dimensions
                    ? {
                          'x-goog-meta-width': dimensions.width,
                          'x-goog-meta-height': dimensions.height,
                      }
                    : {}),
            },
            body: blob,
        })
        if (!uploadResponse?.ok) {
            setToast('Upload failed', 'alert')
            setLoadingSourceUrl(null)
            return
        }
        const { responseData: publishResponseData, publishError } =
            await fetchAPI(`/v1/files/list/me/${dbId}/make-public`, {}, 'POST')

        if (publishError) {
            setToast(publishError, 'alert')
            setLoadingSourceUrl(null)
            return
        }

        setLoadingSourceUrl(null)

        onChange(publishResponseData.publicUrl)
    }

    return (
        <div
            className={`file-picker-input${errors?.length ? ' has-error' : ''}`}
        >
            <InlineStack gap="md">
                {value ? (
                    <img src={value} alt="preview" />
                ) : (
                    <div className="img-placeholder ">
                        <small className="text-subdued">No file</small>
                    </div>
                )}
                <Button
                    outline
                    small
                    text="Pick a file"
                    onClick={handlePickFile}
                />
                {!!value && (
                    <Button
                        icon={ICONS.TRASH_RED}
                        destructive
                        outline
                        small
                        text="Clear"
                        hideText
                        onClick={handleClear}
                    />
                )}
                {(value?.endsWith('.jpg') || value?.endsWith('.png')) && (
                    <Button
                        icon={ICONS.CROP_WHITE}
                        text="Crop"
                        hideText
                        onClick={handleCrop}
                        outline
                        white
                        small
                    />
                )}
                {(externalSources || []).map((s) => (
                    <Button
                        key={s.apiUrl}
                        outline
                        white
                        small
                        text={s.label}
                        onClick={() => loadFromExternalSource(s.apiUrl)}
                        isLoading={loadingSourceUrl === s.apiUrl}
                    />
                ))}
            </InlineStack>
            {pickerHtml}
            {cropperHtml}
        </div>
    )
}
