import { Link } from 'react-router-dom'
import InlineStack from '../../components/common/InlineStack'
import BlockStack from '../../components/common/BlockStack'
import ContactThumbnail from '../admin/clients/ContactThumbnail'

export default function OrgCard({ org: data }) {
    return (
        <div className={`contact-card`}>
            <InlineStack itemsCenter gap={'sm'}>
                <ContactThumbnail small data={data} isOrg />
                <BlockStack gap={'xxs'}>
                    <Link
                        className="strong m-0"
                        to={`/${
                            data.organizationType === 'company'
                                ? 'companies'
                                : 'organizations'
                        }/${data.id}`}
                    >
                        {data.name}
                    </Link>

                    <small className="text-subdued text-caps">
                        {data.organizationType}
                        {data?.members?.length !== undefined &&
                            ` - ${data.members.length} ${
                                data.members.length === 1 ? 'member' : 'members'
                            }`}
                    </small>
                </BlockStack>
            </InlineStack>
        </div>
    )
}
