import DataFormNew from '../../../components/common/DataFormNew'
import SectionContainer from '../../../components/common/SectionContainer'
import { useParams } from 'react-router-dom'

export default function EditSegments() {
    const { organizationId } = useParams()

    return (
        <SectionContainer>
            <DataFormNew
                url={`/v1/organizations/${organizationId}?fields=segments`}
                method="PATCH"
                submitText="Save"
                submitToast="Saved"
                submitNavArg={`/organizations/${organizationId}`}
                inputs={[
                    {
                        key: 'segments',
                        type: 'tree',
                        label: ' ',
                    },
                ]}
                getBody={(data) => ({
                    segments: data.segments.map((s) => ({
                        title: s.title,
                        segmentType: s.itemType,
                        subSegments: s.items.map((ss) => ({
                            title: ss.title,
                            segmentType: ss.itemType,
                            subSegments: ss.items.map((sss) => ({
                                title: sss.title,
                                segmentType: sss.itemType,
                            })),
                        })),
                    })),
                })}
                mapItemToData={(item) => ({
                    segments: (item.segments || []).map((s) => ({
                        title: s.title,
                        itemType: s.segmentType,
                        items: (s.subSegments || []).map((ss) => ({
                            title: ss.title,
                            itemType: ss.segmentType,
                            items: (ss.subSegments || []).map((sss) => ({
                                title: sss.title,
                                itemType: sss.segmentType,
                            })),
                        })),
                    })),
                })}
                mutationRegexes={[
                    new RegExp(`^/v1/organizations/${organizationId}`),
                ]}
                mainButton
            />
        </SectionContainer>
    )
}
