import { useEffect } from 'react'
import Button from '../Button'
import { ICONS } from '../../../common/constants'
import Divider from '../Divider'
import Break from '../Break'
import Input from './Input'

export default function AddInputGroupsInput({
    value = [],
    onChange,
    defaultValue,
    defaultGroupData = {},
    inputs = [],
    errors,
}) {
    useEffect(function () {
        if (typeof defaultValue !== 'undefined') {
            onChange(defaultValue)
        }
    }, [])

    function handleNewItemAdd() {
        onChange([...value, defaultGroupData])
    }

    function handleChange(v, key, index) {
        onChange(
            value.map((item, i) =>
                i === index ? { ...item, [key]: v } : item,
            ),
        )
    }

    function handleDelete(index) {
        onChange(value.filter((_item, i) => i !== index))
    }

    // if (!defaultValue?.length && !value?.length) {
    //     // return null
    // }

    const items = value

    const errorItems = (errors || []).map((e) => ({
        index: Number(e.split('_')[1]),
        key: e.split('_')[2],
    }))

    return (
        <div className="add-input-groups-input">
            {items.map((item, i) => (
                <div key={i}>
                    <Break />
                    {inputs.map(
                        (input, j) =>
                            input.type !== 'hidden' && (
                                <div key={j}>
                                    <Input
                                        {...input}
                                        value={item[input.key]}
                                        onChange={(v) => {
                                            handleChange(v, input.key, i)
                                        }}
                                        errors={
                                            errorItems.some(
                                                (e) =>
                                                    e.index === i &&
                                                    e.key === input.key,
                                            ) && [true]
                                        }
                                    />
                                </div>
                            ),
                    )}
                    <Button
                        text="Delete"
                        type="button"
                        tiny
                        icon={ICONS.TRASH_RED}
                        alignRight
                        destructive
                        outline
                        onClick={() => handleDelete(i)}
                    />
                    <Break />
                    <Divider color={'light'} />
                    <Break />
                </div>
            ))}
            <Button
                noMargin
                type="button"
                outline
                text="Add new address"
                onClick={handleNewItemAdd}
            />
        </div>
    )
}
