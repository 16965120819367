import { useEffect } from 'react'

export default function SwitchInput({
    value,
    onChange,
    defaultValue,
    options,
    id,
    errors,
    ...rest
}) {
    useEffect(function () {
        if (typeof defaultValue !== 'undefined') {
            onChange(defaultValue || options[0].value)
        }
    }, [])

    function handleChange(e) {
        onChange(e.target.value)
    }

    return (
        <div className={`switch-input${errors?.length ? ' has-error' : ''}`}>
            {options.map((option) => (
                <label key={option.value}>
                    <input
                        type="radio"
                        name={`radios_${id}`}
                        value={option.value}
                        checked={value === option.value}
                        onChange={handleChange}
                        required={rest.required}
                    />
                    <div>{option.label || '-'}</div>
                </label>
            ))}
        </div>
    )
}
