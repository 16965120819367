// .company ready

import React, { useState, useRef } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import fetchAPI from '../../../common/fetchAPI'
import useError from '../../../common/hooks/useError'
import useToast from '../../../common/hooks/useToast'
import Button from '../../../components/common/Button'
import useAuth from '../../../common/hooks/useAuth'
import SignatureInput from '../../../components/common/SignatureInput'
import FormSteps from '../../../common/FormSteps'
import ButtonGroup from '../../../components/common/ButtonGroup'
import useData from '../../../common/hooks/useData'
import ErrorMessage from '../../../components/common/ErrorMessage'
import TextInput from '../../../components/common/data-form/TextInput'
import CheckboxInput from '../../../components/common/data-form/CheckboxInput'
import SectionContainer from '../../../components/common/SectionContainer'

function EditProfileVendor() {
    const navigate = useNavigate()
    const auth = useAuth()
    const setToast = useToast()
    const [searchParams] = useSearchParams()

    const [loading, setLoading] = useState(false)
    const [previewLoading, setPreviewLoading] = useState(false)
    const [error, setError] = useError()
    const [step, setStep] = useState(1)
    const [data, setData] = useState({
        signatureUri: null,
        locations: [],
        organizationId: searchParams.get('organizationId') || '',
    })

    const orgData = auth.user.orgsData?.find(
        (o) => o.id === data.organizationId,
    )
    const isDefaultOrg = orgData?.organizationType === 'default'
    const totalSteps = isDefaultOrg ? 2 : 3

    const { contractId, contractIdError } = useData(
        `/v1/html-contracts?contractType=${
            isDefaultOrg
                ? 'vendorConsignmentRegistration'
                : 'vendorRegistration'
        }`,
        'contractId',
        (data) => data?.[0].id,
    )

    const formRef = useRef(null)

    function onNext() {
        setError('')
        const currStep = step
        if (!formRef.current.reportValidity()) {
            return
        }
        switch (currStep) {
            case isDefaultOrg ? 1 : 2:
                if (!data.locations.length) {
                    setError('Please select at least one location')
                    return
                }
                break
            case isDefaultOrg ? 2 : 3:
                if (!data.signatureUri) {
                    setError('Please add your signature')
                    return
                }
                break
            default:
                break
        }

        setStep(currStep + 1)
    }

    function onPrev() {
        setError('')
        setStep(step - 1)
    }

    async function onPreviewContract(e) {
        e.preventDefault()

        setPreviewLoading(true)

        const { responseData, error, headers } = await fetchAPI(
            `/v1/html-contracts/${contractId}/print?organizationId=${data.organizationId}`,
            {},
            'GET',
        )
        setPreviewLoading(false)

        if (error) {
            setToast(error, 'alert')
            return
        }

        const blob = await responseData.blob()
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = headers.get('Filename')
        a.click()
        window.URL.revokeObjectURL(url)
    }

    async function onSubmit(e) {
        setError('')

        if (!formRef.current.reportValidity()) {
            e.preventDefault()
            return
        }
        e.preventDefault()

        if (!data.signatureUri) {
            setError('Please add your signature')
            return
        }

        setLoading(true)

        const body = {
            accountType: 'vendor',
            htmlContractId: contractId,
            signatureUri: data.signatureUri,
            locations: data.locations,
            organizationId: data.organizationId,
        }

        const { error } = await fetchAPI('/v1/accounts/submit', body, 'POST')

        setLoading(false)

        if (error) {
            setError(error)
            return
        }

        setToast('Submitted successfully')
        await auth.refetchAuth()
        navigate('/')
    }

    if (contractIdError) {
        return (
            <SectionContainer>
                <ErrorMessage>{contractIdError}</ErrorMessage>
            </SectionContainer>
        )
    }

    if (!orgData) {
        return (
            <SectionContainer>
                <ErrorMessage>{`Company information is missing. Please contact Support.`}</ErrorMessage>
            </SectionContainer>
        )
    }

    return (
        <SectionContainer>
            <form ref={formRef}>
                <FormSteps step={step} totalSteps={totalSteps} />
                {error && (
                    <ErrorMessage onDismiss={() => setError('')}>
                        {error}
                    </ErrorMessage>
                )}
                {!isDefaultOrg && step === 1 && (
                    <div>
                        <label htmlFor="form-company">Company</label>
                        <TextInput
                            type="text"
                            id="form-company"
                            value={orgData.name}
                            disabled
                        />
                    </div>
                )}

                {((isDefaultOrg && step === 1) ||
                    (!isDefaultOrg && step === 2)) && (
                    <>
                        <div>
                            <CheckboxInput
                                value={data.locations.includes('iowa')}
                                id="form-iowa"
                                label={
                                    'Sell retail at a high-end shopping center in Iowa'
                                }
                                onChange={(v) =>
                                    setData({
                                        ...data,
                                        locations: v
                                            ? [...data.locations, 'iowa']
                                            : data.locations.filter(
                                                  (item) => item !== 'iowa',
                                              ),
                                    })
                                }
                                info={`$299/mo plus commission. Includes online sales. Ideal for small brands and artists. Temporary free trial, accepting donations to help us continue work.\n${process.env.REACT_APP_PUBLIC_URL}/donate`}
                            />
                            <br />
                        </div>

                        <div>
                            <CheckboxInput
                                value={data.locations.includes('online')}
                                id="form-Online"
                                label={
                                    'Sell in the aCo Wholesale and/or Retail Online Marketplaces'
                                }
                                onChange={(v) =>
                                    setData({
                                        ...data,
                                        locations: v
                                            ? [...data.locations, 'online']
                                            : data.locations.filter(
                                                  (item) => item !== 'online',
                                              ),
                                    })
                                }
                                info={
                                    <>
                                        Free for a limited time. Ideal for any
                                        business looking for more customers. We
                                        appreciate patience and feedback while
                                        we&apos;re testing.{' '}
                                        <Link
                                            to={'/pages/donate'}
                                            target="_blank"
                                        >
                                            Donations
                                        </Link>{' '}
                                        are welcome.
                                    </>
                                }
                            />
                            <br />
                        </div>
                    </>
                )}

                {((isDefaultOrg && step === 2) ||
                    (!isDefaultOrg && step === 3)) && (
                    <>
                        <div>
                            <ButtonGroup
                                background
                                label={
                                    isDefaultOrg
                                        ? 'Iowa Consignment Showroom Agreement'
                                        : 'Iowa Vendor Showroom Agreement'
                                }
                                imageSrc="/img/pdf-thumbnails/iowa_vendor_showroom_agreement_preview.jpg"
                                imageWidth="150"
                                imageHeight="201"
                            >
                                <div>
                                    <Button
                                        text="Preview"
                                        type="button"
                                        onClick={onPreviewContract}
                                        isLoading={previewLoading}
                                    />
                                </div>
                            </ButtonGroup>

                            <br />

                            <SignatureInput
                                label={'Sign here'}
                                onChange={(dataUri) =>
                                    setData({
                                        ...data,
                                        signatureUri: dataUri,
                                    })
                                }
                            />
                            <Button
                                onClick={onSubmit}
                                isLoading={loading}
                                text={'Submit'}
                                type="submit"
                            />
                        </div>
                    </>
                )}

                {step < totalSteps && (
                    <div>
                        <Button
                            onClick={onNext}
                            isLoading={loading}
                            text={'Next'}
                            type="button"
                        />
                    </div>
                )}
                {step > 1 && (
                    <div>
                        <Button
                            onClick={onPrev}
                            isLoading={loading}
                            text={'Previous'}
                            type="button"
                            outline
                        />
                    </div>
                )}
            </form>
        </SectionContainer>
    )
}

export default EditProfileVendor
