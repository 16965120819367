import {
    Elements,
    useElements,
    useStripe,
    PaymentElement,
} from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import Button from '../components/common/Button'
import { useState } from 'react'
import Spinner from '../components/common/Spinner'
import ErrorMessage from '../components/common/ErrorMessage'

const apiKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
// process.env.REACT_APP_CONTEXT === 'NETLIFY_DEV' ||
// process.env.NODE_ENV === 'production'
//     ? process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
//     : process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_DEV

const stripePromise = loadStripe(apiKey)

const APPEARANCE_OPTS = {
    theme: 'stripe',
    variables: {
        // colorTextSecondary: 'rgba(255, 255, 255, 0.65)',
        colorTextSecondary: '#626878',

        colorDanger: '#eb5757',
        fontFamily: 'Mulish, system-ui, sans-serif',
        borderRadius: '8px',
    },
    rules: {
        '.Label': {
            color: '#fff',
            fontWeight: 800,
            fontSize: '12px',
            letterSpacing: '1.5px',
            lineHeight: 1,
            marginBottom: '7px',
            textTransform: 'uppercase',
        },
        '.TabLabel': {
            color: '#626878',
        },
        '.TabIcon': {
            fill: '#626878',
        },
        '.PickerItem': {
            color: '#626878',
        },
        '.Input': {
            padding: '12px',
            color: '#626878',
            marginBottom: '9px',
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '24px',
            border: '0.5px solid #7b8794',
            backgroundColor: '#fff',
        },
        '.Input::placeholder': {
            color: '#7b8794',
            opacity: 0.5,
            fontWeight: 400,
        },
    },
}

function ElementsProvider({ children, ...restOptions }) {
    return (
        <Elements
            stripe={stripePromise}
            options={{
                paymentMethodTypes: ['card'],
                // payment_method_types: ['card', 'us_bank_account'],
                // payment_method_options: {
                //     us_bank_account: {
                //         financial_connections: {
                //             permissions: ['payment_method', 'balances'],
                //         },
                //     },
                // },
                ...restOptions,

                hidePostalCode: true,
                appearance: { ...APPEARANCE_OPTS },
                fonts: [
                    {
                        cssSrc: 'https://fonts.googleapis.com/css?family=Mulish',
                    },
                ],
            }}
        >
            {children}
        </Elements>
    )
}

function CardInput(props) {
    const stripe = useStripe()
    const elements = useElements()
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)

    async function onSubmit(e) {
        e.preventDefault()
        setError('')
        setLoading(true)

        if (props.preSubmit) {
            const { error } = await props.preSubmit()
            if (error) {
                setError(error.message)
                setLoading(false)
                return
            }
        }

        if (!stripe || !elements) {
            setLoading(false)
            return null
        }

        setLoading(true)

        const { error: submitError } = await elements.submit()
        if (submitError) {
            setError(submitError.message || "Couldn't submit card details")
            setLoading(false)
            return
        }

        const { error } = await props.onSubmit(stripe, elements)

        setLoading(false)

        if (error) {
            setError(error.message)
            return
        }
    }

    if (!stripe || !elements) {
        return <Spinner />
    }

    return (
        <form onSubmit={onSubmit}>
            {error && (
                <ErrorMessage onDismiss={() => setError('')}>
                    {error}
                </ErrorMessage>
            )}
            <PaymentElement
                options={{
                    terms: { card: 'never' },
                    ...(props.hideCountry
                        ? {
                              fields: {
                                  billingDetails: {
                                      address: {
                                          country: 'never',
                                      },
                                      email: 'never',
                                      name: 'never',
                                  },
                              },
                          }
                        : {}),
                }}
            />
            <br />
            <Button
                disabled={!stripe || !elements}
                type="submit"
                text={props.submitText || 'Submit'}
                isLoading={loading}
            />
        </form>
    )
}

function StripeCardForm({
    onSubmit,
    preSubmit,
    submitText,
    hideCountry,
    ...options
}) {
    return (
        <ElementsProvider {...options}>
            <CardInput
                onSubmit={onSubmit}
                preSubmit={preSubmit}
                submitText={submitText}
                hideCountry={hideCountry}
            />
        </ElementsProvider>
    )
}

export default StripeCardForm
