// .acronym ok
import { useState } from 'react'
import { MONTHS } from '../../../common/constants'
import fetchAPI from '../../../common/fetchAPI'
import useData from '../../../common/hooks/useData'
import useError from '../../../common/hooks/useError'
import useModal from '../../../common/hooks/useModal'
import useToast from '../../../common/hooks/useToast'
import Button from '../../../components/common/Button'
import ConfirmModalContent from '../../../components/common/ConfirmModalContent'
import Spinner from '../../../components/common/Spinner'
import ErrorMessage from '../../../components/common/ErrorMessage'
import FormGroupTitle from '../../../components/common/data-form/FormGroupTitle'
import TextInput from '../../../components/common/data-form/TextInput'
import CheckboxInput from '../../../components/common/data-form/CheckboxInput'

export default function SocialPostHashtagSettings() {
    const [error, setError] = useError()

    const setToast = useToast()
    const { setModal, setModalLoading, isModalLoading } = useModal()

    const initialData = {
        socialPostProductDefaultHashtag: null,
        socialPostProductCompanyTag: null,
        socialPostProductAcronymTag: null,
        socialPostProductMonthTagJan: null,
        socialPostProductMonthTagFeb: null,
        socialPostProductMonthTagMar: null,
        socialPostProductMonthTagApr: null,
        socialPostProductMonthTagMay: null,
        socialPostProductMonthTagJun: null,
        socialPostProductMonthTagJul: null,
        socialPostProductMonthTagAug: null,
        socialPostProductMonthTagSep: null,
        socialPostProductMonthTagOct: null,
        socialPostProductMonthTagNov: null,
        socialPostProductMonthTagDec: null,
    }

    const [data, setData] = useState(initialData)

    const { defaultsError, defaultsLoading, defaultsMutate } = useData(
        `/v1/settings/${Object.keys(initialData).join(',')}?array=true`,
        'defaults',
        (data) => data,
        false,
        false,
        (newData) =>
            setData(
                newData
                    .map((item, index) => [
                        Object.keys(initialData)[index],
                        item?.settingValue || null,
                    ])
                    .reduce((a, b) => ({ ...a, [b[0]]: b[1] }), {}),
            ),
    )

    async function onSubmit(e) {
        setError('')
        if (!e.target.reportValidity()) return e.preventDefault()
        e.preventDefault()

        setModal(
            <ConfirmModalContent
                text="This will affect all future posts that use these defaults."
                onConfirm={async () => {
                    if (isModalLoading) return
                    setModalLoading(true)
                    const { error } = await fetchAPI(
                        `/v1/settings/${Object.keys(initialData).join(
                            ',',
                        )}?array=true`,
                        Object.values(data).map((value) => ({
                            settingValue:
                                value === null ? null : String(value).trim(),
                        })),
                        'PATCH',
                    )
                    if (error) {
                        setModal(null)
                        setError(error)
                        return
                    }
                    setModal(null)
                    setToast('Saved')
                    defaultsMutate()
                }}
            />,
            'Are you sure?',
        )
    }

    if (defaultsLoading) {
        return <Spinner />
    }

    if (defaultsError) {
        return (
            <ErrorMessage onDismiss={() => setError('')} section>
                {defaultsError}
            </ErrorMessage>
        )
    }

    return (
        <>
            <section>
                {error && <ErrorMessage>{error}</ErrorMessage>}

                <form onSubmit={onSubmit}>
                    <FormGroupTitle>
                        Tags configuration - General
                    </FormGroupTitle>

                    <label htmlFor="form-default-tag">Default tags</label>
                    <TextInput
                        id="form-default-tag"
                        type="text"
                        value={data.socialPostProductDefaultHashtag || ''}
                        onChange={(v) =>
                            setData({
                                ...data,
                                socialPostProductDefaultHashtag: v,
                            })
                        }
                    />

                    <CheckboxInput
                        value={data.socialPostProductCompanyTag === 'true'}
                        id="form-company-tag"
                        label="Include company name tag"
                        onChange={(include) =>
                            setData({
                                ...data,
                                socialPostProductCompanyTag: String(include),
                            })
                        }
                    />
                    <br />

                    <CheckboxInput
                        value={data.socialPostProductAcronymTag === 'true'}
                        id="form-acronym-tag"
                        label="Include acronym tag"
                        onChange={(include) =>
                            setData({
                                ...data,
                                socialPostProductAcronymTag: String(include),
                            })
                        }
                    />
                    <br />

                    <FormGroupTitle>Tags configuration - Months</FormGroupTitle>

                    {MONTHS.map((month, i) => (
                        <div key={i}>
                            <label htmlFor={`form-month-${i}`}>
                                {month.name}
                            </label>
                            <TextInput
                                id={`form-month-${i}`}
                                type="text"
                                value={
                                    data[
                                        `socialPostProductMonthTag${month.abbreviation}`
                                    ] || ''
                                }
                                onChange={(v) =>
                                    setData({
                                        ...data,
                                        [`socialPostProductMonthTag${month.abbreviation}`]:
                                            v,
                                    })
                                }
                            />
                        </div>
                    ))}

                    <br />
                    <br />

                    <Button text="Save" type="submit" />
                </form>
            </section>
        </>
    )
}
