import { useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import {
    CONNECTED_ACCOUNT_PROPERTY_TYPE_NAMES,
    CONNECTED_ACCOUNT_SERVICE_NAMES,
    MONTHS,
} from '../../../common/constants'
import fetchAPI from '../../../common/fetchAPI'
import useAuth from '../../../common/hooks/useAuth'
import useData from '../../../common/hooks/useData'
import useError from '../../../common/hooks/useError'
import useModal from '../../../common/hooks/useModal'
import useToast from '../../../common/hooks/useToast'
import ResourceList from '../../../components/admin/ResourceList'
import Button from '../../../components/common/Button'
import ConfirmModalContent from '../../../components/common/ConfirmModalContent'
import TextModalContent from '../../../components/common/TextModalContent'
import Toggle from '../../../components/common/Toggle'
import PremiumBanner from '../../admin/app-billing/PremiumBanner'
import MainButton from '../../../components/admin/MainButton'
import { getProductTagAvailabilityGroups } from '../../../common/helpers'
import MessageSection from '../../../components/common/MessageSection'
import Spinner from '../../../components/common/Spinner'
import ErrorMessage from '../../../components/common/ErrorMessage'
import Tag from '../../../components/common/Tag'
import SelectInput from '../../../components/common/data-form/SelectInput'
import TextInput from '../../../components/common/data-form/TextInput'
import BlockStack from '../../../components/common/BlockStack'
import InlineStack from '../../../components/common/InlineStack'
import CheckboxInput from '../../../components/common/data-form/CheckboxInput'
import SectionContainer from '../../../components/common/SectionContainer'
import Break from '../../../components/common/Break'

function planToData(plan) {
    return {
        propertyDbIds: plan.propertyIds,
        frequency: plan.settings.frequency,
        shop: plan.settings.shop || 'all',
        excludeTags: plan.settings.excludeTags || '',
        includeTags: plan.settings.includeTags || '',
        excludeTagsByMonth: plan.settings.excludeTagsByMonth || {},
        includeTagsByMonth: plan.settings.includeTagsByMonth || {},
        enableHashtags: plan.settings.enableHashtags,
    }
}

export default function EditSocialPostPlan() {
    const { socialPostPlanId } = useParams()
    const [error, setError, errorFields] = useError()
    const [actionLoading, setActionLoading] = useState(false)
    const [tagsLoading, setTagsLoading] = useState(false)
    const navigate = useNavigate()
    const [previewData, setPreviewData] = useState(null)
    const [previewLoading, setPreviewLoading] = useState(false)

    const setToast = useToast()
    const auth = useAuth()
    const { setModal, setModalLoading, isModalLoading } = useModal()

    const { accounts, accountsError, accountsLoading } = useData(
        `/v1/connected-accounts?userId=${auth.user.id}`,
        'accounts',
    )

    const { shops, shopsError, shopsLoading } = useData(
        `/v1/wholesale/shops?userId=${auth.user.id}&fields=name,fullName`,
        'shops',
    )

    const { plan, planError, planLoading, planMutate } = useData(
        `/v1/social-posts/plans/${socialPostPlanId}`,
        'plan',
        (data) => data,
        false,
        false,
        (data) => data && updateData(planToData(data)),
    )

    const initialData = plan
        ? planToData(plan)
        : {
              propertyDbIds: [],
              frequency: '0',
              shop: 'all',
              excludeTags: '',
              includeTags: '',
              enableHashtags: false,
              includeTagsByMonth: MONTHS.map((m) =>
                  m.abbreviation.toLowerCase(),
              ).reduce((a, b) => ({ ...a, [b]: '' }), {}),
              excludeTagsByMonth: MONTHS.map((m) =>
                  m.abbreviation.toLowerCase(),
              ).reduce((a, b) => ({ ...a, [b]: '' }), {}),
          }
    const [data, setData] = useState(initialData)

    function updateData(newData) {
        setData(newData)
        updatePreview(newData)
    }

    async function updatePreview(newData) {
        if (!plan?.postType) return
        setPreviewLoading(true)

        const body = {
            settings: {
                shop: newData.shop,
                excludeTags: newData.excludeTags,
                includeTags: newData.includeTags,
                enableHashtags: newData.enableHashtags,
                includeTagsByMonth: newData.includeTagsByMonth,
                excludeTagsByMonth: newData.excludeTagsByMonth,
            },
        }

        const { responseData, error } = await fetchAPI(
            `/v1/social-posts/plans/${socialPostPlanId}/preview`,
            body,
            'POST',
        )
        setPreviewLoading(false)
        if (error) {
            setToast(error, 'alert')
            setPreviewData(null)
            return
        }

        setPreviewData(responseData)
    }

    function onTagAdd(tag, group) {
        let tags
        let dataField
        switch (group) {
            case 'include':
                tags = data.includeTags
                dataField = 'includeTags'
                break
            case 'exclude':
                tags = data.excludeTags
                dataField = 'excludeTags'
                break
            default:
                return
        }
        tags = tags.trim().toLowerCase().split(',')
        tags.push(tag.toLowerCase())
        tags = tags.filter((t) => t).join(',')
        updateData({ ...data, [dataField]: tags })
        setModal(null)
    }

    function onTagRemove(tag, group) {
        let tags
        let dataField
        switch (group) {
            case 'include':
                tags = data.includeTags
                dataField = 'includeTags'
                break
            case 'exclude':
                tags = data.excludeTags
                dataField = 'excludeTags'
                break
            default:
                return
        }
        tags = tags.trim().toLowerCase().split(',')
        tags = tags.filter((t) => t !== tag.toLowerCase()).join(',')
        updateData({ ...data, [dataField]: tags })
        setModal(null)
    }

    async function showTagsModal(group) {
        if (!group) return

        let usedTags
        switch (group) {
            case 'include':
                usedTags = data.includeTags
                break
            case 'exclude':
                usedTags = data.excludeTags
                break
            default:
                return
        }
        usedTags = usedTags.trim().split(',')

        setTagsLoading(true)
        const { responseData, error } = await fetchAPI(
            `/v1/wholesale/product-tags?shopName=${data.shop}`,
        )
        setTagsLoading(false)

        if (error) {
            setToast(error, 'alert')
            return
        }

        const { unavailableTags, availableTags, nonExistingTags } =
            getProductTagAvailabilityGroups(usedTags, responseData.tags)

        setModal(
            <TextModalContent
                content={
                    <div>
                        <h3 className="m-0">Available</h3>
                        {availableTags
                            ? availableTags.map((tag) => (
                                  <span key={tag}>
                                      <Tag
                                          outline
                                          onClick={() => onTagAdd(tag, group)}
                                      >
                                          {tag}
                                      </Tag>
                                      &nbsp;
                                  </span>
                              ))
                            : 'None'}

                        <br />
                        <br />
                        <h3 className="m-0">Used</h3>
                        {unavailableTags.length
                            ? unavailableTags.map((tag) => (
                                  <span key={tag}>
                                      <Tag
                                          onClick={() =>
                                              onTagRemove(tag, group)
                                          }
                                          color="primary"
                                      >
                                          {tag}
                                      </Tag>
                                      &nbsp;
                                  </span>
                              ))
                            : 'None'}

                        <br />
                        <br />
                        <h3 className="m-0">Not found in this store</h3>
                        {nonExistingTags.length
                            ? nonExistingTags.map((tag) => (
                                  <span key={tag}>
                                      <Tag color={'primary'}>{tag}</Tag>
                                      &nbsp;
                                  </span>
                              ))
                            : 'None'}
                    </div>
                }
            />,
            'Tags',
        )
    }

    async function onSubmit(e) {
        setError('')
        if (!e.target.reportValidity()) return e.preventDefault()
        e.preventDefault()

        const body = {
            propertyIds: data.propertyDbIds,
            settings: {
                frequency: Number(data.frequency),
                shop: data.shop,
                excludeTags: data.excludeTags,
                includeTags: data.includeTags,
                enableHashtags: data.enableHashtags,
                excludeTagsByMonth: data.excludeTagsByMonth,
                includeTagsByMonth: data.includeTagsByMonth,
            },
        }

        if (!data.propertyDbIds.length || !Number(data.frequency)) {
            setActionLoading(true)
            const { error, meta } = await fetchAPI(
                `/v1/social-posts/plans/${socialPostPlanId}`,
                body,
                'PATCH',
            )
            setActionLoading(false)
            if (error) {
                setError(error, meta?.fields)
                return
            }
            planMutate()
            if (Number(data.frequency) === 0) {
                setToast(
                    'Saved, social posting has been disabled for this plan.',
                    'warning',
                )
            } else {
                setToast('Posting settings are saved')
            }
            navigate('/content#shop_post_plans__')
        } else {
            setModal(
                <ConfirmModalContent
                    cancelText="No"
                    confirmText="Reset"
                    onCancel={async () => {
                        setActionLoading(true)
                        const { error, meta } = await fetchAPI(
                            `/v1/social-posts/plans/${socialPostPlanId}`,
                            body,
                            'PATCH',
                        )
                        setActionLoading(false)
                        if (error) {
                            setModal(null)
                            setError(error, meta?.fields)
                            return
                        }
                        planMutate()
                        if (Number(data.frequency) === 0) {
                            setToast(
                                'Saved, social posting has been disabled for this plan.',
                                'warning',
                            )
                        } else {
                            setToast('Posting settings are saved')
                        }
                        navigate('/content#shop_post_plans__')
                    }}
                    onConfirm={async () => {
                        if (isModalLoading) return
                        setModalLoading(true)

                        const { error, meta } = await fetchAPI(
                            `/v1/social-posts/plans/${socialPostPlanId}?resetPostDate=true`,
                            body,
                            'PATCH',
                        )

                        if (error) {
                            setModal(null)
                            setError(error, meta?.fields)
                            return
                        }
                        setModal(null)
                        planMutate()
                        if (Number(data.frequency) === 0) {
                            setToast(
                                'Saved, social posting has been disabled for this plan.',
                                'warning',
                            )
                        } else {
                            setToast('Posting settings are saved')
                        }
                        navigate('/content#shop_post_plans__')
                    }}
                />,
                'Saved. Reset the latest posted date?',
            )
        }
    }

    if (accountsLoading || shopsLoading || planLoading) {
        return <Spinner />
    }
    if (accountsError || shopsError || planError) {
        return (
            <SectionContainer>
                <ErrorMessage>
                    {accountsError || shopsError || planError}
                </ErrorMessage>
            </SectionContainer>
        )
    }

    if (!accounts.length) {
        return (
            <SectionContainer>
                <ErrorMessage>
                    No accounts found, please connect one first.
                </ErrorMessage>
            </SectionContainer>
        )
    }

    if (!shops.length) {
        return (
            <SectionContainer>
                <ErrorMessage>
                    No shops found, please connect one in&nbsp;
                    <Link to="/shop#shop_settings__">Shop Settings</Link>.
                </ErrorMessage>
            </SectionContainer>
        )
    }

    const properties = accounts
        .map((account) =>
            account.properties.map((property) => ({
                ...property,
                account,
            })),
        )
        .flat()

    return (
        <>
            <section>
                {error && (
                    <SectionContainer>
                        <ErrorMessage onDismiss={() => setError('')}>
                            {error}
                        </ErrorMessage>
                    </SectionContainer>
                )}

                <form onSubmit={onSubmit}>
                    <SectionContainer>
                        <label htmlFor="form-properties">
                            Properties to post to
                        </label>
                    </SectionContainer>
                    <ResourceList
                        getSelectedItemText={(item) => item.name}
                        items={properties}
                        isSelectable
                        selectedIdField={'_id'}
                        preselectedIds={data.propertyDbIds}
                        onSelectedItemsChange={(items) =>
                            updateData({
                                ...data,
                                propertyDbIds: items,
                            })
                        }
                        fields={[
                            {
                                column: 1,
                                getValue: (item) => (
                                    <div>
                                        {`${item.name} | ${
                                            CONNECTED_ACCOUNT_PROPERTY_TYPE_NAMES[
                                                item.propertyType
                                            ]
                                        }`}

                                        <div className="text-subdued">
                                            {`${item.account.accountName} | ${
                                                CONNECTED_ACCOUNT_SERVICE_NAMES[
                                                    item.account.service
                                                ]
                                            }`}
                                        </div>
                                    </div>
                                ),
                            },
                        ]}
                    />
                    <Break />

                    <SectionContainer>
                        <label htmlFor="form-shop">
                            {`Shops to post ${
                                plan.postType === 'ownProducts'
                                    ? 'products'
                                    : 'blog posts'
                            } from`}
                        </label>
                        <SelectInput
                            id="form-shop"
                            value={data.shop}
                            onChange={(v) => updateData({ ...data, shop: v })}
                            options={[
                                { value: 'all', label: 'All my shops' },
                                ...shops.map((shop) => ({
                                    value: shop.name,
                                    label: shop.fullName,
                                })),
                            ]}
                        />
                        <label htmlFor="form-tags-include">
                            Post only items with these tags
                        </label>
                        <TextInput
                            type="textarea"
                            id="form-tags-include"
                            labelButton={
                                plan.postType === 'ownProducts' ? (
                                    <Button
                                        text="Show tags"
                                        tiny
                                        outline
                                        alignRight
                                        onClick={() => showTagsModal('include')}
                                        isLoading={tagsLoading}
                                        disabled={data.shop === 'all'}
                                    />
                                ) : null
                            }
                            value={data.includeTags || ''}
                            onChange={(v) =>
                                setData({ ...data, includeTags: v })
                            }
                            autoComplete="off"
                            errors={errorFields.filter(
                                (f) => f === 'settings_includeTags',
                            )}
                        />
                        <small className="input-info">
                            Comma separated, leave empty to post from all items.
                            This field is not case sensitive.{' '}
                        </small>
                        <BlockStack gap={'tiny'}>
                            <InlineStack spaceBetween itemsEnd>
                                <label
                                    htmlFor="form-tags-exclude"
                                    className="m-0"
                                >
                                    Never post items with these tags
                                </label>
                                {plan.postType === 'ownProducts' ? (
                                    <Button
                                        text="Show tags"
                                        tiny
                                        outline
                                        alignRight
                                        onClick={() => showTagsModal('exclude')}
                                        isLoading={tagsLoading}
                                        disabled={data.shop === 'all'}
                                    />
                                ) : null}
                            </InlineStack>
                            <TextInput
                                type="textarea"
                                id="form-tags-exclude"
                                value={data.excludeTags || ''}
                                onChange={(v) =>
                                    setData({ ...data, excludeTags: v })
                                }
                                autoComplete="off"
                                errors={errorFields.filter(
                                    (f) => f === 'settings_excludeTags',
                                )}
                            />
                            <small className="input-info">
                                {`Comma separated. This field is not case sensitive. Any items with the 'aco_app_exclude' tag will also be excluded from posting.`}
                            </small>
                        </BlockStack>
                        <Toggle small title="Tags by month">
                            {auth.isPremiumUser ? (
                                <>
                                    <small className="text-subdued">
                                        Comma separated. Not case sensitive.
                                    </small>
                                    <Break />
                                    {MONTHS.map((month) => (
                                        <div key={month.abbreviation}>
                                            <label
                                                htmlFor={`form-tags-include-${month.abbreviation}`}
                                            >
                                                {`${month.name}: Post only items with these tags`}
                                            </label>
                                            <TextInput
                                                type="textarea"
                                                id={`form-tags-include-${month.abbreviation}`}
                                                value={
                                                    data.includeTagsByMonth[
                                                        month.abbreviation.toLowerCase()
                                                    ]
                                                }
                                                onChange={(v) =>
                                                    updateData({
                                                        ...data,
                                                        includeTagsByMonth: {
                                                            ...data.includeTagsByMonth,
                                                            [month.abbreviation.toLowerCase()]:
                                                                v,
                                                        },
                                                    })
                                                }
                                                autoComplete="off"
                                                rows={1}
                                                errors={errorFields.filter(
                                                    (f) =>
                                                        f ===
                                                        `settings_includeTagsByMonth_${month.abbreviation.toLowerCase()}`,
                                                )}
                                            />
                                            <label
                                                htmlFor={`form-tags-exclude-${month.abbreviation}`}
                                            >
                                                {`${month.name}: Never post items with these tags`}
                                            </label>
                                            <TextInput
                                                type="textarea"
                                                id={`form-tags-exclude-${month.abbreviation}`}
                                                value={
                                                    data.excludeTagsByMonth[
                                                        month.abbreviation.toLowerCase()
                                                    ]
                                                }
                                                onChange={(v) =>
                                                    updateData({
                                                        ...data,
                                                        excludeTagsByMonth: {
                                                            ...data.excludeTagsByMonth,
                                                            [month.abbreviation.toLowerCase()]:
                                                                v,
                                                        },
                                                    })
                                                }
                                                autoComplete="off"
                                                rows={1}
                                                errors={errorFields.filter(
                                                    (f) =>
                                                        f ===
                                                        `settings_excludeTagsByMonth_${month.abbreviation.toLowerCase()}`,
                                                )}
                                            />
                                        </div>
                                    ))}
                                </>
                            ) : (
                                <PremiumBanner />
                            )}
                        </Toggle>

                        <Break />

                        <CheckboxInput
                            id="form-use-hashtags"
                            label="Enable hashtags"
                            value={data.enableHashtags}
                            onChange={(enableHashtags) =>
                                updateData({
                                    ...data,
                                    enableHashtags,
                                })
                            }
                        />
                        <Break />

                        <label htmlFor="form-frequency" className="required">
                            How often to post to each property (times/day)
                        </label>
                        <TextInput
                            id="form-frequency"
                            value={data.frequency}
                            onChange={(v) =>
                                updateData({
                                    ...data,
                                    frequency: v,
                                })
                            }
                            required
                            type="number"
                            min="0"
                            max="10"
                        />
                        <MessageSection type="info">
                            Preview&nbsp;
                            <Tag outline>Beta</Tag>
                            <Break />
                            {previewLoading ? <Spinner /> : null}
                            {previewData && !previewLoading ? (
                                <>
                                    <div>
                                        Total items found: {previewData.count}
                                        <br />
                                        Total items after excluding recent
                                        posts: {previewData.filteredCount}
                                    </div>
                                    <hr />
                                    <pre>{previewData.message}</pre>
                                    <img
                                        src={previewData.imageUrl}
                                        alt="preview"
                                    />
                                </>
                            ) : null}
                        </MessageSection>
                        <Break />

                        <Button
                            text="Save"
                            isLoading={actionLoading}
                            type="submit"
                        />
                    </SectionContainer>

                    <MainButton
                        disabled={actionLoading}
                        functionality="SAVE"
                        loading={actionLoading}
                    />
                </form>
            </section>
        </>
    )
}
