import { useStore } from '../Context'
import fetchAPI from '../fetchAPI'
import { getUserDisplayName } from '../helpers'

export default function useAuth() {
    const [{ user }, dispatch] = useStore()

    const isAdmin = user?.role === 'admin'
    const isStaff = user?.role === 'staff'
    const isClient = user?.role === 'user'
    const isAssistant = user?.teamRoles?.includes('assistants')
    const isAccountant = user?.teamRoles?.includes('accountants')
    const isSocialEditor = user?.teamRoles?.includes('social editors')
    const isClientManager = user?.teamRoles?.includes('client managers')
    const isSales = user?.teamRoles?.includes('sales')
    const isLateFeeAssistant = user?.teamRoles?.includes('late fees')
    const isLateFeeAssistantOnly =
        isLateFeeAssistant && user?.teamRoles?.length === 1
    const isCollections = user?.teamRoles?.includes('collections')
    const isCollectionsOnly = isCollections && user?.teamRoles?.length === 1
    const isPremiumUser = isClient && user?.isPremiumUser
    const seesAds = !isPremiumUser && !isAdmin

    const companies = user?.orgsData?.filter(
        (o) => o.organizationType === 'company',
    )
    const onlyCompany = companies?.length === 1 ? companies[0] : null

    if (user) {
        user.displayName = getUserDisplayName(user)
    }

    async function refetchAuth() {
        const { responseData, error } = await fetchAPI(
            `/v1/users/me`,
            {},
            'GET',
        )
        if (error) {
            return
        }
        dispatch({ type: 'UPDATE_USER', payload: responseData })
    }

    return {
        user,
        isAdmin,
        isStaff,
        isClient,
        isAssistant,
        isAccountant,
        isSocialEditor,
        isClientManager,
        isSales,
        isLateFeeAssistant,
        isLateFeeAssistantOnly,
        isCollections,
        isCollectionsOnly,
        isPremiumUser,
        refetchAuth,
        onlyCompany,
        seesAds,
    }
}
