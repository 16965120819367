import useAuth from '../../common/hooks/useAuth'
import MessageSection from '../../components/common/MessageSection'
import ClientCard from '../admin/clients/ClientCard'
import InvoicesList from '../admin/invoices/InvoicesList'
import SubsList from '../admin/subscription/SubsList'

export default function BillingData() {
    const auth = useAuth()

    return (
        <>
            {auth.isAdmin && (
                <MessageSection type="info">
                    Only admins and collections see this. Legacy feature.
                </MessageSection>
            )}

            {auth.isAdmin && <ClientCard customer={null} activeDate={null} />}

            <br />
            <SubsList renderMainButton={false} />
            <br />
            <InvoicesList renderMainButton={false} inSubscription={false} />
        </>
    )
}
