import Tag from '../../../components/common/Tag'
import PremiumBanner from '../../admin/app-billing/PremiumBanner'

export const COMPONENT_DATA = {
    Tag: {
        versions: [
            {
                title: 'Outline',
                component: <Tag outline>Text</Tag>,
            },
            {
                title: 'Primary',
                component: <Tag color="primary">Text</Tag>,
            },
            {
                title: 'Success',
                component: <Tag color="success">Text</Tag>,
            },
            {
                title: 'Alert',
                component: <Tag color="alert">Text</Tag>,
            },
            {
                title: 'Warning',
                component: <Tag color="warning">Text</Tag>,
            },
            {
                title: 'Gold',
                component: <Tag color="gold">Text</Tag>,
            },
            {
                title: 'Square + Outline',
                component: (
                    <Tag square outline>
                        Text
                    </Tag>
                ),
            },
        ],
    },
    PremiumBanner: {
        versions: [
            {
                // title: 'Outline',
                component: <PremiumBanner requestFeature={'test'} />,
            },
        ],
    },
}
