import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { COUNTRIES, US_STATES } from '../../../common/constants'
import { useStore } from '../../../common/Context'
import fetchAPI from '../../../common/fetchAPI'
import FormSteps from '../../../common/FormSteps'
import useError from '../../../common/hooks/useError'
import Button from '../../../components/common/Button'
import DataFormNew from '../../../components/common/DataFormNew'
import authStorage from '../../../common/authStorage'
import Spinner from '../../../components/common/Spinner'
import ErrorMessage from '../../../components/common/ErrorMessage'
import SectionContainer from '../../../components/common/SectionContainer'

const inputs = [
    {
        key: 'firstName',
        label: 'First name',
        required: true,
    },
    {
        key: 'lastName',
        label: 'Last name',
        required: true,
    },
    {
        key: 'companyName',
        label: 'Company',
    },
    {
        key: 'phoneNumber',
        label: 'Phone',
        required: true,
        type: 'tel',
    },
    {
        key: 'mainAddressAddress',
        label: 'Address',
        required: true,
    },
    {
        key: 'mainAddressCity',
        label: 'City',
        required: true,
    },
    {
        key: 'mainAddressZip',
        label: 'Zip',
        required: true,
    },
    {
        key: 'mainAddressState',
        label: 'State',
        type: 'select',
        options: US_STATES.map(({ text }) => ({ value: text, label: text })),
        required: true,
        shouldBeText: (data) => data.mainAddressCountry !== 'United States',
        defaultValue: 'Alabama',
    },
    {
        key: 'mainAddressCountry',
        label: 'Country',
        type: 'select',
        options: COUNTRIES.map(({ text }) => ({
            value: text,
            label: text,
        })),
        required: true,
        defaultValue: 'United States',
    },
]

const defaultDevData = {
    firstName: 'John',
    lastName: 'Doe',
    companyName: 'Null Co. ' + Math.floor(Math.random() * 1000000),
    phoneNumber: '12341234',
    mainAddressAddress: '2123 Apple Park Way',
    mainAddressCity: 'NY',
    mainAddressState: 'New York',
    mainAddressZip: '11111',
    mainAddressCountry: 'United States',
}

function RegisterDetails() {
    const navigate = useNavigate()

    const devInputs = inputs.map((item) => ({
        ...item,
        defaultValue: defaultDevData[item.key],
    }))

    const [loading, setLoading] = useState(false)
    const [error, setError] = useError()
    const [state, dispatch] = useStore()

    async function onSuccess(responseData) {
        dispatch({
            type: 'UPDATE_USER',
            payload: responseData,
        })

        setLoading(true)
        // TODO might have race condition between action and navigate
        const { responseData: verifyData, error } = await fetchAPI(
            '/v1/auth/send-verification-email',
            {},
            'POST',
        )
        setLoading(false)

        if (error) {
            setError(error)
            return
        }

        if (process.env.NODE_ENV === 'development') {
            window.prompt(
                'DEV: Copy, click OK and paste into browser URL bar',
                verifyData.tokenDdd,
            )
        }

        navigate('/register/verify-email')
    }

    function onLogout(e) {
        e.preventDefault()
        authStorage.removeItem('access_token')
        authStorage.removeItem('refresh_token')
        dispatch({ type: 'LOGOUT' })
    }

    return (
        <SectionContainer>
            {error && (
                <ErrorMessage onDismiss={() => setError('')}>
                    {error}
                </ErrorMessage>
            )}

            <header>
                <img
                    alt="logo"
                    srcSet="/img/agency-couturemulticolor-1.png 1x, /img/agency-couturemulticolor-1-sm.png 2x"
                    width="80"
                    height="98"
                />
                <h1>Register: Contact info</h1>
                <FormSteps step={2} totalSteps={3} />
                <p className="email">{state.user?.email || ''}</p>
            </header>

            {loading && <Spinner />}

            <DataFormNew
                url={`/v1/users/me/registration-details`}
                method="POST"
                submitText="Continue"
                submitToast=""
                inputs={
                    process.env.NODE_ENV === 'development' ? devInputs : inputs
                }
                mutationRegexes={[/\/v1\/users/]}
                onSuccess={onSuccess}
            />

            <footer>
                <div className="links">
                    <Button text="Logout" onClick={onLogout} link />
                </div>
            </footer>
        </SectionContainer>
    )
}

export default RegisterDetails
