// .acronym ok
// .company ready
import { Link, useNavigate, useParams } from 'react-router-dom'
import fetchAPI from '../../../common/fetchAPI'
import useError from '../../../common/hooks/useError'
import { useStore } from '../../../common/Context'
import ActionsDropdown from '../../../components/common/ActionsDropdown'
import DBOrdersList from '../../shop/orders/AdminDBOrdersList'
import ProductsBillingList from '../wholesale-products/ProductsBillingList'
import { ICONS } from '../../../common/constants'
import FinancialBillingList from '../wholesale-products/FinancialBillingList'
import useData from '../../../common/hooks/useData'
import Toggle from '../../../components/common/Toggle'
import ProjectedPayoutsList from '../wholesale-payouts/ProjectedPayoutsList'
import { useState } from 'react'
import ResourceList from '../../../components/admin/ResourceList'
import { formatDate, formatMoney } from '../../../common/helpers'
import useModal from '../../../common/hooks/useModal'
import ConfirmModalContent from '../../../components/common/ConfirmModalContent'
import useToast from '../../../common/hooks/useToast'
import useMatchMutate from '../../../common/hooks/useMatchMutate'
import authStorage from '../../../common/authStorage'
import Spinner from '../../../components/common/Spinner'
import ErrorMessage from '../../../components/common/ErrorMessage'
import Button from '../../../components/common/Button'
import SelectInput from '../../../components/common/data-form/SelectInput'
import SectionContainer from '../../../components/common/SectionContainer'
import InlineStack from '../../../components/common/InlineStack'
import BlockStack from '../../../components/common/BlockStack'

function getMonthsForPayouts() {
    const now = new Date()
    const nextMonthDate = new Date(now.getFullYear(), now.getMonth() + 1, 1)
    // Increase by a month
    nextMonthDate.setMonth(nextMonthDate.getMonth() + 1)

    const firstMonth = [1, 2022]
    const lastMonth = [
        nextMonthDate.getMonth() + 2,
        nextMonthDate.getFullYear(),
    ]

    const result = []
    let month = firstMonth[0]
    let year = firstMonth[1]
    while (
        year < lastMonth[1] ||
        (year === lastMonth[1] && month <= lastMonth[0])
    ) {
        result.push([month, year])
        month++
        if (month > 12) {
            month = 1
            year++
        }
    }

    return result
}

export default function AdminVendorPage() {
    const [_state, dispatch] = useStore()

    const { userId } = useParams()

    const { orgs, orgsError, orgsLoading } = useData(
        `/v1/organizations?memberUserId=${userId}&fields=name,acronym&populate=vendorAccount`,
        'orgs',
        (data) => data?.results || [],
    )

    const vendorOrgs = orgs.filter((org) => org.vendorAccount)
    const vendorOrg = vendorOrgs[0]

    const [error, setError] = useError()
    const navigate = useNavigate()
    const setToast = useToast()
    const { setModal, setModalLoading, isModalLoading } = useModal()
    const matchMutate = useMatchMutate()

    const [shop, setShop] = useState('')

    const { balance, balanceLoading, balanceError } = useData(
        `/v1/payment/balance?userId=${userId}`,
        'balance',
    )

    const { shops, shopsLoading, shopsError } = useData(
        `/v1/wholesale/shops?fields=name`,
        'shops',
        (data) => data?.map((item) => item.name) || [],
    )

    const { billing, billingLoading, billingError } = useData(
        userId
            ? `/v1/wholesale/billing?vendor=${vendorOrg?.acronym}${
                  shop ? `&shop=${shop}` : ''
              }`
            : null,
        'billing',
    )

    const onLoginAsClient = async () => {
        const { responseData, error } = await fetchAPI(
            `/v1/auth/login-as-user`,
            {
                userId: userId,
            },
            'POST',
        )

        if (error) return setError(error)

        const { user: responseUser, tokens } = responseData
        authStorage.setItem('access_token', tokens.access.token)
        authStorage.setItem('refresh_token', tokens.refresh.token)

        dispatch({ type: 'LOGIN', payload: responseUser })
        navigate('/')
        window.location.reload()
    }

    async function onDeleteInvoice(id) {
        setModal(
            <ConfirmModalContent
                onConfirm={async () => {
                    if (isModalLoading) return
                    setModalLoading(true)

                    const { error } = await fetchAPI(
                        `/v1/payment/invoices/${id}`,
                        {},
                        'DELETE',
                    )
                    if (error) {
                        setModal(null)
                        setToast(error, 'alert')
                        return
                    }
                    setModal(null)
                    setToast('Deleted')
                    matchMutate(/\/v1\/payment\/invoices/)
                    matchMutate(/\/v1\/payment\/balance/)
                }}
            />,
            'Are you sure you want to delete this invoice?',
        )
    }

    async function onDeleteExpense(id) {
        setModal(
            <ConfirmModalContent
                onConfirm={async () => {
                    if (isModalLoading) return
                    setModalLoading(true)

                    const { error } = await fetchAPI(
                        `/v1/expenses/${id}`,
                        {},
                        'DELETE',
                    )
                    if (error) {
                        setModal(null)
                        setToast(error, 'alert')
                        return
                    }
                    setModal(null)
                    setToast('Deleted')
                    matchMutate(/\/v1\/expenses/)
                    matchMutate(/\/v1\/payment\/balance/)
                }}
            />,
            'Are you sure you want to delete this expense?',
        )
    }

    if (orgsLoading || billingLoading || shopsLoading || balanceLoading) {
        return <Spinner marginTop />
    }
    if (orgsError || billingError || shopsError || balanceError) {
        return (
            <SectionContainer>
                <ErrorMessage>
                    {orgsError || billingError || shopsError || balanceError}
                </ErrorMessage>
            </SectionContainer>
        )
    }

    if (vendorOrgs.length > 1) {
        return (
            <SectionContainer>
                <ErrorMessage>
                    Found multiple accounts.{' '}
                    <Link to={'#'}>See them here.</Link>
                    {/* TODO */}
                </ErrorMessage>
            </SectionContainer>
        )
    }

    if (!vendorOrg) {
        return (
            <SectionContainer>
                <ErrorMessage>
                    No vendor account found for this user.
                </ErrorMessage>{' '}
            </SectionContainer>
        )
    }

    const { totalBalance, totalPaid, totalOwed, totalPaidOut } = balance

    return (
        <>
            {error && (
                <SectionContainer>
                    <ErrorMessage onDismiss={() => setError('')}>
                        {error}
                    </ErrorMessage>
                </SectionContainer>
            )}
            <SectionContainer>
                <header className="info-header client-page-info-header">
                    <InlineStack gap={'sm'} spaceBetween>
                        <div>
                            <h2>{vendorOrg.acronym}</h2>
                            <Button
                                text={'Profile'}
                                link
                                href={`/users/${userId}`}
                            />
                        </div>
                        <ActionsDropdown
                            actions={[
                                {
                                    title: 'Settings',
                                    onClick: () =>
                                        // navigate(
                                        //     // `/accounts/vendors/${vendorOrg?.vendorAccount?.id}/settings`,
                                        // ),
                                        navigate(
                                            `/users/${userId}/vendor-settings`,
                                        ),
                                    icon: ICONS.SETTINGS_GRAY,
                                },
                                {
                                    title: 'Custom products',
                                    onClick: () =>
                                        navigate(
                                            `/accounts/vendors/${vendorOrg?.vendorAccount?.id}/custom-products`,
                                        ),
                                    icon: ICONS.LINK_GRAY,
                                },
                                {
                                    title: 'Login as this user',
                                    onClick: () => onLoginAsClient(),
                                    icon: ICONS.EDIT_GRAY,
                                },
                            ]}
                        />
                    </InlineStack>
                </header>
            </SectionContainer>

            <SectionContainer>
                <Toggle title="Orders">
                    <DBOrdersList vendor={vendorOrg.acronym} />
                </Toggle>
            </SectionContainer>

            <SectionContainer>
                <Toggle title="Manual payments">
                    <ResourceList
                        title="Bill / Owed from vendor"
                        subtitle={'Concerns all vendor accounts for this user.'}
                        smallTitle
                        apiUrl={'/payment/invoices'}
                        initialQuery={{
                            user: userId,
                            status: 'open',
                        }}
                        getItemsFromResponse={(data) => data?.results}
                        newItemPath={`/invoices/${userId}/new?subId=null&status=open&isAmountOnly=true`}
                        // itemClickPath={'/expenses/:item.id'}
                        paginated={true}
                        loadMoreButton
                        paginationLimit={3}
                        fields={[
                            {
                                column: 1,
                                getValue: (item) => (
                                    <BlockStack gap={'tiny'}>
                                        <h3>{formatMoney(item.amount || 0)}</h3>
                                        <div>{`Owed`}</div>
                                        <small className="text-subdued">{`${formatDate(item.creationDate)}`}</small>
                                    </BlockStack>
                                ),
                            },
                        ]}
                        actions={[
                            {
                                title: 'Delete',
                                onClick: (item) => {
                                    onDeleteInvoice(item.id)
                                },
                                type: 'alert',
                                icon: ICONS.TRASH_RED,
                            },
                        ]}
                    />
                    <br />
                    <br />
                    <ResourceList
                        title="Payment / What vendor paid"
                        subtitle={'Concerns all vendor accounts for this user.'}
                        smallTitle
                        apiUrl={'/payment/invoices'}
                        initialQuery={{
                            user: userId,
                            status: 'paid',
                        }}
                        getItemsFromResponse={(data) => data?.results}
                        newItemPath={`/invoices/${userId}/new?subId=null&status=paid&isAmountOnly=true`}
                        paginated={true}
                        loadMoreButton
                        paginationLimit={3}
                        fields={[
                            {
                                column: 1,
                                getValue: (item) => (
                                    <BlockStack gap={'tiny'}>
                                        <h3>{formatMoney(item.amount || 0)}</h3>
                                        <div>{`Paid`}</div>
                                        <small className="text-subdued">{`${formatDate(item.creationDate)}`}</small>
                                    </BlockStack>
                                ),
                            },
                        ]}
                        actions={[
                            {
                                title: 'Delete',
                                onClick: (item) => {
                                    onDeleteInvoice(item.id)
                                },
                                type: 'alert',
                                icon: ICONS.TRASH_RED,
                            },
                        ]}
                    />

                    <br />
                    <br />
                    <ResourceList
                        title="Payout / What we paid to vendor"
                        smallTitle
                        apiUrl={'/expenses'}
                        initialQuery={{
                            userId: userId,
                            reason: 'vendorManualPayout',
                        }}
                        getItemsFromResponse={(data) => data?.results}
                        newItemPath={`/expenses/new?reason=vendorManualPayout&userId=${userId}`}
                        itemClickPath={'/expenses/:item.id'}
                        paginated={true}
                        loadMoreButton
                        paginationLimit={5}
                        fields={[
                            {
                                column: 1,
                                getValue: (item) => (
                                    <BlockStack gap={'tiny'}>
                                        <h3>{formatMoney(item.amount || 0)}</h3>
                                        <div>{`Paid out`}</div>
                                        <small className="text-subdued">{`${formatDate(item.creationDate)}`}</small>
                                    </BlockStack>
                                ),
                            },
                        ]}
                        actions={[
                            {
                                title: 'Delete',
                                onClick: (item) => {
                                    onDeleteExpense(item.id)
                                },
                                type: 'alert',
                                icon: ICONS.TRASH_RED,
                            },
                        ]}
                    />
                    <br />
                    <div>
                        <small className="text-subdued">
                            Concerns all vendor accounts for this user.
                        </small>
                        <br />
                        <h3>Owed: {formatMoney(totalOwed || 0)}</h3>
                        <h3>Paid: {formatMoney(totalPaid || 0)}</h3>
                        <h3>Paid out: {formatMoney(totalPaidOut || 0)}</h3>

                        <h3>Balance: {formatMoney(totalBalance || 0)}</h3>
                    </div>
                </Toggle>
            </SectionContainer>

            <SectionContainer>
                <SelectInput
                    value={shop}
                    onChange={setShop}
                    options={[
                        { value: '', label: 'All Shops' },
                        ...shops.map((shop) => ({
                            value: shop,
                            label: shop,
                        })),
                    ]}
                />
            </SectionContainer>

            <SectionContainer>
                <Toggle title="Payouts">
                    <small className="notes">
                        Click on an item to see a detailed breakdown
                        <br />
                        <Link to={`/temp/payout-validation`}>
                            View raw Shopify data
                        </Link>
                    </small>

                    {getMonthsForPayouts().map(([month, year]) => (
                        <Toggle
                            key={`${month}-${year}`}
                            title={`${month} / ${year}`}
                        >
                            <ProjectedPayoutsList
                                shop={shop}
                                month={month}
                                year={year}
                                vendor={vendorOrg.acronym}
                            />
                        </Toggle>
                    ))}
                </Toggle>
            </SectionContainer>

            <SectionContainer>
                <Toggle open title="Financial summary">
                    <FinancialBillingList billing={billing} />
                </Toggle>
            </SectionContainer>

            <SectionContainer>
                <Toggle title="Sold products">
                    <ProductsBillingList
                        billing={Object.values(billing?.productData || [])}
                    />
                </Toggle>
            </SectionContainer>
        </>
    )
}
