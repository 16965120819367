export default function TextSkeleton({ defaultText, height }) {
    return (
        <span
            className="text-skeleton"
            style={
                height
                    ? {
                          '--skeleton-height': height,
                          display: 'inline-block',
                      }
                    : {}
            }
        >
            {defaultText}
        </span>
    )
}
