import { useNavigate } from 'react-router-dom'
import { ICONS } from '../../../common/constants'
import fetchAPI from '../../../common/fetchAPI'
import useMatchMutate from '../../../common/hooks/useMatchMutate'
import useModal from '../../../common/hooks/useModal'
import useToast from '../../../common/hooks/useToast'
import ResourceList from '../../../components/admin/ResourceList'
import ConfirmModalContent from '../../../components/common/ConfirmModalContent'
import Tag from '../../../components/common/Tag'
import { useState } from 'react'
import useData from '../../../common/hooks/useData'
import TabsOverview from '../../../components/navigation/TabsOverview'
import BillingOverview from '../../reports/BillingOverview'
import ContactsOverview from '../../contacts/ContactsOverview'
import ContentOverview from '../../content/ContentOverview'
import ShopOverview from '../../shop/ShopOverview'
import Settings from '../../settings/Settings'

function base64ToArrayBuffer(base64) {
    const binaryString = atob(base64)
    const len = binaryString.length
    const bytes = new Uint8Array(len)
    for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i)
    }
    return bytes.buffer
}

function List({ type, id, parentPages }) {
    const navigate = useNavigate()
    const setToast = useToast()
    const matchMutate = useMatchMutate()
    const [_exportLoading, setExportLoading] = useState(false)
    const { setModal, setModalLoading, isModalLoading } = useModal()

    async function onDeletePage(page) {
        setModal(
            <ConfirmModalContent
                onConfirm={async () => {
                    if (isModalLoading) return

                    setModalLoading(true)
                    const url = `/v1/pages/admin/${page.id}`
                    const { error } = await fetchAPI(url, {}, 'DELETE')
                    if (error) {
                        setModal(null)
                        setToast(error, 'alert')
                        return
                    }

                    setModal(null)
                    setToast('Page deleted')
                    matchMutate(/\/v1\/pages/)
                }}
            />,
            'Are you sure you want to delete this page?',
        )
    }

    async function onUrlCopy(page) {
        const fullUrl = `${process.env.REACT_APP_PUBLIC_URL}/pages/${page.slug}`
        navigator.clipboard.writeText(fullUrl)
        setToast('Copied')
    }

    async function _onExportAll() {
        setExportLoading(true)
        const { responseData, error } = await fetchAPI(
            '/v1/pages/export',
            {},
            'POST',
        )
        if (error) {
            setToast(error, 'alert')
            setExportLoading(false)
            return
        }

        setExportLoading(false)

        const arrayBuffer = base64ToArrayBuffer(responseData.xlsxStr)

        // Create a Blob from the ArrayBuffer
        const blob = new Blob([arrayBuffer], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })

        // Create a URL for the Blob and trigger the download
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = 'pages.xlsx'
        a.click()
        window.URL.revokeObjectURL(url)
        a.remove()

        setToast('Exported')
    }

    let initialQuery = {}
    if (type === 'groups') {
        initialQuery = {
            pageType: 'glossary',
        }
    } else if (type === 'group') {
        initialQuery = {
            parentPageId: id,
        }
    }

    return (
        <div key={`pages-${type}-${id}`}>
            <ResourceList
                initialQuery={initialQuery}
                renderMainButton={true}
                paginated
                paginationLimit={1000}
                getItemsFromResponse={(data) => data?.results}
                apiUrl={'/pages'}
                newItemLabel={'New page'}
                newItemPath={'/edit-pages/new'}
                itemClickPath={'/edit-pages/:item.id'}
                filters={{
                    search: true,
                    filters: {
                        pageType: {
                            label: 'Page type',
                            dbField: 'pageType',
                            options: [
                                {
                                    label: 'All',
                                    value: 'undefined',
                                },
                                {
                                    label: 'Groups',
                                    value: 'glossary',
                                },
                            ],
                        },
                        parentPageId: {
                            label: 'Parent page',
                            dbField: 'parentPageId',
                            options: [
                                {
                                    label: 'Any',
                                    value: 'undefined',
                                },
                                ...(parentPages?.map((page) => ({
                                    label: page.title,
                                    value: page.id,
                                })) || []),
                                // ...(parentPagesLoading
                                //     ? [
                                //           {
                                //               label: 'Loading...',
                                //               value: 'undefined',
                                //               loader: true,
                                //           },
                                //       ]
                                //     : []),
                            ],
                        },
                    },
                }}
                fields={[
                    {
                        column: 1,
                        getValue: (item) =>
                            `pages/${item.parentPage ? `${item.parentPage.slug}/` : ''}${item.slug}`,
                        getClassName: () => 'subtitle',
                    },
                    {
                        column: 1,
                        getValue: (item) => item.title,
                        getClassName: () => 'title',
                    },
                    {
                        column: 1,
                        getValue: (item) =>
                            item.appScreen ? (
                                <Tag color={'primary'}>App</Tag>
                            ) : (
                                <>
                                    {item.isPublished ? (
                                        <Tag color={'success'}>Live</Tag>
                                    ) : (
                                        <Tag color={'warning'}>Draft</Tag>
                                    )}
                                    {item.isPublic ? (
                                        <Tag outline color={'primary'}>
                                            Public
                                        </Tag>
                                    ) : (
                                        <Tag outline color={'primary'}>
                                            Private
                                        </Tag>
                                    )}
                                    {item.showOnClientMenu ? (
                                        <Tag outline>Client menu</Tag>
                                    ) : null}
                                    {item.showOnTeamMenu ? (
                                        <Tag outline>Team menu</Tag>
                                    ) : null}
                                </>
                            ),
                    },
                ]}
                actions={[
                    {
                        title: 'View',
                        onClick: (item) => navigate(`/pages/${item.slug}`),
                        icon: ICONS.EYE_GRAY,
                    },
                    {
                        title: 'Copy URL',
                        onClick: onUrlCopy,
                        icon: ICONS.COPY_GRAY,
                    },
                    {
                        title: 'Delete',
                        onClick: onDeletePage,
                        icon: ICONS.TRASH_RED,
                        type: 'alert',
                    },
                ]}
            />
        </div>
    )
}

export default function PagesOverview() {
    const { parentPages } = useData(
        `/v1/pages?pageType=glossary&parentPageId=null&limit=1000`,
        'parentPages',
        (data) => data?.results || [],
    )

    const pages = {}
    const defaultTab = 'all'

    pages.all = <List type={'all'} parentPages={parentPages} />
    pages.groups = <List type={'groups'} parentPages={parentPages} />

    for (const page of parentPages || []) {
        pages[page.title] = <List type={'group'} id={page.id} />
    }

    pages.reports = <BillingOverview parentPage={'reports'} />
    pages.contacts = <ContactsOverview parentPage={'contacts'} />
    pages.content = <ContentOverview parentPage={'content'} />
    pages.shop = <ShopOverview parentPage={'shop'} />
    pages.settings = <Settings parentPage={'settings'} />

    return (
        <>
            <TabsOverview defaultTab={defaultTab} pagesMap={pages} />
        </>
    )
}
