import { useEffect, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

export default function InputWrapper({
    inputId,
    required,
    hideRequired,
    label,
    labelButton,
    infoTop,
    infoBottom,
    children,
    value,
    getPreviewText,
    shouldScrollTo,
}) {
    const [previewValue, setPreviewValue] = useState(null)
    const ref = useRef()
    const [isScrollField, setIsScrollField] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()

    useEffect(
        function () {
            if (!shouldScrollTo) {
                setIsScrollField(false)
                return
            }
            const timeout = setTimeout(function () {
                const newSearchParams = new URLSearchParams(searchParams)
                newSearchParams.delete('scrollField')
                setSearchParams(newSearchParams, {
                    replace: true,
                })
            }, 2000)

            const headerOffset = 51
            const elementPosition = ref.current.getBoundingClientRect().top
            const offsetPosition = elementPosition - headerOffset - 10

            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth',
            })

            setIsScrollField(true)

            return function () {
                clearTimeout(timeout)
            }
        },
        [shouldScrollTo],
    )

    useEffect(
        function () {
            if (!getPreviewText) return
            async function getPreview() {
                const preview = await getPreviewText(value)
                setPreviewValue(preview)
            }
            getPreview()
        },
        [value],
    )

    return (
        <div
            className={`input-wrapper${isScrollField ? ' focused' : ''}`}
            ref={ref}
        >
            <label
                htmlFor={inputId}
                className={`${required && !hideRequired ? 'required' : ''}${
                    labelButton ? ' has-button' : ''
                }`}
            >
                {label}
                {labelButton ? labelButton : null}
            </label>
            {infoTop ? <small className="input-info">{infoTop}</small> : null}

            {children}

            {infoBottom ? (
                <small className="input-info">{infoBottom}</small>
            ) : null}

            {getPreviewText ? (
                <small className="input-info">{previewValue}</small>
            ) : null}
        </div>
    )
}
