import { ICONS } from '../../../common/constants'
import fetchAPI from '../../../common/fetchAPI'
import useMatchMutate from '../../../common/hooks/useMatchMutate'
import useModal from '../../../common/hooks/useModal'
import useToast from '../../../common/hooks/useToast'
import ResourceList from '../../../components/admin/ResourceList'
import ConfirmModalContent from '../../../components/common/ConfirmModalContent'
import InputModalContent from '../../../components/common/InputModalContent'
import MessageSection from '../../../components/common/MessageSection'
import SectionContainer from '../../../components/common/SectionContainer'

export default function CustomLocationsList() {
    const setToast = useToast()
    const { setModal, setModalLoading, isModalLoading } = useModal()
    const matchMutate = useMatchMutate()

    async function onRename(id) {
        setModal(
            <InputModalContent
                label="Location name"
                confirmText="Save"
                onConfirm={async ({ text }) => {
                    if (isModalLoading) return
                    setModalLoading(true)
                    const url = `/v1/shop/locations/${id}?fields=name`
                    const { error } = await fetchAPI(
                        url,
                        {
                            name: text,
                        },
                        'PATCH',
                    )
                    setModalLoading(false)
                    if (error) {
                        setToast(error, 'alert')
                        setModal(null)
                        return
                    }
                    setModal(null)
                    setToast('Saved')
                    matchMutate(/\/v1\/shop\/locations/)
                }}
            />,
            'Location name',
        )
    }

    async function onDelete(id) {
        setModal(
            <ConfirmModalContent
                text={
                    'All quantities assiciated with this location will be deleted. This cannot be undone.'
                }
                onConfirm={async () => {
                    if (isModalLoading) return
                    setModalLoading(true)
                    const { error } = await fetchAPI(
                        `/v1/shop/locations/${id}`,
                        {},
                        'DELETE',
                    )
                    setModalLoading(false)
                    if (error) {
                        setModal(null)
                        setToast(error, 'alert')
                        return
                    }
                    setToast('Deleted')
                    setModal(null)
                    matchMutate(/\/v1\/shop\/locations/)
                }}
            />,
            'Are you sure you want to delete this location?',
        )
    }

    return (
        <>
            <SectionContainer>
                <MessageSection type="info">
                    Only the admin sees this list. These locations are in-app
                    only. Every user can declare quantities based on
                    permissions.
                </MessageSection>
            </SectionContainer>
            <ResourceList
                renderMainButton
                newItemLabel="New location"
                apiUrl={`/shop/locations`}
                initialQuery={{
                    fields: 'name',
                    shopifyShopId: null,
                }}
                newItemPath={`/settings/custom-locations/new`}
                actions={[
                    {
                        title: 'Quantities',
                        link: '/settings/custom-locations/:item.id/inventory-items',
                    },
                    {
                        title: 'Permissions (Beta)',
                        link: '/settings/permissions/shop_location/:item.id',
                        icon: ICONS.LOCK_GRAY,
                        // getHide: (item) => auth.isClient || item.isShared,
                    },
                    {
                        title: 'Rename',
                        onClick: (item) => onRename(item.id),
                        icon: ICONS.EDIT_GRAY,
                    },
                    {
                        title: 'Delete',
                        onClick: (item) => onDelete(item.id),
                        icon: ICONS.TRASH_RED,
                        type: 'alert',
                    },
                ]}
                fields={[
                    {
                        column: 1,
                        getValue: (item) => item.name,
                        getClassName: () => 'title',
                    },
                ]}
            />
        </>
    )
}
