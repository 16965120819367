import { useEffect, useState } from 'react'
import useAuth from './useAuth'

export default function useScrollMeasure() {
    const auth = useAuth()
    const [scrollTop, setScrollTop] = useState(0)

    useEffect(
        function () {
            setScrollTop(0)

            function handleScroll() {
                const scrolledY = window.scrollY
                setScrollTop(scrolledY)
            }

            window.addEventListener('scroll', handleScroll)

            return () => window.removeEventListener('scroll', handleScroll)
        },
        [auth?.seesAds],
    )

    return {
        stickyTop: Math.max(0, (auth?.seesAds ? 60 : 0) - scrollTop),
        hasScrolled: scrollTop > (auth?.seesAds ? 60 : 0),
        scrollTop,
    }
}
