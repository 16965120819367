import { useParams } from 'react-router-dom'
import { ICONS } from '../../../common/constants'
import { formatDate, formatMoney } from '../../../common/helpers'
import ResourceList from '../../../components/admin/ResourceList'
import Tag from '../../../components/common/Tag'
import InlineStack from '../../../components/common/InlineStack'
import BlockStack from '../../../components/common/BlockStack'
import Grid from '../../../components/common/Grid'

function isPastDue(invoice) {
    return (
        invoice.dueDate &&
        invoice.dueDate * 1000 < new Date().getTime() &&
        invoice.status === 'open'
    )
}

function InvoicesList(props) {
    const { userId, source, subId } = useParams()
    return (
        <ResourceList
            smallTitle
            renderMainButton={props.renderMainButton === false ? false : true}
            apiUrl={`/payment/clients/${userId}/invoices`}
            initialQuery={
                props.inSubscription
                    ? {
                          source,
                          sub: subId,
                      }
                    : undefined
            }
            getItemsFromResponse={(data) => data?.invoices}
            mapItems={(invoice, response) => ({
                ...invoice,
                email: response.email,
            })}
            newItemPath={
                props.inSubscription
                    ? '/users/:userId/invoices/new?source=:source&sub=:subId'
                    : '/users/:userId/invoices/new'
            }
            title={'Invoices'}
            itemClickPath={
                '/users/:userId/subscriptions/:item.source/:item.subscription/invoices/:item.id'
            }
            actions={[
                {
                    title: 'Subscription',
                    link: '/users/:userId/subscriptions/:item.source/:item.subscription',
                    icon: ICONS.LINK_GRAY,
                },
                {
                    title: 'Edit',
                    link: '/users/:userId/subscriptions/:item.source/:item.subscription/invoices/:item.id/edit',
                    getDisabled: (item) => item.source !== 'manual',
                    icon: ICONS.EDIT_GRAY,
                },
            ]}
            fields={[
                {
                    column: 1,
                    getValue: (item) => (
                        <Grid cols={2}>
                            <BlockStack gap={'tiny'}>
                                {!userId && <div>{item.email}</div>}
                                <h3
                                    className={`m-0 ${item.status === 'paid' ? 'success' : ''} ${
                                        isPastDue(item) ? 'alert' : ''
                                    }  ${item.status === 'draft' ? 'warning' : ''} ${
                                        item.status === 'void' ? 'disabled' : ''
                                    }`}
                                >
                                    {formatMoney(item.total)}
                                </h3>

                                {item.createdDate ? (
                                    <div>
                                        {`Created on ${formatDate(item.createdDate)}`}
                                    </div>
                                ) : (
                                    ''
                                )}

                                {item.dueDate && item.status !== 'paid' && (
                                    <div
                                        className={`${isPastDue(item) ? 'alert' : ''}`}
                                    >
                                        {`Due ${formatDate(item.dueDate)}`}
                                    </div>
                                )}
                            </BlockStack>
                            <BlockStack gap={'tiny'}>
                                <div className="text-caps">
                                    {isPastDue(item) ? 'Past due' : item.status}
                                </div>

                                {!source && (
                                    <div>
                                        <Tag outline>
                                            {item.source === 'manual'
                                                ? 'Manual billing'
                                                : item.source}
                                        </Tag>
                                    </div>
                                )}
                            </BlockStack>
                        </Grid>
                    ),
                },
            ]}
        />
    )
}

export default InvoicesList
