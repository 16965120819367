import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import fetchAPI from '../../../common/fetchAPI'
import { formatDate, formatMoney } from '../../../common/helpers'
import useError from '../../../common/hooks/useError'
import ActionsDropdown from '../../../components/common/ActionsDropdown'
import useAuth from '../../../common/hooks/useAuth'
import useToast from '../../../common/hooks/useToast'
import useModal from '../../../common/hooks/useModal'
import ConfirmModalContent from '../../../components/common/ConfirmModalContent'
import { ICONS } from '../../../common/constants'
import Spinner from '../../../components/common/Spinner'
import ErrorMessage from '../../../components/common/ErrorMessage'
import Tag from '../../../components/common/Tag'
import SectionContainer from '../../../components/common/SectionContainer'
import InlineStack from '../../../components/common/InlineStack'

function InvoicePage() {
    const { userId, source, invoiceId } = useParams()
    const navigate = useNavigate()
    const [invoice, setInvoice] = useState({})
    const [loading, setLoading] = useState(true)
    const [_actionLoading, _setActionLoading] = useState(false)
    const auth = useAuth()
    const setToast = useToast()
    const { setModal, setModalLoading, isModalLoading } = useModal()

    const [error, setError] = useError()

    const isPastDue =
        invoice.dueDate &&
        invoice.dueDate * 1000 < new Date().getTime() &&
        invoice.status === 'open'

    useEffect(() => {
        const getData = async () => {
            setLoading(true)
            const { responseData, error } = await fetchAPI(
                `/v1/payment/clients/${userId}/invoices/${source}/${invoiceId}`,
                {},
                'GET',
            )
            setLoading(false)
            if (error) return setError(error)
            setInvoice(responseData)
        }
        getData()
    }, [])

    const onDeleteManual = async () => {
        setError('')

        setModal(
            <ConfirmModalContent
                onConfirm={async () => {
                    if (isModalLoading) return
                    setModalLoading(true)

                    const { error } = await fetchAPI(
                        `/v1/payment/clients/${userId}/invoices/manual/${invoiceId}`,
                        {},
                        'DELETE',
                    )
                    setModalLoading(false)
                    if (error) {
                        setError(error)
                        setModal(null)
                        return
                    }
                    setModal(null)
                    setToast('Done')
                    navigate(-1)
                }}
            />,
            'Are you sure you want to delete this invoice?',
        )
    }

    const onMarkUncollectible = async () => {
        setError('')

        setModal(
            <ConfirmModalContent
                onConfirm={async () => {
                    if (isModalLoading) return
                    setModalLoading(true)

                    const { error } = await fetchAPI(
                        `/v1/payment/clients/${userId}/invoices/${invoice.source}/${invoice.id}/mark-uncollectible`,
                        {},
                        'POST',
                    )
                    setModalLoading(false)
                    if (error) {
                        setError(error)
                        setModal(null)
                        return
                    }
                    setModal(null)
                    setToast('Done')
                    navigate(-1)
                }}
            />,
            'Are you sure you want to mark this invoice as uncollectible?',
        )
    }

    const onSend = async () => {
        setError('')

        setModal(
            <ConfirmModalContent
                onConfirm={async () => {
                    if (isModalLoading) return
                    setModalLoading(true)
                    const { error } = await fetchAPI(
                        `/v1/payment/clients/${userId}/invoices/${invoice.source}/${invoice.id}/send`,
                        {},
                        'POST',
                    )
                    setModalLoading(false)
                    if (error) {
                        setError(error)
                        setModal(null)
                        return
                    }
                    setModal(null)
                    setToast('Done')
                    navigate(-1)
                }}
            />,
            'Are you sure you want to send this invoice?',
        )
    }

    if (loading) return <Spinner marginTop />

    return (
        <>
            {!!error && (
                <SectionContainer>
                    <ErrorMessage onDismiss={() => setError('')}>
                        {error}
                    </ErrorMessage>
                </SectionContainer>
            )}

            <SectionContainer>
                <header className="info-header">
                    <InlineStack spaceBetween gap="sm">
                        <h2>Invoice {invoice.id}</h2>
                        {auth.isAdmin && (
                            <ActionsDropdown
                                actions={[
                                    {
                                        title: 'Edit',
                                        onClick: () => {
                                            navigate(
                                                `/users/${userId}/subscriptions/${invoice.source}/${invoice.subscription}/invoices/${invoice.id}/edit`,
                                            )
                                        },
                                        disabled: source !== 'manual',
                                        icon: ICONS.EDIT_GRAY,
                                    },
                                    {
                                        title: 'Mark uncollectible',
                                        onClick: () => onMarkUncollectible(),
                                        disabled: source === 'manual',
                                    },
                                    {
                                        title: 'Send',
                                        onClick: () => onSend(),
                                        disabled: source === 'manual',
                                        icon: ICONS.MAIL_GRAY,
                                    },
                                    {
                                        title: 'Delete',
                                        onClick: () => {
                                            onDeleteManual()
                                        },
                                        disabled: source !== 'manual',
                                        type: 'alert',
                                        icon: ICONS.TRASH_RED,
                                    },
                                ]}
                            />
                        )}
                    </InlineStack>
                </header>
            </SectionContainer>
            <SectionContainer className="info-details">
                <p
                    className={`${isPastDue ? 'alert' : ''} ${
                        invoice.status === 'paid' ? 'success' : ''
                    }`}
                >
                    {formatMoney(invoice.total)}{' '}
                    {invoice.total !== invoice.remaining
                        ? `, remaining ${formatMoney(invoice.remaining)}`
                        : ''}
                </p>
                <p
                    className={`text-caps ${isPastDue ? 'alert' : ''} ${
                        invoice.status === 'paid' ? 'success' : ''
                    } ${invoice.status === 'draft' ? 'warning' : ''} `}
                >
                    {isPastDue ? 'Past due' : invoice.status}
                </p>
                <Tag outline>{source}</Tag>

                <p>Created: {formatDate(invoice.createdDate)}</p>
                <p>Due: {formatDate(invoice.dueDate)}</p>

                {invoice.source === 'stripe' && (
                    <p>
                        {/* eslint-disable-next-line react/jsx-no-target-blank */}
                        <a
                            target="_blank"
                            href={`https://dashboard.stripe.com/invoices/${invoice.id}`}
                        >
                            Go to Stripe
                        </a>
                    </p>
                )}
            </SectionContainer>
        </>
    )
}

export default InvoicePage
