import { ICONS } from '../../common/constants'

function MessageSection({ type, onDismiss, children }) {
    let className = 'message-section'
    if (type === 'alert') className += ' alert'
    if (type === 'warning') className += ' warning'
    if (type === 'success') className += ' success'
    if (type === 'info') className += ' info'

    let finalTitle
    if (type === 'alert') {
        finalTitle = 'Error'
    } else if (type === 'success') {
        finalTitle = 'Success'
    } else if (type === 'info') {
        finalTitle = 'Information'
    } else if (type === 'warning') {
        finalTitle = 'Warning'
    }

    let icon
    if (type === 'success') {
        icon = ICONS.CHECK_WHITE
    } else if (type === 'alert') {
        icon = ICONS.ALERT_WHITE
    } else if (type === 'warning') {
        icon = ICONS.ALERT_WHITE
    } else if (type === 'info') {
        icon = ICONS.INFO_WHITE
    }

    return (
        <div className={className}>
            {finalTitle ||
            onDismiss ||
            ['success', 'warning', 'info'].includes(type) ? (
                <header>
                    {icon ? (
                        <img src={icon} width={14} height={14} alt="check" />
                    ) : null}
                    <h3>{finalTitle}</h3>

                    {onDismiss ? (
                        <button className="close-btn" onClick={onDismiss}>
                            &#10005;
                        </button>
                    ) : null}
                </header>
            ) : null}
            <div className="message-body">{children}</div>
        </div>
    )
}

export default MessageSection
