import { useSearchParams } from 'react-router-dom'
import useAuth from '../../common/hooks/useAuth'
import TasksList from '../admin/tasks/TasksList'
import { useRef, useState } from 'react'
import Button from '../../components/common/Button'
import { setSearchParams } from '../../common/helpers'
import InlineStack from '../../components/common/InlineStack'
import { TASK_STATUSES } from '../../common/constants'
import Divider from '../../components/common/Divider'
import Break from '../../components/common/Break'

const statusTabs = [
    TASK_STATUSES.REVIEW,
    TASK_STATUSES.REJECTED,
    TASK_STATUSES.ACCEPTED,
    TASK_STATUSES.OPEN,
    TASK_STATUSES.DENIED,
    TASK_STATUSES.APPROVAL,
    TASK_STATUSES.RECREATION,
    TASK_STATUSES.BLOCKED,
]

export default function UserTasks({ role, userId }) {
    const auth = useAuth()
    const [searchParams] = useSearchParams()
    const tagFromParams = searchParams.get('tag_task_list')
    const ref = useRef(null)
    const [hasTag, setHasTag] = useState(tagFromParams)

    function handleCloseTagTasks() {
        const paramsObj = Object.fromEntries(searchParams)
        delete paramsObj.tag_task_list
        setSearchParams(paramsObj)
        setHasTag(false)
    }

    return (
        <>
            {role === 'user' ? (
                <>
                    <h2 className="h3">Homework</h2>
                    <TasksList
                        defaultUserOrder
                        targetClient
                        clientId={userId}
                        statusTabs={statusTabs}
                        newItemPath={`/users/${userId}/tasks/new`}
                        newItemLabel={'New Homework'}
                        renderMainButton={false}
                    />
                    <Break />
                    <Divider />
                    <h2 className="h3">Team Tasks</h2>
                    <TasksList
                        defaultUserOrder
                        targetTeam
                        targetRole
                        clientId={userId}
                        statusTabs={statusTabs}
                        assignedTeamMemberId={auth.isStaff ? userId : undefined}
                        renderMainButton={false}
                    />
                </>
            ) : (
                <>
                    {Boolean(hasTag) && (
                        <>
                            <InlineStack gap={'sm'} spaceBetween>
                                <h2
                                    ref={ref}
                                    className="h3"
                                    id="tag-tasks"
                                >{`Tasks tagged "${tagFromParams}"`}</h2>
                                <Button
                                    text="Close"
                                    tiny
                                    outline
                                    onClick={handleCloseTagTasks}
                                />
                            </InlineStack>
                            <TasksList
                                defaultUserOrder
                                assignedTeamMemberId={userId}
                                statusTabs={statusTabs}
                                tag={tagFromParams}
                            />
                            <br />
                            <br />
                            <h2 className="h3">Tasks</h2>
                        </>
                    )}

                    <TasksList
                        defaultUserOrder
                        assignedTeamMemberId={userId}
                        statusTabs={statusTabs}
                        newItemPath={`/users/${userId}/tasks/new`}
                    />
                </>
            )}
        </>
    )
}
