import { useParams } from 'react-router-dom'
import useAuth from '../../../common/hooks/useAuth'
import Button from '../../../components/common/Button'
import MessageSection from '../../../components/common/MessageSection'
import useData from '../../../common/hooks/useData'
import DataFormNew from '../../../components/common/DataFormNew'
import Input from '../../../components/common/data-form/Input'
import { useState } from 'react'
import Spinner from '../../../components/common/Spinner'
import ErrorMessage from '../../../components/common/ErrorMessage'
import SectionContainer from '../../../components/common/SectionContainer'
import Break from '../../../components/common/Break'

const adminInputs = [
    {
        key: 'available',
        type: 'number',
        min: 0,
        step: 1,
    },
    {
        key: 'reportedAvailable',
        type: 'jsx',
        render: (v) => `Current reported by owner: ${v === 'null' ? '-' : v}`,
    },
]

const allowedInputs = [
    {
        key: 'available',
        type: 'number',
        min: 0,
        step: 1,
    },
]

const notAlowedInputs = [
    {
        key: 'reportedAvailable',
        type: 'number',
        min: 0,
        step: 1,
    },
    {
        key: 'available',
        type: 'jsx',
        render: (v) => `Current approved by aCo: ${v === 'null' ? '-' : v}`,
    },
]

export default function EditProductQuantities() {
    const { productId } = useParams()
    const auth = useAuth()
    const [currentLocation, setCurrentLocation] = useState(null)

    const { product, productError, productLoading } = useData(
        `/v1/shop/products/${productId}?fields=title&populate=productVariants.title`,
        'product',
    )

    const { locations, locationsError, locationsLoading } = useData(
        `/v1/shop/locations?fields=name&populate=shopifyShop.fullName`,
        'locations',
    )

    const { permission, permissionError, permissionLoading } = useData(
        currentLocation
            ? `/v1/settings/permissions/check/me?scope=edit_quantities&resourceId=${currentLocation?.id}&resource=shop_location`
            : null,
        'permission',
    )

    if (productLoading || locationsLoading) {
        return <Spinner />
    }

    if (productError || locationsError) {
        return (
            <SectionContainer>
                <ErrorMessage>{productError || locationsError}</ErrorMessage>
            </SectionContainer>
        )
    }

    if (!auth.user.orgsData.length) {
        return (
            <SectionContainer>
                <MessageSection type="warning">
                    Please add a company first.
                    <Break />
                    <Button
                        small
                        outline
                        white
                        href={`/users/${auth.user.id}/edit?type=${auth.user.role}&isOwnProfile=true`}
                        text="Add company"
                    />
                </MessageSection>
            </SectionContainer>
        )
    }

    let inputs
    if (auth.isAdmin) {
        inputs = adminInputs
    } else if (permission?.isAllowed) {
        inputs = allowedInputs
    } else {
        inputs = notAlowedInputs
    }

    return (
        <SectionContainer>
            <Input
                label="Location"
                id="select-location"
                type="select"
                value={currentLocation?.id || ''}
                onChange={(v) =>
                    setCurrentLocation(locations.find((l) => l.id === v))
                }
                options={[
                    {
                        value: '',
                        label: 'Select location',
                    },
                    ...locations.map((location) => ({
                        value: location.id,
                        label: `${
                            location.shopifyShop?.fullName || 'aCo App'
                        } / ${location.name}`,
                    })),
                ]}
            />

            {permissionLoading && <Spinner />}
            {Boolean(permissionError) && (
                <ErrorMessage>{permissionError}</ErrorMessage>
            )}

            {permission?.isAllowed && !auth.isAdmin && (
                <MessageSection type="info">
                    You are allowed to edit quantities for this location.
                </MessageSection>
            )}

            {permission?.isAllowed === false && !auth.isAdmin && (
                <MessageSection type="info">
                    Editing quantities for this location requires approval.
                    Request the quantities to be changed below.
                </MessageSection>
            )}

            <Break />

            {Boolean(currentLocation) && Boolean(permission) && (
                <>
                    {/* <h2>
                        {currentLocation.shopifyShop?.fullName}
                        {' / '}
                        {currentLocation.name}
                    </h2> */}
                    {product.productVariants.map((variant) => (
                        <DataFormNew
                            inline
                            key={`${currentLocation.id}-${variant.id}`}
                            getUrl={`/v1/shop/inventory-item?shopLocationId=${currentLocation.id}&productId=${productId}&variantId=${variant.id}`}
                            url={`/v1/shop/inventory-items/:item.id`}
                            method="PATCH"
                            submitText="Save"
                            submitToast="Saved"
                            inputs={inputs.map((input, i) => ({
                                ...input,
                                id: `${currentLocation.id}-${variant.id}-${i}`,
                                label:
                                    input.type === 'jsx'
                                        ? ''
                                        : `${product.title} - ${variant.title}`,
                            }))}
                            mapItemToData={(item) => ({
                                reportedAvailable: String(
                                    item.reportedAvailable,
                                ),
                                available: String(item.available),
                            })}
                            getBody={(data) =>
                                permission?.isAllowed || auth.isAdmin
                                    ? {
                                          available: data.available,
                                          reportedAvailable: data.available,
                                      }
                                    : {
                                          reportedAvailable:
                                              data.reportedAvailable,
                                      }
                            }
                        />
                    ))}
                </>
            )}
        </SectionContainer>
    )
}
