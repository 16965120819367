import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import fetchAPI from '../../../common/fetchAPI'
import { formatMoney } from '../../../common/helpers'
import useError from '../../../common/hooks/useError'
import useModal from '../../../common/hooks/useModal'
import useToast from '../../../common/hooks/useToast'
import Button from '../../../components/common/Button'
import ConfirmModalContent from '../../../components/common/ConfirmModalContent'
import ErrorMessage from '../../../components/common/ErrorMessage'
import SelectInput from '../../../components/common/data-form/SelectInput'
import SectionContainer from '../../../components/common/SectionContainer'

function CreateSub() {
    const { userId } = useParams()
    const initialData = {
        service: {},
        price: {},
    }
    const [loading, setLoading] = useState(false)
    const [error, setError] = useError()
    const [data, setData] = useState(initialData)
    const [services, setServices] = useState([])
    const setToast = useToast()
    const { setModal, setModalLoading, isModalLoading } = useModal()

    useEffect(() => {
        const getData = async () => {
            setLoading(true)

            const { responseData: servicesResponse, error: servicesError } =
                await fetchAPI(`/v1/services`, {}, 'GET')
            setLoading(false)
            if (servicesError) {
                setError(servicesError)
                return
            }

            setServices(servicesResponse)

            if (servicesResponse.length) {
                setData({
                    service: servicesResponse[0],
                    price: servicesResponse[0].prices[0],
                })
            }
        }

        getData()
    }, [])

    const onSubmit = async (e) => {
        setError('')
        if (!e.target.reportValidity()) return e.preventDefault()
        e.preventDefault()

        setModal(
            <ConfirmModalContent
                onConfirm={async () => {
                    if (isModalLoading) return
                    setModalLoading(true)
                    const { error } = await fetchAPI(
                        `/v1/payment/clients/${userId}/subscriptions/manual`,
                        {
                            user: userId,
                            items: [
                                {
                                    marketingService: data.service.id,
                                    servicePrice: data.price.id,
                                },
                            ],
                        },
                        'POST',
                    )
                    setModalLoading(false)
                    if (error) {
                        setError(error)
                        setModal(null)
                        return
                    }
                    setModal(null)
                    setToast('Done')
                }}
            />,
            'Are you sure you want to create this subscription?',
        )
    }

    const onServicePage = (value) => {
        const newService = services.find((service) => service.id === value)
        setData({
            ...data,
            service: newService || {},
            price: newService.prices[0] || {},
        })
    }

    return (
        <SectionContainer>
            {!!error && (
                <ErrorMessage onDismiss={() => setError('')}>
                    {error}
                </ErrorMessage>
            )}
            {loading && 'Loading...'}

            <h2>New Subscription</h2>
            <form onSubmit={onSubmit}>
                <div>
                    <label htmlFor="form-source">Source</label>
                    <SelectInput
                        name="source"
                        id="form-source"
                        value={'manual'}
                        disabled
                        options={[{ value: 'manual', label: 'Manual' }]}
                    />

                    <label htmlFor="form-service">Service</label>
                    <SelectInput
                        name="service"
                        id="form-service"
                        value={data.service.id}
                        onChange={(v) => onServicePage(v)}
                        options={services.map((service) => ({
                            value: service.id,
                            label: service.title,
                        }))}
                    />

                    <label htmlFor="form-price">Pricing</label>
                    <SelectInput
                        name="price"
                        id="form-price"
                        value={data.price._id}
                        onChange={(v) =>
                            setData({
                                ...data,
                                price:
                                    data.service?.prices?.find(
                                        (price) => price._id === v,
                                    ) || {},
                            })
                        }
                        options={(data.service?.prices || []).map((price) => ({
                            value: price._id,
                            label: `${price.pricingModel} / ${
                                price.recurringPeriod
                            } / ${formatMoney(price.amount)}`,
                        }))}
                    />
                </div>

                <div>
                    <Button text="Save" isLoading={loading} type="submit" />
                </div>
            </form>
        </SectionContainer>
    )
}

export default CreateSub
