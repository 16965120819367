// .company ready

import { useNavigate, useParams } from 'react-router-dom'
import {
    getLocalDateStr,
    getOneMsBeforeDate,
    getTimesheetEndDateFromStartDate,
    getTimezoneInfo,
} from '../../../common/helpers'
import ResourceList from '../../../components/admin/ResourceList'
import useAuth from '../../../common/hooks/useAuth'
import Tag from '../../../components/common/Tag'
import { useEffect } from 'react'

function getCurrentTimesheetStart() {
    const now = new Date()
    const fifteenthOfMonth = new Date(now.getTime())
    fifteenthOfMonth.setDate(15)
    fifteenthOfMonth.setHours(0, 0, 0, 0)
    let currTimesheetStart
    if (now < fifteenthOfMonth) {
        currTimesheetStart = new Date(now.getTime())
        currTimesheetStart.setDate(1)
        currTimesheetStart.setHours(0, 0, 0, 0)
    } else {
        currTimesheetStart = fifteenthOfMonth
    }
    return currTimesheetStart
}

function isCurrent(timesheet) {
    return (
        new Date(timesheet.startDate) < new Date() &&
        new Date() < new Date(timesheet.endDate)
    )
}

function TimesheetsList() {
    const { userId } = useParams()
    const auth = useAuth()
    const navigate = useNavigate()

    useEffect(function () {
        if (auth.isAdmin && !userId) {
            navigate('/timesheets-users')
        }
    }, [])

    const timezone = encodeURIComponent(getTimezoneInfo(new Date()).offset)

    return (
        <div>
            <ResourceList
                apiUrl={'/timesheets/timesheets-list'}
                initialQuery={
                    auth.isAdmin ? { userId, timezone } : { timezone }
                }
                getItemsFromResponse={(response) => {
                    // const responseDates = response.map(
                    //     ({ date }) => new Date(date),
                    // )
                    const includesCurrDate = response.some(({ date }) => {
                        return (
                            new Date(date).toISOString() ===
                            getCurrentTimesheetStart().toISOString()
                        )
                    })
                    const timesheetItems = includesCurrDate
                        ? response
                        : [{ date: getCurrentTimesheetStart() }, ...response]
                    const result = timesheetItems.map((item) => ({
                        startDate: new Date(item.date),
                        endDate: getTimesheetEndDateFromStartDate(
                            new Date(item.date),
                        ),
                        localStartDateStr: getLocalDateStr(new Date(item.date)),
                        totalEntries: item.totalEntries,
                        hasSubmittedEntry: item.hasSubmittedEntry,
                        hasUnsubmittedEntry: item.hasUnsubmittedEntry,
                    }))
                    return result
                }}
                itemClickPath={
                    auth.isAdmin
                        ? `/users/${userId}/timesheets/:item.localStartDateStr`
                        : '/timesheets/:item.localStartDateStr'
                }
                fields={[
                    {
                        column: 1,
                        getValue: (item) => (
                            <>
                                <h3>
                                    {item.startDate.toLocaleDateString()}-
                                    {getOneMsBeforeDate(
                                        item.endDate,
                                    ).toLocaleDateString()}
                                </h3>
                                <br />
                                <div>
                                    {isCurrent(item) ? (
                                        <Tag outline color="primary">
                                            Current
                                        </Tag>
                                    ) : null}
                                    {!isCurrent(item) &&
                                    item.totalEntries &&
                                    item.hasUnsubmittedEntry ? (
                                        <Tag color={'warning'} outline>
                                            Unsubmitted
                                        </Tag>
                                    ) : null}
                                    {!isCurrent(item) &&
                                    item.totalEntries &&
                                    !item.hasUnsubmittedEntry ? (
                                        <Tag outline color={'success'}>
                                            Submitted
                                        </Tag>
                                    ) : null}
                                </div>
                            </>
                        ),
                    },
                ]}
            />
        </div>
    )
}

export default TimesheetsList
