import useAuth from '../../../common/hooks/useAuth'
import useData from '../../../common/hooks/useData'
import ResourceList from '../../../components/admin/ResourceList'
import MessageSection from '../../../components/common/MessageSection'
import Spinner from '../../../components/common/Spinner'
import PremiumBanner from '../../admin/app-billing/PremiumBanner'

export default function ContactFormsList() {
    const auth = useAuth()

    const { hasForms, hasFormsLoading, hasFormsError } = useData(
        '/v1/users/me/has-forms',
        'hasForms',
        (data) => data?.hasForms,
    )

    if (hasFormsLoading) {
        return <Spinner />
    }

    if (hasFormsError) {
        return (
            <MessageSection type="alert">
                {`Unexpected error, please contact Support.`}
            </MessageSection>
        )
    }

    if (!auth.user.isPremiumUser && !hasForms && !auth.isAdmin) {
        return (
            <section>
                <PremiumBanner />
            </section>
        )
    }

    return (
        <ResourceList
            emptyText={'Create a new contact form to embed to your website.'}
            renderMainButton
            itemClickPath={`/contact-forms/:item.id`}
            newItemLabel={'New form'}
            initialQuery={{ source: 'contact' }}
            apiUrl={'/contact-forms'}
            getItemsFromResponse={(data) => data?.results}
            newItemPath={'/contact-forms/new'}
            paginated
            paginationLimit={20}
            fields={[
                {
                    column: 1,
                    getValue: (item) => item.title || item.id,
                    getClassName: () => 'title',
                },
                {
                    column: 1,
                    getValue: (item) => item.whitelistedDomains.join(', '),
                    getClassName: () => 'subtitle',
                },
            ]}
        />
    )
}
