import { useEffect, useState } from 'react'
import CheckboxInput from './CheckboxInput'
import Grid from '../Grid'

export default function RadiosInput({
    value,
    options = [],
    onChange,
    nullValue,
    defaultValue,
    id,
    errors,
}) {
    const [chosenValue, setChosenValue] = useState(
        defaultValue || value || null,
    )

    useEffect(
        function () {
            onChange(chosenValue === null ? nullValue : chosenValue)
        },
        [chosenValue],
    )

    return (
        <>
            <Grid cols={2}>
                {options.map((o) => (
                    <CheckboxInput
                        errors={errors}
                        id={`${id}-${o.value}`}
                        key={o.value}
                        noBr
                        label={o.label}
                        value={chosenValue === o.value}
                        onChange={(v) => setChosenValue(v ? o.value : null)}
                    />
                ))}
            </Grid>
        </>
    )
}
