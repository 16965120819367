import { useState } from 'react'
import BlockStack from '../BlockStack'
import TextInput from './TextInput'
import InlineStack from '../InlineStack'
import Button from '../Button'
import { ICONS } from '../../../common/constants'
import useModal from '../../../common/hooks/useModal'
import InputModalContent from '../InputModalContent'

function Item({ item, handleAdd, handleRemove, segmentTypes, level }) {
    return (
        <InlineStack>
            <span>
                {Array(level)
                    .fill(0)
                    .map((_, i) => (
                        <span key={i}>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    ))}
            </span>
            <BlockStack gap={'tiny'}>
                <InlineStack gap={'tiny'}>
                    <div>
                        {item.title}
                        <br />
                        <span className="text-subdued">{item.itemType}</span>
                    </div>
                    <Button
                        destructive
                        title={'Delete'}
                        icon={ICONS.TRASH_RED}
                        tiny
                        outline
                        onClick={handleRemove(item, level)}
                    />
                </InlineStack>
                {item.items.map((item, i) => (
                    <Item
                        key={i}
                        item={item}
                        handleAdd={handleAdd}
                        segmentTypes={segmentTypes}
                        level={level + 1}
                        handleRemove={handleRemove}
                    />
                ))}
                {segmentTypes.filter(Boolean).length > level + 1 && (
                    <InlineStack>
                        <span>
                            {Array(level + 1)
                                .fill(0)
                                .map((_, i) => (
                                    <span key={i}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                ))}
                        </span>
                        <Button
                            text={`Add ${segmentTypes[level + 1]}`}
                            icon={ICONS.PLUS_ACTIVE}
                            outline
                            tiny
                            onClick={() =>
                                handleAdd(level + 1, segmentTypes[level + 1])
                            }
                        />
                    </InlineStack>
                )}
            </BlockStack>
        </InlineStack>
    )
}

function getTypesFromItems(items) {
    function collectTypes(items) {
        const types = items.reduce((acc, item) => {
            if (!acc.includes(item.itemType)) {
                acc.push(item.itemType)
            }
            return [...acc, ...collectTypes(item.items || [])]
        }, [])
        return [...new Set(types)]
    }

    const allTypes = collectTypes(items)

    const limitedTypes =
        allTypes.length > 3
            ? allTypes.slice(0, 3)
            : allTypes.concat(Array(3 - allTypes.length).fill(''))

    return limitedTypes
}

export default function TreeInput({ value = [], onChange, errors }) {
    const [segmentTypes, setSegmentTypes] = useState(getTypesFromItems(value))

    const { setModal } = useModal()

    function handleTypeChange(v, i) {
        if (i > 0 && !segmentTypes[i - 1]) {
            return
        }

        const newTypes = [...segmentTypes]
        newTypes[i] = v
        setSegmentTypes(newTypes)

        function updateSegmentType(itemsList, currentLevel) {
            return itemsList.map((item) => ({
                ...item,
                items: updateSegmentType(item.items, currentLevel + 1),
                itemType: currentLevel === i ? v : item.itemType,
            }))
        }

        onChange(updateSegmentType(value, 0))
    }

    function handleAdd(level, segmentType) {
        setModal(
            <InputModalContent
                label={`${segmentType} title`}
                onConfirm={async ({ text }) => {
                    if (!text) {
                        setModal(null)
                        return
                    }

                    function addItemAtLevel(itemsList, currentLevel) {
                        if (currentLevel === level) {
                            return [
                                ...itemsList,
                                {
                                    title: text,
                                    itemType: segmentType,
                                    items: [],
                                },
                            ]
                        }
                        return itemsList.map((item) => ({
                            ...item,
                            items: addItemAtLevel(item.items, currentLevel + 1),
                        }))
                    }

                    onChange(addItemAtLevel(value, 0))
                    setModal(null)
                }}
            />,
            `${segmentType} title`,
        )
    }

    function handleRemove(item, level) {
        return () => {
            function removeItemAtLevel(itemsList, currentLevel) {
                if (currentLevel === level) {
                    return itemsList.filter((i) => i !== item)
                }
                return itemsList.map((i) => ({
                    ...i,
                    items: removeItemAtLevel(i.items, currentLevel + 1),
                }))
            }

            onChange(removeItemAtLevel(value, 0))
        }
    }

    return (
        <div className={`tree-input${errors?.length ? ' has-error' : ''}`}>
            {segmentTypes.map((t, i) => (
                <div key={i}>
                    <label htmlFor={`ttt-level-${i + 1}`}>
                        Level {i + 1} title
                    </label>
                    <TextInput
                        id={`ttt-level-${i + 1}`}
                        value={t}
                        onChange={(v) => handleTypeChange(v, i)}
                    />
                </div>
            ))}

            <InlineStack justifyEnd></InlineStack>
            <BlockStack gap={'sm'}>
                {value.map((item, i) => (
                    <Item
                        key={i}
                        item={item}
                        handleAdd={handleAdd}
                        segmentTypes={segmentTypes}
                        level={0}
                        handleRemove={handleRemove}
                    />
                ))}
                {segmentTypes.filter(Boolean).length > 0 && (
                    <Button
                        text={`Add ${segmentTypes[0]}`}
                        icon={ICONS.PLUS_ACTIVE}
                        outline
                        tiny
                        onClick={() => handleAdd(0, segmentTypes[0])}
                    />
                )}
            </BlockStack>
        </div>
    )
}
