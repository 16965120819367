import { useState } from 'react'
import useToast from '../../../common/hooks/useToast'
import useAuth from '../../../common/hooks/useAuth'
import useMatchMutate from '../../../common/hooks/useMatchMutate'
import fetchAPI from '../../../common/fetchAPI'
import ResourceList from '../../../components/admin/ResourceList'
import MessageSection from '../../../components/common/MessageSection'
import InlineStack from '../../../components/common/InlineStack'
import Button from '../../../components/common/Button'
import Break from '../../../components/common/Break'

export default function CompanyInvites({ setListKey }) {
    const [loading, setLoading] = useState(false)
    const setToast = useToast()
    const auth = useAuth()
    const matchMutate = useMatchMutate()

    async function acceptInvite(id) {
        setLoading(true)
        const url = `/v1/organizations/invites/${id}/accept`
        const { error } = await fetchAPI(url, {}, 'POST')
        setLoading(false)

        if (error) {
            setToast(error, 'alert')
            return
        }

        setToast('Invite accepted')
        setListKey(Date.now())
        matchMutate(/\/v1\/organizations\/invites*/)
    }

    async function declineInvite(id) {
        setLoading(true)
        const url = `/v1/organizations/invites/${id}`
        const { error } = await fetchAPI(url, {}, 'DELETE')
        setLoading(false)

        if (error) {
            setToast(error, 'alert')
            return
        }

        setToast('Invite declined')
        setListKey(Date.now())
        matchMutate(/\/v1\/organizations\/invites*/)
    }

    return (
        <ResourceList
            emptyText={''}
            minimal
            apiUrl={'/organizations/invites'}
            initialQuery={{ invitedId: auth.user.id }}
            fields={[
                {
                    column: 1,
                    getValue: (item) => (
                        <MessageSection type="info">
                            {`You were invited to join ${item.org?.name}.`}
                            <Break />
                            <InlineStack gap={'sm'}>
                                <Button
                                    outline
                                    text="Decline"
                                    white
                                    small
                                    isLoading={loading}
                                    onClick={() => declineInvite(item.id)}
                                />
                                <Button
                                    text="Accept"
                                    small
                                    isLoading={loading}
                                    onClick={() => acceptInvite(item.id)}
                                />
                            </InlineStack>
                        </MessageSection>
                    ),
                },
            ]}
        />
    )
}
