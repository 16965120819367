import { useState, useEffect, useRef } from 'react'
import { API_URL, ICONS } from '../../common/constants'
import authStorage from '../../common/authStorage'
import useAuth from '../../common/hooks/useAuth'
import refreshAccessToken, {
    getTokenExpirationDate,
} from '../../common/refreshTokens'
import useToast from '../../common/hooks/useToast'
import TextInput from './data-form/TextInput'
import InlineStack from './InlineStack'
import InlineStackItem from './InlineStackItem'
import Button from './Button'
import Break from './Break'
import useFilePicker from './FilePicker'
import useLongPress from '../../common/hooks/useLongPress'
import BlockStack from './BlockStack'
import Spinner from './Spinner'
import useMatchMutate from '../../common/hooks/useMatchMutate'
import ContactCard from '../../pages/admin/clients/ContactCard'

function Message({ message, onStartReply, date, showAvatar }) {
    const auth = useAuth()
    const mouseListeners = useLongPress(
        () => onStartReply(message),
        () => {},
    )
    return (
        <li>
            {date ? (
                <time className="text-center text-subdued">
                    <Break />
                    <Break />
                    <Break />
                    <div>
                        <small className="text-center text-subdued">
                            {date}
                        </small>
                    </div>
                    <Break />
                    <Break />
                    <Break />
                </time>
            ) : null}
            <InlineStack
                gap={'tiny'}
                itemsStart
                justifyEnd={message.fromUserId === auth.user.id}
            >
                {message.fromUserId !== auth.user.id && (
                    <div className="chat-msg-avatar-container">
                        {showAvatar && (
                            <ContactCard
                                thumbnailOnly
                                withDetails
                                user={message.fromUser}
                            />
                        )}
                    </div>
                )}

                <div
                    data-message-id={message.id}
                    {...mouseListeners}
                    className={`chat-msg-content chat-type-${
                        message.messageType
                    } chat-msg-${message.fromUserId === auth.user.id ? 'own' : 'other'}`}
                >
                    {message.replyToMessage && (
                        <div
                            className={`chat-msg-content-reply chat-type-${message.replyToMessage.messageType}`}
                        >
                            {message.replyToMessage.messageType === 'image' && (
                                <img
                                    src={message.replyToMessage.content}
                                    alt="chat"
                                    width={message.replyToMessage.file?.width}
                                    height={message.replyToMessage.file?.height}
                                />
                            )}
                            <pre>
                                {message.replyToMessage.messageType ===
                                    'text' && message.replyToMessage.content}
                            </pre>
                        </div>
                    )}

                    <div className="chat-msg-content-original">
                        {message.messageType === 'image' && (
                            <img
                                src={message.content}
                                alt="chat"
                                width={message.file?.width}
                                height={message.file?.height}
                            />
                        )}
                        <pre>
                            {message.messageType === 'text' && message.content}
                        </pre>
                    </div>
                </div>
            </InlineStack>
        </li>
    )
}

function Chat({ resource, resourceId, otherUserIds, roomType = 'resource' }) {
    const [messages, setMessages] = useState(null)
    const [ws, setWs] = useState(null)
    const auth = useAuth()
    const setToast = useToast()
    const [chatRoomId, setChatRoomId] = useState(null)
    const [text, setText] = useState('')
    const { pickerHtml, openPicker } = useFilePicker()
    const [replyMessage, setReplyMessage] = useState(null)
    const ref = useRef(null)
    const ulRef = useRef(null)
    const matchMutate = useMatchMutate()

    useEffect(
        function () {
            if (ws) {
                return
            }

            const socket = new WebSocket(
                `${API_URL.replace('https', 'wss').replace('http', 'ws')}/?userId=${auth.user.id}`,
            )

            setWs(socket)

            socket.onclose = function () {
                setToast('Connection closed', 'alert')
                setWs(null)
                setMessages([])
                setChatRoomId(null)
            }

            socket.onopen = function () {
                const accessToken = authStorage.getItem('access_token')
                socket.send(
                    JSON.stringify({
                        type: 'init',
                        token: accessToken,
                        resource,
                        resourceId,
                        roomType,
                        otherUserIds,
                    }),
                )
            }

            socket.onmessage = async (event) => {
                const data = JSON.parse(event.data)

                if (data.status === 401) {
                    await refreshAccessToken()
                    setToast(data.message, 'alert')
                    return
                }

                if (data.type === 'init') {
                    setChatRoomId(data.chatRoomId)
                    setMessages(data.messages)
                    setTimeout(function () {
                        ulRef.current?.scrollTo(0, ulRef.current.scrollHeight)
                    }, 1)
                    return
                }

                setMessages((prevMessages) => [...prevMessages, data.message])
                ulRef.current?.scrollTo(0, ulRef.current.scrollHeight, {
                    behavior: 'smooth',
                })
            }

            return function () {
                if (ws) {
                    return socket.close()
                }
            }
        },
        [ws],
    )

    useEffect(function () {
        return function () {
            matchMutate(/\/v1\/users\/me\/chats/)
        }
    }, [])

    async function sendFile() {
        const files = await openPicker({
            isPublic: true,
            maxFiles: 1,
            confirmLabel: 'Send',
        })

        if (!files) {
            return
        }

        const url = files?.[0]?.publicUrl

        if (!url) {
            return
        }

        let hasExpiredToken = false

        const expirationDate = getTokenExpirationDate(
            authStorage.getItem('access_token'),
        )
        if (expirationDate < new Date()) {
            await refreshAccessToken()
            hasExpiredToken = true
        }

        ws.send(
            JSON.stringify({
                // TODO: refresh on server side
                token: hasExpiredToken
                    ? authStorage.getItem('access_token')
                    : undefined,
                content: url,
                fileId: files[0].dbId,
                messageType: 'image',
                chatRoomId: chatRoomId,
                replyToMessageId: replyMessage?.id,
            }),
        )

        setReplyMessage(null)
    }

    async function sendMessage() {
        let hasExpiredToken = false

        const expirationDate = getTokenExpirationDate(
            authStorage.getItem('access_token'),
        )
        if (expirationDate < new Date()) {
            await refreshAccessToken()
            hasExpiredToken = true
        }

        ws.send(
            JSON.stringify({
                token: hasExpiredToken
                    ? authStorage.getItem('access_token')
                    : undefined,
                content: text,
                chatRoomId: chatRoomId,
                replyToMessageId: replyMessage?.id,
            }),
        )

        setText('')
        setReplyMessage(null)
        ref.current?.querySelector('textarea')?.focus()
    }

    const dateGroups = {}
    if (messages) {
        messages.forEach((msg) => {
            const date = new Date(msg.createdAt).toDateString()
            if (dateGroups[date]) {
                return
            }
            dateGroups[date] = msg
        })
    }

    return (
        <div className="chat" ref={ref}>
            {messages === null ? (
                <Spinner />
            ) : (
                <>
                    <ul ref={ulRef}>
                        {messages.map((msg, index) => {
                            const nextMessage = messages?.[index + 1]
                            const nextHasDifferentFrom =
                                nextMessage?.fromUserId !== msg.fromUserId
                            return (
                                <Message
                                    key={msg.id || index}
                                    showAvatar={
                                        msg.fromUserId !== auth.user.id &&
                                        (nextHasDifferentFrom || !nextMessage)
                                    }
                                    date={
                                        dateGroups[
                                            new Date(
                                                msg.createdAt,
                                            ).toDateString()
                                        ] === msg &&
                                        new Date(msg.createdAt).toDateString()
                                    }
                                    message={msg}
                                    onStartReply={(message) => {
                                        setReplyMessage(message)
                                        ref.current
                                            ?.querySelector('textarea')
                                            ?.focus()
                                    }}
                                />
                            )
                        })}
                    </ul>
                </>
            )}

            <div className="chat-controls">
                <BlockStack gap={'sm'}>
                    {replyMessage && (
                        <div className="chat-reply">
                            <InlineStack gap={'sm'} spaceBetween>
                                <div>
                                    <address>
                                        Replying to{' '}
                                        {replyMessage.fromUser?.firstName ||
                                            '-'}
                                    </address>
                                    <div>
                                        {replyMessage.messageType ===
                                            'image' && (
                                            <img
                                                src={replyMessage.content}
                                                alt="chat"
                                            />
                                        )}
                                        {replyMessage.messageType === 'text' &&
                                            replyMessage.content}
                                    </div>
                                </div>
                                <Button
                                    small
                                    plain
                                    icon={ICONS.X_WHITE}
                                    title={'Clear'}
                                    onClick={() => setReplyMessage(null)}
                                />
                            </InlineStack>
                        </div>
                    )}
                    <BlockStack gap={'tiny'}>
                        <InlineStack gap={'tiny'} itemsStretch>
                            <Button
                                plain
                                small
                                title="File"
                                icon={ICONS.IMAGE_WHITE}
                                onClick={sendFile}
                            />
                            <InlineStackItem grow1>
                                <TextInput
                                    value={text}
                                    onChange={(v) => setText(v)}
                                    placeholder="Your message"
                                    type="textarea"
                                    rows={1}
                                />
                            </InlineStackItem>
                            <Button small text="Send" onClick={sendMessage} />
                        </InlineStack>
                        <small className="text-center text-subdued">
                            Messages are not encrypted.
                        </small>
                    </BlockStack>
                </BlockStack>
            </div>

            {pickerHtml}
        </div>
    )
}

export default Chat
