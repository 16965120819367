import useToast from '../../../common/hooks/useToast'
import { useState } from 'react'
import useData from '../../../common/hooks/useData'
import fetchAPI from '../../../common/fetchAPI'
import Spinner from '../../../components/common/Spinner'
import ErrorMessage from '../../../components/common/ErrorMessage'
import SectionContainer from '../../../components/common/SectionContainer'
import Break from '../../../components/common/Break'
import { DEFALT_MENU_TILES, ICONS } from '../../../common/constants'
import AddItemsInput from '../../../components/common/data-form/AddItemsInput'
import MainButton from '../../../components/admin/MainButton'
import Button from '../../../components/common/Button'
import useError from '../../../common/hooks/useError'

function mergeData(settingItems) {
    const result = [...settingItems]

    for (const item of DEFALT_MENU_TILES) {
        const itemInData = settingItems.find((i) => i.href === item.href)
        if (!itemInData) {
            result.push(item)
        } else {
            // Ensure that a default item is always marked as default
            itemInData.isDefault = true
        }
    }

    return result
}

export default function MenuTiles() {
    const [data, setData] = useState(DEFALT_MENU_TILES)
    const [saveLoading, setSaveLoading] = useState(false)
    const setToast = useToast()
    const [error, setError, fieldErrors] = useError(null)

    const { tilesError, tilesLoading, tilesMutate, tilesStatus } = useData(
        `/v1/settings/menuTiles`,
        'tiles',
        (data) => JSON.parse(data?.settingValue || '[]'),
        null,
        null,
        (data) => setData(mergeData(JSON.parse(data?.settingValue || '[]'))),
    )

    // TODO: on submit make sure to clear duplicate hrefs
    async function onSubmit() {
        // Check if there are any empty labels or hrefs
        if (data.some((d) => !d.label || !d.href)) {
            setError('Please fill in all fields')
            return
        }

        // Clear isDefault from the data
        const processedData = data.map((d) => {
            const { isDefault: _, ...rest } = d
            return rest
        })

        // Remove duplicates
        const uniqueData = processedData.filter(
            (d, i) => processedData.findIndex((dd) => dd.href === d.href) === i,
        )

        setSaveLoading(true)
        const { responseData, error } = await fetchAPI(
            `/v1/settings/menuTiles`,
            { settingValue: JSON.stringify(uniqueData) },
            'PATCH',
        )
        setSaveLoading(false)

        if (error) {
            setError(error)
            return
        }

        tilesMutate(responseData)
        setToast('Saved, refresh the page to see the changes')
    }

    if (tilesLoading) {
        return <Spinner />
    }

    if (tilesError && tilesStatus !== 404) {
        return (
            <SectionContainer>
                <ErrorMessage>{tilesError}</ErrorMessage>
            </SectionContainer>
        )
    }

    return (
        <SectionContainer>
            {!!error && (
                <ErrorMessage onDismiss={() => setError('')}>
                    {error}
                </ErrorMessage>
            )}
            <Break />
            <Break />
            <Break />
            <AddItemsInput
                errors={fieldErrors}
                onChange={(d) => {
                    setData(d)
                }}
                value={data.map((d) => ({
                    ...d,
                    hideDelete: DEFALT_MENU_TILES.some(
                        (dt) => dt.href === d.href,
                    ),
                }))}
                innerInputs={[
                    {
                        key: 'label',
                        type: 'text',
                        label: 'Label',
                        required: true,
                        collapseInput: true,
                    },
                    {
                        key: 'href',
                        type: 'text',
                        label: 'URL',
                        required: true,
                        collapseInput: true,
                        getDisabled: (item) => item.isDefault,
                        placeholder: '/pages/example',
                        autoComplete: 'off',
                    },
                    {
                        key: 'icon',
                        type: 'select',
                        label: 'Icon',
                        options: [
                            {
                                label: 'None',
                                value: '',
                            },
                            ...Object.keys(ICONS)
                                .filter((key) => key.endsWith('_ACTIVE'))
                                .map((key) => ({
                                    label: key
                                        .replace('_ACTIVE', '')
                                        .replace(/_/g, ''),
                                    value: ICONS[key],
                                })),
                        ],
                        collapseInput: true,
                    },
                    {
                        key: 'showToAssistants',
                        type: 'select',
                        label: 'Assistants menu',
                        collapseInput: true,
                        options: [
                            { label: 'Show', value: 'true' },
                            { label: 'Hide', value: 'false' },
                        ],
                    },
                    {
                        key: 'showToStaff',
                        type: 'select',
                        label: 'aCo Team menu',
                        collapseInput: true,
                        options: [
                            { label: 'Show', value: 'true' },
                            { label: 'Hide', value: 'false' },
                        ],
                    },
                    {
                        key: 'showToClients',
                        type: 'select',
                        label: 'Clients (users) menu',
                        collapseInput: true,
                        options: [
                            { label: 'Show', value: 'true' },
                            { label: 'Hide', value: 'false' },
                        ],
                    },
                    {
                        key: 'isDefault',
                        type: 'hidden',
                    },
                ]}
            />

            <Button text="Save" onClick={onSubmit} isLoading={saveLoading} />

            <MainButton
                onClick={onSubmit}
                functionality="SAVE"
                loading={saveLoading}
            />
        </SectionContainer>
    )
}
