import { useEffect, useState } from 'react'
import useAuth from '../../../common/hooks/useAuth'
import { formatZipCode, getUserDisplayName } from '../../../common/helpers'
import {
    COMPANY_TYPES,
    COUNTRIES,
    DEFAULT_TEAM_ROLES,
    ICONS,
    OTHER_CONTACT_DETAILS_FIELDS,
    PRESERVER_CONTACT_METAFIELDS,
    US_STATES,
} from '../../../common/constants'
import DataFormNew from '../../../components/common/DataFormNew'
import useDebounceFn from '../../../common/hooks/useDebounceFn'
import fetchAPI from '../../../common/fetchAPI'
import Input from '../../../components/common/data-form/Input'
import SectionContainer from '../../../components/common/SectionContainer'
import useData from '../../../common/hooks/useData'
import useToast from '../../../common/hooks/useToast'
import { useSearchParams } from 'react-router-dom'
import CheckboxInput from '../../../components/common/data-form/CheckboxInput'
import Grid from '../../../components/common/Grid'
import Break from '../../../components/common/Break'
import PremiumBanner from '../app-billing/PremiumBanner'

function CreateContact() {
    const auth = useAuth()
    const canCreateClients =
        auth.isAdmin || auth.isAssistant || auth.isClientManager
    const canCreateCompanies = auth.isAdmin || auth.isAssistant
    const canCreateStaff = auth.isAdmin || auth.isAssistant
    const [searchParams] = useSearchParams()
    const [typeValue, setTypeValue] = useState(
        searchParams.get('type') || 'contact',
    )
    const debounceGetAcronymPreview = useDebounceFn(getAcronymPreview, 500)

    const setToast = useToast()
    const { definitions, definitionsError } = useData(
        auth.isAdmin
            ? `/v1/metafields/definitions?scope=app&resource[]=User&resource[]=Lead`
            : null,
        'definitions',
    )

    const { labelsError, labels } = useData(
        auth.isAdmin || auth.isAssistant ? '/v1/settings/userLabels' : null,
        'labels',
        (data) => [...(data?.settingValue?.split('\n') || []), 'active'],
    )

    useEffect(
        function () {
            if (
                definitionsError ||
                (labelsError && labelsError !== 'Setting not found')
            ) {
                setToast(definitionsError || labelsError, 'alert')
            }
        },
        [definitionsError, labelsError],
    )

    async function getAcronymPreview(name) {
        if (!name) return 'Acronym preview: -'
        const { responseData, error } = await fetchAPI(
            `/v1/organizations/generate-acronym?name=${name}`,
        )
        if (error) return 'Error while getting preview.'
        return `Acronym preview: ${responseData.acronym}`
    }

    const isCompany = typeValue === 'company'
    const isUser = [
        'user',
        'staff',
        'vendor',
        'wholesaleBuyer',
        'retailBuyer',
    ].includes(typeValue)
    const isRoleUser = [
        'user',
        'vendor',
        'wholesaleBuyer',
        'retailBuyer',
    ].includes(typeValue)
    const isRoleStaff = typeValue === 'staff'
    const isContact = [
        'contact',
        'client',
        'lead',
        'family',
        'friend',
    ].includes(typeValue)

    const allInputs = [
        {
            key: isCompany ? 'logoUrl' : 'avatarUrl',
            label: isUser || isContact ? 'Avatar' : 'Logo',
            type: 'filePicker',
            accept: 'image/*',
        },
        ...(isUser || isContact
            ? [
                  {
                      key: 'firstName',
                      label: 'First name',
                      required: isUser,
                  },
                  ...(isRoleUser
                      ? [
                            {
                                key: 'contactDetails_middleName',
                                label: 'Middle name',
                            },
                        ]
                      : []),
                  {
                      key: 'lastName',
                      label: 'Last name',
                      required: isUser,
                  },
              ]
            : [
                  {
                      key: 'name',
                      label: 'Name',
                      required: true,
                      autoComplete: 'new-password',
                      getPreviewText: debounceGetAcronymPreview,
                  },
                  {
                      key: 'companyInfo_companyType',
                      label: 'Company Type',
                      type: 'select',
                      options: [
                          {
                              value: '',
                              label: 'Unknown',
                          },
                          ...COMPANY_TYPES.map((text) => ({
                              value: text,
                              label: text,
                          })),
                      ],
                  },
              ]),
        ...(isUser || isContact
            ? [
                  {
                      key: 'contactDetails_otherNames',
                      type: 'multipleInputs',
                      label: 'Nicknames',
                      toggleGroup: 'Other names',
                  },
                  {
                      key: 'contactDetails_alternativeFirstName',
                      label: 'Alternative first name',
                      toggleGroup: 'Other names',
                  },
                  {
                      key: 'contactDetails_alternativeLastName',
                      label: 'Alternative last name',
                      toggleGroup: 'Other names',
                  },
              ]
            : []),
        {
            key: 'contactDetails_emails',
            label: 'Email',
            type: 'multipleInputs',
            inputType: 'email',
            autoComplete: 'new-password',
            typeLabels: {
                main: 'Main',
                business: 'Work',
                other: 'Other',
            },
            icon: ICONS.MAIL_GRAY,
            requiredType: 'main',
            uniqueType: 'main',
        },
        {
            label: 'Phone',
            key: 'contactDetails_phones',
            type: 'multiplePhoneInputs',
            uniqueType: 'main',
            typeLabels: {
                main: 'Primary',
                business: 'Work',
                mobile: 'Mobile',
                home: 'Home',
                other: 'Other',
            },
            defaultValue: [],
        },
        {
            key: 'addresses',
            label: 'Address',
            type: 'addInputGroupsInput',
            inputs: [
                {
                    key: 'addressType',
                    label: 'Type',
                    type: 'select',
                    options: [
                        {
                            value: 'main',
                            label: 'Main',
                        },
                        {
                            value: 'shipping',
                            label: 'Shipping',
                        },
                        {
                            value: 'billing',
                            label: 'Billing',
                        },
                        {
                            value: 'work',
                            label: 'Work',
                        },
                        {
                            value: 'school',
                            label: 'School',
                        },
                        {
                            value: '',
                            label: 'Other',
                        },
                    ],
                },
                {
                    key: 'country',
                    label: 'Country',
                    type: 'select',
                    options: COUNTRIES.sort((a, b) =>
                        a.text.localeCompare(b.text),
                    ).map(({ text }) => ({
                        value: text,
                        label: text,
                    })),
                },
                {
                    key: 'address',
                    label: 'Address',
                },
                {
                    key: 'address2',
                    label: 'Unit/apartment/suite',
                },
                {
                    key: 'city',
                    label: 'City',
                },
                {
                    key: 'state',
                    label: 'State',
                    type: 'select',
                    options: US_STATES.map(({ text }) => ({
                        value: text,
                        label: text,
                    })),
                    shouldBeText: (data) => data.country !== 'United States',
                },
                {
                    key: 'zip',
                    label: 'Zip',
                    transformValue: (v, data, itemIndex) => {
                        if (!v) return v
                        const isUS =
                            data.addresses[itemIndex].country ===
                            'United States'
                        return isUS ? formatZipCode(v) : v
                    },
                },
            ],
            defaultGroupData: {
                addressType: 'main',
                country: 'United States',
                address: '',
                city: '',
                state: 'Iowa',
                zip: '',
            },
        },

        ...(isUser
            ? [
                  {
                      key: 'defaultOrgInfo_ssn',
                      label: 'Social Security Number, ITIN, or ETIN',
                  },
                  {
                      key: 'defaultOrgInfo_birthday',
                      label: 'Birthday',
                      type: 'date',
                  },
              ]
            : []),
        ...(isContact
            ? [
                  {
                      key: 'metafield_app.ssn',
                      label: 'Social Security Number, ITIN, or ETIN',
                  },
                  {
                      key: 'metafield_app.birthday',
                      label: 'Birthday',
                      type: 'date',
                  },
              ]
            : []),

        ...(isRoleUser
            ? [
                  {
                      key: 'companyOrgInfo_name_id',
                      label: 'Company',
                      type: 'liveSearch',
                      url: '/v1/organizations?organizationType=company&fields=name',
                      showAll: false,
                      getItemsFromResults: (data) => data || [],
                      hasStrValueOnNull: true,
                      getResultValue: (item) => item.name || item,
                  },
                  {
                      key: 'companyOrgInfo_title',
                      type: 'text',
                      label: 'Job title',
                  },
                  {
                      key: 'companyOrgInfo_segment_1',
                      label: 'Sector',
                      type: 'resourceDropdown',
                      getOverride: (data) => ({
                          disabled: !/^[a-fA-F0-9]{24}$/.test(
                              data.companyOrgInfo_name_id,
                          ),
                          url: /^[a-fA-F0-9]{24}$/.test(
                              data.companyOrgInfo_name_id,
                          )
                              ? `/v1/organizations/${data.companyOrgInfo_name_id}?fields=segments`
                              : null,
                      }),
                      url: null,
                      getItems: (data) => data?.segments || [],
                      getItemLabel: (item) => item.title,
                      getItemValue: (item) => item.title,
                  },
                  {
                      key: 'companyOrgInfo_segment_2',
                      label: 'Division',
                      type: 'resourceDropdown',
                      getOverride: (data) => ({
                          disabled:
                              !/^[a-fA-F0-9]{24}$/.test(
                                  data.companyOrgInfo_name_id,
                              ) || !data.companyOrgInfo_segment_1,
                          url: /^[a-fA-F0-9]{24}$/.test(
                              data.companyOrgInfo_name_id,
                          )
                              ? `/v1/organizations/${data.companyOrgInfo_name_id}?fields=segments`
                              : null,
                          getItems: (fetchData) =>
                              (fetchData?.segments || []).find(
                                  (s) =>
                                      s.title === data.companyOrgInfo_segment_1,
                              )?.subSegments || [],
                      }),
                      getItems: () => [],
                      url: null,
                      getItemLabel: (item) => item.title,
                      getItemValue: (item) => item.title,
                  },
              ]
            : []),
        ...(isContact
            ? [
                  {
                      key: 'companyName',
                      type: 'text',
                      label: 'Company',
                  },
                  {
                      key: 'metafield_app.jobTitle',
                      type: 'text',
                      label: 'Job title',
                  },
                  {
                      key: 'metafield_app.companySegment1',
                      type: 'text',
                      label: 'Sector',
                  },
                  {
                      key: 'metafield_app.companySegment2',
                      type: 'text',
                      label: 'Division',
                  },
              ]
            : []),
        ...((isUser || isContact) && (auth.isAdmin || auth.isAssistant)
            ? [
                  {
                      defaultValue: [],
                      key: 'labels',
                      label: 'Tags',
                      type: 'liveSearchAddItems',
                      items: labels,
                      getItemText: (item) => item,
                      fields: [
                          {
                              column: 1,
                              getValue: (item) => item,
                          },
                      ],
                  },
              ]
            : []),
        ...(isCompany && (auth.isAdmin || auth.isAssistant)
            ? [
                  {
                      defaultValue: [],
                      key: 'tags',
                      label: 'Tags',
                      type: 'liveSearchAddItems',
                      canAddNewItem: true,
                      url: '/v1/tags?resource=Organization',
                      getItemText: (item) => item,
                      fields: [
                          {
                              column: 1,
                              getValue: (item) => item,
                          },
                      ],
                  },
              ]
            : []),

        {
            key: 'venmo',
            type: 'text',
            label: 'Venmo',
            toggleGroup: 'Billing',
        },
        {
            key: 'paypalEmail',
            type: 'email',
            label: 'PayPal email',
            toggleGroup: 'Billing',
        },
        {
            key: 'zelle',
            type: 'text',
            label: 'Zelle',
            toggleGroup: 'Billing',
        },
        {
            key: 'cashApp',
            type: 'text',
            label: 'Cash.me',
            toggleGroup: 'Billing',
        },

        ...Object.entries(OTHER_CONTACT_DETAILS_FIELDS)
            .filter(([key]) => {
                if (!key.includes('facebook')) {
                    return true
                }
                if (isCompany && key === 'facebook') {
                    return false
                }
                if (!isCompany && key === 'facebookPage') {
                    return false
                }
                return true
            })
            .map(([key, { label }]) => ({
                key: `contactDetails_${key}`,
                type: 'text',
                label,
                toggleGroup: 'Websites and social media',
            })),
        ...(isRoleStaff
            ? [
                  {
                      key: 'acoCompanyDisabled',
                      label: 'Company',
                      type: 'text',
                      disabled: true,
                      defaultValue: 'aCo Digital',
                  },
                  {
                      key: 'companyOrgMemberInfo_salary',
                      label: 'Salary',
                      type: 'number',
                      min: 0,
                      step: 0.01,
                  },
                  {
                      key: 'companyOrgMemberInfo_defaultHourlyRate',
                      label: 'Default hourly rate',
                      type: 'number',
                      min: 0,
                      step: 0.01,
                  },
                  {
                      key: 'companyOrgMemberInfo_startDate',
                      label: 'Start date',
                      type: 'date',
                  },
                  {
                      key: 'companyOrgMemberInfo_endDate',
                      label: 'End date',
                      type: 'date',
                  },
                  {
                      key: 'companyOrgMemberInfo_title',
                      label: 'Job title',
                      type: 'resourceDropdown',
                      url:
                          canCreateStaff && isRoleStaff
                              ? '/v1/settings/jobTypes'
                              : null,
                      getItems: (data) => data?.settingValue?.split('\n'),
                      getItemLabel: (item) => item,
                      getItemValue: (item) => item,
                  },
                  {
                      key: 'teamRoles',
                      required: true,
                      label: 'Team roles',
                      getItemsFromResults: (data) => [
                          ...(data?.settingValue?.split('\n') || []),
                          ...DEFAULT_TEAM_ROLES,
                      ],
                      type: 'liveSearchAddItems',
                      //   canAddNewItem: false,
                      url: '/settings/teamRoles',
                      searchInExistingOnly: true,
                      getItemText: (item) => item,
                      fields: [
                          {
                              column: 1,
                              getValue: (item) => item,
                          },
                      ],
                      defaultValue: [],
                  },
                  {
                      key: 'companyOrgMemberInfo_supervisorId',
                      label: 'Supervisor',
                      type: 'liveSearch',
                      url: '/v1/users?role=staff&fields=firstName,lastName,email',
                      showAll: false,
                      getItemsFromResults: (data) => data?.results || [],

                      getResultValue: (item) => getUserDisplayName(item),
                  },
              ]
            : []),
        ...(isUser || isContact
            ? [
                  {
                      //
                      key: 'contactDetails_marketingOptIn',
                      label: 'Opted in for marketing',
                      type: 'switch',
                      defaultValue: 'false',
                      options: [
                          {
                              value: 'true',
                              label: 'Yes',
                          },
                          {
                              value: 'false',
                              label: 'No',
                          },
                      ],
                  },
              ]
            : []),

        ...(isContact
            ? [
                  {
                      key: 'notes',
                      label: 'Notes (internal)',
                      type: 'textarea',
                      rows: 3,
                  },
              ]
            : []),
        ...(isUser
            ? [
                  {
                      key: 'internalNotes',
                      label: 'Notes (internal)',
                      type: 'textarea',
                      rows: 3,
                  },
                  {
                      key: 'externalNotes',
                      label: 'Notes (user facing)',
                      type: 'textarea',
                      rows: 3,
                  },
              ]
            : []),
        ...(isCompany
            ? [
                  {
                      key: 'internalNotes',
                      label: 'Notes (internal)',
                      type: 'textarea',
                      rows: 3,
                  },
              ]
            : []),

        ...(isRoleUser
            ? [
                  {
                      infoBottom:
                          'This will be overwritten if the a new status is updated in Stripe.',
                      key: 'isPremiumUser',
                      label: 'Premium status',
                      type: 'switch',
                      defaultValue: 'false',
                      options: [
                          {
                              value: 'false',
                              label: 'Free',
                          },
                          {
                              value: 'true',
                              label: 'Premium',
                          },
                      ],
                  },
              ]
            : []),

        ...(definitions?.length
            ? definitions
                  .filter((d) => {
                      if (PRESERVER_CONTACT_METAFIELDS.includes(d.key)) {
                          return false
                      }
                      if (isUser && d.resource !== 'User') {
                          return false
                      }
                      if (isContact && d.resource !== 'Lead') {
                          return false
                      }
                      if (isCompany && d.resource !== 'Organization') {
                          return false
                      }
                      return true
                  })
                  .map((d) => ({
                      key: `metafield_${d.key}`,
                      label: d.title,
                      type: d.valueType,
                      toggleGroup: 'Metafields',
                  }))
            : []),
    ]

    for (const item of allInputs) {
        item.autoComplete = 'new-password'
    }

    function getBody(data) {
        if (
            isUser &&
            !data.contactDetails_emails?.find((item) => item.type === 'main')
                ?.value
        ) {
            throw new Error('Main email is required')
        }

        const body = {
            ...(isUser || isContact
                ? {
                      email: data.contactDetails_emails?.find(
                          (item) => item.type === 'main',
                      )?.value,
                  }
                : {}),
            ...(isCompany
                ? {
                      logoUrl: data.logoUrl,

                      name: data.name,
                      companyInfo: {
                          companyType: data.companyInfo_companyType,
                      },
                      tags: data.tags,
                  }
                : {
                      avatarUrl: data.avatarUrl,

                      firstName: data.firstName,

                      lastName: data.lastName,

                      labels: data.labels,
                  }),

            billingMethods: [
                ...(data.venmo
                    ? [{ method: 'venmo', username: data.venmo }]
                    : []),

                ...(data.paypalEmail
                    ? [{ method: 'paypal', email: data.paypalEmail }]
                    : []),

                ...(data.zelle
                    ? [{ method: 'zelle', username: data.zelle }]
                    : []),

                ...(data.cashApp
                    ? [{ method: 'cashapp', username: data.cashApp }]
                    : []),
            ],
            ...(isUser
                ? {
                      defaultOrgInfo: {
                          ssn: data.defaultOrgInfo_ssn,

                          birthday: data.defaultOrgInfo_birthday,
                      },
                  }
                : {}),
            ...(isContact
                ? {
                      ['metafield_app.ssn']: data['metafield_app.ssn'],

                      'metafield_app.birthday': data['metafield_app.birthday'],
                  }
                : {}),
            ...(isRoleUser
                ? {
                      companyOrgInfo: {
                          nameId: data.companyOrgInfo_name_id,
                          memberInfo: {
                              title: data.companyOrgInfo_title,
                          },
                          member: {
                              segments: [
                                  data.companyOrgInfo_segment_1,
                                  data.companyOrgInfo_segment_2,
                              ].filter(Boolean),
                          },
                      },
                  }
                : {}),
            ...(isContact
                ? {
                      companyName: data.companyName,

                      ['metafield_app.jobTitle']:
                          data['metafield_app.jobTitle'],
                      ['metafield_app.companySegment1']:
                          data['metafield_app.companySegment1'],
                      ['metafield_app.companySegment2']:
                          data['metafield_app.companySegment2'],
                  }
                : {}),
            ...(isRoleStaff
                ? {
                      teamRoles: data.teamRoles,
                      companyOrgInfo: {
                          memberInfo: {
                              salary: data.companyOrgMemberInfo_salary,

                              defaultHourlyRate:
                                  data.companyOrgMemberInfo_defaultHourlyRate,

                              startDate: data.companyOrgMemberInfo_startDate,

                              endDate: data.companyOrgMemberInfo_endDate,

                              title: data.companyOrgMemberInfo_title,

                              supervisorId:
                                  data.companyOrgMemberInfo_supervisorId,
                          },
                      },
                  }
                : {}),

            addresses: (data.addresses || [])
                .filter((item) => item.address)
                .map((item) => ({
                    addressTypes: [item.addressType] || undefined,
                    country: item.country || undefined,
                    address: item.address || undefined,
                    address2: item.address2 || undefined,
                    city: item.city || undefined,
                    state: item.state || undefined,
                    zip: item.zip || undefined,
                })),
            contactDetails: {
                ...(isUser || isContact
                    ? {
                          middleName: data.contactDetails_middleName,

                          otherNames:
                              (data.contactDetails_otherNames || [])
                                  .map((item) => item.value)
                                  .filter(Boolean) || [],

                          preferredName: data.contactDetails_preferredName,

                          alternativeFirstName:
                              data.contactDetails_alternativeFirstName,

                          alternativeLastName:
                              data.contactDetails_alternativeLastName,

                          marketingOptIn:
                              data.contactDetails_marketingOptIn === 'true',
                      }
                    : {}),

                ...Object.keys(OTHER_CONTACT_DETAILS_FIELDS).reduce(
                    (a, b) => ({
                        ...a,
                        [b.replace('contactDetails_', '')]:
                            data[`contactDetails_${b}`],
                    }),
                    {},
                ),

                emails: (data.contactDetails_emails || [])
                    .filter((item) => item.value)
                    .map((item) => ({
                        email: item.value,
                        emailType: item.type,
                    })),

                phones: (data.contactDetails_phones || [])
                    .filter((item) => item.value)
                    .map((item) => ({
                        phone: item.value,
                        phoneType: item.type,
                    })),
            },

            ...(isContact
                ? {
                      notes: data.notes,
                  }
                : {}),
            ...(isUser
                ? {
                      internalNotes: data.internalNotes,
                      externalNotes: data.externalNotes,
                  }
                : {}),
            ...(isCompany
                ? {
                      internalNotes: data.internalNotes,
                  }
                : {}),
            ...(isRoleUser && data.isPremiumUser === 'true'
                ? {
                      isPremiumUser: true,
                  }
                : {}),
        }

        if (!body.contactDetails?.emails?.length) {
            delete body.contactDetails.emails
        }
        if (!body.contactDetails?.phones?.length) {
            delete body.contactDetails.phones
        }
        if (!body.contactDetails?.otherNames?.length) {
            delete body.contactDetails.otherNames
        }
        if (!body.contactDetails.marketingOptIn) {
            delete body.contactDetails.marketingOptIn
        }
        if (
            Object.values(body.contactDetails || {}).every(
                (v) => v === undefined || v === '',
            )
        ) {
            delete body.contactDetails
        }
        if (!body.addresses?.length) {
            delete body.addresses
        }
        if (!body.labels?.length) {
            delete body.labels
        }
        if (!body.tags?.length) {
            delete body.tags
        }
        if (!body.billingMethods?.length) {
            delete body.billingMethods
        }
        if (
            Object.values(body.defaultOrgInfo || {}).every(
                (v) => v === undefined || v === '',
            )
        ) {
            delete body.defaultOrgInfo
        }
        if (
            body.companyOrgInfo &&
            Object.values(body.companyOrgInfo.memberInfo || {}).every(
                (v) => v === undefined || v === '',
            )
        ) {
            delete body.companyOrgInfo.memberInfo
        }
        if (
            body.companyOrgInfo &&
            !(body.companyOrgInfo.member?.segments || []).filter(Boolean).length
        ) {
            delete body.companyOrgInfo.member.segments
        }
        if (
            body.companyOrgInfo &&
            Object.values(body.companyOrgInfo.member || {}).every(
                (v) => v === undefined || v === '',
            )
        ) {
            delete body.companyOrgInfo.member
        }
        if (body.companyOrgInfo && !body.companyOrgInfo?.nameId) {
            delete body.companyOrgInfo.nameId
        }
        if (
            Object.values(body.companyOrgInfo || {}).every(
                (v) => v === undefined || v === '',
            )
        ) {
            delete body.companyOrgInfo
        }
        if (
            Object.values(body.companyInfo || {}).every(
                (v) => v === undefined || v === '',
            )
        ) {
            delete body.companyInfo
        }
        if (!body.teamRoles?.length) {
            delete body.teamRoles
        }

        if (isRoleUser) {
            body.role = 'user'
        } else if (isRoleStaff) {
            body.role = 'staff'
        } else if (isUser || isContact) {
            body.contactType = typeValue
        } else {
            body.organizationType = 'company'
        }

        if (['vendor', 'wholesaleBuyer', 'retailBuyer'].includes(typeValue)) {
            body.accountType = typeValue
        }

        body.metafields = []

        for (const key of Object.keys(data)) {
            if (!key.startsWith('metafield_')) {
                continue
            }

            if (data[key] === undefined || data[key] === '') {
                continue
            }

            const metafieldKey = key.replace('metafield_', '')
            body.metafields.push({
                key: metafieldKey,
                value: data[key],
            })
        }

        if (!body.metafields.length) {
            delete body.metafields
        }

        return body
    }

    let redirectUrl = '/contacts'
    if (typeValue === 'lead') {
        redirectUrl += '#leads'
    } else if (typeValue === 'contact') {
        redirectUrl += auth.isClient ? '#all' : '#contacts'
    } else if (typeValue === 'family') {
        redirectUrl += '#family'
    } else if (typeValue === 'friend') {
        redirectUrl += '#friends'
    } else if (typeValue === 'vendor') {
        redirectUrl += '#vendors'
    }

    let url
    switch (typeValue) {
        case 'contact':
        case 'client':
        case 'lead':
        case 'family':
        case 'friend':
            url = '/v1/contacts'
            break
        case 'user':
        case 'vendor':
        case 'wholesaleBuyer':
        case 'retailBuyer':
            url = '/v1/users'
            break
        case 'staff':
            url = '/v1/users'
            break
        case 'company':
            url = '/v1/organizations'
            break
        default:
    }

    return (
        <SectionContainer>
            <form>
                <Input
                    type="select"
                    id="user-type"
                    value={typeValue}
                    onChange={(v) => setTypeValue(v)}
                    label="Type"
                    options={[
                        { value: 'contact', label: 'Contact' },
                        ...(canCreateCompanies
                            ? [
                                  {
                                      value: 'company',
                                      label: 'Company',
                                  },
                              ]
                            : []),
                        ...(auth.isClient && !canCreateCompanies
                            ? [
                                  {
                                      value: 'company_request_feature',
                                      label: 'Company',
                                  },
                              ]
                            : []),
                    ]}
                />

                {(isUser || isContact) &&
                    typeValue !== 'company_request_feature' && (
                        <Grid cols={2}>
                            <CheckboxInput
                                noBr
                                label="Friend"
                                value={typeValue === 'friend'}
                                onChange={(v) =>
                                    setTypeValue(v ? 'friend' : 'contact')
                                }
                            />
                            <CheckboxInput
                                noBr
                                label="Family"
                                value={typeValue === 'family'}
                                onChange={(v) =>
                                    setTypeValue(v ? 'family' : 'contact')
                                }
                            />
                            <CheckboxInput
                                noBr
                                label="Lead"
                                value={typeValue === 'lead'}
                                onChange={(v) =>
                                    setTypeValue(v ? 'lead' : 'contact')
                                }
                            />
                            <CheckboxInput
                                noBr
                                label="Client"
                                value={typeValue === 'client'}
                                onChange={(v) =>
                                    setTypeValue(v ? 'client' : 'contact')
                                }
                            />
                            {auth.isClient && !canCreateStaff && (
                                <CheckboxInput
                                    noBr
                                    label="Team member"
                                    value={
                                        typeValue === 'staff_request_feature'
                                    }
                                    onChange={(v) =>
                                        setTypeValue(
                                            v
                                                ? 'staff_request_feature'
                                                : 'contact',
                                        )
                                    }
                                />
                            )}
                            {(canCreateClients || canCreateStaff) && (
                                <>
                                    {canCreateClients && (
                                        <>
                                            <CheckboxInput
                                                noBr
                                                label="User"
                                                value={typeValue === 'user'}
                                                onChange={(v) =>
                                                    setTypeValue(
                                                        v ? 'user' : 'contact',
                                                    )
                                                }
                                            />
                                            <CheckboxInput
                                                noBr
                                                label="Vendor"
                                                value={typeValue === 'vendor'}
                                                onChange={(v) =>
                                                    setTypeValue(
                                                        v
                                                            ? 'vendor'
                                                            : 'contact',
                                                    )
                                                }
                                            />
                                            <CheckboxInput
                                                noBr
                                                label="Wholesale buyer"
                                                value={
                                                    typeValue ===
                                                    'wholesaleBuyer'
                                                }
                                                onChange={(v) =>
                                                    setTypeValue(
                                                        v
                                                            ? 'wholesaleBuyer'
                                                            : 'contact',
                                                    )
                                                }
                                            />
                                            <CheckboxInput
                                                noBr
                                                label="Retail buyer"
                                                value={
                                                    typeValue === 'retailBuyer'
                                                }
                                                onChange={(v) =>
                                                    setTypeValue(
                                                        v
                                                            ? 'retailBuyer'
                                                            : 'contact',
                                                    )
                                                }
                                            />
                                        </>
                                    )}
                                    {canCreateStaff && (
                                        <CheckboxInput
                                            noBr
                                            label="aCo Team member"
                                            value={typeValue === 'staff'}
                                            onChange={(v) =>
                                                setTypeValue(
                                                    v ? 'staff' : 'contact',
                                                )
                                            }
                                        />
                                    )}
                                </>
                            )}
                        </Grid>
                    )}
            </form>
            <Break />
            <Break />

            {typeValue === 'staff_request_feature' && (
                <PremiumBanner requestFeature={'create_team_members'} />
            )}
            {typeValue === 'company_request_feature' && (
                <PremiumBanner requestFeature={'create_companies'} />
            )}

            {!['staff_request_feature', 'company_request_feature'].includes(
                typeValue,
            ) && (
                <DataFormNew
                    key={typeValue}
                    url={url}
                    submitText="Save"
                    submitToast="Saved"
                    submitNavArg={redirectUrl}
                    inputs={allInputs}
                    getBody={getBody}
                    mainButton
                    saveAndNewButton
                />
            )}
        </SectionContainer>
    )
}

export default CreateContact
