import { useNavigate, useSearchParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import useAuth from '../../common/hooks/useAuth'
import Toggle from '../../components/common/Toggle'
import MessageSection from '../../components/common/MessageSection'
import useData from '../../common/hooks/useData'
import NotifsListWidget from '../admin/notifs/NotifsListWidget'
import Button from '../../components/common/Button'
import MainButton from '../../components/admin/MainButton'
import TasksList from '../admin/tasks/TasksList'
import Spinner from '../../components/common/Spinner'
import ErrorMessage from '../../components/common/ErrorMessage'
import FavoriteContacts from '../contacts/FavoriteContacts'
import SectionContainer from '../../components/common/SectionContainer'
import PremiumBanner from '../admin/app-billing/PremiumBanner'
import Break from '../../components/common/Break'
import ContactCard from '../admin/clients/ContactCard'
import InlineStack from '../../components/common/InlineStack'

function TaskSection(props) {
    const { title, ...rest } = props
    const [counter, setCounter] = useState(null)
    const [initOpen, setInitOpen] = useState(false)
    const [searchParams] = useSearchParams()

    useEffect(function () {
        if (searchParams.get('f_url_key') === title) {
            setInitOpen(true)
        }
    }, [])

    return (
        <div className={!counter && !initOpen ? ' aria-hidden' : ''}>
            <SectionContainer>
                <Toggle
                    forceOpen={initOpen}
                    title={title}
                    headerChildrenHtml={
                        <div className="counter">{counter}</div>
                    }
                >
                    <TasksList
                        title={title}
                        hideFilterTags
                        onResponseLoaded={(res) => {
                            setCounter(res.totalResults)
                        }}
                        {...rest}
                    />
                </Toggle>
            </SectionContainer>
        </div>
    )
}

export default function Home() {
    const auth = useAuth()
    const navigate = useNavigate()

    const {
        profileCompletion,
        profileCompletionError,
        profileCompletionValidating,
        profileCompletionLoading,
    } = useData(
        auth.isClient ? '/v1/users/me/profile-completion' : null,
        'profileCompletion',
    )

    return (
        <>
            <SectionContainer>
                <InlineStack>
                    <ContactCard withDetails user={auth.user} />
                </InlineStack>
            </SectionContainer>
            <NotifsListWidget />

            {auth.isClient && !auth.isPremiumUser ? (
                <SectionContainer>
                    <MessageSection type="info">
                        Upgrade to Premium.
                        <Break />
                        <Button
                            small
                            outline
                            white
                            href={`/profile/billing/purchase-premium`}
                            text="Upgrade now"
                        />
                    </MessageSection>
                </SectionContainer>
            ) : null}

            {profileCompletionError ? (
                <SectionContainer>
                    <ErrorMessage section>
                        There is an issue with loading profile information,
                        please contact Support.
                    </ErrorMessage>
                </SectionContainer>
            ) : null}

            {profileCompletionValidating || profileCompletionLoading ? (
                <>
                    <br />
                    <Spinner />
                    <br />
                </>
            ) : null}

            {!profileCompletionValidating &&
                !profileCompletionLoading &&
                profileCompletion?.completedProfileInfo === false && (
                    <SectionContainer>
                        <MessageSection type="warning">
                            There are some details missing from your profile.
                            <Break />
                            <Button
                                small
                                outline
                                white
                                href={`/users/${auth.user.id}/edit?type=user&isOwnProfile=true`}
                                text="Edit my profile"
                            />
                        </MessageSection>
                    </SectionContainer>
                )}

            {!profileCompletionValidating &&
                !profileCompletionLoading &&
                profileCompletion?.hasBillingAddress === false && (
                    <SectionContainer>
                        <MessageSection type="warning">
                            Please add a billing address to your profile.
                            <Break />
                            <Button
                                small
                                outline
                                white
                                href={`/users/${auth.user.id}/edit?type=user&isOwnProfile=true&scrollField=addresses`}
                                text="Add address"
                            />
                        </MessageSection>
                    </SectionContainer>
                )}

            {!profileCompletionValidating &&
                !profileCompletionLoading &&
                profileCompletion?.addedPayPal === false && (
                    <SectionContainer>
                        <MessageSection type="warning">
                            Please add a PayPal email to your profile.
                            <Break />
                            <Button
                                small
                                outline
                                white
                                href={`/users/${auth.user.id}/edit?type=user&isOwnProfile=true&scrollField=paypalEmail`}
                                text="Add PayPal email"
                            />
                        </MessageSection>
                    </SectionContainer>
                )}

            {auth.user.orgsData.some((o) => o.organizationType === 'company') &&
                !profileCompletionValidating &&
                !profileCompletionLoading &&
                profileCompletion?.completedCompanyOrgInfo === false && (
                    <SectionContainer>
                        <MessageSection type="warning">
                            There are some missing details about your company.
                            Add a type, email, phone, and full main address.
                            <Break />
                            <Button
                                small
                                outline
                                white
                                href={`/contacts/${profileCompletion.completedCompanyOrgInfoOrgIds?.[0]}/edit?type=company`}
                                text="Fill in company info"
                            />
                        </MessageSection>
                    </SectionContainer>
                )}

            {!profileCompletionValidating &&
                !profileCompletionLoading &&
                profileCompletion?.isValidCompanyOrgMember === false && (
                    <SectionContainer>
                        <MessageSection type="warning">
                            Please add some info about your role in the company.
                            <Break />
                            <Button
                                small
                                outline
                                white
                                href={`/profile/company-member-info/edit?organizationId=${profileCompletion?.isValidCompanyOrgMemberMissingOrgIds?.[0]}`}
                                text="Fill in info"
                            />
                        </MessageSection>
                    </SectionContainer>
                )}

            {!profileCompletionValidating &&
                !profileCompletionLoading &&
                profileCompletion?.hasOneAccountOnUserOrCompany === false && (
                    <SectionContainer>
                        <MessageSection type="warning">
                            Select the type of account you want.
                            <Break />
                            <Button
                                small
                                outline
                                white
                                href={`/profile/accounts/new`}
                                text="Select accounts"
                            />
                        </MessageSection>
                    </SectionContainer>
                )}

            {!profileCompletionValidating &&
                !profileCompletionLoading &&
                profileCompletion?.completedCompanyOrgTaxInfo === false && (
                    <SectionContainer>
                        <MessageSection type="warning">
                            Please fill in your EIN.
                            <Break />
                            <Button
                                small
                                outline
                                white
                                href={`/profile/company-ein/edit?organizationId=${profileCompletion?.completedCompanyOrgTaxInfoMissingOrgIds?.[0]}`}
                                text="Go to form"
                            />
                        </MessageSection>
                    </SectionContainer>
                )}

            {!profileCompletionValidating &&
                !profileCompletionLoading &&
                profileCompletion?.completedCompanyOrgTaxInfo === true &&
                profileCompletion?.completedCompanyOrgTaxForms === false && (
                    <SectionContainer>
                        <MessageSection type="warning">
                            Please complete your tax form.
                            <Break />
                            <Button
                                small
                                outline
                                white
                                href={`/profile/tax-forms/edit?organizationId=${profileCompletion?.completedCompanyOrgTaxFormsMissingOrgIds?.[0]}`}
                                text="Go to form"
                            />
                        </MessageSection>
                    </SectionContainer>
                )}

            {!profileCompletionValidating &&
                !profileCompletionLoading &&
                profileCompletion?.signedVendorContractMissingOrgIds?.[0] && (
                    <SectionContainer>
                        <MessageSection type="warning">
                            There are some missing details about your Vendor
                            account.
                            <Break />
                            <Button
                                small
                                outline
                                white
                                href={`/profile/vendor/edit?organizationId=${profileCompletion?.signedVendorContractMissingOrgIds?.[0]}`}
                                text="Add vendor information"
                            />
                        </MessageSection>
                    </SectionContainer>
                )}

            <SectionContainer>
                <FavoriteContacts />
            </SectionContainer>

            {(auth.isAdmin || auth.isAssistant) && (
                <>
                    <SectionContainer>
                        <TasksList searchOnly />
                    </SectionContainer>

                    <TaskSection
                        title="Waiting for Review"
                        activeStatusKeys={['statusReview', 'statusApproval']}
                    />

                    <TaskSection
                        title="Needs details revision"
                        activeStatusKeys={['statusRecreation']}
                    />

                    <TaskSection
                        title="My Tasks"
                        targetTeam
                        assignedTeamMemberId={auth.user.id}
                    />

                    <TaskSection
                        title="Client Homework"
                        targetClient
                        paidOnly
                    />

                    <TaskSection
                        title="aCo Team Tasks"
                        roleWithStaffAssigned={'true'}
                        targetRole
                    />

                    <TaskSection
                        title="Team Member Tasks"
                        // groups
                        targetTeam
                        activeStatusKeys={[
                            'statusRejected',
                            'statusAccepted',
                            'statusOpen',
                            'statusDenied',
                        ]}
                    />

                    <TaskSection
                        title="Unassigned Tasks"
                        roleWithStaffAssigned={'false'}
                        targetRole
                        activeStatusKeys={['statusOpen']}
                    />

                    <TaskSection
                        title="Completed Tasks"
                        activeStatusKeys={['statusComplete']}
                    />
                </>
            )}

            {auth.isStaff && !auth.isAssistant && (
                <>
                    <SectionContainer>
                        <TasksList
                            searchOnly
                            assignedTeamMemberId={auth.user.id}
                        />
                    </SectionContainer>

                    <TaskSection
                        title="Waiting for admin review"
                        assignedTeamMemberId={auth.user.id}
                        activeStatusKeys={['statusReview']}
                        targetTeam
                        targetRole
                    />

                    <TaskSection
                        title="New tasks under admin approval"
                        assignedTeamMemberId={auth.user.id}
                        activeStatusKeys={['statusApproval']}
                        targetRole
                    />

                    <TaskSection
                        title="New homework under admin approval"
                        activeStatusKeys={['statusApproval']}
                        targetClient
                    />

                    <TaskSection
                        title="Tasks that need details revision"
                        assignedTeamMemberId={auth.user.id}
                        activeStatusKeys={['statusRecreation']}
                        targetRole
                    />

                    <TaskSection
                        title="Homework that needs details revision"
                        activeStatusKeys={['statusRecreation']}
                        targetClient
                    />

                    <TaskSection
                        title="My Team Tasks"
                        assignedTeamMemberId={auth.user.id}
                        targetTeam
                        targetRole
                    />

                    <TaskSection
                        title="Completed Tasks"
                        assignedTeamMemberId={auth.user.id}
                        activeStatusKeys={['statusComplete']}
                        targetTeam
                        targetRole
                    />
                </>
            )}
            {auth.isClient && (
                <>
                    <SectionContainer>
                        <Toggle title={'Company tasks'}>
                            <PremiumBanner requestFeature={'company_tasks'} />
                        </Toggle>
                    </SectionContainer>
                    <TaskSection
                        title="Waiting for Review"
                        clientId={auth.user.id}
                        clientReviewStatus={'submitted'}
                        activeStatusKeys={['statusApproval', 'statusReview']}
                        targetClient
                    />

                    <TaskSection
                        title="My Homework"
                        clientId={auth.user.id}
                        targetClient
                    />

                    <TaskSection
                        title="aCo Team tasks to review"
                        clientId={auth.user.id}
                        clientReviewStatus={'submitted'}
                        activeStatusKeys={['statusReview']}
                        targetTeam
                        targetRole
                    />

                    <TaskSection
                        title="Completed Tasks"
                        clientId={auth.user.id}
                        activeStatusKeys={['statusComplete']}
                        targetClient
                    />
                </>
            )}

            <MainButton
                label={'New task'}
                functionality="CREATE"
                onClick={() => {
                    navigate('/tasks/new')
                }}
            />
        </>
    )
}
