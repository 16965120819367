import { useEffect } from 'react'
import ErrorMessage from '../common/ErrorMessage'
import InlineStack from '../common/InlineStack'
import Spinner from '../common/Spinner'
import useToast from '../../common/hooks/useToast'

function ResourceListSelectedItems(props) {
    const { loading, error, items, removeItem, getItemText } = props
    const setToast = useToast()

    useEffect(
        function () {
            if (error) {
                setToast(error, 'alert')
            }
        },
        [error],
    )

    return (
        <div className="resource-list-selected-items-container">
            {loading && <Spinner size="tiny" />}

            {!loading && <strong>{items.length} items selected</strong>}
            {items.map((item, i) => (
                <button
                    className="resource-list-selected-item"
                    key={i}
                    type="button"
                    title="Remove"
                    onClick={() => removeItem(item)}
                >
                    <InlineStack itemsCenter>
                        <span>{getItemText(item)}</span>
                        <img src="/img/icons/x-white.svg" alt="close" />
                    </InlineStack>
                </button>
            ))}
        </div>
    )
}

export default ResourceListSelectedItems
