import BlockStack from '../../components/common/BlockStack'

export default function TeamInfo({ teamRoles }) {
    return (
        <BlockStack gap={'sm'}>
            <h3 className="h6 text-subdued m-0">Teams</h3>
            <div className="text-caps">{(teamRoles || []).join(', ')}</div>
        </BlockStack>
    )
}
