import { useParams } from 'react-router-dom'
import { addressToString } from '../../../common/helpers'
import useAuth from '../../../common/hooks/useAuth'
import Tag from '../../../components/common/Tag'
import useData from '../../../common/hooks/useData'
import InfoList from '../../../components/common/InfoList'
import Button from '../../../components/common/Button'
import { ICONS } from '../../../common/constants'
import Spinner from '../../../components/common/Spinner'
import ErrorMessage from '../../../components/common/ErrorMessage'
import useToast from '../../../common/hooks/useToast'
import fetchAPI from '../../../common/fetchAPI'

function AddressesList({ userId, contactId, orgId }) {
    const auth = useAuth()
    const { userId: paramUserId, contactId: paramContactId } = useParams()
    const finalUserId = userId || paramUserId
    const finalContactId = contactId || paramContactId
    const setToast = useToast()

    let url = ''
    if (finalContactId) {
        url = `/v1/contacts/${finalContactId}?fields=email&populate=addresses`
    } else if (orgId) {
        url = `/v1/organizations/${orgId}?fields=name&populate=addresses`
    } else {
        url = `/v1/users/${finalUserId || auth.user.id}?fields=email&populate=addresses`
    }

    const {
        addresses,
        addressesError,
        addressesValidating,
        addressesLoading,
        addressesMutate,
    } = useData(url, 'addresses', (data) => data?.addresses || [])

    async function setType(item, type) {
        let url
        if (finalUserId) {
            url = `/v1/users/${finalUserId}`
        } else if (finalContactId) {
            url = `/v1/contacts/${finalContactId}`
        } else if (orgId) {
            url = `/v1/organizations/${orgId}`
        } else {
            url = `/v1/users/${auth.user.id}`
        }

        const { error } = await fetchAPI(
            url,
            {
                addresses: addresses.map((a) => ({
                    id: a.id || a._id,
                    addressTypes:
                        a === item ? [...a.addressTypes, type] : a.addressTypes,
                    country: a.country,
                    address: a.address,
                    address2: a.address2,
                    city: a.city,
                    state: a.state,
                    zip: a.zip,
                })),
            },
            'PATCH',
        )
        if (error) {
            setToast(error, 'alert')
            return
        }

        addressesMutate()
    }

    if (addressesLoading || addressesValidating) {
        return <Spinner />
    }

    if (addressesError) {
        return <ErrorMessage section>{addressesError}</ErrorMessage>
    }

    if (!addresses.length) {
        return '-'
    }

    return (
        <InfoList
            tight
            noIcons
            rows={addresses.map((a) => ({
                content: (
                    <>
                        <span>{addressToString(a)}</span>
                        {a.addressTypes
                            .filter((a) => a === 'main')
                            .map((addressType) => (
                                <Tag
                                    key={addressType}
                                    reverseMargin
                                    outline
                                    color={'gray'}
                                >
                                    {addressType}
                                </Tag>
                            ))}
                        <Button
                            className="vertical-middle"
                            icon={
                                a.addressTypes.includes('billing')
                                    ? ICONS.DOLLAR_FILLED_WHITE
                                    : ICONS.DOLLAR_OUTLINE_GRAY_2
                            }
                            inline
                            plain
                            tiny
                            onClick={() => setType(a, 'billing')}
                        />
                        <Button
                            className="vertical-middle"
                            icon={
                                a.addressTypes.includes('shipping')
                                    ? ICONS.TRUCK_FILLED_WHITE
                                    : ICONS.TRUCK_OUTLINE_GRAY_2
                            }
                            inline
                            plain
                            tiny
                            onClick={() => setType(a, 'shipping')}
                        />
                    </>
                ),
            }))}
        />
    )
}

export default AddressesList
