import { useEffect, useState } from 'react'
import fetchAPI from '../../../common/fetchAPI'
import {
    formatDateTime,
    getCommissionDBAmount,
    getCommissionDisplayAmount,
} from '../../../common/helpers'
import useData from '../../../common/hooks/useData'
import useToast from '../../../common/hooks/useToast'
import ResourceList from '../../../components/admin/ResourceList'
import Button from '../../../components/common/Button'
import Toggle from '../../../components/common/Toggle'
import PrivateFile from '../../../components/common/PrivateFile'
import { useParams } from 'react-router-dom'
import Spinner from '../../../components/common/Spinner'
import ErrorMessage from '../../../components/common/ErrorMessage'
import Tag from '../../../components/common/Tag'
import TextInput from '../../../components/common/data-form/TextInput'
import SelectInput from '../../../components/common/data-form/SelectInput'
import CheckboxInput from '../../../components/common/data-form/CheckboxInput'
import SectionContainer from '../../../components/common/SectionContainer'
import useError from '../../../common/hooks/useError'

function ClientVendorSettingsForm({ account, accountMutate, org }) {
    const setToast = useToast()
    const [error, setError, errorFields] = useError()

    const [data, setData] = useState({
        commissionType: account?.commissionType || 'none',
        commissionAmount:
            account?.commissionType && account?.commissionAmount
                ? getCommissionDisplayAmount(
                      account.commissionType,
                      account.commissionAmount,
                  )
                : '0',
        enabledDate: account?.enabledDate || null,
        expireDate: account?.expireDate || null,
    })

    useEffect(function () {}, [])

    const [updateLoading, setUpdateLoading] = useState(false)

    async function onSubmit(e) {
        e.preventDefault()

        if (!org?.acronym) {
            setError('User must have an acronym and company', 'alert')
            return
        }

        setUpdateLoading(true)
        const { error, meta } = await fetchAPI(
            `/v1/accounts/${account.id}`,
            {
                accountType: 'vendor',
                expireDate: data.expireDate || null,
                enabledDate: data.enabledDate ? new Date() : null,
                commissionType: data.commissionType,
                commissionAmount: getCommissionDBAmount(
                    data.commissionType,
                    data.commissionAmount,
                ),
            },
            'PATCH',
        )
        setUpdateLoading(false)

        if (error) {
            setError(error, meta?.fields)
            return
        }

        setToast('Saved')
        accountMutate()
    }

    return (
        <>
            {!!error && (
                <ErrorMessage onDismiss={() => setError('')}>
                    {error}
                </ErrorMessage>
            )}

            <form onSubmit={onSubmit}>
                <h3>{org?.name}</h3>
                {account?.isConsignment ? (
                    <small className="text-subdued">(Consignment)</small>
                ) : null}

                <div>
                    <CheckboxInput
                        value={Boolean(data.enabledDate)}
                        id="is-vendor"
                        label={`Enable wholesale Vendor${
                            account?.isConsignment ? ' (Consignment)' : ''
                        }`}
                        onChange={(enabledDate) => {
                            setData({
                                ...data,
                                enabledDate,
                            })
                        }}
                    />
                </div>
                <br />

                <label htmlFor="form-wholesale-type">
                    Product commission type
                </label>
                <SelectInput
                    id="form-wholesale-type"
                    onChange={(v) =>
                        setData({
                            ...data,
                            commissionType: v,
                        })
                    }
                    value={data.commissionType}
                    options={[
                        { value: 'none', label: 'None' },
                        { value: 'percentage', label: 'Percentage' },
                    ]}
                />

                {!!data.commissionType && data.commissionType !== 'none' && (
                    <>
                        <label htmlFor="form-wholesale-amount">Amount</label>
                        <TextInput
                            type="number"
                            id="form-wholesale-amount"
                            value={data.commissionAmount}
                            onChange={(v) =>
                                setData({
                                    ...data,
                                    commissionAmount: v,
                                })
                            }
                            error={errorFields.filter(
                                (f) => f === 'commissionAmount',
                            )}
                        />
                    </>
                )}

                <label htmlFor="form-expire">Account renews on</label>

                <TextInput
                    type="date"
                    id="form-expire"
                    onChange={(v) =>
                        setData({
                            ...data,
                            expireDate: v,
                        })
                    }
                    value={
                        data.expireDate ? data.expireDate.split('T')[0] : null
                    }
                    error={errorFields.filter((f) => f === 'expireDate')}
                />

                <div>
                    <Button
                        type="submit"
                        text="Save"
                        small
                        alignRight
                        isLoading={updateLoading}
                    />
                </div>
            </form>

            <div>
                <h4>Information</h4>
                <div>
                    <small>
                        <strong>EIN / SSN</strong>
                    </small>
                    <br />
                    <small>
                        {org?.ein || <span className="alert">None</span>}
                    </small>
                </div>
                <div>
                    <small>
                        <strong>Type</strong>
                    </small>
                    <br />
                    <small>
                        {org?.companyType || (
                            <span className="alert">None</span>
                        )}
                    </small>
                </div>
                <div>
                    <small>
                        <strong>Company</strong>
                    </small>
                    <br />
                    <small>
                        {org?.name || <span className="alert">None</span>}
                    </small>
                </div>
                <div>
                    <small>
                        <strong>Acronym</strong>
                    </small>
                    <br />
                    <small>
                        {org?.acronym || <span className="alert">None</span>}
                    </small>
                </div>

                <div>
                    <small>
                        <strong>Locations</strong>
                    </small>
                    <br />
                    <small>
                        {account?.locations?.join(', ') || (
                            <span className="alert">None</span>
                        )}
                    </small>
                </div>
                <br />
                <Toggle title="Questions & product photos">
                    <ResourceList
                        loadMoreButton
                        emptyText={<span className="warning">None</span>}
                        apiUrl={'/forms'}
                        getItemsFromResponse={(data) => data?.results}
                        initialQuery={{
                            vendorId: account.id,
                            source: 'productsQuestions',
                        }}
                        itemClickPath={'/plain-forms/:item.id'}
                        fields={[
                            {
                                column: 1,
                                getValue: (item) =>
                                    formatDateTime(item.creationDate),
                                getClassName: () => 'subtitle',
                            },
                            {
                                column: 1,
                                getValue: (item) => `ID: ${item.id}`,
                                getClassName: () => 'title',
                            },
                        ]}
                    />
                </Toggle>
            </div>

            <div>
                <h4>Submitted contract</h4>

                {account.signedContractFileId ? (
                    <PrivateFile
                        openFullOnClick
                        driveFileId={account.signedContractFileId}
                    />
                ) : (
                    <span className="warning">None</span>
                )}
            </div>
        </>
    )
}

function ClientVendorSettings(props) {
    const { open } = props
    const { userId } = useParams()

    const setToast = useToast()
    const [createLoading, setCreateLoading] = useState(false)
    const [error, setError] = useError()

    const { user, userError, userLoading } = useData(
        `/v1/users/${userId}?fields=email&populate=allCompanyOrgs`,
        'user',
    )

    const companyOrgs = user?.allCompanyOrgs || []

    const { accounts, accountsError, accountsLoading, accountsMutate } =
        useData(
            companyOrgs?.length
                ? `/v1/accounts?accountType=vendor${companyOrgs
                      .map((o) => `&ownerId=${o.id}`)
                      .join('')}`
                : null,
            'accounts',
        )

    async function onCreateAccount(e) {
        e.preventDefault()

        if (!companyOrgs[0]?.acronym) {
            setError('User must have an acronym and company')
            return
        }

        setCreateLoading(true)
        const { error } = await fetchAPI(
            '/v1/accounts?accountType=vendor',
            {
                ownerId: companyOrgs[0].id,
            },
            'POST',
        )
        setCreateLoading(false)

        if (error) {
            setError(error)
            return
        }

        setToast('Account created, please add commission')
        accountsMutate()
    }

    if (userError || accountsError) {
        return (
            <SectionContainer>
                <Toggle title="Vendor">
                    <ErrorMessage>{userError || accountsError}</ErrorMessage>
                </Toggle>
            </SectionContainer>
        )
    }

    if (userLoading || accountsLoading) {
        return (
            <SectionContainer>
                <Toggle title="Vendor">
                    <Spinner />
                </Toggle>
            </SectionContainer>
        )
    }

    const hasPending = accounts?.some((a) =>
        a.enabledDate && a.commissionType && a.commissionAmount ? false : true,
    )

    const headerHtml = hasPending && <Tag color={'warning'}>Pending</Tag>

    return (
        <SectionContainer>
            <Toggle
                title={`Vendor`}
                open={open || false}
                headerChildrenHtml={headerHtml}
            >
                {error && (
                    <ErrorMessage onDismiss={() => setError('')}>
                        {error}
                    </ErrorMessage>
                )}
                {!accounts?.length ? (
                    <div>
                        <h4>{companyOrgs[0]?.name}</h4>
                        <Button
                            text="Create vendor account"
                            isLoading={createLoading}
                            onClick={onCreateAccount}
                        />
                    </div>
                ) : null}

                {accounts?.map((account) => (
                    <ClientVendorSettingsForm
                        key={account.id}
                        account={account}
                        accountMutate={accountsMutate}
                        org={user.allCompanyOrgs.find(
                            (o) => o.id === account.ownerId,
                        )}
                    />
                ))}
            </Toggle>
        </SectionContainer>
    )
}

export default ClientVendorSettings
