import ResourceList from '../../../components/admin/ResourceList'
import useAuth from '../../../common/hooks/useAuth'
import {
    getDisplayDateTimeFromTime,
    getZonedTimeFromUTCTime,
    formatMoney,
} from '../../../common/helpers'
import { useNavigate } from 'react-router-dom'
import { ICONS } from '../../../common/constants'
import Tag from '../../../components/common/Tag'

const filterFields = {
    sort: {
        name: {
            label: 'Order number',
            dbFields: ['orderNumber'],
        },
        creationDate: {
            label: 'Creation date',
            dbFields: ['creationDate'],
        },
    },
}

export default function AdminDBOrdersList(props) {
    const navigate = useNavigate()
    const auth = useAuth()

    const initialQuery = {}
    if (props.vendor) {
        initialQuery.vendor = props.vendor
    }
    if (props.product) {
        initialQuery.product = props.product
    }

    let title = ''
    if (props.product || props.vendor) {
        title = 'Orders'
    }
    if (typeof props.title !== 'undefined') {
        title = props.title
    }

    return (
        <ResourceList
            apiUrl={'/wholesale/db-orders'}
            paginated
            itemClickPath={auth.isAdmin ? '/shop/orders/:item.id' : undefined}
            paginationLimit={props.vendor || props.product ? 5 : 10}
            initialQuery={initialQuery}
            getItemsFromResponse={(data) => data.results}
            filters={filterFields}
            title={title}
            fields={[
                {
                    column: 1,
                    getValue: (item) => (
                        <>
                            <span>
                                {item.shopName}&nbsp;Order&nbsp;#
                                {item.orderNumber}
                            </span>
                            {item.fulfillmentStatus === 'fulfilled' &&
                            item.financialStatus === 'paid' &&
                            !item.cancelDate ? (
                                <Tag color={'success'}>Completed</Tag>
                            ) : null}
                            {(item.fulfillmentStatus !== 'fulfilled' ||
                                item.financialStatus !== 'paid') &&
                            !item.cancelDate ? (
                                <Tag color={'warning'}>Open</Tag>
                            ) : null}
                            {item.cancelDate ? (
                                <Tag color={'alert'}>Cancelled</Tag>
                            ) : null}
                        </>
                    ),
                    getClassName: () => 'title with-tag',
                },
                {
                    column: 1,
                    getValue: (item) =>
                        item.customerFullName || 'No customer name',
                    getClassName: () => 'subtitle',
                },
                {
                    column: 1,
                    getValue: (item) => (
                        <strong>
                            {!props.vendor && !props.product
                                ? item.lineItems
                                      .map(
                                          (line) =>
                                              line?.vendorOrg?.name ||
                                              '(No company)',
                                      )
                                      .join(', ')
                                : null}
                        </strong>
                    ),
                },
                {
                    column: 1,
                    getValue: (item) => {
                        const quantity = item.lineItems.reduce(
                            (a, b) => a + b.quantity,
                            0,
                        )
                        return (
                            <>
                                {!props.vendor && !props.product ? (
                                    <>
                                        <strong>Vendor:</strong>&nbsp;
                                        {item.lineItems
                                            .map((line) => line.vendor)
                                            .join(', ')}
                                        &nbsp;&nbsp;&nbsp; &nbsp;
                                    </>
                                ) : null}
                                <strong>Quantity:</strong>&nbsp;{quantity}
                            </>
                        )
                    },
                },
                {
                    column: 1,
                    getValue: (item) => (
                        <>
                            <strong>Purchased:&nbsp;</strong>
                            {getDisplayDateTimeFromTime(
                                getZonedTimeFromUTCTime(
                                    null,
                                    new Date(item.creationDate).getTime(),
                                ),
                            )}
                        </>
                    ),
                },
                {
                    column: 1,
                    getValue: (item) => (
                        <>
                            <strong>Total price:</strong>&nbsp;
                            {formatMoney(item.totalPrice, false, 'shopify')}
                        </>
                    ),
                },
                {
                    column: 1,
                    getValue: (item) => (
                        <div>
                            {item.fulfillmentStatus ? (
                                <Tag outline>{item.fulfillmentStatus}</Tag>
                            ) : (
                                <Tag outline>Unfulfilled</Tag>
                            )}
                            {item.financialStatus ? (
                                <Tag outline>{item.financialStatus}</Tag>
                            ) : (
                                ''
                            )}
                        </div>
                    ),
                },
                // {
                //     column: 1,
                //     getValue: (item) => (
                //         <div className="list">
                //             <h5>Items</h5>
                //             <ul>
                //                 {item.lineItems.map((line) => (
                //                     <li key={line.lineItemId}>
                //                         <span>{line.quantity}</span>&nbsp;
                //                         {line.quantity !==
                //                             line.fulfillableQuantity && (
                //                             <>
                //                                 <span>
                //                                     ({line.fulfillableQuantity}
                //                                     &nbsp;fulfillable)&nbsp;
                //                                 </span>
                //                             </>
                //                         )}
                //                         x&nbsp;{line.name}
                //                     </li>
                //                 ))}
                //             </ul>
                //         </div>
                //     ),
                // },
            ]}
        />
    )
}
