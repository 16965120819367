import SectionContainer from '../../components/common/SectionContainer'
import { useEffect, useRef, useState } from 'react'
import { PhoneInput } from 'react-international-phone'
import 'react-international-phone/style.css'
import TreeInput from '../../components/common/data-form/TreeInput'
import RadiosInput from '../../components/common/data-form/RadiosInput'
import MessageSection from '../../components/common/MessageSection'
import Button from '../../components/common/Button'
import { ICONS } from '../../common/constants'
import InlineStack from '../../components/common/InlineStack'
import Break from '../../components/common/Break'
import Tag from '../../components/common/Tag'
import useToast from '../../common/hooks/useToast'
import fetchAPI from '../../common/fetchAPI'
import ContactCard from './clients/ContactCard'
import ResourceList from '../../components/admin/ResourceList'
import { getUserDisplayName } from '../../common/helpers'
import useModal from '../../common/hooks/useModal'
import InputModalContent from '../../components/common/InputModalContent'
import useData from '../../common/hooks/useData'
import useAuth from '../../common/hooks/useAuth'
import useMatchMutate from '../../common/hooks/useMatchMutate'
import OrgCard from '../contacts/OrgCard'
import Popover from '../../components/common/Popover'

function getItemPatchUrl(item) {
    if (item.role) {
        return `/v1/users/${item.id}`
    } else if (item.organizationType || item.name) {
        return `/v1/organizations/${item.id}`
    } else {
        return `/v1/contacts/${item.id}`
    }
}

/*
- filter/search/sort for forms; filter by date
- images upload to pages drag and drop doesn’t work
- images library uses too much data 
- individual permissions is broken 
- make filter/search/sort component
- left hand drawer nav component for desktop
- implement left nav 
- component pages need a tertiary nav on page: all, requirements, design, component (with code snippet) there are examples of this already but I am manually doing it so a page template for components library would be good
- prioritizing tasks doesn’t work. They still go into the negative. And changes numbers. Would be nice to be able to connect to pages and projects
*/

export default function Dev() {
    // https://app.aco.digital/tasks/66c391ba94871c5bc52b906f
    const ref = useRef(null)
    return (
        <SectionContainer>
            <div ref={ref}>
                <Button text={'Click'} />
            </div>
            <Popover
                actvator={ref}
                renderActivator={(ref, listeners) => null}
                // renderActivator={(ref, listeners) => (
                //     <div ref={ref}>
                //         <Button
                //             small
                //             icon={ICONS.LINK_WHITE}
                //             {...listeners}
                //             type="button"
                //         />
                //     </div>
                // )}
            >
                Inner content
            </Popover>
        </SectionContainer>
    )
}
