import { ICONS } from '../../common/constants'
import useAuth from '../../common/hooks/useAuth'
import BlockStack from '../../components/common/BlockStack'
import Button from '../../components/common/Button'
import InfoList from '../../components/common/InfoList'
import InlineStack from '../../components/common/InlineStack'
import InlineStackItem from '../../components/common/InlineStackItem'
import AddressesList from '../admin/addresses/AddressesList'
import Tag from '../../components/common/Tag'
import Social from './Social'
import fetchAPI from '../../common/fetchAPI'
import useToast from '../../common/hooks/useToast'
import useMatchMutate from '../../common/hooks/useMatchMutate'

export default function BasicInfo({
    userId,
    contactId,
    contactDetails,
    canSeeContactInfo,
    user,
    defaultOrgInfo,
}) {
    const auth = useAuth()
    const isOwnProfile = !userId && !contactId
    const setToast = useToast()
    const matchMutate = useMatchMutate()

    async function setPhonePreferred(phoneId) {
        const isAlreadyPreferred = contactDetails.phones.some(
            (p) => p.isPreferred && (p.id || p._id) === phoneId,
        )
        const { error } = await fetchAPI(
            isOwnProfile || userId
                ? `/v1/users/${userId || auth.user.id}`
                : `/v1/contacts/${contactId}`,
            {
                contactDetails: {
                    phones: contactDetails.phones.map((p) => ({
                        id: p.id || p._id,
                        phone: p.phone,
                        phoneType: p.phoneType,
                        isPreferred:
                            !isAlreadyPreferred && (p.id || p._id) === phoneId,
                    })),
                },
            },
            'PATCH',
        )
        if (error) {
            setToast(error, 'alert')
            return
        }
        let mutateRegex
        if (isOwnProfile) {
            mutateRegex = /\/v1\/me/
        } else if (userId) {
            mutateRegex = new RegExp(`/v1/users/${userId}`)
        } else {
            mutateRegex = new RegExp(`/v1/users/${contactId}`)
        }
        matchMutate(mutateRegex)
    }

    async function setEmailPreferred(emailId) {
        const isAlreadyPreferred = contactDetails.emails.some(
            (e) => e.isPreferred && (e.id || e._id) === emailId,
        )
        const { error } = await fetchAPI(
            isOwnProfile || userId
                ? `/v1/users/${userId || auth.user.id}`
                : `/v1/contacts/${contactId}`,
            {
                contactDetails: {
                    emails: contactDetails.emails.map((e) => ({
                        id: e.id || e._id,
                        email: e.email,
                        emailType: e.emailType,
                        isPreferred:
                            !isAlreadyPreferred && (e.id || e._id) === emailId,
                    })),
                },
            },
            'PATCH',
        )
        if (error) {
            setToast(error, 'alert')
            return
        }
        let mutateRegex
        if (isOwnProfile) {
            mutateRegex = /\/v1\/me/
        } else if (userId) {
            mutateRegex = new RegExp(`/v1/users/${userId}`)
        } else {
            mutateRegex = new RegExp(`/v1/users/${contactId}`)
        }
        matchMutate(mutateRegex)
    }

    return (
        <div>
            {canSeeContactInfo && (
                <BlockStack gap={'md'}>
                    <BlockStack gap={'tiny'}>
                        <BlockStack>
                            {!!user?.firstName && (
                                <InlineStack itemsStart gap="sm">
                                    <InlineStackItem shrink0>
                                        <div className="text-subdued">
                                            {isOwnProfile
                                                ? 'Legal first name:'
                                                : 'First name:'}
                                        </div>
                                    </InlineStackItem>
                                    <div>{user.firstName}</div>
                                </InlineStack>
                            )}
                            {!!contactDetails?.middleName && (
                                <InlineStack itemsStart gap="sm">
                                    <InlineStackItem shrink0>
                                        <div className="text-subdued">
                                            Middle name:
                                        </div>
                                    </InlineStackItem>
                                    <div>{contactDetails?.middleName}</div>
                                </InlineStack>
                            )}
                            {!!user?.lastName && (
                                <InlineStack itemsStart gap="sm">
                                    <InlineStackItem shrink0>
                                        <div className="text-subdued">
                                            {isOwnProfile
                                                ? 'Legal last name:'
                                                : 'Last name:'}
                                        </div>
                                    </InlineStackItem>
                                    <div>{user.lastName}</div>
                                </InlineStack>
                            )}
                            {!!contactDetails?.preferredName && (
                                <InlineStack itemsStart gap="sm">
                                    <InlineStackItem shrink0>
                                        <div className="text-subdued">
                                            Preferred name:
                                        </div>
                                    </InlineStackItem>
                                    <div>{contactDetails.preferredName}</div>
                                </InlineStack>
                            )}
                            {!!contactDetails?.otherNames?.length && (
                                <InlineStack itemsStart gap="sm">
                                    <InlineStackItem shrink0>
                                        <div className="text-subdued">
                                            Nicknames:
                                        </div>
                                    </InlineStackItem>
                                    <div>
                                        {contactDetails.otherNames.join(', ')}
                                    </div>
                                </InlineStack>
                            )}
                            {!!contactDetails?.alternativeFirstName && (
                                <InlineStack itemsStart gap="sm">
                                    <InlineStackItem shrink0>
                                        <div className="text-subdued">
                                            Alt. first name:
                                        </div>
                                    </InlineStackItem>
                                    <div>
                                        {contactDetails.alternativeFirstName}
                                    </div>
                                </InlineStack>
                            )}
                            {!!contactDetails?.alternativeLastName && (
                                <InlineStack gap="sm">
                                    <InlineStackItem>
                                        <div className="text-subdued">
                                            Alt. last name:
                                        </div>
                                    </InlineStackItem>
                                    <div>
                                        {contactDetails.alternativeLastName}
                                    </div>
                                </InlineStack>
                            )}
                        </BlockStack>
                    </BlockStack>

                    {!!contactDetails?.emails?.length && (
                        <BlockStack gap={'tiny'}>
                            <h3 className="h6 text-subdued m-0">Emails</h3>
                            <InfoList
                                tight
                                rows={contactDetails.emails.map((e) => ({
                                    icon: ICONS.MAIL_GRAY,
                                    content: (
                                        <InlineStack itemsCenter wrap>
                                            {e.email || '-'}&nbsp;&nbsp;
                                            <Tag
                                                noMargin
                                                outline
                                                color={'gray'}
                                            >
                                                {e.emailType}
                                            </Tag>
                                            <Button
                                                className={
                                                    e.isPreferred
                                                        ? ''
                                                        : 'opacity-25'
                                                }
                                                icon={
                                                    e.isPreferred
                                                        ? ICONS.STAR_FILLED_WHITE
                                                        : ICONS.STAR_WHITE
                                                }
                                                inline
                                                plain
                                                tiny
                                                onClick={() =>
                                                    setEmailPreferred(
                                                        e.id || e._id,
                                                    )
                                                }
                                            />
                                        </InlineStack>
                                    ),
                                }))}
                            />
                        </BlockStack>
                    )}

                    {!!contactDetails?.phones?.length && (
                        <BlockStack gap={'tiny'}>
                            <h3 className="h6 text-subdued m-0">Phones</h3>
                            <InfoList
                                tight
                                rows={contactDetails.phones.map((p) => ({
                                    icon: ICONS.PHONE_GRAY,
                                    content: (
                                        <InlineStack itemsCenter wrap>
                                            {p.phone || '-'}&nbsp;&nbsp;
                                            <Tag
                                                noMargin
                                                outline
                                                color={'gray'}
                                            >
                                                {p.phoneType}
                                            </Tag>
                                            <Button
                                                className={
                                                    p.isPreferred
                                                        ? ''
                                                        : 'opacity-25'
                                                }
                                                icon={
                                                    p.isPreferred
                                                        ? ICONS.STAR_FILLED_WHITE
                                                        : ICONS.STAR_WHITE
                                                }
                                                inline
                                                plain
                                                tiny
                                                onClick={() =>
                                                    setPhonePreferred(
                                                        p.id || p._id,
                                                    )
                                                }
                                            />
                                        </InlineStack>
                                    ),
                                }))}
                            />
                        </BlockStack>
                    )}

                    <BlockStack gap={'tiny'}>
                        <h3 className="h6 text-subdued m-0">Addresses</h3>
                        <AddressesList
                            plain
                            userId={userId}
                            contactId={contactId}
                        />
                    </BlockStack>

                    {(defaultOrgInfo?.birthday ||
                        user.metafields?.some((m) => m.key === 'app.birthday')
                            ?.value) && (
                        <BlockStack gap={'tiny'}>
                            <h3 className="h6 text-subdued m-0">
                                Personal information
                            </h3>
                            <InlineStack itemsStart gap="sm">
                                <InlineStackItem shrink0>
                                    <div className="text-subdued">
                                        Birthday:
                                    </div>
                                </InlineStackItem>
                                <div>
                                    {new Date(
                                        defaultOrgInfo.birthday ||
                                            user.metafields.find(
                                                (m) => m.key === 'app.birthday',
                                            ).value,
                                    ).toLocaleDateString()}
                                </div>
                            </InlineStack>
                        </BlockStack>
                    )}

                    <BlockStack gap={'tiny'}>
                        <h3 className="h6 text-subdued m-0">
                            Websites and social media
                        </h3>
                        <div>
                            <Social contactDetails={contactDetails} />
                        </div>
                    </BlockStack>

                    <BlockStack gap={'tiny'}>
                        <h3 className="h6 text-subdued m-0">Marketing</h3>
                        <InlineStack itemsStart gap="sm">
                            <InlineStackItem shrink0>
                                <div className="text-subdued">Opted in:</div>
                            </InlineStackItem>
                            <div>
                                {contactDetails?.marketingOptIn ? 'Yes' : 'No'}
                            </div>
                        </InlineStack>
                    </BlockStack>
                </BlockStack>
            )}
        </div>
    )
}
