import { useState } from 'react'
import Input from '../../../components/common/data-form/Input'
import Button from '../../../components/common/Button'
import StripeCardForm from '../../../common/StripeCardForm'
import fetchAPI from '../../../common/fetchAPI'
import useAuth from '../../../common/hooks/useAuth'
import { COUNTRIES, US_STATES } from '../../../common/constants'
import useMatchMutate from '../../../common/hooks/useMatchMutate'
import useData from '../../../common/hooks/useData'
import useModal from '../../../common/hooks/useModal'
import { useLocation } from 'react-router-dom'
import useTranslations from '../../../common/hooks/useTranslations'
import Spinner from '../../../components/common/Spinner'
import ErrorMessage from '../../../components/common/ErrorMessage'
import SectionContainer from '../../../components/common/SectionContainer'

export default function CreateDonation() {
    const auth = useAuth()
    const matchMutate = useMatchMutate()
    const { setModal } = useModal()
    const { pathname } = useLocation()
    const { t, tLoading } = useTranslations()

    const [method, setMethod] = useState('card')
    const [amount, setAmount] = useState('7')
    const [isOther, setIsOther] = useState(false)
    const [recurrence, setRecurrence] = useState('monthly')
    const [info, setInfo] = useState({
        address: '',
        city: '',
        zip: '',
        state: '',
        country: 'United States',
    })

    const { addresses, addressesLoading, addressesError } = useData(
        `/v1/addresses/${auth.user.id}?ownerId=${auth.user.id}&ownerType=User`,
        'addresses',
        (data) => data?.results || [],
    )

    const billingAddress = addresses.find((address) =>
        address.addressTypes.includes('billing'),
    )

    function handleAmountChange(value) {
        if (value === 'other') {
            setIsOther(true)
            setAmount('1')
        } else {
            setIsOther(false)
            setAmount(value)
        }
    }

    async function updateAddress() {
        const body = {
            ...info,
            addressTypes: ['billing'],
            ownerId: auth.user.id,
            ownerType: 'User',
        }

        const { error } = await fetchAPI(
            `/v1/addresses/${auth.user.id}`,
            body,
            'POST',
        )

        if (error) {
            return {
                error: {
                    message: error,
                },
            }
        }

        matchMutate(/\/v1\/addresses*/)
        matchMutate(/\/v1\/user*/)
        matchMutate(/\/v1\/me*/)

        return {}
    }

    async function updateInfo() {
        if (!billingAddress) {
            if (
                !info.address ||
                !info.city ||
                !info.zip ||
                !info.state ||
                !info.country
            ) {
                return {
                    error: {
                        message: 'Please fill out all fields',
                    },
                }
            }
        }

        if (!billingAddress) {
            const { error: addressError } = await updateAddress()
            if (addressError) return addressError
        }

        await auth.refetchAuth()

        return {}
    }

    async function onSubmitPaymentForm(stripe, elements) {
        const url =
            recurrence === 'monthly'
                ? '/v1/app-billing/me/create-donation-subscription'
                : '/v1/app-billing/me/donate'
        const { responseData, error } = await fetchAPI(
            url,
            { amount: Number(amount) * 100 },
            'POST',
        )

        if (error) return { error: { message: error } }

        const country = info.country || billingAddress?.country

        const { error: stripeError } = await stripe.confirmPayment({
            elements,
            clientSecret: responseData.clientSecret,
            confirmParams: {
                return_url: `${window.location.origin}/callbacks/donation-complete`,
                payment_method_data: {
                    billing_details: {
                        address: {
                            country: COUNTRIES.find(
                                ({ text }) => text === country,
                            ).value,
                        },
                        email: auth.user.email,
                        name: `${auth.user.firstName} ${auth.user.lastName}`,
                    },
                },
            },
        })

        if (stripeError) return { error: stripeError }

        return {}
    }

    if (addressesLoading || tLoading) {
        return <Spinner />
    }

    if (addressesError) {
        return <ErrorMessage section>{addressesError}</ErrorMessage>
    }

    return (
        <SectionContainer>
            <div style={{ whiteSpace: 'pre-line' }}>
                {t(
                    'donation.new.intro',
                    `
                    Hello! Thanks for joining The App. We're a small but hard working team.

                    In order to keep most of the app free and full of features we've added ads to the free version. Help us keep building by donating whatever amount you can ❤ Thank you so much!

                    Donating is a free-will monthly subscription or one-time donation.
                    
                    `,
                )}
            </div>

            <div className="donation-page">
                <Input
                    // label="Select method"
                    type="switch"
                    id={'method'}
                    onChange={setMethod}
                    options={[
                        { label: 'Credit card', value: 'card' },
                        // { label: 'ACH', value: 'ach' },
                        { label: 'PayPal', value: 'paypal' },
                        { label: 'Venmo', value: 'venmo' },
                    ]}
                    value={method}
                />

                {method === 'paypal' && (
                    <Button
                        text={<>Donate with PayPal</>}
                        href={
                            'https://www.paypal.com/qrcodes/managed/d33fdd61-d129-4a07-8019-54d48d0a74a3'
                        }
                    />
                )}

                {method === 'venmo' && (
                    <Button
                        text={<>Donate with Venmo</>}
                        href={'https://account.venmo.com/u/AgencyCouture'}
                    />
                )}

                {method === 'ach' && (
                    <StripeCardForm
                        mode={'payment'}
                        amount={Number(amount) * 100}
                        currency={'usd'}
                        onSubmit={onSubmitPaymentForm}
                        preSubmit={updateInfo}
                        submitText={'Donate now'}
                        hideCountry
                        paymentMethodTypes={['us_bank_account']}
                    />
                )}

                {method === 'card' && (
                    <>
                        <Input
                            label="Choose your donation type"
                            type="switch"
                            id={'recurrence'}
                            onChange={(v) => setRecurrence(v)}
                            options={[
                                { label: 'Monthly', value: 'monthly' },
                                { label: 'One time', value: 'once' },
                            ]}
                            value={recurrence}
                        />

                        <Input
                            label="Select amount"
                            type="switch"
                            id={'amount'}
                            onChange={handleAmountChange}
                            options={[
                                { label: '$7', value: '7' },
                                { label: '$10', value: '10' },
                                { label: '$25', value: '25' },
                                { label: '$100', value: '100' },
                                {
                                    label: <small>Other</small>,
                                    value: 'other',
                                },
                            ]}
                            value={isOther ? 'other' : amount}
                        />

                        {isOther && (
                            <Input
                                type="number"
                                id={'other-amount'}
                                onChange={(v) =>
                                    setAmount(String(parseInt(v || '1', 10)))
                                }
                                value={amount}
                                placeholder="Enter amount"
                                min={1}
                            />
                        )}

                        {!billingAddress && (
                            <>
                                <Input
                                    label="Address"
                                    id="address"
                                    value={info.address}
                                    onChange={(v) =>
                                        setInfo({ ...info, address: v })
                                    }
                                />
                                <Input
                                    label="City"
                                    id="city"
                                    value={info.city}
                                    onChange={(v) =>
                                        setInfo({ ...info, city: v })
                                    }
                                />
                                <Input
                                    label="Zip"
                                    id="zip"
                                    value={info.zip}
                                    onChange={(v) =>
                                        setInfo({ ...info, zip: v })
                                    }
                                />

                                {info.country === 'United States' ? (
                                    <Input
                                        type="select"
                                        label="State"
                                        id="state"
                                        value={info.state}
                                        onChange={(v) =>
                                            setInfo({ ...info, state: v })
                                        }
                                        options={US_STATES.map(({ text }) => ({
                                            value: text,
                                            label: text,
                                        }))}
                                    />
                                ) : (
                                    <Input
                                        label="Province"
                                        id="state"
                                        value={info.state}
                                        onChange={(v) =>
                                            setInfo({ ...info, state: v })
                                        }
                                    />
                                )}
                                <Input
                                    label="Country"
                                    id="country"
                                    type="select"
                                    value={info.country}
                                    onChange={(v) =>
                                        setInfo({ ...info, country: v })
                                    }
                                    options={COUNTRIES.map(({ text }) => ({
                                        value: text,
                                        label: text,
                                    }))}
                                />
                            </>
                        )}

                        <StripeCardForm
                            mode={
                                recurrence === 'monthly'
                                    ? 'subscription'
                                    : 'payment'
                            }
                            amount={Number(amount) * 100}
                            currency={'usd'}
                            onSubmit={onSubmitPaymentForm}
                            preSubmit={updateInfo}
                            submitText={'Donate now'}
                            hideCountry
                            paymentMethodTypes={['card']}
                        />
                    </>
                )}

                {/* <Button text={'Donate now'} /> */}
            </div>

            {pathname === '/donate' ? null : (
                <>
                    <Button
                        text={'Skip'}
                        link
                        underline
                        fullWidth
                        onClick={() => setModal(null)}
                    />
                    <br />
                    <br />
                </>
            )}
        </SectionContainer>
    )
}

//  {/*

//     {
//         key: 'state',
//         label: 'State',
//         type: 'select',
//         options: US_STATES.map(({ text }) => ({ value: text, label: text })),
//         required: true,
//         shouldBeText: (data) => data.country !== 'United States',
//     },
//     {
//         key: 'country',
//         label: 'Country',
//         type: 'select',
//         options: COUNTRIES.map(({ text }) => ({
//             value: text,
//             label: text,
//         })),
//         required: true,
//         defaultValue: 'United States',
//     },

//                                 */}
