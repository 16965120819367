import { Link } from 'react-router-dom'
import { ICONS } from '../../common/constants'
import BlockStack from '../../components/common/BlockStack'
import InfoList from '../../components/common/InfoList'
import InlineStack from '../../components/common/InlineStack'
import InlineStackItem from '../../components/common/InlineStackItem'
import { getUserDisplayName } from '../../common/helpers'
import useAuth from '../../common/hooks/useAuth'
import CompanyInvites from '../more/organizations/CompanyInvites'
import { useState } from 'react'

export default function CompanyInfo({
    contactId,
    user,
    allCompaniesMemberInfo,
    allCompaniesMember,
    allCompanyOrgs,
}) {
    const auth = useAuth()
    const [listKey, setListKey] = useState(Date.now())

    const isOwnProfile = auth.user.id === user.id
    const noCompany =
        (!contactId && !allCompanyOrgs?.length) ||
        (contactId && !user.companyName)

    return (
        <div key={listKey}>
            {noCompany ? (
                '-'
            ) : (
                <>
                    {!contactId && !!allCompanyOrgs?.length && (
                        <BlockStack gap={'tiny'}>
                            {allCompanyOrgs.map((org) => {
                                const member = allCompaniesMember.find(
                                    (m) => m.organizationId === org.id,
                                )
                                const memberInfo = allCompaniesMemberInfo.find(
                                    (m) => m.organizationId === org.id,
                                )
                                return (
                                    <InfoList
                                        key={org.id}
                                        tight
                                        rows={[
                                            {
                                                icon:
                                                    org.logoUrl ||
                                                    ICONS.FOLDER_WHITE,
                                                content: (
                                                    <Link
                                                        to={`/companies/${org.id}`}
                                                    >
                                                        {org.name}
                                                    </Link>
                                                ),
                                                strong: true,
                                            },

                                            {
                                                content: (
                                                    <div>
                                                        <small className="text-subdued">
                                                            {org.acronym}
                                                        </small>
                                                    </div>
                                                ),
                                            },

                                            ...(member?.segments
                                                ? [
                                                      {
                                                          icon: null,
                                                          content: (
                                                              <InlineStack
                                                                  itemsStart
                                                                  gap="sm"
                                                              >
                                                                  <InlineStackItem
                                                                      shrink0
                                                                  >
                                                                      <div className="text-subdued">
                                                                          Sector
                                                                          /
                                                                          Division:
                                                                      </div>
                                                                  </InlineStackItem>
                                                                  <div>
                                                                      {member.segments.join(
                                                                          ' / ',
                                                                      )}
                                                                  </div>
                                                              </InlineStack>
                                                          ),
                                                      },
                                                  ]
                                                : [{}]),
                                            ...(!auth.isClient || isOwnProfile
                                                ? [
                                                      {
                                                          icon: null,
                                                          content: (
                                                              <InlineStack
                                                                  itemsStart
                                                                  gap="sm"
                                                              >
                                                                  <InlineStackItem
                                                                      shrink0
                                                                  >
                                                                      <div className="text-subdued">
                                                                          Role:
                                                                      </div>
                                                                  </InlineStackItem>
                                                                  <div>
                                                                      {memberInfo?.role ||
                                                                          'Member'}
                                                                  </div>
                                                              </InlineStack>
                                                          ),
                                                      },
                                                  ]
                                                : [{}]),

                                            ...(memberInfo?.title
                                                ? [
                                                      {
                                                          icon: null,
                                                          content: (
                                                              <InlineStack
                                                                  itemsStart
                                                                  gap="sm"
                                                              >
                                                                  <InlineStackItem
                                                                      shrink0
                                                                  >
                                                                      <div className="text-subdued">
                                                                          Job
                                                                          title:
                                                                      </div>
                                                                  </InlineStackItem>
                                                                  <div>
                                                                      {
                                                                          memberInfo.title
                                                                      }
                                                                  </div>
                                                              </InlineStack>
                                                          ),
                                                      },
                                                  ]
                                                : [{}]),
                                            ...(memberInfo?.salary
                                                ? [
                                                      {
                                                          icon: null,
                                                          content: (
                                                              <InlineStack
                                                                  itemsStart
                                                                  gap="sm"
                                                              >
                                                                  <InlineStackItem
                                                                      shrink0
                                                                  >
                                                                      <div className="text-subdued">
                                                                          Salary:
                                                                      </div>
                                                                  </InlineStackItem>
                                                                  <div>
                                                                      $
                                                                      {
                                                                          memberInfo.salary
                                                                      }
                                                                  </div>
                                                              </InlineStack>
                                                          ),
                                                      },
                                                  ]
                                                : [{}]),
                                            ...(memberInfo?.defaultHourlyRate
                                                ? [
                                                      {
                                                          icon: null,
                                                          content: (
                                                              <InlineStack
                                                                  itemsStart
                                                                  gap="sm"
                                                              >
                                                                  <InlineStackItem
                                                                      shrink0
                                                                  >
                                                                      <div className="text-subdued">
                                                                          Default
                                                                          hourly
                                                                          rate:
                                                                      </div>
                                                                  </InlineStackItem>
                                                                  <div>
                                                                      $
                                                                      {
                                                                          memberInfo.defaultHourlyRate
                                                                      }
                                                                  </div>
                                                              </InlineStack>
                                                          ),
                                                      },
                                                  ]
                                                : [{}]),
                                            ...(memberInfo?.startDate
                                                ? [
                                                      {
                                                          icon: null,
                                                          content: (
                                                              <InlineStack
                                                                  itemsStart
                                                                  gap="sm"
                                                              >
                                                                  <InlineStackItem
                                                                      shrink0
                                                                  >
                                                                      <div className="text-subdued">
                                                                          Start
                                                                          date:
                                                                      </div>
                                                                  </InlineStackItem>
                                                                  <div>
                                                                      {new Date(
                                                                          memberInfo.startDate,
                                                                      ).toLocaleDateString()}
                                                                  </div>
                                                              </InlineStack>
                                                          ),
                                                      },
                                                  ]
                                                : [{}]),
                                            ...(memberInfo?.endDate
                                                ? [
                                                      {
                                                          icon: null,
                                                          content: (
                                                              <InlineStack
                                                                  itemsStart
                                                                  gap="sm"
                                                              >
                                                                  <InlineStackItem
                                                                      shrink0
                                                                  >
                                                                      <div className="text-subdued">
                                                                          End
                                                                          date:
                                                                      </div>
                                                                  </InlineStackItem>
                                                                  <div>
                                                                      {new Date(
                                                                          memberInfo.endDate,
                                                                      ).toLocaleDateString()}
                                                                  </div>
                                                              </InlineStack>
                                                          ),
                                                      },
                                                  ]
                                                : [{}]),
                                            ...(memberInfo?.supervisor
                                                ? [
                                                      {
                                                          icon: null,
                                                          content: (
                                                              <InlineStack
                                                                  itemsStart
                                                                  gap="sm"
                                                              >
                                                                  <InlineStackItem
                                                                      shrink0
                                                                  >
                                                                      <div className="text-subdued">
                                                                          Supervisor:
                                                                      </div>
                                                                  </InlineStackItem>
                                                                  <div>
                                                                      {getUserDisplayName(
                                                                          memberInfo.supervisor,
                                                                      )}
                                                                  </div>
                                                              </InlineStack>
                                                          ),
                                                      },
                                                  ]
                                                : [{}]),
                                        ]}
                                    />
                                )
                            })}
                        </BlockStack>
                    )}

                    {contactId && !!user.companyName && (
                        <InfoList
                            tight
                            rows={[
                                {
                                    icon: ICONS.FOLDER_WHITE,
                                    content: user.companyName,
                                    strong: true,
                                },
                                ...(user.metafields.some(
                                    (m) => m.key === 'app.jobtitle',
                                )
                                    ? [
                                          {
                                              icon: null,
                                              content: (
                                                  <InlineStack
                                                      itemsStart
                                                      gap="sm"
                                                  >
                                                      <InlineStackItem shrink0>
                                                          <div className="text-subdued">
                                                              Job title:
                                                          </div>
                                                      </InlineStackItem>
                                                      <div>
                                                          {
                                                              user.metafields.find(
                                                                  (m) =>
                                                                      m.key ===
                                                                      'app.jobtitle',
                                                              ).value
                                                          }
                                                      </div>
                                                  </InlineStack>
                                              ),
                                          },
                                      ]
                                    : [{}]),
                            ]}
                        />
                    )}
                </>
            )}
            <CompanyInvites setListKey={setListKey} />
        </div>
    )
}
