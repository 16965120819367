import { useStore } from '../Context'

const useToast = () => {
    const [state, dispatch] = useStore()

    const setToast = (text, type = 'success') => {
        const toastDate = Date.now()

        const sameToast = state.toasts.find(
            (toast) => toast.text === text && toast.messageType === type,
        )
        if (sameToast) {
            dispatch({
                type: 'CLEAR_TOAST',
                payload: sameToast.date,
            })
        }

        dispatch({
            type: 'SET_TOAST',
            payload: {
                text,
                date: toastDate,
                messageType: type,
                counter: sameToast ? sameToast.counter + 1 : 1,
            },
        })

        setTimeout(() => {
            dispatch({
                type: 'CLEAR_TOAST',
                payload: toastDate,
            })
        }, 5000)
    }

    return setToast
}

export default useToast
