import ResourceList from '../../../components/admin/ResourceList'
import ContactCard from '../clients/ContactCard'

function AdminTimesheetsUserList() {
    return (
        <div>
            <ResourceList
                apiUrl={'/timesheets/timesheets-users'}
                itemClickPath={'/users/:item.id/timesheets'}
                fields={[
                    {
                        column: 1,
                        getValue: (item) => <ContactCard user={item} summary />,
                    },
                ]}
            />
        </div>
    )
}

export default AdminTimesheetsUserList
