import { useEffect, useRef } from 'react'
import SignatureCanvas from 'react-signature-canvas'
import { ICONS } from '../../common/constants'
import Button from './Button'

function SignatureInput(props) {
    const { onChange, label = 'Sign here', value, errors } = props
    const signatureRef = useRef(undefined)

    useEffect(() => {
        if (value) {
            signatureRef.current.fromDataURL(value)
        }
    }, [])

    const onStrokeEnd = () => {
        if (signatureRef?.current?.isEmpty()) {
            return
        }
        const canvas = signatureRef.current.getTrimmedCanvas()
        const dataUrl = canvas.toDataURL('image/png')
        onChange(dataUrl)
    }

    const onClearSignature = (e) => {
        e.preventDefault()
        signatureRef?.current?.clear()
        onChange(null)
    }

    return (
        <section
            className={`signature-input${errors?.length ? ' has-error' : ''}`}
        >
            <div>
                <label>{label}</label>
                <Button
                    text="Clear"
                    icon={ICONS.TRASH_RED}
                    outline
                    small
                    destructive
                    onClick={onClearSignature}
                />
            </div>
            <div>
                <SignatureCanvas
                    ref={signatureRef}
                    penColor="black"
                    canvasProps={{
                        className: 'sign-canvas',
                        width: 300,
                        height: 130,
                    }}
                    onEnd={onStrokeEnd}
                />
            </div>
        </section>
    )
}

export default SignatureInput
