import { useEffect, useRef, useState } from 'react'
import Button from './Button'
import fetchAPI from '../../common/fetchAPI'
import useToast from '../../common/hooks/useToast'
import InlineStack from './InlineStack'
import BlockStack from './BlockStack'

function getImageDimensions(file) {
    return new Promise(function (resolve) {
        const reader = new FileReader()
        reader.onload = function (e) {
            const img = new Image()
            img.onload = function () {
                resolve({
                    width: img.width,
                    height: img.height,
                })
            }
            img.src = e.target.result
        }
        reader.readAsDataURL(file)
    })
}

function ImageCanvas({ imageUrl, onConfirm, saveLoading }) {
    const canvasRef = useRef(null)
    const containerRef = useRef(null)
    const resizedImageCanvas = useRef(null)
    const [zoom, setZoom] = useState(1)
    const [offset, setOffset] = useState({ x: 0, y: 0 })
    const isDragging = useRef(false)
    const dragStart = useRef({ x: 0, y: 0 })

    function initializeCanvas() {
        if (canvasRef.current && containerRef.current) {
            const canvas = canvasRef.current
            const container = containerRef.current

            const containerWidth = container.clientWidth
            canvas.width = containerWidth
            canvas.height = containerWidth

            drawCanvas()
        }
    }

    function resizeImage(image) {
        const maxImageWidth = 500
        let imageWidth = image.width
        let imageHeight = image.height

        if (image.width > maxImageWidth) {
            const scaleFactor = maxImageWidth / image.width
            imageWidth = image.width * scaleFactor
            imageHeight = image.height * scaleFactor
        }

        resizedImageCanvas.current = document.createElement('canvas')
        resizedImageCanvas.current.width = imageWidth
        resizedImageCanvas.current.height = imageHeight

        const context = resizedImageCanvas.current.getContext('2d')
        context.drawImage(
            image,
            0,
            0,
            image.width,
            image.height,
            0,
            0,
            imageWidth,
            imageHeight,
        )
    }

    function drawCanvas() {
        if (canvasRef.current && resizedImageCanvas.current) {
            const canvas = canvasRef.current
            const context = canvas.getContext('2d')
            const resizedCanvas = resizedImageCanvas.current

            const canvasWidth = canvas.width
            const canvasHeight = canvas.height

            const imageWidth = resizedCanvas.width * zoom
            const imageHeight = resizedCanvas.height * zoom
            const offsetX = (canvasWidth - imageWidth) / 2 + offset.x
            const offsetY = (canvasHeight - imageHeight) / 2 + offset.y

            context.clearRect(0, 0, canvasWidth, canvasHeight)

            context.globalAlpha = 0.7
            context.drawImage(
                resizedCanvas,
                0,
                0,
                resizedCanvas.width,
                resizedCanvas.height,
                offsetX,
                offsetY,
                imageWidth,
                imageHeight,
            )
            context.globalAlpha = 1.0

            const circleDiameter = canvasWidth * (2 / 3)
            const circleRadius = circleDiameter / 2
            const centerX = canvasWidth / 2
            const centerY = canvasHeight / 2

            context.beginPath()
            context.arc(centerX, centerY, circleRadius, 0, Math.PI * 2)
            context.strokeStyle = 'red'
            context.lineWidth = 4
            context.stroke()
        }
    }

    function handleDragStart(e) {
        e.preventDefault()
        const { clientX, clientY } = e.touches ? e.touches[0] : e
        isDragging.current = true
        dragStart.current = { x: clientX - offset.x, y: clientY - offset.y }
    }

    function handleDrag(e) {
        if (!isDragging.current) return
        e.preventDefault()
        const { clientX, clientY } = e.touches ? e.touches[0] : e
        const newOffset = {
            x: clientX - dragStart.current.x,
            y: clientY - dragStart.current.y,
        }
        setOffset(newOffset)
    }

    function handleDragEnd() {
        isDragging.current = false
    }

    function handleConfirm() {
        if (!resizedImageCanvas.current) return

        const canvas = canvasRef.current
        const resizedCanvas = resizedImageCanvas.current

        const canvasWidth = canvas.width
        const circleRadius = (canvasWidth * 2) / 6
        const centerX = canvasWidth / 2
        const centerY = canvasWidth / 2

        const squareSize = circleRadius * 2

        const offscreenCanvas = document.createElement('canvas')
        offscreenCanvas.width = squareSize
        offscreenCanvas.height = squareSize

        const offscreenContext = offscreenCanvas.getContext('2d')

        const imageWidth = resizedCanvas.width * zoom
        const imageHeight = resizedCanvas.height * zoom
        const offsetX = (canvasWidth - imageWidth) / 2 + offset.x
        const offsetY = (canvasWidth - imageHeight) / 2 + offset.y

        offscreenContext.drawImage(
            resizedCanvas,
            0,
            0,
            resizedCanvas.width,
            resizedCanvas.height,
            offsetX - centerX + circleRadius,
            offsetY - centerY + circleRadius,
            imageWidth,
            imageHeight,
        )

        const imageData = offscreenCanvas.toDataURL('image/jpg')
        if (onConfirm) onConfirm(imageData)
    }

    useEffect(
        function () {
            const image = new Image()
            image.crossOrigin = 'anonymous'

            image.onload = function () {
                resizeImage(image)
                initializeCanvas()
            }
            image.src = imageUrl

            function handleResize() {
                initializeCanvas()
            }

            window.addEventListener('resize', handleResize)

            return function () {
                window.removeEventListener('resize', handleResize)
            }
        },
        [imageUrl],
    )

    useEffect(
        function () {
            drawCanvas()
        },
        [zoom, offset],
    )

    return (
        <>
            <BlockStack gap="md">
                <div
                    ref={containerRef}
                    style={{
                        width: '100%',
                        height: '100%',
                        overflow: 'hidden',
                    }}
                    onMouseDown={handleDragStart}
                    onMouseMove={handleDrag}
                    onMouseUp={handleDragEnd}
                    onMouseLeave={handleDragEnd}
                    onTouchStart={handleDragStart}
                    onTouchMove={handleDrag}
                    onTouchEnd={handleDragEnd}
                >
                    <canvas
                        style={{
                            display: 'block',
                            width: '100%',
                            height: 'auto',
                        }}
                        ref={canvasRef}
                    />
                </div>
                <InlineStack gap="sm">
                    <Button
                        small
                        outline
                        type="button"
                        text="Zoom In"
                        onClick={() => setZoom((prev) => prev + 0.1)}
                    />
                    <Button
                        small
                        outline
                        type="button"
                        text={'Zoom Out'}
                        onClick={() =>
                            setZoom((prev) => Math.max(prev - 0.1, 1))
                        }
                    />
                </InlineStack>
                <Button
                    text="Save"
                    isLoading={saveLoading}
                    onClick={handleConfirm}
                />
            </BlockStack>
        </>
    )
}

function FileCropperModal({ onClose, url, onConfirm }) {
    const [uploadLoading, setUploadLoading] = useState(null)
    const setToast = useToast()

    async function onUpload(dataUrl) {
        setUploadLoading(true)

        const file = await fetch(dataUrl).then((res) => res.blob())

        if (!file.type.startsWith('image')) {
            return null
        }
        const { width, height } = await getImageDimensions(file)

        const name = `${Date.now()}-cropped.jpg`

        const { responseData: urlResponseData, error: urlError } =
            await fetchAPI(
                `/v1/files/list/me`,
                {
                    files: [
                        {
                            fileName: name,
                            width: width,
                            height: height,
                            mimeType: file.type,
                            size: file.size,
                        },
                    ],
                },
                'POST',
            )

        if (urlError) {
            setToast(urlError, 'alert')
            return
        }

        let finalUrl

        try {
            const dimensions = { width, height }
            const { uploadUrl, dbId } = urlResponseData[0]
            const uploadResponse = await fetch(uploadUrl, {
                method: 'PUT',
                headers: {
                    'Content-Type': file.type,
                    'x-goog-meta-filename': name,
                    'x-goog-meta-width': dimensions.width,
                    'x-goog-meta-height': dimensions.height,
                },
                body: file,
            })
            if (!uploadResponse?.ok) {
                throw new Error('File upload failed')
            }
            const { responseData, error } = await fetchAPI(
                `/v1/files/list/me/${dbId}/make-public`,
                {},
                'POST',
            )

            if (error) {
                throw new Error('File make public failed')
            }
            finalUrl = responseData.publicUrl
        } catch (error) {
            console.error(error)
            setToast(`File upload failed for ${file.name}`, 'alert')
        }

        setUploadLoading(false)
        setToast('Uploaded successfully')

        const backendFileItem = {
            // TODO: non-public etc.
            url: finalUrl,
            publicUrl: finalUrl,
            dbId: urlResponseData[0].dbId,
            id: finalUrl,
            height: height,
            width: width,
            mimeType: file.type,
            name: name,
        }

        onConfirm(backendFileItem)
    }

    function handleClose() {
        onClose()
    }

    return (
        <div className="modal file-cropper-modal">
            <div className={`modal-container show`}>
                <div className="modal-header">
                    <h4>Crop</h4>

                    <button className="modal-close-btn" onClick={handleClose}>
                        &#10005;
                    </button>
                </div>
                <div className="modal-content">
                    <ImageCanvas
                        saveLoading={uploadLoading}
                        onConfirm={onUpload}
                        imageUrl={url}
                    />
                </div>
            </div>
            <div className="modal-overlay"></div>
        </div>
    )
}

export default function useFileCropper(url) {
    const [cropperHtml, setCropperHtml] = useState(false)

    function openCropper({ isPublic, confirmLabel } = {}) {
        return new Promise(function (resolve) {
            const pickerHtml = (
                <FileCropperModal
                    url={url}
                    confirmLabel={confirmLabel}
                    isPublic={isPublic}
                    onClose={() => {
                        resolve()
                        setCropperHtml(null)
                    }}
                    onConfirm={(results) => {
                        resolve(results)
                        setCropperHtml(null)
                    }}
                />
            )

            setCropperHtml(pickerHtml)
        })
    }

    return {
        openCropper,
        cropperHtml,
    }
}
