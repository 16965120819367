import React from 'react'
import useAuth from '../../../common/hooks/useAuth'
import useData from '../../../common/hooks/useData'
import MessageSection from '../../../components/common/MessageSection'
import Button from '../../../components/common/Button'
import { formatDate } from '../../../common/helpers'
import { formatMoney } from '../../../common/helpers'
import useModal from '../../../common/hooks/useModal'
import ConfirmModalContent from '../../../components/common/ConfirmModalContent'
import fetchAPI from '../../../common/fetchAPI'
import useToast from '../../../common/hooks/useToast'
import { useNavigate } from 'react-router-dom'
import Spinner from '../../../components/common/Spinner'
import ErrorMessage from '../../../components/common/ErrorMessage'
import Tag from '../../../components/common/Tag'
import SectionContainer from '../../../components/common/SectionContainer'
import Break from '../../../components/common/Break'

function PremiumSubPage() {
    const auth = useAuth()

    const { sub, subError, subLoading } = useData(
        `/v1/app-billing/me/premium-subscription`,
        'sub',
        (data) => data?.subscription,
    )

    const { setModal, setModalLoading, isModalLoading } = useModal()
    const setToast = useToast()
    const navigate = useNavigate()

    async function cancelSub() {
        setModal(
            <ConfirmModalContent
                onConfirm={async () => {
                    if (isModalLoading) return
                    setModalLoading(true)

                    const { error } = await fetchAPI(
                        '/v1/app-billing/me/cancel-premium-subscription',
                        {},
                        'DELETE',
                    )

                    if (error) {
                        setModal(null)
                        setToast(error, 'alert')
                        return
                    }

                    setModal(null)
                    auth.refetchAuth()
                    setToast('Canceled Premium subscription')
                    navigate('/profile')
                }}
            />,
            'Are you sure you want to cancel your Premium subscription?',
        )
    }

    if (subLoading) {
        return <Spinner />
    }

    if (subError) {
        return (
            <SectionContainer>
                <ErrorMessage>{subError}</ErrorMessage>
            </SectionContainer>
        )
    }

    return (
        <>
            <SectionContainer>
                Current plan:{' '}
                {auth.isPremiumUser ? (
                    <strong className="text-gold">Premium</strong>
                ) : (
                    'Free'
                )}
            </SectionContainer>

            {!auth.isPremiumUser ? (
                <SectionContainer>
                    <MessageSection type="info">
                        Upgrade to Premium
                        <Break />
                        <Button
                            small
                            outline
                            white
                            href={`/profile/billing/purchase-premium`}
                            text="Upgrade now"
                        />
                    </MessageSection>
                </SectionContainer>
            ) : null}

            {sub?.status === 'incomplete' && (
                <SectionContainer>
                    <MessageSection type="warning">
                        You have not completed payment for your Premium
                        subscription.
                        <Break />
                        <Button
                            small
                            outline
                            white
                            href={`/profile`}
                            text="Pay now"
                        />
                    </MessageSection>
                </SectionContainer>
            )}
            {sub ? (
                <SectionContainer>
                    <strong>Subscription status&nbsp;</strong>
                    {sub.status === 'active' && (
                        <Tag color={'success'}>Active</Tag>
                    )}
                    {sub.status === 'trialing' && (
                        <Tag color={'success'}>Trial</Tag>
                    )}
                    {sub.status === 'unpaid' && (
                        <Tag color={'alert'}>Unpaid</Tag>
                    )}
                    {sub.status === 'past_due' && (
                        <Tag color={'alert'}>Past due</Tag>
                    )}
                    {sub.status === 'incomplete' && (
                        <Tag color={'warning'}>Incomplete</Tag>
                    )}
                    <br />
                    {sub.startedAt ? (
                        <>
                            <strong>Started&nbsp;</strong>
                            {formatDate(Math.floor(sub.startedAt / 1000))}
                        </>
                    ) : null}
                    <br />
                    {sub.currPeriodStartedAt && sub.currPeriodEndsAt ? (
                        <>
                            <strong>Current period&nbsp;</strong>
                            {formatDate(
                                Math.floor(sub.currPeriodStartedAt / 1000),
                            )}{' '}
                            -{' '}
                            {formatDate(
                                Math.floor(sub.currPeriodEndsAt / 1000),
                            )}
                        </>
                    ) : null}
                    <br />
                    <>
                        <strong>Price&nbsp;</strong>
                        {formatMoney(sub.price, false, 'api')}
                    </>
                    {/* {Object.entries(sub).map(([key, value]) => {
                    return (
                        <div key={key}>
                            <span>{key}</span>
                            <span>{value}</span>
                        </div>
                    )
                })} */}
                </SectionContainer>
            ) : null}
            {['active', 'trialing', 'past_due'].includes(sub?.status) ? (
                <SectionContainer>
                    <Button
                        small
                        outline
                        alignRight
                        destructive
                        text="Cancel"
                        onClick={cancelSub}
                    />
                </SectionContainer>
            ) : null}
        </>
    )
}

export default PremiumSubPage
